import React from 'react'
import 'date-fns';
import { Container, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle'
import BookingBtn from '../BookingCommon/BookingBtn';
import CourseInfo from './CourseInfo';
import CourseProgress from '../BookingCommon/CourseProgress';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import loginService from '../../../services/loginService';

const UNIQUE = gql`
query CheckUniqueness($end_day: date = "", $course_code: String = "", $start_day: date = "",$course_name: String = "") {
    Course(where: {start_day: {_eq: $start_day}, course_code: {_eq: $course_code}, end_day: {_eq: $end_day},course_name: {_eq: $course_name}}) {
      end_day
      start_day
      id
      course_name
    }
  }
`

const ADD_SESSION = gql`
mutation AddSessions($sessions: [CourseSession_insert_input!]!) {
    insert_CourseSession(objects: $sessions) {
      returning {
        id
        created_at
      }
    }
  }
`;
const ADD_COURSE = gql`
mutation AddCourses($courses: [Course_insert_input!]!) {
    insert_Course(objects: $courses) {
      returning {
        id
        created_at
      }
    }
  }
`;

export default function CourseReview(props) {
    const { post } = loginService()
    const data = props.course.dataCourse()

    const [crId, setCrId] = React.useState(data.Course1?.id)
    const [sessions, setSessions] = React.useState([])
    const [courses, setCourses] = React.useState([])
    const [uniqueId, setUniqueId] = React.useState(true)

    const orgIds = props.user.duorg_id ?? null;
    const orgTz = orgIds.find(org => org.organisation_id === data.selectedOrg)

    const timezone = orgTz?.Organisation?.tz ?? null


    const [CU_CALL, { un_error, un_data }] = useLazyQuery(UNIQUE, {
        onError: errors => {
            setLoadState(false);
            setMessage(errors.message)
        },
        onCompleted: data => {
            if (data.Course.length === 0)
                uniqueTest(true)
            else {
                uniqueTest(false)
                setUniqueId(false)
            }
        }
    }
    );

    const [AddCourses, { course, loading, error }] = useMutation(ADD_COURSE, {
        onCompleted: (data2) => {
            data2.insert_Course.returning.forEach((element, index) => {
                //console.log(element, 'AddCourses element')
                createTSCourse(element, index)
            });
            // if(!error){
            // }
        }, onError: (err) => {
            setLoadState(false);
            setMessage(err.message);
        }
    });

    const [AddSessions, { session, as_loading, as_error }] = useMutation(ADD_SESSION, {
        onCompleted: (data3) => {
            let element = data3.insert_CourseSession.returning
            //console.log(element,'sessions element')
            createTSCourse(element)
            // if(!error){
            // }
        }, onError: (err) => {
            setLoadState(false);
            setMessage(err.message);
        }
    });

    async function createTSCourse(element, index) {
        let courseData = props.course.dataCourse()
        let params
        if (!courseData['Course' + (index + 1)]?.services?.live_captions && !courseData['Course' + (index + 1)]?.services?.summary_notes) {
            let userOrgs = props.user.duorg_id
            let selectedOrg = courseData.selectedOrg
            let services = userOrgs.filter(x => x.organisation_id === selectedOrg)[0].services
            if (services && services.length != 0) {
                params = "create_ts_booking?sms_course_id=" + (uniqueId === true ? element.id : crId) + "&sms_booker_id=" + (props.user.aorg_id) + "&team_id=" + services[0].team.id + "&service_type=" + services[0].team.teams_service_types[0].service_type_name
            }
            else {
                params = "create_ts_booking?sms_course_id=" + (uniqueId === true ? element.id : crId) + "&sms_booker_id=" + (props.user.aorg_id)
            }
        }
        else {
            params = "create_ts_booking?sms_course_id=" + (uniqueId === true ? element.id : crId) + "&sms_booker_id=" + (props.user.aorg_id)
        }
        const { data } = await post(params, null, 3);
        if (data?.success) {
            setRedirect(`./BookingComplete`);
        } else if (data?.errorMessage) {
            setMessage(data.errorMessage)
            setLoadState(false);
        }
        /*Just for now, api has empty params*/
        setRedirect(`./BookingComplete`);
    }

    const [message, setMessage] = React.useState(null)
    const [loadState, setLoadState] = React.useState(false)
    if (loading) 'Submitting...';
    if (error)`Submission error! ${error}`;

    const history = useHistory()
    let { path, url } = useRouteMatch();
    // const data = JSON.parse(localStorage.getItem('bookingData'))

    const [values, setValues] = React.useState(data);

    // const [redirect, setRedirect] = React.useState(null)
    const handleUpdate = (e) => {
        setLoadState(true);
        // console.log('CourseReview: ', values);
        // localStorage.setItem('bookingData',JSON.stringify(values));       
        let $cources = []
        for (let i = 1; i <= data.courseQuantity; i++) {
            let cour = data['Course' + i];
            $cources[i - 1] = {
                course_code: cour.course_code,
                course_name: cour.course_name,
                start_day: moment((cour.instances?.events[0] ? cour.instances?.events[0].date : cour.schedule?.Start_date) ?? cour.schedule?.Start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                end_day: moment((cour.instances?.events[cour.instances?.events.length - 1] ? cour.instances?.events[cour.instances?.events.length - 1].date : cour.schedule?.End_date) ?? cour.schedule?.End_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                subject_ids: cour.subject?.id ?? cour.subject_names?.id,
                subject_names: cour.subject_names?.category ?? '',
                platform: cour.credentials?.platform,
                platform_id: cour.credentials?.platform,
                platform_location: cour.credentials?.platform == 'In person' ? cour.credentials?.location : 'Online',
                platform_password: cour.credentials?.password,
                platform_url: cour.credentials?.url,
                platform_username: cour.credentials?.username,
                organisation_id: data.selectedOrg ? data.selectedOrg : props.user.dorg_id ?? props.user.duorg_id,
                CourseSessions: {
                    data: cour.instances.events.map(w => {
                        return {
                            end_time: moment(w.date + ' ' + w.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
                            start_time: moment(w.date + ' ' + w.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
                            tz: timezone,
                            live_captioning: cour.services?.live_captions || false,
                            live_note_taking: cour.services?.summary_notes || cour.services?.computerized_note_taking || false
                        }
                    })
                },
                CourseStudents: {
                    data: [{
                        student_id: data.id
                    }]
                },
            }
            /* Inspector is a user.. need to create one?
             Error: field "email" not found in type: 'User_obj_rel_insert_input' */
            if (cour.instructor_id && cour.instructor_id != '') {
                $cources[i - 1].CourseInstructors = {
                    data: {
                        instructor_id: cour.instructor_id
                    }
                }
            }
            else if (cour.instructor_user_id && cour.instructor_user_id != '') {
                $cources[i - 1].CourseInstructors = {
                    data: {
                        Instructor: {
                            data: {
                                user_id: cour.instructor_user_id
                            }
                        }
                    }
                }
            }
            else if (cour.instructor_email && cour.instructor_email != '') {
                //console.log(props.user.dorg_id??props.user.duorg_id??$cources[0].organisation_id,'what')
                $cources[i - 1].CourseInstructors = {
                    data: {
                        Instructor: {
                            data: {
                                User: {
                                    data: {
                                        role_id: '70c5272c-22f2-4744-9421-bca96f4335f1',
                                        first_name: cour.instructor_name?.split(' ')[0] ?? 'no name',
                                        last_name: cour.instructor_name?.split(' ')[1] ?? 'no surname',
                                        organisation_id: $cources[0].organisation_id ?? props.user.dorg_id ?? props.user.duorg_id,
                                        email: cour.instructor_email
                                    },
                                    on_conflict: {
                                        constraint: "User_email_key", update_columns: ["updated_at"],
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        let $sessions = []
        for (let i = 1; i <= data.courseQuantity; i++) {
            let session = data['Course' + i];
            $sessions[i - 1] = {
                data: session.instances.events.map((w) => {
                    return {
                        end_time: moment(w.date + ' ' + w.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
                        start_time: moment(w.date + ' ' + w.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
                        tz: timezone,
                        live_captioning: session.services?.live_captions || false,
                        live_note_taking: session.services?.summary_notes || false,
                        course_id: data.Course1.id
                    }
                })
            }
        }

        setSessions($sessions[0]?.data)
        setCourses($cources)
        CU_CALL({
            variables: {
                course_code: data.Course1?.course_code,
                start_day: moment(data.Course1?.schedule?.repeat === data.Course1?.instances?.events[0]?.date ? null : data.Course1?.schedule?.Start_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                end_day: moment(data.Course1?.schedule?.repeat === data.Course1?.instances?.events[0]?.date ? null : data.Course1?.schedule?.End_date, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                course_name: data.Course1?.course_name
            }
        });

    }
    function uniqueTest(result) {
        if (result === false) {
            AddSessions({
                variables: {
                    sessions: sessions
                }
            })
        }
        else {
            AddCourses({
                variables: {
                    courses: courses
                }
            })
        }
    }
    function setRedirect(redirect) {
        history.push(redirect)
        //    return <Redirect to={redirect}/>
    }
    /* add to blocks */
    const handleSelect = (prop) => (e) => {
        props.course.selectCurrent(prop)
        setRedirect('./CourseDetails')
    }
    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={4}>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PageTitle mb={2.5} color='accent' text='Review' />
                            <Box mb={3.125}>
                                <Typography variant="body1" component="p">*Here are all the details we have gathered for your super accessible bookings!</Typography>
                            </Box>
                            {[...Array(values.courseQuantity)].map((e, index) =>
                                <CourseInfo key={('Course' + (index + 1)).toString()} num={index + 1} courseData={data['Course' + (index + 1)]} />)}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CourseProgress course={props.course} onHandleSelect={handleSelect} />
                        </Grid>
                    </Grid>
                    <Grid justifyContent='center' container>
                        <Grid item xs={12} md={4}>
                            <Box align="center" mt={4} mb={4}>
                                <BookingBtn loading={loadState} textLoading='Please wait' onHandleUpdate={handleUpdate} text="Proceed" />
                            </Box>
                            <Box mt={2}>
                                <Typography align="center" mt={2} color="error">
                                    {message}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
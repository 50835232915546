import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FilledInput, FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { useHistory  } from 'react-router-dom';
import loginService from '../services/loginService'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    container:{
      margin:'auto',
    },
    margin:{
      marginBottom: theme.spacing(4),
    },
    loginbox:{
      padding:theme.spacing(4.75,3,5.75),
      boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.2);'
    },
    subheader:{
      fontSize:'34px',
      lineHeight:'36px',
      color:'#333333',
      fontFamily:theme.typography.ppWoodland,
      marginBottom:theme.spacing(2),
    },
    intro:{
      color:'#888',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      marginBottom:theme.spacing(3),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
    },
    submit: {
      fontSize:'14px',
      lineHeight:'16px',
      fontWeight:'500',
      letterSpacing:'1.25px',
      padding:theme.spacing(1.25),
      background:'#ffffff',
      color:theme.palette.text.emphasis1,
      borderRadius:'5rem',
      minWidth:'161px',
      '&:hover': {
        background:'#ffffff',
        color:theme.palette.text.emphasis1,
      },
    },
    message:{
      margin:theme.spacing(1),
      color:theme.palette.error.main,
      fontWeight:500,
  },
  }));
  

export default function ResetPassword() {

  const{post} = loginService()
  const [message, setMessage] = React.useState('');
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [invalidForm, setDisabled] = useState(true);
  let {location} = useHistory();

  let history = useHistory();

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

   async function handleSubmit(event){
    event.preventDefault();
      if(password!=password2){
        setMessage("Passwords don't match!");
        return;
      }
      setMessage('');
      const { data } = await post("reset-password",{
           email:location.state.email,
           password:password,
           password_confirmation:password2,
           token:location.state.token
      });
      
      setMessage(data.message);
      
      if(data.errors){
        if(data.errors['password'])
          setMessage(data.message+' '+data.errors['password'].join(' '));
        if(data.errors['email'])
          setMessage(data.message+' '+data.errors['email'].join(' '));
      }
      else{
        history.push('/')
      }
    }

    const classes = useStyles();
    return (
     <Container className={classes.container} component="main" maxWidth="xs">
     <Box className={classes.loginbox} bgcolor="#fbfbfa" borderRadius={24}>
        <div className={classes.paper}>
          <Box display="flex" flexGrow={1} width="100%">
            <Typography component="h1" variant="subtitle2" className={classes.subheader}>
            Enter a new password
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.intro} component="p">
              Please use at least one Capital letter, one number, and one special character (*&_^-!)
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="password">New password</InputLabel>
            <FilledInput
              variant="filled"
              required
              disableUnderline
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              label="New password"
              name="password"
              onChange={e=>setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton 
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="password2">Repeat password</InputLabel>
            <FilledInput
              variant="filled"
              required
              disableUnderline
              id="password2"
              type={values.showPassword ? 'text' : 'password'}
              label="Repeat password"
              name="password2"
              onChange={e=>setPassword2(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton 
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              />
            </FormControl>
            <Typography align="center" className={classes.message}>
              {message}
            </Typography>
            <Box mb={2} textAlign="center">
              <Button
                  type="submit"
                  variant="contained"
                  align="center"
                  color="primary"
                  className={classes.submit}
                >
                  Submit
              </Button>
              </Box>
              <Box mt={1.375} mb={4.25} textAlign="center">                
                <Link href="#" onClick={() => { history.goBack(); }} className={classes.remind}>
                      Go back           
                </Link>
              </Box>
          </form>
         </div>
       </Box>
      </Container>
    );
  }
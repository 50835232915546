import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useEffect } from 'react';
import SessionRow from './SessionRow';


const useStyles = makeStyles((theme) => ({
    table:{
        border:'unset',
        background:'unset',
        boxShadow:'unset',
    },
    tableCellHead:{
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        fontFamily:theme.typography.averta,
        letterSpacing:'0.15px',
        fontWeight:'bold',
    },
}));
export default function SessionsTable(props) {
  //console.log(props,'session table')
  const classes = useStyles();

  const [values, setValues] = React.useState(props.rows || [])

  const updateValues =  (event,prop) =>{
    values[prop] =event ;
    setValues(values)
  }
  
  useEffect(()=>{
      props.onValueHandleUpdate(values);
  },[values])

  useEffect(()=>{
    setValues(props.rows)
  },[props.time])


  return (
    <TableContainer className={classes.table} component={Paper}>
      {/* <div>{props.rows[0]?.date ?? 'no data'}</div> */}
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHead}>#</TableCell>
            <TableCell className={classes.tableCellHead} align="left">Date</TableCell>
            <TableCell className={classes.tableCellHead} align="left">Start</TableCell>
            <TableCell className={classes.tableCellHead} align="left">End</TableCell>
            <TableCell className={classes.tableCellHead} align="right">Override</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row,index) => 
          <SessionRow time={props.time} key={row.session} rowID={index} rowNum={index+1} row={row} onUpdateValues={updateValues} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import ProgressBar from '../../Common/ProgressBar';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) =>({
    card: {
      background:theme.palette.background.default,
      boxSizing:'border-box',
      padding:theme.spacing(2,3,2.5),
      borderRadius: 24,
      border: (props) =>
        props.active === 'true'
          ? '2px solid #5B8466'
          : '1px solid rgba(0, 0, 0, 0.25)',
      boxShadow: (props) =>
        props.active === 'true'
        ? theme.shadows[10]
        : 'unset',
      marginBottom:theme.spacing(2.25),
    },
    title:{
        fontSize:'1.25rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        color:theme.palette.text.dark.body,
        minHeight:32,
    },
    code:{
        marginBottom:theme.spacing(1),
        fontSize:'0.875rem',
        lineHeight:'1rem',
        letterSpacing:'1.25px',
        fontWeight:500,
        color:theme.palette.text.dark.mediumEmphasis,
        textTransform:'uppercase',
    },
    list:{
        paddingLeft:'1.5rem',
        fontFamily:theme.typography.manrope,
        fontWeight:'400',
        fontSize:'1rem',
        lineHeight:'1.375rem',
        letterSpacing:'0.5px',
        color:theme.palette.text.dark.body,
    },
    button:{
        padding:'unset',
        transition:'0.35s ease-out',
        '&.active':{
            transform:'scaleY(-1)',
        },
    },
  }));


  function CustomCard(props) {
    const { color, ...other } = props;
    const classes = useStyles(props);
    return <Card className={classes.card} {...other} ></Card>;
  }
  
  CustomCard.propTypes = {
    active: PropTypes.oneOf(['true', 'false']).isRequired,
  };

export default function CourseTile(props){

    const [checked, setChecked] = React.useState(false);
    /* INF - easy true/false state set */ 
    const handleClick = (e) =>{
        setChecked((prev) => !prev);
    }

    const classes = useStyles();
    return (
        <React.Fragment>
            <CustomCard active={props.active}>
                <Typography className={classes.code}>
                    {props.code}
                </Typography>
                <Typography className={classes.title}>
                    {props.title}
                </Typography>
                <ProgressBar min="0" max="5" value="2"/>
                <Collapse in={checked}>
                    <ul className={classes.list}>
                        <li>Courses</li>
                        <li>Course details</li>
                        <li>Schedule</li>
                        <li>Credentials</li>
                        <li>Instances</li>
                    </ul>
                </Collapse>
                <Typography align="center">
                    <IconButton className={clsx(classes.button, checked ? 'active' : null)} disableRipple onClick={handleClick} aria-label="expand list">
                     <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.316,1L10.316,9L19.316,1" stroke="#3C3C3C" strokeWidth="2"/>
                    </svg>
                    </IconButton>
                </Typography>
            </CustomCard>
        </React.Fragment>
    )
}
import React from 'react';
import { useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

export default function Toast(props) {

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (props.alert.message === null || props.alert.severity === null) {
            return;
        }
        else {
            setOpen(true)
        }
    }, [props.alert]);

    return (
        <React.Fragment>
            <Snackbar style={{ maxWidth: 600 }} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.alert.severity}>
                    {props.alert.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
function Alert(props) {
    return <MuiAlert elevation={4} variant="filled" {...props} />;
}
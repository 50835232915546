import React from 'react';
import { useQuery, gql } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import CourseEntry from './CourseSearchEntry'

const getCourseList = gql`
query courseListQuery($organisation_id: uuid) {
  Course(distinct_on: course_name, where: {organisation_id: {_eq: $organisation_id}}, order_by: {course_name: asc}) {
        course_code
        course_name
        id
        platform
        platform_id
        platform_location
        platform_password
        platform_url
        platform_username
        subject_names
        start_day
        end_day
        CourseSessions {
          start_time
          end_time
          live_captioning
          live_note_taking
        }
        CourseInstructors {
          Instructor {
            User {
              first_name
              last_name
              email
            }
            id
            user_id
          }
        }
    }
}`;



export default function CourseListQuery(props){
  
    const orgId = props.user.dorg_id??props.user.duorg_id

    const queryId = props.courseData?.selectedOrg ? props.courseData.selectedOrg : orgId

    const { loading, error, data } = useQuery(getCourseList,{variables:{
      organisation_id: queryId
    }}); 
    if (loading) { 
      return <CircularProgress/>; 
    } 
    if (error) { 
      console.error(error); 
      return <div>Error!</div>; 
    } 
    if (props.SearchValue != ''){
      const SearchResults = data.Course.filter(course => {
        return(
          course.course_name.toLowerCase().includes(props.SearchValue.toLowerCase().trim())
        )},
      )
      if (SearchResults !=""){
        if(loading){
          return <CircularProgress/>; 
        }
        return(
          SearchResults.map(({course_name, course_code,id,platform_id,platform_location,platform_password,platform_url,platform_username,CourseInstructors,platform,subject_names,start_day,end_day,CourseSessions}) =>(
            <CourseEntry onHandleCourseSelect={props.onHandleCourseSelect} course_info={[platform_id,platform_location,platform_password,platform_url,platform_username,CourseInstructors,platform,subject_names,start_day,end_day,CourseSessions]} course_code={course_code} id={id} key={id} course_name={course_name}/>
          ))
        )
      }
      else{
        return(
          <div>No courses found</div>
        )
      }

    }
    return data.Course.map(({course_name, course_code,id,platform_id,platform_location,platform_password,platform_url,platform_username,CourseInstructors,platform,subject_names,start_day,end_day,CourseSessions}) =>(
      <CourseEntry onHandleCourseSelect={props.onHandleCourseSelect} course_info={[platform_id,platform_location,platform_password,platform_url,platform_username,CourseInstructors,platform,subject_names,start_day,end_day,CourseSessions]} course_code={course_code} id={id} key={id} course_name={course_name}/>
    )); 
  };
  

  export {getCourseList};
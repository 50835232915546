import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { CircularProgress } from '@material-ui/core';



const getBookings = gql`
query BookingInfo($ids: [uuid!] = "") {
  CourseStudent(where: {Student: {User: {_or:[{deleted: {_neq: true}},{deleted: {_is_null: true}}], organisation_id: {_in: $ids}}}, Course: {_or: [{archived: {_eq: false}},{archived: {_is_null:true}}]}}) {
    Course {
      course_code
      course_name
      id
      CourseSessions(order_by: {start_time: asc}) {
        id
        live_captioning
        live_note_taking
        subject_names
        course_id
        start_time
        end_time
        tz
      }
    }
    Student {
      id
      User {
        first_name
        last_name
        id
        UserOrganisations {
          id
        }
      }
    }
  }
}`;

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-body': {
      wordWrap: 'anywhere',
      color: theme.palette.text.dark.body,
    },
    '& .MuiTableCell-body:not(:first-child):not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
    }
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
    }
  },
  pagination: {
    borderBottom: 'unset',
    borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
  },
  search: {
    '& input': {
      padding: theme.spacing(1),
    },
  },
  divider:{
    margin:'0.5rem auto',
  }
}));

function convertLocalTime(tz,st,et){
  let time = st.split('T').at(-1)
  let endTime = et.split('T').at(-1)

  let baseDate = new Date(st)
  let date = baseDate.toLocaleString('en-US',{timeZone:tz.trim(),timeZoneName:'short'}).split(' ').at(0)
  let tzShort = baseDate.toLocaleString('en-US',{timeZone:tz.trim(),timeZoneName:'short'}).split(' ').at(-1)

  let baseEndDate = new Date(et)
  let endDate = baseEndDate.toLocaleString('en-US',{timeZone:tz.trim(),timeZoneName:'short'}).split(' ').at(0)
  let endTzShort = baseEndDate.toLocaleString('en-US',{timeZone:tz.trim(),timeZoneName:'short'}).split(' ').at(-1)
  
  let localTime = new Date(date.concat(' ',time,' ',tzShort))
  let endLocalTime = new Date(endDate.concat(' ',endTime,' ',endTzShort))
  let tzString = localTime.toLocaleTimeString('en-US',{timeStyle:'long'}).split(' ').at(-1)

  return tzString.concat(' - ',localTime.toLocaleString('en-US',{dateStyle:'long',timeStyle:'short'}),' - ',endLocalTime.toLocaleTimeString('en-US',{timeStyle:'short'}))
}


function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box display={'flex'} className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton> */}
      <Divider orientation='vertical' flexItem />
      <Box>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>

      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton> */}
    </Box>
  );
}

const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 201, 175, 0.1)',
  },
}))(TableRow);
const StyledCellHead = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.averta,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    borderLeft: 'solid 1px transparent',
    borderRight: 'solid 1px transparent',
    '&:not(:first-child):not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)'
    },
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(4n-3)': {
      background: 'rgba(251, 251, 250, 1)',
    },
    backgroundColor: '#FFFFFF',
  },
}))(TableRow);
const StyledRowHead = withStyles((theme) => ({
  body: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.1px',
  },
}))(TableCell);

// function testData(tz,data,sessions){
//   let baseStart = new Date(data.toLocaleString('en-US',{timeZone:'America/Toronto'}))
//   let localeStart = new Date(baseStart.toLocaleString('en-US',{timeZone:'Europe/London'}))
//   console.log(baseStart,localeStart,'Times')
//   let date = new Date(data).toLocaleString({timeZone:tz})
//   let date2 = date.toLocaleString({timeZone:'Europe/Warsaw'})
//   return date
// }

function createData(id, name, code, services_lc, services_lnt, bookings, sessions, date, session_id,student_id) {
  return {
    id,
    name,
    code,
    live_captioning: services_lc ? 'Live Captioning' : null,
    live_note_taking: services_lnt ? 'Live Note Taking' : null,
    bookings,
    sessions,
    date,
    session_id,
    student_id,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.code}</TableCell>
        <TableCell>{new Date(row.date).toLocaleString("en-US",{dateStyle:'full',timeStyle:'short'})}</TableCell>
        <TableCell>
          {!row.live_captioning && !row.live_note_taking
            ? <Typography>Captioning</Typography>
            : <Typography>
              {row.live_captioning}
              {row.live_captioning && row.live_note_taking ? ', ' : null}
              {row.live_note_taking}
            </Typography>
          }
        </TableCell>
        <TableCell>{row.bookings}</TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="student's bookings">
              <TableBody>
                {row.sessions.map((sessions, index) => (
                  <TableRow key={sessions.course_id + sessions.start_time}>
                    <TableCell width="5%" align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      {new Date(sessions.start_time).toLocaleDateString('en-US',{dateStyle:'full'}).concat(', ',new Date(sessions.start_time).toLocaleTimeString('en-US',{timeStyle:'short'}),' - ',new Date(sessions.end_time).toLocaleTimeString('en-US',{timeStyle:'short'}))}
                      <Divider style={{margin:'0.15rem auto'}} light />
                      Local time: 
                      {convertLocalTime(sessions.tz,sessions.start_time,sessions.end_time)}
                      {/* {testData(sessions.tz,sessions.start_time,sessions)} */}
                    </TableCell>
                    <TableCell align="right">
                      <Link component={RouterLink} color="secondary" to={props.path.concat('/edit/',row.student_id,'/',sessions.course_id,'/',sessions.id)}>
                        Click to view and edit
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Bookings(props) {
  const classes = useStyles1();
  let rows = []

  useEffect(() => {
    handleRequestSort(props.sortValue)
  }, [props.sortValue]);

  useEffect(() => {
    setPage(0);
  }, [props.searchValue]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === event && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(event);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.sort);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows, setEmptyRows] = React.useState(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage));

  useEffect(() => {
    setEmptyRows(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage))
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const orgArray = props.user.duorg_id.map(function (org) {
    return org.organisation_id
  });

  const { loading, error, data, refetch } = useQuery(getBookings, {
    variables: {
      ids: orgArray ?? props.user.duorg_id[0].id
    }
  });
  if (loading) {
    return (
      <TableContainer>
        <Table aria-label="Student table">
          <TableHead>
            <StyledTableHead>
              <StyledCellHead width="1%"></StyledCellHead>
              <StyledCellHead width="10%">Student Name</StyledCellHead>
              <StyledCellHead width="10%">Course Code</StyledCellHead>
              <StyledCellHead width="18%">Start date & time</StyledCellHead>
              <StyledCellHead width="18%">Services</StyledCellHead>
              <StyledCellHead width="10%"># of bookings</StyledCellHead>
            </StyledTableHead>
          </TableHead>
          <TableBody>
          <StyledTableRow style={{ height: 62 * 5 }}>
            <TableCell colSpan={12}>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress/>
                <Typography align="center">
                  Loading
                </Typography>
              </Box>
            </TableCell>
          </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  if (error) {
    console.error(error);
    return <div>Error!</div>;
  }
  else {
    data.CourseStudent.forEach(Course => {
      rows.push(createData(
        Course.Course.id,
        Course.Student.User.first_name.concat(' ',Course.Student.User.last_name),
        Course.Course.course_code,
        Course.Course.CourseSessions[0]?.live_captioning,
        Course.Course.CourseSessions[0]?.live_note_taking,
        Course.Course.CourseSessions.length,
        Course.Course.CourseSessions,
        Course.Course.CourseSessions[0]?.start_time,
        Course.Course.CourseSessions[0]?.end_time,
        Course.Student.id,
      ))
    })
  }
  if (props.searchValue !== '' && props.searchValue !== null) {
    const searchRes = rows.filter(row => {
      return (
        row.name.toLowerCase().includes(props.searchValue.toLowerCase().trim()) ||
        row.code.toLowerCase().includes(props.searchValue.toLowerCase().trim())
      )
    },
    )
    if (searchRes.length !== 0) {
      rows = searchRes;
    }
    else {
      rows = []
    }
  }
  else {
    rows = rows
  }
  return (
    <Box mb={props.mb}>
      <TableContainer>
        <Table aria-label="Student table">
          <TableHead>
            <StyledTableHead>
              <StyledCellHead width="1%"></StyledCellHead>
              <StyledCellHead width="10%">Student Name</StyledCellHead>
              <StyledCellHead width="10%">Course Code</StyledCellHead>
              <StyledCellHead width="18%">Start date & time</StyledCellHead>
              <StyledCellHead width="18%">Services</StyledCellHead>
              <StyledCellHead width="10%"># of bookings</StyledCellHead>
            </StyledTableHead>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <Row path={props.path} key={index + row.id} row={row} />
            ))}
            {rows.length === 0
              ?
              <React.Fragment>
                <StyledTableRow style={{ height: 62 * 5 }}>
                  <TableCell colSpan={12}><Typography align="center">No data</Typography></TableCell>
                </StyledTableRow>
              </React.Fragment>
              :
              <React.Fragment>
                {emptyRows > 0 && (
                  <StyledTableRow style={{ height: 62 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </React.Fragment>
            }
          </TableBody>
          <TableFooter>
            {rows.length != 0
              ?
              <TableRow>
                <TablePagination
                  className={classes.pagination}
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={12}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
              : null
            }
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

import React from 'react'
import { Divider} from '@material-ui/core';
import { Box } from '@material-ui/core';
import TitleLine from '../Common/TitleLine';
import { makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));


export default function AccountOrganizations(props){

    let {path,url} = useRouteMatch();
    const classes = useStyles();

    const handleUpdate = (e) =>{
        console.log(values);
    }
    return(
        <React.Fragment>
            <Box mb={3}>
                <TitleLine text='Your Organizations'/>
                <Divider/>
            </Box>
            <Box>
            {props.organisations !== undefined?
            <React.Fragment>
                {Object.values(props.organisations).map((row) => (
                    <React.Fragment key={row.Organisation.name+row.Organisation.department}>
                    <Box justifyContent="space-between" display="flex">
                        <Box p={1}>
                        {row.Organisation?.name+" - "+row.Organisation?.department??'Not provided'}
                        </Box>
                        <Box>
                            <Button color="primary" component={RouterLink} to={`${path}/organization/`+row.organisation_id}>
                            View details
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                        <Divider/>
                    </Box>        
                    </React.Fragment>        
                ))}
                </React.Fragment>
            : 'No organizations found'
            }
            </Box>
        </React.Fragment>
    );
};
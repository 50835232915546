import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import ProgressBar from '../../Common/ProgressBar';
import { IconButton } from '@material-ui/core';
import TitleLine from '../../Common/TitleLine';

const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.background.default,
        boxSizing: 'border-box',
        padding: theme.spacing(2, 3, 2.5),
        borderRadius: 24,
        border: (props) =>
            props.active === 'true'
                ? '2px solid' + theme.palette.accent.purple
                : '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: (props) =>
            props.active === 'true'
                ? theme.shadows[10]
                : 'unset',
        marginBottom: theme.spacing(2.25),
    },
    title: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        color: theme.palette.text.dark.body,
        minHeight: 32,
    },
    code: {
        marginBottom: theme.spacing(1),
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '1.25px',
        fontWeight: 500,
        color: theme.palette.text.dark.mediumEmphasis,
        textTransform: 'uppercase',
    },
    list: {
        paddingLeft: '1.5rem',
        fontFamily: theme.typography.manrope,
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.5px',
        color: theme.palette.text.dark.body,
    },
    button: {
        padding: 'unset',
        transition: '0.35s ease-out',
        '&.active': {
            transform: 'scaleY(-1)',
        },
    },
}));


function CustomCard(props) {
    const { color, ...other } = props;
    const classes = useStyles(props);
    return <Card className={classes.card} {...other} ></Card>;
}

CustomCard.propTypes = {
    active: PropTypes.oneOf(['true', 'false']).isRequired,
};

export default function CourseProgress(props) {

    const [checked, setChecked] = React.useState(false);
    const data = props.course.dataCourse()
    /* INF - easy true/false state set */
    const handleClick = (e) => {
        setChecked((prev) => !prev);
    }
    //    props.course.selectCurrent(e)
    const classes = useStyles();
    const selectedId = props.course.getSelected();
    return (
        null
        // <React.Fragment>
        //     <TitleLine mt={3.5} className={classes.titleLine} color="accent" text="Your progress" />
        //     {[...Array(data.courseQuantity)].map((e, index) =>

        //         <CustomCard onClick={props.onHandleSelect ? props.onHandleSelect(index + 1) : null} key={index} active={(selectedId == (index + 1)).toString()}>
        //             <Typography className={classes.code}>
        //                 {data['Course' + (index + 1)].course_code}
        //             </Typography>
        //             <Typography className={classes.title}>
        //                 {data['Course' + (index + 1)].course_name}
        //             </Typography>
        //             <ProgressBar min="0" max="5" value="2" />
        //             <Collapse in={checked}>
        //                 <ul className={classes.list}>
        //                     <li>Courses</li>
        //                     <li>Course details</li>
        //                     <li>Schedule</li>
        //                     <li>Credentials</li>
        //                     <li>Instances</li>
        //                 </ul>
        //             </Collapse>
        //             <Typography align="center">
        //                 <IconButton className={clsx(classes.button, checked ? 'active' : null)} disableRipple onClick={w => handleClick(index)} aria-label="expand list">
        //                     <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                         <path d="M1.316,1L10.316,9L19.316,1" stroke="#3C3C3C" strokeWidth="2" />
        //                     </svg>
        //                 </IconButton>
        //             </Typography>
        //         </CustomCard>
        //     )}

        // </React.Fragment>
    )
}
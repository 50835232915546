import React from 'react';
import { Container, Paper } from '@material-ui/core';
import PageTitle from '../Common/PageTitle';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import BoldHeading from '../Common/BoldHeading';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';
import Bookings from './Bookings';
import BookingFilters from './BookingsFilters';
import BulkDropzone from '../BulkUpload/bulkDropzone';

const useStyles = makeStyles((theme) => ({
    root:{
        paddingTop:theme.spacing(5),
        paddingBottom:theme.spacing(10),
        background:'#F3F9F6',
    },
    info:{
        background:theme.palette.background.white,
    },
    paper:{
        padding:theme.spacing(7),
        background:'#ffffff',
    },
    buttonWrapper:{
        gap:theme.spacing(3),
    },
    tabsHead:{
        background:'unset',
        boxShadow:'unset',
        '& .indicator':{
            color:'crimson',
            background:'crimson',
        }
    },
    tabs:{
        fontFamily:theme.typography.averta,
        color:theme.palette.text.dark.body,
        fontSize:'1.25rem',
        lineHeight:'1.5rem',
        minWidth:160,
        fontWeight:'bold',
        '&indicator':{
            color:'crimson',
            background:'crimson',
        },
    },
    input:{
        marginBottom:theme.spacing(3),
        '& input:read-only + fieldset':{
            border:'unset',
            outline:'unset',
        }
    },
    divider:{
        marginBottom:theme.spacing(7),
    },
    infoBox:{
        padding:theme.spacing(3,8),
    },
    header:{
        fontFamily: theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#818181',
        marginBottom:theme.spacing(1),
    },
    subheader:{
        fontFamily: theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.25rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#3C3C3C',
    },
    divider:{
        marginBottom:theme.spacing(2.75),
    },
    label:{
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        marginBottom:theme.spacing(1),
        textTransform:'uppercase',
        color:'#979797', //Primary 900?
        display:'inline-block',
    },
    formRow:{
        gap:theme.spacing(4.25),
    },
    tableElements:{
        gap:'1.5rem',
    },
}));

const StyledTabHead = withStyles((theme) => ({
    root: {
        backgroundColor: 'unset',
    },
}))(AppBar);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`student-tabpanel-${index}`}
        aria-labelledby={`student-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `student-profile-tab-${index}`,
      'aria-controls': `student-profile-tabpanel-${index}`,
    };
  }

export default function ViewBookings(props) {
    /* Modal */
    const [open, setOpen] = React.useState(false);
    const handleConfirm = (e) =>{
        console.log(e);
    }
    const handleOpen = (e) => {
      setOpen(true);
    };
  
    const handleClose = (e) => {
      setOpen(false);
    };

    /* Profile Info */
    const [editProfile, setEditProfile] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState('')
    const [sortValue, setSortValue] = React.useState(null)

    const handleSelectChange = (prop,e) =>{
        if(e != null){
            setValues({...values,[prop]:e.option})
        }else{
            setValues({...values,[prop]:''})
        }
    }
    
    /* Tabs */
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleSearch = (event) =>{
        setSearchValue(event)
    }
    const handleSort = (event) =>{
        setSortValue(event)
    }

    const classes = useStyles();

    return (
    <React.Fragment>
        <Container maxWidth="lg">
            <Box mt={8}>
                <Box mb={7}>
                    <PageTitle text="View your bookings"/>
                </Box>
            {/* TODO - Readd tabs when past bookings are available */}
            {/* <StyledTabHead className={classes.tabsHead} position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Student tabs">
                    <Tab className={classes.tabs} label="FUTURE" {...a11yProps(0)} />
                    <Tab disabled className={classes.tabs} label="PAST" {...a11yProps(1)} />
                </Tabs>
            </StyledTabHead> */}
            <TabPanel value={value} index={0}>
                <Box
                elevation={0}
                component={Paper}
                >
                    <BookingFilters onSort={handleSort} onSearch={handleSearch}/>
                    <Bookings path={props.path} sortValue={sortValue} searchValue={searchValue} user={props.user}/>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box
                boxShadow={0}
                className={classes.paper}
                >
                    <Box mb={3}>
                        <BoldHeading text="Booking List"/>
                        <Divider/>
                    </Box>
                </Box>
            </TabPanel>
            </Box>
        </Container>         
    </React.Fragment>
    );
  }
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import logo from '../img/habitat-logo.svg';
import { CircularProgress, FilledInput, FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import loginService from '../services/loginService'
import { gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import getService from '../services/getService';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  margin: {
    marginBottom: theme.spacing(4),
  },
  loginbox: {
    padding: theme.spacing(4.75, 3, 5.75),
    boxShadow: '0px 7px 26px rgba(0, 0, 0, 0.2);',
  },
  header: {
    fontFamily: theme.typography.averta,
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.primary,
    opacity: '0.3',
  },
  subheader: {
    fontSize: '34px',
    lineHeight: '36px',
    color: '#333333',
    fontFamily: theme.typography.ppWoodland,
  },
  intro: {
    color: '#888',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '500',
    letterSpacing: '1.25px',
    padding: theme.spacing(1.25),
    background: '#ffffff',
    color: theme.palette.text.emphasis1,
    borderRadius: '5rem',
    minWidth: '161px',
    '&:hover': {
      background: '#ffffff',
      color: theme.palette.text.emphasis1,
    },
  },
  remind: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: "#000000",
    textDecoration: 'underline',
  },
  message: {
    margin: theme.spacing(1),
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));



const randomThing = gql`
query MyQuery($userId: uuid = "") {
    UserOrganisation(where: {User: {id: {_eq: $userId}}}) {
      Organisation {
        name
      }
    }
  }
`

const GET_USER = gql`
  query GetUser($email: String = "", $role: uuid ="") {
    User(where: {email: {_eq: $email}}) {
      id
      UserOrganisations {
        id
        organisation_id
        Organisation {
          name
          department
          province
          street_address
          postal_code
          country
          country_code
          city
          acronym_name
          tz
          UserOrganisations(where: {User: {role_id: {_eq: $role}}}) {
            User {
              first_name
              last_name
              email
            }
          }
        }
      }
      OrganisationAdmins {
        id
      }
      Organisation {
        id
      }
    }
  }
  `;

const { post } = loginService()
const { get } = getService()

export default function LogIn({ setToken, setUser }) {

  const [loadState, setLoadState] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [tempToken, setTempToken] = React.useState(null);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loggedUser, setLoggedUser] = useState();

  async function getServiceType(org) {
    const { data } = await get("get_service_types_by_customer?customer_name=" + org.Organisation.name + "&customer_department=" + org.Organisation.department, null, 4)
    if (data?.success) {
      return { data }
    } else if (data?.errorMessage || data.error) {
      return { data }
    }
  }

  const [GetUserGraph, { loading, data2, error, called }] = useLazyQuery(GET_USER, {
    onCompleted: data2 => {
      let loggU = loggedUser;
      loggU.id = data2.User[0]?.id
      if (data2.User[0]?.OrganisationAdmins !== undefined) {
        loggU.aorg_id = data2.User[0]?.OrganisationAdmins[0]?.id
      }
      if (data2.User[0]?.UserOrganisations !== undefined) {
        loggU.uorg_id = data2.User[0]?.UserOrganisations[0]?.id
      }
      loggU.dorg_id = data2.User[0]?.Organisation !== null
        ? data2.User[0]?.Organisation[0]?.id
        : []
      loggU.duorg_id = data2.User[0]?.UserOrganisations !== null
        ? data2.User[0]?.UserOrganisations
        : []
      if (loggU.duorg_id) {
        let apiCalls = loggU.duorg_id.map(org => getServiceType(org).then(response => { return response }))
        Promise.all(apiCalls).then(data => {
          data.forEach((data, index) => {
            if (data.data.success == true) {
              loggU.duorg_id[index].services = data.data.customers_teams
            }
            else{
              loggU.duorg_id[index].services = null
            }
          })
          setUser(loggU)
          setToken(tempToken);
        })
      } else {
        setUser(loggU)
        setToken(tempToken)
      }
    }, onError: (err) => {
      console.log(err)
      setMessage(err.message);
      setLoadState(false);
    }, fetchPolicy: 'no-cache'
  });
  if (called || loading) {
    { <CircularProgress size={24} /> }
  }

  async function handleSubmit(event) {
    setLoadState(true);
    setMessage('');
    event.preventDefault();
    const { data } = await post("auth", {
      email: username,
      password: password
    });
    if (data?.user_token) {
      token = data?.user_token
      //GetRandomCheck({ variables: { userId: data.user_id } })
      GetUserGraph({ variables: { email: username, role: process.env.REACT_APP_ADMIN_ROLE_ID } }, { fetchPolicy: 'no-cache' })
      setTempToken(data?.user_token);
      setLoggedUser({ 'user_id': data.user_id, 'team_id': data.team_id, 'org_id': data.org_id })
      //history.push('/')
      /* Cant make it work every time, not only because of not existing user.. */
      // setUser({'user_id':data.user_id,'team_id':data.team_id,'org_id':data.org_id})
    }
    if (data.error) {
      setMessage(data.message);
      setLoadState(false);
    }
    if (error) {
      setMessage(error);
      setLoadState(false);
    }
  }
  var token = '';
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();
  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.loginbox} bgcolor="#fbfbfa" borderRadius={24}>
        <div className={classes.paper}>
          <Box mb={1.4} display="flex" flexGrow={1} width="100%">
            <img src={logo} width="55" height="56" alt="Habitat Logo" />
            <Box ml={2.1}>
              <Typography component="span" variant="subtitle1" className={classes.header}>
                Welcome to Habitat
              </Typography>
              <Typography component="h1" variant="subtitle2" className={classes.subheader}>
                Please login
              </Typography>
            </Box>
          </Box>
          <Box mb={1}>
            <Typography className={classes.intro} component="p">
              Use the same credentials you use with any other habitatlearn.com products or pages. 🙂
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl fullWidth className={classes.margin} variant="filled">
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <FilledInput
                variant="filled"
                type="email"
                required
                disableUnderline
                id="email"
                label="Email Address"
                onChange={e => setUserName(e.target.value)}
                name="email"
                autoComplete="email"
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
              <InputLabel htmlFor="password">Password</InputLabel>
              <FilledInput
                variant="filled"
                disableUnderline
                required
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box mt={1.375} mb={4.25} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                align="center"
                color="primary"
                className={classes.submit}
                disabled={loadState}
              >
                {loadState
                  ? <CircularProgress color="inherit" size={20} />
                  : 'Login'
                }
              </Button>
            </Box>
            <Typography align="center" className={classes.message}>
              {message}
            </Typography>
            <Box mb={1} textAlign="center">
              <Link href="/remindPassword" className={classes.remind}>
                Forgot password?
              </Link>
            </Box>
            {/* <Box textAlign="center">
                    <Link href="/createuser" className={classes.remind}>
                      Create an account
                    </Link>
              </Box> */}
          </form>
        </div>
      </Box>
    </Container>
  );
}




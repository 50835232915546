import { useState } from 'react';

const axios = require('axios').default;

export default function loginService() {

  const url1 = process.env.REACT_APP_API_ENDPOINT_1
  const url2 = process.env.REACT_APP_API_ENDPOINT_2
  const url3 = process.env.REACT_APP_API_ENDPOINT_3
  
 // const [data, setData] = useState();
  const postAoxis = function post(slug,params,urltype,config){
    let url
    switch (urltype){
      case 1:
        url = url1
        break;
      case 2:
        url = url2
        break;
      case 3:
        url = url3
        break;
      default:
        url = url1
    }
    
    try{
      return axios.post(url+slug,params,config).catch(error=>{
        if (error.response) {
          return {data:{error:true,message:error.response}};
        }
        return {data:{error:true,message:error.message}};
      });
    }
    catch(error) {
      return {data:{error:true,message:error.message}};
    };
  }
  return {
    post: postAoxis
  }
}
/*How to use?

import loginService from '../services/loginService'

const post  = loginService()

your function mush have async like: 
async function handleSubmit(event){...}

and inside async function call for url1:
const { data } = await post("url_ending",{
  param: 1,
  other_param:'value'
});

call for url2
const { data } = await post("url_ending",{
  param: 1,
  other_param:'value'
},2);

 (check pages/login.js)
*/
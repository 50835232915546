import React, { useEffect } from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import theme from '../../theme';

const ColSwitch = withStyles({
  switchBase: {
    color: theme.palette.background.onSurface,
    '&$checked': {
      color: theme.palette.background.paper,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.accent.purple,
      opacity:1,
    },
  },
  checked: false,
  track: {},
})(Switch);

export default function CustomSwitch(props) {
    const checkName = props.name
    const [state, setState] = React.useState(props.state);
    const handleChange = (event) => {
        setState(event.target.checked);
        props.onHandleSwitchChange(event.target.checked);
      };
    //    useEffect(() => { 
    //      props.onHandleSwitchChange(state);
    //  }, [state]);

    return (
        <ColSwitch
        checked={state}
        onChange={handleChange}
        name={checkName}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    );
  }
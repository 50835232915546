import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PillButton from '../Common/Buttons/PillButton';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    search:{
      '& input':{
        padding:theme.spacing(1),
      },
    }
  }));
  
  
export default function BookingFilters(props){

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (event) => {
      props.onSort(event.target.id)
      handleClose();
  };

  return (
        <Box display="flex" alignItems="center" justifyContent="end" mt={5} mb={4}>
          <Box mr={3}>
            <PillButton onHandleUpdate={handleClick} variant='accent' text="Sort By" />
          </Box>
          <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
          >
              <MenuItem id="name" onClick={handleSort}>Student Name</MenuItem>
              <MenuItem id="code" onClick={handleSort}>Course Code</MenuItem>
              <MenuItem id="bookings" onClick={handleSort}># of bookings</MenuItem>
          </Menu>
          <TextField
          className={classes.search}
          variant="outlined"
          placeholder="Search"
          onChange={(event) => props.onSearch(event.target.value)}
          InputProps={{
              endAdornment:(
                  <InputAdornment
                   position="end"
                   disablePointerEvents
                  >
                  <IconButton
                      aria-label="search"
                      edge="end"
                  >
                      <SearchIcon/>
                  </IconButton>
                  </InputAdornment>
              ),
          }}
          >
              Search
          </TextField>
        </Box>
  );
};

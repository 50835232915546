import React, {Suspense} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LogIn from './login'
import RemindPassword from './remindPassword'
import ResetPassword from './resetPassword'
import CreateUser from './createUser'
import ConfirmAccount from './ConfirmAccount';


const useStyles = makeStyles((theme) => ({
    margin:{
        marginTop:theme.spacing(6),
    },
}));

  export default function LoginPage({setToken,setUser}) {
    const classes = useStyles();

    return (
    /* REV - Fix link and redirects */
    <React.Fragment>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/" component={()=><LogIn setToken={setToken} setUser={setUser}/>} />
              <Route path="/remindpassword" component={RemindPassword}/>
              <Route path="/confirmaccount/:id" component={ConfirmAccount}/>
              {/* <Route path="/resetpassword" component={ResetPassword}/> */}
              {/* <Route path="/createuser" component={()=><CreateUser setToken={setToken} setUser={setUser}/>} /> */}
              <Redirect to="/"/>
            </Switch>
          </Suspense>
      </React.Fragment>
    );
  }
let Navigation = [
            {
                url: '/booking',
                name: 'Bookings',
            },
            {
                url: '/bulkUpload',
                name: 'Bulk Booking',
            },
            {
                url: '/bookingCreation',
                name: 'Create Bookings',
            },
            {
                url: '/account',
                name: 'Account',
            },
            {
                url: '/students',
                name: 'Students',
            },
        ]
export default Navigation;
import React, {useEffect} from 'react';
import loginService from '../services/loginService';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import PageTitle from '../components/Common/PageTitle';
import TitleLine from '../components/Common/TitleLine'
import PillButton from '../components/Common/Buttons/PillButton';
import TextField from '@material-ui/core/TextField';
import {useParams} from 'react-router-dom'
import { useLazyQuery,useMutation, gql } from '@apollo/client';
import { Route, Switch,useRouteMatch, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';



const adminRequest = gql`
query requestAdmin($id: uuid!) {
    OrganisationAdminRequest(where: {id: {_eq: $id}}) {
        admin_email
        admin_name
        admin_phone
        billing_contact_email
        billing_contact_name
        organisation_address
        organisation_department
        organisation_name
      }
}`;

const updateAdminRequest = gql`
mutation MyMutation($id: uuid = "", $organisation_name: String = "", $organisation_department: String = "", $organisation_address: String = "", $billing_contact_name: String = "", $billing_contact_email: String = "", $admin_phone: String = "", $admin_name: String = "", $admin_email: String = "", $organisation_tz : String = "") {
    update_OrganisationAdminRequest(where: {id: {_eq: $id}}, _set: {admin_email: $admin_email, admin_name: $admin_name, admin_phone: $admin_phone, billing_contact_email: $billing_contact_email, billing_contact_name: $billing_contact_name, organisation_address: $organisation_address, organisation_department: $organisation_department, organisation_name: $organisation_name, organisation_tz: $organisation_tz}) {
      returning {
        id
      }
    }
}`;

const useStyles = makeStyles((theme) => ({
    margin:{
        marginTop:theme.spacing(6),
    },
    input:{
        '&error':{
            background:'crimson',
        },
        '& input':{
            padding:theme.spacing(2.5,1.5),
            '&//:not(:focus):not(:placeholder-shown):invalid':{
                background:'crimson',
            },
        },
    },
    loginbox:{
        padding:theme.spacing(4.75,3,5.75),
        boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.2);'
      },
      subheader:{
        fontSize:'34px',
        lineHeight:'36px',
        color:'#333333',
        fontFamily:theme.typography.ppWoodland,
        marginBottom:theme.spacing(2),
      },
      intro:{
        color:'#888',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginBottom:theme.spacing(5.5),
      },
      paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      form: {
        width: '100%',
      },
      submit: {
        fontSize:'14px',
        lineHeight:'16px',
        fontWeight:'500',
        letterSpacing:'1.25px',
        padding:theme.spacing(1.25),
        background:'#ffffff',
        color:theme.palette.text.emphasis1,
        borderRadius:'5rem',
        minWidth:'161px',
        '&:hover': {
          background:'#ffffff',
          color:theme.palette.text.emphasis1,
        },
      },
}));

export default function ConfirmAccount() {

    const {post} = loginService()
    let {id} = useParams();
    let history = useHistory();
    let {path,url} = useRouteMatch();
    var response = null

    const [message, setMessage] = React.useState(null)
    const [loadState, setLoadState] = React.useState(false)
      
    const [values,setValues] = React.useState({
        organisation_name:'',
        organisation_department:'',
        organisation_address:'',
        admin_name:'',
        admin_email:'',
        admin_phone:'',
        billing_contact_name:'',
        billing_contact_email:'',
        tz:''
    })

    const handleChange = (event) => {
        setValues({...values,['tz']: event.target.value});
    };

    async function handleRequest(){
        const { data } = await post("create_organisation_admin?organisation_admin_request_id="+id,{
            address:  values.organisation_address,
            admin_email:  values.admin_email,
            admin_phone:  values.admin_phone,
            admin_name:  values.admin_name,
            billing_contact_email:  values.billing_contact_email,
            billing_contact_name:  values.billing_contact_name,
            campus_department:  values.organisation_department,
            institution:  values.organisation_name,
            organisation_tz:values.tz,
        },2);
        console.log(data)
        if(data.success){
            setLoadState(false)
            history.push(`${id}/success`);
        }
        if(data.error){
            setMessage('An error has occured')
            setLoadState(false)
        }
    }

    const [invalidId, setInvalidId] = React.useState(false)

    const [test,setTest] = React.useState({})
    
    const [
        requestAdmin, 
        { loading, data,error }
    ] = useLazyQuery(adminRequest,{
        onCompleted: data => {
            if(data?.OrganisationAdminRequest.length === 0){
                setInvalidId(true)
            }else{
                response = data?.OrganisationAdminRequest[0];
                response.tz = ''
                setValues(response);
            }
            if(error){
                setMessage('An error has occured')
            }
        },
        onError: data=>{
            setMessage('An error has occured')
        },
        fetchPolicy: 'no-cache'
    });


    const [
        queryUpdateAdminRequest, 
        { adm_loading, adm_data,adm_error }
    ] = useMutation(updateAdminRequest,{
        onCompleted: data => {
            console.log(data)
            if(adm_error){
                setMessage('An error has occured')
                setLoadState(false)
            }
            else{ 
                handleRequest();
            }
        },
        onError:data=>{
            setMessage('An error has occured')
            setLoadState(false)
        },
        fetchPolicy: 'no-cache'
    });
    
    useEffect(() => {
        if(id !== undefined){
            requestAdmin({ variables: { id:id }},{fetchPolicy: 'network-only'})
        }
    },[]);
    
    const classes = useStyles();
    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoadState(true)
        queryUpdateAdminRequest({ variables: {
            id: id,
            admin_email: values.admin_email,
            admin_name: values.admin_name,
            admin_phone:values.admin_phone,
            billing_contact_email:values.billing_contact_email,
            billing_contact_name:values.billing_contact_name,
            organisation_address:values.organisation_address,
            organisation_department:values.organisation_department,
            organisation_name:values.organisation_name,
            organisation_tz:values.tz,
        }})        
    }
    return(
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16} mb={8}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={8} lg={6}>
                            <Switch>
                            <Route exact path={path}>
                            {invalidId?
                            <Box textAlign='center' mb={5.625}>
                                <PageTitle text='Invalid request'/>
                                <TitleLine text='No request for id has been found, please contact site administrator'/>
                            </Box>
                            :
                            <React.Fragment>
                            <Box textAlign='start' mb={5.625}>
                                <PageTitle text='Confirm account'/>
                                <Typography
                                color='error'
                                >
                                    {message}
                                </Typography>
                            </Box>
                            <form id="verifyAccount" name="verifyAccount" className={classes.form} onSubmit={handleSubmit}>
                                <Box mb={2}>
                                    <TitleLine text='Institution'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="organisation_name"
                                    type='text'
                                    name="institution"
                                    required
                                    value={values?.organisation_name}
                                    onChange={e=>setValues({...values,'organisation_name':e.target.value})}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Campus/Department'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="organisation_department"
                                    type='text'
                                    name="Campus department"
                                    value={values?.organisation_department}
                                    onChange={e=>setValues({...values,'organisation_department':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Address'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="organisation_address"
                                    type='text'
                                    name="Adress"
                                    required
                                    value={values?.organisation_address}
                                    onChange={e=>setValues({...values,'organisation_address':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Admin name'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="admin_name"
                                    type='text'
                                    name="Admin name"
                                    required
                                    value={values?.admin_name}
                                    onChange={e=>setValues({...values,'admin_name':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Admin Email'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="admin_email"
                                    type='email'
                                    name="Admin email"
                                    required
                                    value={values?.admin_email}
                                    onChange={e=>setValues({...values,'admin_email':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Admin phone'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="admin_phone"
                                    type='text'
                                    name="Admin phone"
                                    required
                                    value={values?.admin_phone}
                                    onChange={e=>setValues({...values,'admin_phone':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Billing contact name'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="billing_contact_name"
                                    type='text'
                                    name="billing name"
                                    required
                                    value={values?.billing_contact_name}
                                    onChange={e=>setValues({...values,'billing_contact_name':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Billing contact email'/>
                                    <TextField
                                    fullWidth
                                    placeholder=""
                                    className={classes.input}
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    id="billing_contact_email"
                                    type='email'
                                    name="billing email"
                                    required
                                    value={values?.billing_contact_email}
                                    onChange={e=>setValues({...values,'billing_contact_email':e.target.value})}
                                    helperText=""
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TitleLine text='Timezone'/>
                                    <FormControl fullWidth variant="filled" className={classes.input}>
                                        <InputLabel id="tz">Select timezone</InputLabel>
                                        <Select
                                        required
                                        labelId="timezone-select"
                                        id="tz"
                                        value={values.tz ?? ''}
                                        onChange={handleChange}
                                        label="Timezone"
                                        autoWidth
                                        >
                                        <MenuItem disabled value={``}>
                                            <em>Please select timezone</em>
                                        </MenuItem>
                                        <MenuItem value={'EST'}>EST</MenuItem>
                                        <MenuItem value={'MST'}>MST</MenuItem>
                                        <MenuItem value={'PST'}>PST</MenuItem>
                                        <MenuItem value={'HST'}>HST</MenuItem>
                                        <MenuItem value={'GMT'}>GMT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <Typography
                                    color='error'
                                    >
                                        {message}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="end" mt={4}>
                                {loadState
                                ?<PillButton form="verifyAccount" type="submit" loadState disabled text="Please wait" noIcon variant="accent"/> 
                                :<PillButton form="verifyAccount" type="submit" text="Verify account" noIcon variant="accent"/> 
                                }
                                </Box>
                            </form>
                            </React.Fragment>
                            }
                            </Route>
                            <Route path={`${path}/success`}>
                            <Container className={classes.container} component="main" maxWidth="xs">
                            <Box className={classes.loginbox} bgcolor="#fbfbfa" borderRadius={24}>
                                <div className={classes.paper}>
                                    <Box display="flex" flexGrow={1} width="100%">
                                        <Typography component="h1" variant="subtitle2" className={classes.subheader}>
                                         🎉 Account created successfully!
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.intro} component="p">
                                        You can now proceed to login page
                                        </Typography>
                                    </Box>
                                    <Box mt={12.5} mb={6.25} textAlign="center">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            align="center"
                                            color="primary"
                                            onClick={()=>history.push('/')}
                                            className={classes.submit}
                                            >
                                            Login
                                        </Button>
                                    </Box>
                                    </div>
                                    </Box>
                                </Container>
                            </Route>
                            <Route component={() => (<div>404 Not found</div>)} />
                            </Switch>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
  }
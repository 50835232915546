import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const CHECK_STUDENT = gql`
query GetStudents($email: String = "") {
  User(where: {Students: {}, email: {_ilike: $email }}) {
    id
    first_name
    last_name
    email
  }
}  
`;

const useStyles = makeStyles((theme) => ({
  message: {
    margin: theme.spacing(1),
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));

export default function CheckStudent(props) {
  const classes = useStyles();
  const [message, setMessage] = React.useState('')
  const [email, setEmail] = React.useState(null)
  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

  const [checkStudent, { loading, data }] = useLazyQuery(CHECK_STUDENT, {
    onCompleted: data => {
      //console.log('CheckStudent :',data)
      if (data.User.length) {
        setMessage('User with following email already exists, please provide different email adress')
      }
      else {
        props.onConfirmStudent();
      }
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(
    () => {
      setTimeout(() => setMessage(''), 5000);
    },
    [message]
  );

  function ValidateStudent(props) {
    if (props.newStudent === true) {
      let isValid = true;
      if (props.values.email == '') {
        props.onInvalidFields('email')
        setMessage('*Some credentials are missing!')
        isValid = false;
      }
      if (props.values.last_name == '') {
        props.onInvalidFields('last_name')
        setMessage('*Some credentials are missing!')
        isValid = false;
      }
      if (props.values.first_name == '') {
        props.onInvalidFields('first_name')
        setMessage('*Some credentials are missing!')
        isValid = false;
      }
      if (props.values.email) {
        if (!validEmail.test(props.values.email)) {
          //console.log('invalid email!')
          props.onInvalidFields('email')
          isValid = false;
        }
      }
      if (isValid) {
        checkStudent({ variables: { email: ('%' + props.values.email.trim() + '%') } }, { fetchPolicy: 'network-only' })
      }
    }
    else {
      props.onConfirmStudent();
    }
  }

  return (
    null
    // <Box textAlign="center">
    //   <Button
    //     style={{ borderRadius: 50, padding: '8px 20px' }}
    //     variant="contained"
    //     color="primary"
    //     startIcon={<CheckIcon />}
    //     onClick={() => ValidateStudent(props)}
    //   >
    //     Done
    //     {loading && <CircularProgress size={24} />}
    //   </Button>
    //   <Typography className={classes.message}>
    //     {message}
    //   </Typography>
    // </Box>
  );
}
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom'
import { Button, Box, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import { Container } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle';
import { Divider } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import StylizedModal from '../../Common/UI/StylizedModal';
import BoldHeading from '../../Common/BoldHeading';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import StudentBookings from './StudentBookings';
import StudentFilters from './StudentFilters';
import { KeyboardReturn } from '@material-ui/icons';
import Toast from '../../Common/UI/Toast';

const updateUser = gql`
mutation MyMutation($user: User_set_input = {}, $student: Student_set_input = {}, $id: uuid = "") {
    update_Student(where: {User: {id: {_eq: $id}}}, _set: $student){
        affected_rows
        returning {
          id
        }
    }
    update_User(where: {id: {_eq: $id}}, _set: $user) {
      returning {
        id
      }
      affected_rows
    }
  }
`

const userRequest = gql`
query getUser($id: uuid = "") {
    User(where: {id: {_eq: $id}}) {
      email
      first_name
      last_name
      Students {
        id
        academic_email
        reference
        comment
        CourseStudents {
          course_id
          comment
          id
          Course {
            id
            course_crn
            course_code
            course_name
            start_day
            end_day
            CourseSessions {
              live_captioning
              live_note_taking
              start_time
              id
            }
            }
        }
      }
      Organisation {
        department
        name
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    formRow: {
        gap: theme.spacing(2.25),
    },
    info: {
        background: theme.palette.background.white,
    },
    paper: {
        padding: theme.spacing(7),
        background: '#ffffff',
    },
    buttonWrapper: {
        gap: theme.spacing(3),
    },
    tabsHead: {
        background: 'unset',
        boxShadow: 'unset',
        '& .indicator': {
            color: 'crimson',
            background: 'crimson',
        }
    },
    tabs: {
        fontFamily: theme.typography.averta,
        color: theme.palette.text.dark.body,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        minWidth: 160,
        fontWeight: 'bold',
        '&indicator': {
            color: 'crimson',
            background: 'crimson',
        },
    },
    input: {
        marginBottom: theme.spacing(3),
        '& *[readonly=""] + fieldset': {
            border: 'unset',
            outline: 'unset',
        }
    },
    divider: {
        marginBottom: theme.spacing(7),
    },
    infoBox: {
        padding: theme.spacing(3, 8),
    },
    header: {
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        color: '#818181',
        marginBottom: theme.spacing(1),
    },
    subheader: {
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        color: '#3C3C3C',
    },
    divider: {
        marginBottom: theme.spacing(2.75),
    },
    label: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        marginBottom: theme.spacing(1),
        textTransform: 'uppercase',
        color: '#979797', //Primary 900?
        display: 'inline-block',
    },
    formRow: {
        gap: theme.spacing(4.25),
    },
    tableElements: {
        gap: '1.5rem',
    },
    timeline: {
        '& .MuiTimelineItem-missingOppositeContent::before': {
            display: 'none',
        }
    },
    timelineIcon: {
        padding: theme.spacing(1.25),
    },
    timelineDate: {
        fontFamily: theme.typography.averta,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#474747',
        marginBottom: '0.5rem'
    },
    timelineTime: {
        fontFamily: theme.typography.averta,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#A3A3A3',
        textTransform: 'uppercase',
    },
}));

const StyledTabHead = withStyles((theme) => ({
    root: {
        backgroundColor: 'unset',
    },
}))(AppBar);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`student-tabpanel-${index}`}
            aria-labelledby={`student-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `student-overview-tab-${index}`,
        'aria-controls': `student-overview-tabpanel-${index}`,
    };
}

function errorMessage(error) {
    if (!error) {
        return 'An error occured'
    }
    let msg = error.toString()
    switch (msg) {
        case 'Error: Uniqueness violation. duplicate key value violates unique constraint "User_email_key"':
            return 'User with following email already exists, please provide different email';
        default:
            return 'A following error occured while updating, please contact administrator: ' + msg
    }
}

export default function EditBookings(props) {

    let orgs = props.user.duorg_id
    let history = useHistory();
    let { id } = useParams();
    let emailRegex = new RegExp('[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+')

    /* Modal */
    const [loader, setLoader] = React.useState(true);
    const [updateLoader, setUpdateLoader] = React.useState(false);
    const [loadError, setLoadError] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('')
    const [sortValue, setSortValue] = React.useState(null)


    const handleConfirm = (e) => {
        console.log(e);
    }
    const handleOpen = (e) => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const classes = useStyles();

    const [editValues, setEditValues] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [values, setValues] = React.useState({})
    const [courseData, setCourseData] = React.useState({})
    const [alert, setAlert] = React.useState({
        message: null,
        severity: null,
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectChange = (prop, e) => {
        if (e != null) {
            setValues({ ...values, [prop]: e.option })
        } else {
            setValues({ ...values, [prop]: '' })
        }
    }

    // Edit handlers
    const handleEditValues = (e) => {
        setInitialValues(values)
        setEditValues((prev) => !prev);
    }
    const handleCancelEdit = (e) => {
        setEditValues((prev) => !prev);
        setValues(initialValues)
        setAlert({
            message: 'Editing cancelled',
            severity: 'info',
        })
    }
    const handleUpdate = (e) => {
        setValues({ ...values, [e.target.id]: e.target.value })
    }

    /* Tabs */
    const [value, setValue] = React.useState(0);
    const [bookingSeries, setBookingSeries] = React.useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearch = (event) => {
        setSearchValue(event)
    }

    const handleSort = (event) => {
        setSortValue(event);
        handleClose();
    };

    const checkUpdates = () => {
        setUpdateLoader(true)
        if (values.first_name === '' || values.last_name === '' || values.academic_email === '') {
            setUpdateLoader(false)
            setAlert({
                message: 'Please fill in all required fields',
                severity: 'error',
            })
        }
        else if (!emailRegex.test(values.academic_email)) {
            setUpdateLoader(false)
            setAlert({
                message: 'Invalid email',
                severity: 'error',
            })
        }
        else {
            let user = {
                email:values.academic_email.toLowerCase().trim().replace(/[<>;]+/g, ''),
                first_name: values.first_name.trim(),
                last_name: values.last_name.trim(),
            }
            let student = {
                academic_email:values.academic_email.toLowerCase().trim().replace(/[<>;]+/g, ''),
                comment:values.notes,
                reference:values.student_id
            }
            updateUserData({
                variables: {
                    id:id,
                    user: user,
                    student:student,
                }
            })
        }
    }

    const [updateUserData] = useMutation(updateUser, {
        onCompleted: data => {
            setAlert({
                message: 'Profile updated successfully',
                severity: 'success',
            })
            setEditValues((prev) => !prev);
            setUpdateLoader(false)
        },
        onError: data => {
            setAlert({
                message: errorMessage(data),
                severity: 'error',
            })
            setUpdateLoader(false)
        }
    })

    const [
        requestBookingData,
        { loading, data, error }
    ] = useLazyQuery(userRequest, {
        onCompleted: data => {
            if (error) {
                setLoadError(true);
                return;
            }
            setValues({
                first_name: data.User[0].first_name ?? '',
                last_name: data.User[0].last_name ?? '',
                name: data.User[0].first_name.concat(' ', data.User[0].last_name) ?? '',
                email: data.User[0].email ?? '',
                academic_email: data.User[0].Students[0].academic_email ?? '',
                notes: data.User[0].Students[0].comment ?? '',
                student_id: data.User[0].Students[0].reference ?? '',
                institution: data.User[0].Organisation.name.concat(' - ', data.User[0].Organisation.department) ?? '',
                course_quantity: data.User[0].Students.flatMap(student => student.CourseStudents).length ?? 0,
                captioning: true,
                note_taking: true,
                studentId: data.User[0].Students[0].id ?? '',
            })
            setCourseData(data.User[0].Students.flatMap(student => student.CourseStudents))
            setLoader(false);
        },
        onError: data => {
            setLoadError(true);
            return;
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (id !== undefined) {
            requestBookingData({ variables: { id: id } }, { fetchPolicy: 'network-only' })
        }
    }, []);

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Box mt={8}>
                    <Box mb={7}>
                        {loader
                            ? null
                            : <React.Fragment>
                                <Link to={'/students'} children={<Button startIcon={<KeyboardReturn />}>Student List</Button>} component={RouterLink}/>
                                <PageTitle text={values.first_name + ' ' + values.last_name} />
                            </React.Fragment>
                        }
                    </Box>
                </Box>
                <Box
                    mb={5}
                    p={1}
                    elevation={0}
                    className={classes.info}
                    display="flex"
                    flexWrap="wrap"
                >
                    {loader
                        ?
                        <React.Fragment>
                            {loadError
                                ?
                                <Box width="100%" className={classes.infoBox} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                    <WarningRoundedIcon color="error" />
                                    <Typography variant="h6" color='error'> An error has occured!</Typography>
                                </Box>
                                :
                                <Box width="100%" className={classes.infoBox} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                    <Box mr={2}><CircularProgress /></Box>
                                    <Typography>Loading</Typography>
                                </Box>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Box flexGrow={1} flexShrink={0} width="33%" textAlign='center' className={classes.infoBox}>
                                <Typography className={classes.header}>
                                    Institution
                                </Typography>
                                <Typography className={classes.subheader}>
                                    {values.institution}
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box flexGrow={1} flexShrink={0} width="33%" className={classes.infoBox} textAlign='center'>
                                <Typography className={classes.header}>
                                    Services
                                </Typography>
                                <Typography className={classes.subheader}>
                                    {values.captioning ? 'Captioning' : null}
                                    {values.captioning && values.note_taking ? ', ' : null}
                                    {values.note_taking ? 'Notetaking' : null}
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box flexGrow={1} flexShrink={0} width="33%" textAlign='center' className={classes.infoBox}>
                                <Typography className={classes.header}>
                                    Number of bookings
                                </Typography>
                                <Typography className={classes.subheader}>
                                    {values.course_quantity}
                                </Typography >
                            </Box>
                        </React.Fragment>
                    }
                </Box>

                <StyledTabHead className={classes.tabsHead} position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="Student tabs">
                        <Tab className={classes.tabs} label="About" {...a11yProps(0)} />
                        <Tab className={classes.tabs} label="Bookings" {...a11yProps(1)} />
                    </Tabs>
                </StyledTabHead>
                <TabPanel value={value} index={0}>
                    {loader
                        ?
                        <Box
                            mb={7}
                            boxShadow={0}
                            className={classes.paper}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <React.Fragment>
                                {loadError
                                    ?
                                    <Box className={classes.paper} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                        <WarningRoundedIcon color="error" />
                                        <Typography variant="h6" color='error'> An error has occured!</Typography>
                                    </Box>
                                    :
                                    <React.Fragment>
                                        <Box mr={2}><CircularProgress /></Box>
                                        <Typography>Loading</Typography>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        </Box>
                        :
                        <React.Fragment>
                            <Box
                                mb={7}
                                boxShadow={0}
                                className={classes.paper}
                            >
                                <Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="start"
                                    >
                                        <BoldHeading text="Student profile" />
                                        {updateLoader
                                        ? 
                                            <CircularProgress size={28}/>
                                        :
                                        <React.Fragment>
                                            {editValues
                                                ?
                                                <Box>
                                                    <Button onClick={handleCancelEdit}>Cancel</Button>
                                                    <Button onClick={checkUpdates}>Save</Button>
                                                </Box>
                                                :
                                                <IconButton onClick={handleEditValues}>
                                                    <EditIcon />
                                                </IconButton>
                                            }
                                        </React.Fragment>
                                        }
                                    </Box>
                                    <Divider className={classes.divider} />
                                    {/* Course Details */}
                                    <Grid spacing={8} container>
                                        <Grid item container md={6}>
                                            <Box className={classes.formRow} display='flex'>
                                                <Grid item md={6}>
                                                    <label className={classes.label} htmlFor="first_name">First name</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Student first name'
                                                            variant="outlined"
                                                            id='first_name'
                                                            readOnly={!editValues}
                                                            disabled={updateLoader}
                                                            value={values.first_name ?? ''}
                                                            onChange={handleUpdate}
                                                            name='first_name'
                                                            placeholder="Students's first name"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <label className={classes.label} htmlFor="last_name">Last name</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Student last name'
                                                            variant="outlined"
                                                            id='name'
                                                            readOnly={!editValues}
                                                            disabled={updateLoader}
                                                            value={values.last_name ?? ''}
                                                            onChange={handleUpdate}
                                                            name='name'
                                                            placeholder="Students's last name"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Box>
                                            {/* <Grid item xs={12}>
                                                <label className={classes.label} htmlFor="name" >Name</label>
                                                <FormControl fullWidth variant="outlined">
                                                    <OutlinedInput
                                                        className={classes.input}
                                                        aria-labelledby='Student name'
                                                        variant="outlined"
                                                        id='name'
                                                        readOnly={!editValues}
                                                        value={values.name ?? 'Not provided'}
                                                        onChange={handleUpdate}
                                                        name='name'
                                                        placeholder="Students's name"
                                                    />
                                                </FormControl>
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <label className={classes.label} htmlFor="academic_email" >Academic email</label>
                                                <FormControl fullWidth variant="outlined">
                                                    <OutlinedInput
                                                        className={classes.input}
                                                        aria-labelledby='Academic email'
                                                        variant="outlined"
                                                        id='academic_email'
                                                        value={values.academic_email ?? 'Not provided'}
                                                        readOnly={!editValues}
                                                        disabled={updateLoader}
                                                        onChange={handleUpdate}
                                                        name='academic_email'
                                                        placeholder="Students's email"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <label className={classes.label} htmlFor="notes" >Profile notes</label>
                                                <FormControl fullWidth variant="outlined">
                                                    <OutlinedInput
                                                        className={classes.input}
                                                        aria-labelledby='Profile notes'
                                                        variant="outlined"
                                                        id='notes'
                                                        value={values.notes ?? 'Not provided'}
                                                        readOnly={!editValues}
                                                        disabled={updateLoader}
                                                        onChange={handleUpdate}
                                                        name='notes'
                                                        placeholder="Students's profile notes"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6}>
                                            {/* <label className={classes.label} htmlFor="institution" >Editable</label>
                                            <FormControl style={{ width: '100%' }} variant="outlined">
                                                <Select placeholder="Students's campus" className={classes.input} readOnly={!editValues} id="campus" name="campus" value={values.institution ?? ""} onChange={handleSelectChange}>
                                                    <MenuItem disabled value="">
                                                        Select a campus
                                                    </MenuItem>
                                                    {orgs.map(org =>
                                                        <MenuItem
                                                            key={org.id}
                                                            value={org.organisation_id}
                                                        >
                                                            {org.Organisation.name}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl> */}
                                            <label className={classes.label} htmlFor="institution" >Institution</label>
                                            <FormControl fullWidth variant="outlined">
                                                <OutlinedInput
                                                    className={classes.input}
                                                    aria-labelledby='Student institution'
                                                    variant="outlined"
                                                    id='institution'
                                                    value={values.institution ?? 'Not provided'}
                                                    readOnly={true}
                                                    onChange={handleUpdate}
                                                    name='institution'
                                                    placeholder="Students's institution"
                                                />
                                            </FormControl>
                                            <label className={classes.label} htmlFor="student_id" >Student number</label>
                                            <FormControl fullWidth variant="outlined">
                                                <OutlinedInput
                                                    className={classes.input}
                                                    aria-labelledby='Subject number'
                                                    variant="outlined"
                                                    id='student_id'
                                                    value={values.student_id ?? 'Not provided'}
                                                    readOnly={!editValues}
                                                    disabled={updateLoader}
                                                    onChange={handleUpdate}
                                                    name='student_id'
                                                    placeholder="Students's ID number"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </React.Fragment>
                    }
                </TabPanel>
                {/* 2nd Panel */}
                <TabPanel value={value} index={1}>
                    <Box
                        boxShadow={0}
                        className={classes.paper}
                    >
                        <Box mb={3}>
                            <BoldHeading text="Booking List" />
                            <Divider />
                            <StudentFilters onSort={handleSort} onSearch={handleSearch} />
                            <StudentBookings values={values} data={courseData} sortValue={sortValue} searchValue={searchValue} />
                        </Box>
                    </Box>
                </TabPanel>
                <Toast alert={alert}/>
                <StylizedModal header="Save Changes?" content="Are you sure you want to make changes to student profile?" open={open} onHandleConfirm={handleConfirm} onHandleClose={handleClose} />
            </Container>
        </React.Fragment>
    );
};

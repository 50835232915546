import React, { useEffect } from 'react'
import 'date-fns';
import { Container} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle'
import TitleLine from '../../Common/TitleLine'
import CourseTile from '../BookingCommon/CourseTile';
import BookingBtn from '../BookingCommon/BookingBtn';
import ButtonHeading from '../../Common/ButtonHeading';
import InstancesTable from './InstancesTable';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';
import { DateComparer } from '../../../services/dateComparer';
import CourseProgress from '../BookingCommon/CourseProgress';
import { CollectionsOutlined } from '@material-ui/icons';


export default function CourseInstances(props){
    const current = props.course.getCurrent()
    const history=useHistory()
    const dateCompar = new DateComparer();
    let {path,url} = useRouteMatch();

   // const data = JSON.parse(localStorage.getItem('bookingData'))

    function getDatesArray(startDate,endDate,startTime,endTime,repeat){
        var arr=[]
        let i=0
        var hours = dateCompar.getHours(startTime,endTime);
        var  interval = dateCompar.getInterval(repeat)
        var lastDate=startDate;
        if(interval>0){
        while(startDate!=null)
        { 
           arr[i]={
                booked:true,
                date:startDate,
                hours: hours,
                start:startTime,
                end:endTime,
                message:'',
            }
            startDate =dateCompar.getDateLessThan(startDate,endDate,interval)
            if(startDate!=null)
            {
                lastDate = startDate
            }
            i=i+1;
        } 
    
        if(lastDate &&  lastDate!= endDate){
            arr[i]={
                booked:true,
                date:endDate,
                hours: hours,
                start:startTime,
                end:endTime,
                message:'',
            }
        }}else{
            arr[i]={
                booked:true,
                date:startDate,
                hours: hours,
                start:startTime,
                end:endTime,
                message:'',
            }
        }
        
        return arr;
    }
   
    const [values ,setValues] = React.useState(
        current.orgEvents && current.orgEvents.length>0
        ?
        {events:current.orgEvents}
        : 
        {
            events:getDatesArray(current.schedule.Start_date,current.schedule.End_date,current.schedule.Start_time,current.schedule.End_time,current.schedule.repeat=='true'?current.schedule.repeat_schedule:'-')
        }
    );

    const handleValueUpdate = (event) =>{
        // if(values[e.target.name]==undefined){
        //     values[e.target.name]=[]
        // }
        // values[e.target.name][i] = e.target.value ;
        // setValues({...values, [e.target.name]:values[e.target.name]});

        setValues({...values,events:event}); 

        // const props = Object.keys(event).map((key) => [(key), event[key]])
        // props.forEach((element) =>{
        //     setValues({...values,[element[0]]:element[1]}); 
        // })
    }

   // const [redirect, setRedirect] = React.useState(null)
    const handleUpdate = (e) =>{
        let orgValues = {...values}.events
        let sessions = orgValues.filter(e=>e.booked === true)
        props.course.setCurrnet({...current,instances:{events:sessions},status:5,orgEvents:orgValues})
        // localStorage.setItem('bookingData',JSON.stringify(values));
      var s = props.course.goNext();
      if(s)
        setRedirect(`./CourseDetails`);
      else
        setRedirect(`./CourseReview`);
    }
    function setRedirect(redirect){
        history.push(redirect)
       // return <Redirect to={redirect}/>
    }
    return(
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={4}>
                            
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PageTitle mb={2.5} color='accent' text='Instances'/>
                            <Box mb={3.625}>
                                <ButtonHeading text="Are there any days in this series you want to block?"/>
                            </Box>
                            <InstancesTable onValueHandleUpdate={handleValueUpdate} rows={values.events}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                         <CourseProgress course={props.course}/>
                        </Grid>
                        <Box mt={4} mb={4}>
                            <BookingBtn onHandleUpdate={handleUpdate} text="Proceed"/>
                        </Box>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
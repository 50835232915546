import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import { Container, RadioGroup, TextField } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle'
import TitleLine from '../../Common/TitleLine'
import CourseTile from '../BookingCommon/CourseTile';
import BookingBtn from '../BookingCommon/BookingBtn';
import CustomRadio from '../../Common/CustomRadio';
import { FormControl } from '@material-ui/core';
import DatePicker from '../../Common/DatePicker';
import TimePicker from '../../Common/TimePicker';
import { FormControlLabel } from '@material-ui/core';
import RepeatSelect from './RepeatSelect';
import Subtitle2 from '../../Common/Subtitle2';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import moment from "moment";
import { DateComparer } from '../../../services/dateComparer';
import CourseProgress from '../BookingCommon/CourseProgress';
import DaySelect from './DaySelect';

const StyledToggle = withStyles((theme) => ({
    root: {
      width: '48px',
      height: '32px',
      color: '#191919',
      '&.Mui-selected, &.Mui-selected:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
}))(ToggleButton);

export default function CourseSchedule(props) {
    const noRegex = new RegExp('^[0-9]*$')
    const current = props.course.getCurrent()
    const dateComparer = new DateComparer();
    let { path, url } = useRouteMatch();
    const history = useHistory()

    const [class_repeat, setRepeat] = React.useState(current.schedule?.repeat || 'false');
    const [dateType, setDateType] = React.useState('day');

    const [values, setValues] = React.useState(current.schedule ?? {
        // TODO - Moment is obsolete, this needs total rewrite
        Start_date: moment(new Date()).format('DD/MM/YYYY'),
        End_date: moment(new Date()).format('DD/MM/YYYY'),
        repeat_schedule: 'Weekly',
        Start_time: '08:00',
        End_time: '10:30',
        hours: 2.5,
        repeat: false,
        weeks: 0,
        repeats:['1']
    });

    const handleDateChange = (e, prop) => {
        var hours = dateComparer.getHours(values.Start_time, values.End_time);
        var days = dateComparer.getDays(prop == 'Start_date' ? e : values.Start_date, prop == 'End_date' ? e : values.End_date)
        var interval = dateComparer.getInterval(values.repeat_schedule)

        setValues({ ...values, [prop]: e, hours: (hours * Math.ceil(days / interval)) });
    }
    const handleTimeChange = (e, prop) => {
        var hours = dateComparer.getHours(prop == 'Start_time' ? e : values.Start_time, prop == 'End_time' ? e : values.End_time);
        var days = dateComparer.getDays(values.Start_date, values.End_date)
        var interval = dateComparer.getInterval(values.repeat_schedule)

        setValues({ ...values, [prop]: e, hours: (hours * Math.ceil(days / interval)) });
    }

    const handleToggleChange = (e, formats) => {
        setValues({...values,repeats:formats})
    }

    const handleRadio = (e) => {
        setRepeat(e.target.value);
        setValues({ ...values, repeat: e.target.value })
    };

    const handleDateType = (e) => {
        setDateType(e.target.value)
    };

    const handleUpdate = (e) => {
        props.course.setCurrnet({ ...current, schedule: values, status: 2 })
        setRedirect(`./CourseServices`);
    }

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    }

    const handleSelectChange = (id, prop) => {
        setValues({ ...values, [id]: prop });
    }

    function setRedirect(redirect) {
        history.push(redirect)
    }

    const setWeeks = (event) => {
        if (noRegex.test(event.target.value)) {
            setValues({ ...values, weeks: event.target.value })
        }
    }

    useEffect(() => {
        let multiplier = values.weeks ? values.weeks : '0';
        let endDate = moment(values.Start_date, 'DD/MM/YYYY').add(parseInt(multiplier) * 7,'days')
        handleDateChange(moment(endDate).format('DD/MM/YYYY'),'End_date')
    }, [values.weeks])

    useEffect(() => {
        if(dateType == 'weeks'){
            let multiplier = values.weeks ? values.weeks : '0';
            let endDate = moment(values.Start_date, 'DD/MM/YYYY').add(parseInt(multiplier) * 7,'days')
            handleDateChange(moment(endDate).format('DD/MM/YYYY'),'End_date')
        }
    }, [values.Start_date])

    useEffect(() => {
        if(dateType == 'weeks'){     
            let endDate = moment(values.Start_date, 'DD/MM/YYYY').add(7,'days')
            handleDateChange(moment(endDate).format('DD/MM/YYYY'),'End_date')
            setValues({...values,['weeks']:'1'})
        }
        else{
            setValues({...values,['weeks']:'0'})
        }
    }, [dateType])

    useEffect(() => {
        if(class_repeat === 'false'){
            setValues({...values,End_date:moment(new Date()).format('DD/MM/YYYY'),weeks:0})
        }
    },[class_repeat])

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <form onSubmit={handleUpdate}></form>
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={4}>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box mb={3.125}>
                                <PageTitle mb={2.5} color="accent" text='Course schedule' />
                                <TitleLine text="Does this class repeat on a regular basis?" />
                                <FormControl required component="fieldset">
                                    <RadioGroup name='class_repeat' aria-label="Does this class repeat on a regular basis?" value={class_repeat} onChange={handleRadio}>
                                        <FormControlLabel value="true" control={<CustomRadio required={true} name='class_repeat' checked={class_repeat == 'true'} />} label="Yes" />
                                        <FormControlLabel value="false" control={<CustomRadio required={true} name='class_repeat' checked={class_repeat == 'false'} />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            {class_repeat === 'true'
                                ?
                                <Grid spacing={4} container justifyContent="flex-start">
                                    <Grid item xs={12}>
                                        <Subtitle2 text="Set course schedule based on:" />
                                        <FormControl component="fieldset">
                                            <RadioGroup row name='dateType' aria-label="Does this class repeat on a regular basis?" value={dateType} onChange={handleDateType}>
                                                <FormControlLabel value="day" control={<CustomRadio required={true} name='dateType' checked={dateType == 'day'} />} label="Start and end days" />
                                                <FormControlLabel value="weeks" control={<CustomRadio required={true} name='dateType' checked={dateType == 'weeks'} />} label="Start day and number of weeks" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TitleLine text="First day of class*" />
                                        <DatePicker onHandleDateChange={handleDateChange} id="Start_date" value={values.Start_date} />
                                    </Grid>
                                    {dateType == 'weeks'
                                        ?
                                        <Grid item xs={6}>
                                            <TitleLine text="Number of weeks:" />
                                            <TextField value={values.weeks} fullWidth onChange={setWeeks} variant='outlined' id="weeks_number" />
                                        </Grid>
                                        : null
                                    }
                                    <Grid item xs={6}>
                                        <TitleLine text="Last day of class*" />
                                        <DatePicker disabled={dateType == 'weeks'} onHandleDateChange={handleDateChange} id="End_date" value={values.End_date} />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <TitleLine text="Day of the class?" />
                                        <ToggleButtonGroup name="repeats" value={values.repeats} exclusive onChange={handleToggleChange}>
                                            <StyledToggle value='0'>
                                                Sun
                                            </StyledToggle>
                                            <StyledToggle value='1'>
                                                Mon
                                            </StyledToggle>
                                            <StyledToggle value='2'>
                                                Tue
                                            </StyledToggle>
                                            <StyledToggle value='3'>
                                                Wed
                                            </StyledToggle>
                                            <StyledToggle value='4'>
                                                Thu
                                            </StyledToggle>
                                            <StyledToggle value='5'>
                                                Fri
                                            </StyledToggle>
                                            <StyledToggle value='6'>
                                                Sat
                                            </StyledToggle>
                                        </ToggleButtonGroup>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <TitleLine text="How often does the class repeat?" />
                                        <Subtitle2 text="Don’t worry about holidays, you can indicate those later." />
                                        <RepeatSelect onHandleSelectChange={handleSelectChange} id="repeat_schedule" name="Select from the list" value={values.repeat_schedule} />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                    <TitleLine text="Class repeats on:"/>
                                    <DaySelect/>
                                </Grid> */}
                                    <Grid item xs={6}>
                                        <TitleLine text="Start Time*" />
                                        <TimePicker onHandleTimeChange={handleTimeChange} id="Start_time" value={values.Start_time} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TitleLine text="End Time*" />
                                        <TimePicker onHandleTimeChange={handleTimeChange} id="End_time" value={values.End_time} />
                                    </Grid>
                                </Grid>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CourseProgress course={props.course} />
                        </Grid>
                        <Box mt={4} mb={4}>
                            <BookingBtn onHandleUpdate={handleUpdate} text="Proceed" />
                        </Box>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
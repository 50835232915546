import React from 'react';
import { Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    radio:{
      '&:checked': {
        color:theme.palette.accent.purple,
      },
    },
  }));

export default function CustomRadio(props){
    const classes = useStyles();
    return(
        <Radio
        required={props.required ?? true}
        className={classes.radio}
        onChange={props.onRadioChange}
        value={props.value}
        name={props.name}
        checked={props.checked}
        />
    )
}
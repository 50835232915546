import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Navbar from './components/Navigation/navbar';
import LoginPage from './pages/loginPage';
import Dashboard from './pages/dashboard';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';
import useToken from './services/useToken';

const useStyles = makeStyles((theme) => ({
  loginbox: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));
const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_SMS_GRAPHQL_ENDPOINT,
      headers: {
        'x-hasura-admin-secret': process.env.REACT_APP_SMS_HASURA_KEY,
      },
      credentials: 'same-origin'
    }),
    cache: new InMemoryCache(),
  });
};

const client = createApolloClient();


export default function App() {

  const { token, user, setToken, setUser } = useToken()

  const classes = useStyles();
  return (
    <ApolloProvider client={client}>
        <Container className='navbar' maxWidth="xl">
          <Box>
            <Navbar user={user} isLoggedIn={token} setToken={setToken} setUser={setUser} />
          </Box>
        </Container>
        {!token ?
          <LoginPage setToken={setToken} setUser={setUser} />
          :
          <Dashboard user={user} />
        }
    </ApolloProvider>
  );
}

import React, { useEffect } from 'react'
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle'
import TitleLine from '../../Common/TitleLine'
import BoldHeading from '../../Common/BoldHeading';
import TextInput from '../BookingCommon/TextInput'
import BookingBtn from '../BookingCommon/BookingBtn'
import ComboBoxSelect from './SelectInput';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CourseProgress from '../BookingCommon/CourseProgress';
import { gql, useLazyQuery } from '@apollo/client';


const CHECK_INSTRUCTOR = gql`
query GetInstructor($email: String = "") {
    User(where: {email: {_eq: $email}}) {
      id
      Instructors {
        id
      }
    }
  }
`;

export default function CourseDetails(props) {
    const current = props.course.getCurrent()
    const course = props.course.dataCourse()
    const history = useHistory()
    let { path, url } = useRouteMatch();

    //console.log(current,'Current course info')

    const [
        checkInstructor,
        { loading, data }
    ] = useLazyQuery(CHECK_INSTRUCTOR, {
        onCompleted: data => {
            //console.log('checkInstructor :',data)
            if (data.User.length) {
                setValues({ ...values, 'instructor_user_id': data.User[0].id, 'instructor_id': data.User[0].Instructors[0].id });
            }
        },
        fetchPolicy: 'no-cache'
    });


    const [values, setValues] = React.useState(current ?? {
        email: '',
        instructor_name: '',
        course_code: '',
        course_name: '',
        instructor_id: '',
        subject_names: '',
    });
    // const [redirect, setRedirect] = React.useState(null)
    const handleUpdate = (e) => {
        setValues({ ...values, status: 1 });
        props.course.setCurrnet(values)
        // localStorage.setItem('bookingData',JSON.stringify(values));
        setRedirect(`./CourseSchedule`);
    }
    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    }
    const handleSelectChange = (prop, e) => {
        setValues({ ...values, [prop]: e });
    }
    const handleEmailChange = (prop) => (e) => {
        checkInstructor({
            variables: {
                email: e.target.value.toLowerCase()
            }
        })
        setValues({ ...values, [prop]: e.target.value.toLowerCase() });
    }

    function setRedirect(redirect) {
        history.push(redirect);
        //  return <Redirect to={redirect}/>
    }
    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <form onSubmit={handleUpdate}>
                    <Box mt={16}>
                        <Grid container justifyContent="center" spacing={3}>
                            <Grid item xs={12} md={4}>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <PageTitle mb={2.5} textAlign='left' color="accent" text='Course details' />
                                <Box mb={3.125}>
                                    <TitleLine required text="Course name" />
                                    <TextInput value={values.course_name} onHandleChange={handleChange} id="course_name" name="Course name" required />
                                </Box>
                                <Box mb={3.125}>
                                    <TitleLine required text="Course Code" />
                                    <TextInput defaultValue={values.course_code} onHandleChange={handleChange} id="course_code" name="Course code" required />
                                </Box>
                                <Box mb={3.125}>
                                    <TitleLine required text="What is the subject of study?" />
                                    <ComboBoxSelect course={course} user={props.user} onHandleSelectChange={handleSelectChange} id="subject_names" name="subject_names" text="Select from the list" value={values.subject_names || ''} required={true} />
                                </Box>
                                <BoldHeading text="Instructor contact information" />
                                <Box mb={3.125}>
                                    <TitleLine text="Instructor's first and last name" />
                                    <TextInput onHandleChange={handleChange} id="instructor_name" name="Instructor's name" value={values.instructor_name || ''} />
                                </Box>
                                <Box mb={3.125}>
                                    <TitleLine text="Instructor's email" />
                                    <TextInput onHandleChange={handleEmailChange} name="instructor_email" id="instructor_email" value={values.instructor_email || ''} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CourseProgress course={props.course} />
                            </Grid>
                            <Box mb={4}>
                                <BookingBtn text="Proceed" type="submit" />
                            </Box>
                        </Grid>
                    </Box>
                </form>
            </Container>
        </React.Fragment>
    );
};
import React from 'react'
import { FormControl, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';

const useStyles = makeStyles((theme) =>({
  input: {
    marginBottom: (props) => props.mb ? theme.spacing(props.mb) : null,
  },
}));


/* REV - Maybe make it global? */
export default function TextInput(props){

  const [defaultValue, setDefaultValue] = React.useState(props.defaultValue)

  useEffect(()=>{
    props.onHandleChange(props.id);
  },[defaultValue])

    const classes = useStyles(props);
    return(
        <FormControl fullWidth variant="outlined">
        <OutlinedInput
          className={classes.input}
          required={props.required}
          aria-labelledby={props.name}
          disabled={props.disabled}
          variant="outlined"
          defaultValue={defaultValue}
          id={props.id}
          onChange={props.onHandleChange(props.id)}
          name={props.name}
          multiline={props.multiline}
          value={props.controlled ?? props.value}
          rows={props.rows}
          autoComplete={props.id}
          placeholder={props.placeholder}
          />
        </FormControl>
    )
}
import 'date-fns';
import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField:{

  },
}));


export default function TimePicker(props) {
  const [selectedTime, setSelectedTime] = React.useState(props.value);
  const classes = useStyles();

  return (
    <TextField
    id={props.id}
    label={props.title}
    fullWidth
    variant="outlined"
    defaultValue={props.value??"00:00"}
    type="time"
    onChange={(time,event) => {
      setSelectedTime(time.target.value);
      props.onHandleTimeChange(time.target.value,props.id);
    }}
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
  />
  );
}

import React from 'react';
import { Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

/* INF - Switch example */
const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'unset',
        background: (props) => {
            switch (props.variant) {
                case 'light':
                    return theme.palette.background.surface2;
                case 'accent':
                    return theme.palette.accent.purple;
                case 'error':
                    return theme.palette.error.main;
                default:
                    return theme.palette.background.onSurface;
            }
        },
        color: (props) => {
            switch (props.variant) {
                case 'light':
                    return theme.palette.text.dark.body;
                case 'accent':
                    return theme.palette.text.light.onSecondaryHighEmphasis;
                case 'error':
                    return theme.palette.text.light.onSecondaryHighEmphasis;
                default:
                    return theme.palette.text.light.onSecondaryHighEmphasis;
            }
        },
        borderRadius: 8,
        display: 'inline-flex',
        width: 160,
        alignItems: 'center',
        padding: theme.spacing(2),
        '&:hover': {
            filter: 'brightness(1.1)',
            background: (props) => {
                switch (props.variant) {
                    case 'light':
                        return theme.palette.background.surface2;
                    case 'accent':
                        return theme.palette.accent.purple;
                    case 'error':
                        return theme.palette.error.main;
                    default:
                        return theme.palette.background.onSurface;
                }
            },
            color: (props) => {
                switch (props.variant) {
                    case 'light':
                        return theme.palette.text.dark.body;
                    case 'accent':
                        return theme.palette.text.light.onSecondaryHighEmphasis;
                    case 'error':
                        return theme.palette.text.light.onSecondaryHighEmphasis;
                    default:
                        return theme.palette.text.light.onSecondaryHighEmphasis;
                }
            },
        },
    },
    btnText: {
        minWidth: 116,
        fontWeight: 500,
        fontSize: '0.875rem',
    },
}));

export default function IconButton(props) {
    const classes = useStyles(props);

    return (
        <Button
            className={classes.button}
            onClick={props.onHandleUpdate}
            endIcon={props.noIcon
                ? null
                : <ChevronRightIcon fontSize="large" className={classes.btnIcon} />
            }>
            <Typography className={classes.btnText}>{props.text}</Typography>
        </Button>
    );
}
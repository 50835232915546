import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

export default function useToken() {

  const [cookies, setCookie, removeCookie] = useCookies(['userToken'])
  
  const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
  };

  const token = cookies['userToken'];
  const [user, setUser] = useState(getUser());

  const saveToken = userToken => {
    if (userToken == '') {
      removeCookie('userToken')
    }
    else {
      setCookie('userToken', userToken, { path: '/', maxAge: 86400, sameSite: 'Lax' })
    }
  };
  const saveUser = user => {
    if (user == null)
      localStorage.removeItem('user');
    else
      localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
  };

  return {
    setToken: saveToken,
    setUser: saveUser,
    token,
    user
  }
}

/* Token and user are taken in App.js and set to other components, look dashboard.js how to set it */
import React, {useEffect} from 'react'
import { Container, Divider, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import TitleLine from '../Common/TitleLine';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { useLazyQuery, gql } from '@apollo/client';
import loginService from '../../services/loginService';
import PillButton from '../Common/Buttons/PillButton';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    label:{
        textTransform:'uppercase',
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#A3a3a3',
    },
    input:{
        '& input':{
            color:'#191919',
        }
    }
}));
function ChangePasswordForm({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    classes,
    loader,
    message,
})
{
    return(
        <form onSubmit={handleSubmit} autoComplete="off" id="changePassword">
        <Box mb={2}>
            <Typography className={classes.label}>Old password</Typography>
            <TextField
            variant="outlined"
            fullWidth
            className={classes.input}
            id="old_password"
            type='password'
            name="old_password"
            required
            error={errors.old_password ? true : false}
            value={values.old_password}
            onChange={handleChange}
            onBlur={handleBlur}
            />
            <Typography color="error">{touched.old_password && errors.old_password}</Typography>
        </Box>
        <Box mb={2}>
            <Typography className={classes.label}>New password</Typography>
            <TextField
            variant="outlined"
            fullWidth
            className={classes.input}
            id="new_password"
            type='password'
            name="new_password"
            required
            error={errors.new_password ? true : false}
            value={values.new_password}
            onChange={handleChange}
            onBlur={handleBlur}
            />
            <Typography color="error">{touched.new_password && errors.new_password}</Typography>
        </Box>
        <Box mb={2}>
            <Typography className={classes.label}>Repeat new password</Typography>
            <TextField
            variant="outlined"
            fullWidth
            className={classes.input}
            id="new_password_confirm"
            type='password'
            name="new_password_confirm"
            required
            error={errors.new_password_confirm ? true : false}
            value={values.new_password_confirm}
            onChange={handleChange}
            onBlur={handleBlur}
            />
            <Typography color="error">{touched.new_password_confirm && errors.new_password_confirm}</Typography>
        </Box>
        <Box>
            <Typography color={message.error?'error':'inherit'}>{message.message}</Typography>
            <PillButton disabled={loader} onHandleUpdate={handleSubmit} form="changePassword" variant="accent" type="submit" loadState={loader} noIcon text={loader?'Processing':'Change password'}/>
        </Box>
        </form> 
    )
}
export default function ChangePassword(props){
    const{post} = loginService()
    const validateOldPassword = (fieldName, fieldValue) => {
        if (fieldValue.trim() === '') {
          return `${fieldName} is required`
        }
        if (fieldValue.trim().length < 8) {
          return `${fieldName} needs to be at least eigth characters`;
        }
        return null;
    };
    const validateNewPassword = (fieldName, fieldValue) => {
        if (fieldValue.trim() === '') {
          return `${fieldName} is required`;
        }
        if (fieldValue.trim() === values.old_password) {
            return `New password cannot be the same as the old one!`;
          }
        if (fieldValue.trim().length < 8) {
          return `${fieldName} needs to be at least eigth characters long`;
        }
        return null;
    };
    const validateNewPasswordConfirm = (fieldName, fieldValue) => {
        if (fieldValue.trim() === '') {
          return `New password confirm is required`;
        }
        if (fieldValue.trim() != values.new_password) {
          return `Passwords don't match!`;
        }
        return null;
    };

    const classes = useStyles();

    const [loader, setLoader] = React.useState(false)
    const [message, setMessage] = React.useState({
      error:false,
      message:null
    })
    const [values, setValues] = React.useState({
        old_password:"",
        new_password:"",
        new_password_confirm:"",
    });
    const validate = {
        old_password: name => validateOldPassword('Old password', name),
        new_password: name => validateNewPassword('New password', name),
        new_password_confirm: name => validateNewPasswordConfirm('Confirm new password', name),
    };

  const [errors, setErrors] = React.useState({});

  const [touched, setTouched] = React.useState({});

  async function changePassword(values){
    setLoader(true)
    const { data } = await post("reset-password",{
      password:values.new_password,
      password_confirmation:values.new_password_confirm,
      old_password:values.old_password,
      email:props.email,
    });
    setLoader(false)
    if(data.error){
      setMessage({message:'An error has occured!',error:true})
      setTimeout(() => {
        setMessage({message:null,error:false})
      }, 3000);
    }
  }
  // change event handler
  const handleChange = (e) => {
    const { name, value: newValue, type } = e.target;

    // keep number fields as numbers
    const value = type === 'number' ? +newValue : newValue;

    // save field values
    setValues({
      ...values,
      [name]: value,
    });

    // was the field modified
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    // remove whatever error was there previously
    const { [name]: removedError, ...rest } = errors;

    // check for a new error
    const error = validate[name](value);

    // // validate the field if the value has been touched
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error }),
    });
  };

  // form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    // validate the form
    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key](values[key]);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      },
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (
      !Object.values(formValidation.errors).length && // errors object is empty
      Object.values(formValidation.touched).length ===
        Object.values(values).length && // all fields were touched
      Object.values(formValidation.touched).every(t => t === true) // every touched field is true
    ) {
      changePassword(values)
    }
  };
    return(
        <React.Fragment>
            <Box mb={3}>
                <TitleLine text='Change password'/>
                <Divider/>
            </Box>
            <Grid spacing={3} container>
                <Grid item xs={4}>
                    <ChangePasswordForm
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        touched={touched}
                        values={values}
                        classes={classes}
                        loader={loader}
                        message={message}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
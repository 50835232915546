import React from 'react';
import { useQuery, gql } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';


const getSubjects = gql`
query subjectsQuery($where: Subject_bool_exp = {}) {
  Subject(where: $where) 
    {
      id 
      category 
      name
    }
}`;

export default function ComboBoxSelect(props) {

  let org = props.user.duorg_id.filter(org => org.organisation_id === props.course.selectedOrg)[0]

  const [value, setValue] = React.useState();

  let queryVars

  if(org && org.Organisation.name.toLowerCase() == 'connexcel'){
    queryVars = {
      where:{
        source:{
          _eq:'ConnExcel'
        }
      }
    }
  }
  else{
    queryVars = {
      where:{
        source:{
          _eq:'Beehive'
        },
        parent_id:{
          _eq:process.env.REACT_APP_DEFAULT_SUBJECT_PARENT_ID
        }
      }
    }
  }

  const { loading, error, data } = useQuery(getSubjects, {
    variables: queryVars
  });
  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return (<div>An error has occured</div>)
  }
  else {
    return (
      <Autocomplete
        id={props.id}
        options={data.Subject}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.onHandleSelectChange(props.id, newValue);
        }}
        value={props.value}
        getOptionLabel={(subject) => subject.name || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField id={props.id} required={props.required} value={props.title} placeholder={props.text} fullWidth {...params} variant="outlined" />}
      />
    );
  }
}

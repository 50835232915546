import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom'
import { Button, Box, Typography, CircularProgress, Select, MenuItem, Link } from '@material-ui/core';
import { Container } from '@material-ui/core';
import PageTitle from '../Common/PageTitle';
import { Divider } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import StylizedModal from '../Common/UI/StylizedModal';
import BoldHeading from '../Common/BoldHeading';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Schedule, KeyboardReturn, Today } from '@material-ui/icons';
import getService from '../../services/getService';
import loginService from '../../services/loginService'


const getSessionStatus = gql`
query getSessionStatus($id: uuid = "") {
    calendar_tasks(where: {sms_course_session_id: {_eq: $id}}) {
      status
      user {
        first_name
        email
        last_name
      }
    }
  }
`

const updateTSSession = gql`
mutation updateCalendarTask( $task: calendar_tasks_set_input = {}, $sms_id: uuid = "") {
    update_calendar_tasks(where: {sms_course_session_id: {_eq: $sms_id}}, _set: $task){
        returning{
            id
            start_time
            end_time
        }
    }
  }
`

const updateCourseIns = gql`
mutation updateCourseInfoIns($course: Course_set_input = {}, $course_id: uuid = "", $courseSession: CourseSession_set_input = {}, $session_id: uuid = "", $instructor: CourseInstructor_set_input = {}, $course_subject: CourseSubject_set_input = {}) {
    update_Course(where: {id: {_eq: $course_id}}, _set: $course) {
      returning {
        id
      }
    }
    update_CourseSession(where: {id: {_eq: $session_id}}, _set: $courseSession) {
      returning {
        id
      }
    }
    update_CourseInstructor(where: {Course: {id: {_eq: $course_id}}}, _set: $instructor) {
      returning {
        id
      }
    }
    update_CourseSubject(where: {Course: {id: {_eq: $course_id}}}, _set: $course_subject) {
      returning {
        id
      }
    }
  }
`
const upsertInstructor = gql`
mutation upsertInstructor($upsert_ins: [CourseInstructor_insert_input!] = {}) {
    insert_CourseInstructor(objects: $upsert_ins, on_conflict: {constraint: CourseInstructor_pkey, update_columns: instructor_id}) {
      affected_rows
      returning {
        id
      }
    }
  }
`

const updateCourse = gql`
mutation updateCourseInfo($course: Course_set_input = {}, $course_id: uuid = "", $courseSession: CourseSession_set_input = {}, $session_id: uuid = "", $course_subject: CourseSubject_set_input = {}) {
    update_Course(where: {id: {_eq: $course_id}}, _set: $course) {
      returning {
        id
      }
    }
    update_CourseSession(where: {id: {_eq: $session_id}}, _set: $courseSession) {
      returning {
        id
      }
    }
    update_CourseSubject(where: {Course: {id: {_eq: $course_id}}}, _set: $course_subject) {
      returning {
        id
      }
    }
  }
`

const createInstructor = gql`
mutation createInstructor($objects: [Instructor_insert_input!] = {User: {}}){
    insert_Instructor(objects: $objects) {
        affected_rows
        returning {
          id
          user_id
        }
    }
}`

const checkInstructor = gql`
query checkInstructor($email: String = "") {
    User(where: {email: {_eq: $email}}) {
      id
      first_name
      last_name
      title
      role_id
      email
      Instructors {
        id
        user_id
      }
    }
  }`


const bookingRequest = gql`
query requestBooking($id: uuid!, $parent_id:uuid) {
    Subject(where: {_and: {source: {_eq: "Beehive"}, parent_id: {_eq: $parent_id}}}){
      id 
      category 
      name
    }
    Course(where: {id: {_eq: $id}}) {
        Organisation{
            name
            id
        }
        course_code
        course_name
        platform_location
        platform_password
        platform_url
        platform_username
        subject_names
        subject_ids
        id
        start_day
        platform
        end_day
        additional_reciptients
        comment
        course_crn
        organisation_id
        CourseInstructors {
          id
          Instructor {
            id
            user_id
            User {
              email
              first_name
              last_name
              title
            }
          }
        }
        CourseLanguages {
          Language {
            name
            iso_code
          }
        }
        CourseSessions(order_by: {start_time: asc}) {
          course_id
          live_captioning
          live_note_taking
          start_time
          subject_names
          tz
          end_time
          id
        }
        CourseStudents {
          Student {
            id
            User {
              email
              first_name
              last_name
            }
          }
        }
        CourseSubjects {
            subject_id
            course_id
        }
      }
    }` ;

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    info: {
        background: theme.palette.background.white,
    },
    paper: {
        padding: theme.spacing(7),
        background: '#ffffff',
    },
    buttonWrapper: {
        gap: theme.spacing(3),
    },
    tabsHead: {
        background: 'unset',
        boxShadow: 'unset',
        '& .indicator': {
            color: 'crimson',
            background: 'crimson',
        }
    },
    tabs: {
        fontFamily: theme.typography.averta,
        color: theme.palette.text.dark.body,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        minWidth: 160,
        fontWeight: 'bold',
        '&indicator': {
            color: 'crimson',
            background: 'crimson',
        },
    },
    input: {
        marginBottom: theme.spacing(3),
        '& *[readonly=""] ~ fieldset': {
            border: 'unset',
            outline: 'unset',
        },
    },
    selectDisabled: {
        pointerEvents: 'none',
        border: 'unset',
        '& fieldset': {
            border: 'unset',
        },
    },
    divider: {
        marginBottom: theme.spacing(7),
    },
    infoBox: {
        padding: theme.spacing(3, 8),
    },
    header: {
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        color: '#818181',
        marginBottom: theme.spacing(1),
    },
    subheader: {
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        color: '#3C3C3C',
        textTransform: 'capitalize',
    },
    divider: {
        marginBottom: theme.spacing(2.75),
    },
    label: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        marginBottom: theme.spacing(1),
        textTransform: 'uppercase',
        color: '#979797', //Primary 900?
        display: 'inline-block',
    },
    formRow: {
        gap: theme.spacing(4.25),
    },
    tableElements: {
        gap: '1.5rem',
    },
    timeline: {
        '& .MuiTimelineItem-missingOppositeContent::before': {
            display: 'none',
        }
    },
    timelineIcon: {
        padding: theme.spacing(1.25),
    },
    timelineDate: {
        fontFamily: theme.typography.averta,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#474747',
        marginBottom: '0.5rem'
    },
    timelineTime: {
        fontFamily: theme.typography.averta,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#A3A3A3',
        textTransform: 'uppercase',
    },
}));

const StyledTabHead = withStyles((theme) => ({
    root: {
        backgroundColor: 'unset',
    },
}))(AppBar);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`student-tabpanel-${index}`}
            aria-labelledby={`student-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `booking-overview-tab-${index}`,
        'aria-controls': `booking-overview-tabpanel-${index}`,
    };
}
function BookingsTimeline(props) {
    const series = props.bookingSeries
    const classes = useStyles();
    return (
        <Timeline className={classes.timeline}>
            {series.map(({ start_time, end_time, id }, index) => (
                <TimelineItem key={id}>
                    <TimelineSeparator>
                        <TimelineDot className={classes.timelineIcon} color="primary" style={props.sessionId === id ? null : { opacity: "0.25" }} />
                        {index + 1 != series.length
                            ? <TimelineConnector />
                            : null
                        }
                    </TimelineSeparator>
                    <TimelineContent>
                        <Box gap='3' mb={3} display={'flex'} alignItems='center'>
                            <Box>
                                <Typography className={classes.timelineDate}>
                                    {new Date(start_time).toLocaleDateString('en-US', { dateStyle: 'full' })}
                                </Typography>
                                <Typography className={classes.timelineTime}>
                                    {new Date(start_time).toLocaleTimeString('en-US', { timeStyle: 'short' }).concat(' - ', new Date(end_time).toLocaleTimeString('en-US', { timeStyle: 'short' }))}
                                </Typography>
                            </Box>
                            {props.sessionId != id
                                ?
                                <Box ml={2}>
                                    <Typography>
                                        <Link to={id} component={RouterLink} color="secondary">
                                            View
                                        </Link>
                                    </Typography>
                                </Box>
                                :
                                null
                            }
                        </Box>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    )
}
export default function EditBookings(props) {
    const { get } = getService()
    const { post } = loginService()
    let history = useHistory();
    let { id } = useParams();
    let { studentId } = useParams();

    // Will delete later
    let { sessionIndex } = useParams() ?? 0;
    let { sessionId } = useParams();

    /* Modal */
    const [loader, setLoader] = React.useState(true);
    const [loadError, setLoadError] = React.useState(false);

    //Snackbar
    const [alert, setAlert] = React.useState({
        message: null,
        severity: null
    })

    // Edit states
    const [editLoader, setEditLoader] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const handleConfirm = (e) => {
        console.log(e);
    }
    const handleOpen = (e) => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const classes = useStyles();

    const [editValues, setEditValues] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState({})
    const [subjects, setSubjects] = React.useState(null)
    const [hasInstructor, setHasInstructor] = React.useState(false)
    const [org, setOrg] = React.useState(null)

    const [values, setValues] = React.useState({})
    const [bookingState, setBookingState] = React.useState()
    const [spInfo, setSpInfo] = React.useState(null)

    const handleSelectChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
    }

    // Edit handlers
    const handleEditValues = (e) => {
        setInitialValues(values)
        setEditValues((prev) => !prev);
    }
    const handleUpdateValues = (e) => {
        setEditLoader(true)
        setEditValues((prev) => !prev);
        // Check if user under provided email adress already exists
        if (values.ins_email) {
            checkInstructorRequest({
                variables: {
                    email: values.ins_email,
                }
            })
        }
        else {
            updateCourseInfo({
                variables: {
                    course_id: values.course_id,
                    session_id: values.session_id,
                    course: {
                        course_name: values.course_name,
                        course_code: values.course_code,
                        course_crn: values.crn,
                        comment: values.notes,
                        platform: values.platform,
                        platform_password: values.password,
                        platform_url: values.url,
                        platform_username: values.username,
                        additional_reciptients: values.notes_recipient ? values.notes_recipient.toLowerCase() : null,
                        subject_ids: values.subject_matter,
                        // platform_location:null
                    },
                    courseSession: {
                        start_time: setSessionTime(values.first_date, values.start_time),
                        end_time: setSessionTime(values.first_date, values.end_time),
                    },
                    course_subject: {
                        subject_id: values.subject_matter,
                    }
                }
            })
        }
    }
    const handleCancelEdit = (e) => {
        setEditValues((prev) => !prev);
        setValues(initialValues)
        setAlert({
            message: 'Editing cancelled',
            severity: 'info'
        })
    }
    const handleUpdate = (e) => {
        setValues({ ...values, [e.target.id]: e.target.value })
    }
    const handleDateChange = (date, e) => {
        setValues({ ...values, 'first_date': date })
    }

    /* Tabs */
    const [value, setValue] = React.useState(0);
    const [bookingSeries, setBookingSeries] = React.useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Create Instructor in SMS
    const [createInstructorCall, { ins_loading, ins_error }] = useMutation(createInstructor, {
        onCompleted: (data) => {
            setValues({ ...values, 'instructor_id': data.insert_Instructor.returning[0].id })
            let instructor_id = data?.insert_Instructor?.returning[0]
            CreateInstructorTS(instructor_id)
        },
        onError: (err) => {
            console.log(err)
            setAlert({
                message: `An error has occured, please contact site administrator ${err}`,
                severity: 'error'
            })
            setEditLoader(false)
        }
    });

    const [upsertInstructorSMS] = useMutation(upsertInstructor, {
        onCompleted: (data) => {
            setAlert({
                message: 'Information updated successfully!',
                severity: 'success'
            })
            setEditLoader(false)
        },
        onError: (err) => {
            setAlert({
                message: `An error has occured, please contact site administrator ${err}`,
                severity: 'error'
            })
            setEditLoader(false)
        }
    });
    const [updateSessionTS] = useMutation(updateTSSession, {
        onCompleted: (data) => {
            if (hasInstructor) {
                setAlert({
                    message: 'Information updated successfully!',
                    severity: 'success'
                })
                setEditLoader(false)
            }
            else {
                upsertInstructorSMS({
                    variables: {
                        upsert_ins: {
                            course_id: values.course_id,
                            instructor_id: values.instructor_id,
                        }
                    }
                })
            }
        },
        onError: (err) => {
            setAlert({
                message: `An error has occured, please contact site administrator ${err}`,
                severity: 'error'
            })
            setEditLoader(false)
        }
    });

    // Create user in TS
    async function CreateInstructorTS(instructor_id) {
        const { data } = await post("create_user", {
            name: (values.ins_first_name ? values.ins_first_name.trim() : '').concat(' ', (values.ins_last_name ? values.ins_last_name.trim() : '')),
            first_name: values.ins_first_name ? values.ins_first_name.trim() : '',
            last_name: values.ins_last_name ? values.ins_last_name.trim() : '',
            email: values.ins_email.toLowerCase(),
            password: process.env.REACT_APP_STUDENT_DEFAULT_PASSWORD,
        });
        if (data) {
            //console.log(data,'TS call successfull')
            updateCourseInfo({
                variables: {
                    course_id: values.course_id,
                    session_id: values.session_id,
                    course: {
                        course_name: values.course_name,
                        course_code: values.course_code,
                        course_crn: values.crn,
                        comment: values.notes,
                        platform: values.platform,
                        platform_password: values.password,
                        platform_url: values.url,
                        platform_username: values.username,
                        additional_reciptients: values.notes_recipient ? values.notes_recipient.toLowerCase() : null,
                        subject_ids: values.subject_matter,
                        // platform_location:null
                    },
                    courseSession: {
                        start_time: setSessionTime(values.first_date, values.start_time),
                        end_time: setSessionTime(values.first_date, values.end_time),
                    },
                    instructor: {
                        instructor_id: instructor_id.id,
                    },
                    course_subject: {
                        subject_id: values.subject_matter,
                    }
                }
            })
        }
        if (data.error) {
            console.log(data, 'TS Call Error')
            setAlert({
                message: 'An error has occured, please contact site administrator.',
                severity: 'error'
            })
        }
    }

    // Course info update (SMS)
    const [updateCourseInfo] = useMutation((!values.ins_email ? updateCourse : updateCourseIns), {
        onCompleted: (data) => {
            updateSessionTS({
                context: {
                    uri: process.env.REACT_APP_TS_GRAPHQL_ENDPOINT,
                    headers: {
                        'x-hasura-admin-secret': process.env.REACT_APP_TS_HASURA_KEY,
                    }
                },
                variables: {
                    sms_id: sessionId,
                    task: {
                        start_time: setSessionTime(values.first_date, values.start_time),
                        end_time: setSessionTime(values.first_date, values.end_time),
                    }
                }
            })
        },
        onError: (err) => {
            setAlert({
                message: `An error has occured, please contact site administrator. ${err}`,
                severity: 'error'
            })
            setEditLoader(false)
        }
    });

    const getStudent = (data,type) =>{
        if(studentId){
            let student = data.filter(stu=>stu.Student.id === studentId)
            if(type === 'email'){
                return student[0].Student.User.email
            }
            else{
                return student[0].Student.User.first_name.concat(' ',student[0].Student.User.last_name)
            }
        }
        else{
            let student = data[0]
            if(type === 'email'){
                return student[0].Student.User.email
            }
            else{
                return student[0].Student.User.first_name.concat(' ',student[0].Student.User.last_name)
            }
        }
    }


    // Check booking status in TS
    async function bookingStatus(sessionID) {
        const { data } = await get("session_booking_status", {
            params: { sms_course_session_id: sessionID }
        });
        if (data.error || data.errorMessage || data?.status === 404) {
            console.log(data, 'Booking status error')
            setBookingState('Error occured')
            setLoader(false)
            return;
        }
        setBookingState(data?.sessions[0]?.status ?? 'No data')
        setLoader(false)
    }

    const [TSSessionStatus] = useLazyQuery(getSessionStatus, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data.calendar_tasks.length > 0) {
                setBookingState(data.calendar_tasks[0].status)
            }
            else {
                setBookingState('Status not found')
            }
            if (data.calendar_tasks.length > 0 && data.calendar_tasks[0].user) {
                setSpInfo({
                    name: data.calendar_tasks[0].user.first_name.concat(' ', data.calendar_tasks[0].user.last_name),
                    email: data.calendar_tasks[0].user.email,
                })
            }
            setLoader(false)
        },
        onError: error => {
            console.log('TS status error:', error)
            setBookingState('Error occured')
            setLoader(false)
        }
    })
    // Check if instructor exists  
    const [checkInstructorRequest] = useLazyQuery(checkInstructor, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            let instructorData
            if (data?.User[0]?.email && data?.User[0]?.role_id === process.env.REACT_APP_INSTRUCTOR_ROLE_ID) {
                setValues({ ...values, 'instructor_id': data.User[0].Instructors[0].id })
                // Assign instructor to course path, update course info
                updateCourseInfo({
                    variables: {
                        course_id: values.course_id,
                        session_id: values.session_id,
                        course: {
                            course_name: values.course_name,
                            course_code: values.course_code,
                            course_crn: values.crn,
                            comment: values.notes,
                            platform: values.platform,
                            platform_password: values.password,
                            platform_url: values.url,
                            platform_username: values.username,
                            additional_reciptients: values.notes_recipient ? values.notes_recipient.toLowerCase() : null,
                            subject_ids: values.subject_matter,
                            // platform_location:null
                        },
                        courseSession: {
                            start_time: setSessionTime(values.first_date, values.start_time),
                            end_time: setSessionTime(values.first_date, values.end_time),
                        },
                        instructor: {
                            instructor_id: data?.User[0]?.Instructors[0]?.id,
                        },
                        course_subject: {
                            subject_id: values.subject_matter,
                        }
                    }
                })
            }

            else if (data?.User[0]?.email && data?.User[0]?.role_id != process.env.REACT_APP_INSTRUCTOR_ROLE_ID) {
                setEditLoader(false)
                // End of path                 
                setAlert({
                    message: 'Following user is not an Instructor! Please provide different credentials',
                    severity: 'warning'
                })
                setEditValues(true)
            }

            else if (data?.User.length === 0) {
                //console.log('User doesn\'t exist')
                //Create user > SMS > TS > Mutate other data
                instructorData = {
                    User: {
                        data: {
                            role_id: process.env.REACT_APP_INSTRUCTOR_ROLE_ID,
                            first_name: values.ins_first_name ? values.ins_first_name.trim() : '',
                            last_name: values.ins_last_name ? values.ins_last_name.trim() : '',
                            email: values.ins_email.toLowerCase(),
                            title: values.ins_title ? values.ins_title.trim() : '',
                            organisation_id: values.organisation_id,
                        }
                    }
                }
                createInstructorCall({
                    variables: {
                        objects: instructorData
                    }
                })
            }

            else {
                setAlert({
                    message: `An error has occured, please contact site administrator`,
                    severity: 'error'
                })
                setEditLoader(false)
            }
        },
        onError: error => {
            console.log(error)
            setAlert({
                message: `An error has occured, please contact site administrator ${err}`,
                severity: 'error'
            })
            setEditLoader(false)
        }
    })

    const [requestBookingData, { loading, data, error }] = useLazyQuery(bookingRequest, {
        onCompleted: data => {
            if (error) {
                setLoadError(true);
                setAlert({
                    message: `An error has occured, please contact site administrator ${err}`,
                    severity: 'error'
                })
                setEditLoader(false)
                return;
            }

            if (data.Course[0].CourseInstructors.length) {
                setHasInstructor(true)
            }

            let currentSession = data.Course[0].CourseSessions.find(Session => Session.id === sessionId);

            setOrg(data.Course[0].Organisation.name)
            setSubjects(data.Subject)

            setValues({
                // Details
                course_id: data?.Course[0]?.id,
                course_name: data?.Course[0]?.course_name,
                crn: data?.Course[0]?.course_crn,
                language: data?.Course[0]?.CourseLanguages[0]?.Language.name,
                course_code: data?.Course[0]?.course_code,
                values, subject_matter: data?.Course[0]?.subject_ids,
                //Instructor
                ins_first_name: data?.Course[0]?.CourseInstructors[0]?.Instructor?.User?.first_name,
                ins_last_name: data?.Course[0]?.CourseInstructors[0]?.Instructor?.User?.last_name,
                ins_email: data?.Course[0]?.CourseInstructors[0]?.Instructor?.User?.email,
                ins_title: data?.Course[0]?.CourseInstructors[0]?.Instructor?.User?.title,
                ins_user_id: data?.Course[0]?.CourseInstructors[0]?.Instructor?.user_id,
                //Student
                // stu_name: data?.Course[0]?.CourseStudents[0]?.Student?.User?.first_name + ' ' + data?.Course[0]?.CourseStudents[0]?.Student?.User?.last_name,
                // stu_email: data?.Course[0]?.CourseStudents[0]?.Student?.User?.email,
                stu_name:getStudent(data.Course[0].CourseStudents,'name'),
                stu_email:getStudent(data.Course[0].CourseStudents,'email'),
                //Booking Creator - No data for now
                // Session
                //first_date:date,
                first_date: new Date(currentSession.start_time),
                start_time: new Date(currentSession.start_time),
                end_time: new Date(currentSession.end_time),
                time_zone: currentSession.tz,
                session_id: currentSession.id,
                //reccurence:null,
                notes: data?.Course[0]?.comment,
                notes_recipient: data?.Course[0]?.additional_reciptients,
                //Credentials
                platform: data?.Course[0]?.platform,
                url: data?.Course[0]?.platform_url,
                username: data?.Course[0]?.platform_username,
                password: data?.Course[0]?.platform_password,
                //Other
                time_difference: ((new Date(currentSession.end_time) - new Date(currentSession.start_time)) / ((1000 * 60 * 60))).toFixed(2),
                captioning: currentSession.live_captioning,
                note_taking: currentSession.live_note_taking,
                mp_link: 'N/A',
                session_status: null,
                organisation_id: data?.Course[0]?.organisation_id,
                baseTimeString: currentSession.start_time,
                localTime: currentSession.tz ? convertLocalTime(currentSession.tz, currentSession.start_time) : 'N/A'
            })
            setBookingSeries(data?.Course[0]?.CourseSessions)
            // bookingStatus(currentSession.id);
            TSSessionStatus({
                context: {
                    uri: process.env.REACT_APP_TS_GRAPHQL_ENDPOINT,
                    headers: {
                        'x-hasura-admin-secret': process.env.REACT_APP_TS_HASURA_KEY,
                    }
                },
                variables: {
                    id: currentSession.id
                }
            })
            //setLoader(false);
        },
        onError: data => {
            setLoadError(true);
            console.log(data, 'Error');
            setAlert({
                message: `An error has occured, please contact site administrator ${err}`,
                severity: 'error'
            })
            setEditLoader(false)
            return;
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (id !== undefined) {
            requestBookingData({ variables: { id: id, parent_id: process.env.REACT_APP_DEFAULT_SUBJECT_PARENT_ID } }, { fetchPolicy: 'network-only' })
        }
    }, []);

    const setSessionTime = (date, time) => {
        let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), 0, 0)
        let offset = (newDate.getTimezoneOffset() * 60000)
        return new Date(newDate - offset).toISOString().split('.')[0]
    }

    function convertLocalTime(tz, st) {
        let time = st.split('T').at(-1)
        let baseDate = new Date(st)
        let date = baseDate.toLocaleString('en-US', { timeZone: tz.trim(), timeZoneName: 'short' }).split(' ').at(0)
        let tzShort = baseDate.toLocaleString('en-US', { timeZone: tz.trim(), timeZoneName: 'short' }).split(' ').at(-1)

        let localTime = new Date(date.concat(' ', time, ' ', tzShort))
        return localTime.toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'long' })
    }

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Box mt={8}>
                    <Box mb={7}>
                        <Link to={'/booking'} children={<Button startIcon={<KeyboardReturn />}>Booking List</Button>} component={RouterLink} />
                        <Toast alert={alert} />
                        <PageTitle text="Booking details" />
                        {editLoader ? <Box mr={2}><CircularProgress /></Box> : null}
                    </Box>
                </Box>
                <Box
                    mb={5}
                    p={1}
                    elevation={0}
                    className={classes.info}
                    display="flex"
                    flexWrap="wrap"
                >
                    {loader
                        ?
                        <React.Fragment>
                            {loadError
                                ?
                                <Box width="100%" className={classes.infoBox} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                    <WarningRoundedIcon color="error" />
                                    <Typography variant="h6" color='error'> An error has occured!</Typography>
                                </Box>
                                :
                                <Box width="100%" className={classes.infoBox} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                    <Box mr={2}><CircularProgress /></Box>
                                    <Typography>Loading</Typography>
                                </Box>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {bookingSeries.length < (sessionIndex - 1)
                                ? null
                                :
                                <React.Fragment>
                                    <Box flexGrow={1} flexShrink={0} textAlign='center' className={classes.infoBox}>
                                        <Typography className={classes.header}>
                                            Booking Status
                                        </Typography>
                                        <Typography className={classes.subheader}>
                                            {bookingState}
                                        </Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box flexGrow={1} flexShrink={0} className={classes.infoBox} textAlign='center'>
                                        <Typography className={classes.header}>
                                            Service Selection
                                        </Typography>
                                        <Typography className={classes.subheader}>
                                            {values.captioning ? 'Captioning' : null}
                                            {values.captioning && values.note_taking ? ', ' : null}
                                            {values.note_taking ? 'Notetaking' : null}
                                        </Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box flexGrow={1} flexShrink={0} textAlign='center' className={classes.infoBox}>
                                        <Typography className={classes.header}>
                                            Duration
                                        </Typography>
                                        <Typography className={classes.subheader}>
                                            {values.time_difference}
                                            <React.Fragment>
                                                {values.time_difference < 2
                                                    ? ' Hour'
                                                    : ' Hours'
                                                }
                                            </React.Fragment>
                                        </Typography >
                                    </Box>
                                    {/* <Divider orientation="vertical" flexItem />
                    <Box flexGrow={1} textAlign='center'className={classes.infoBox}>
                        <Typography className={classes.header}>
                            Messenger Pigeon Link
                        </Typography>
                        <Typography className={classes.subheader}>
                            {values.mp_link}
                        </Typography>
                    </Box> */}
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </Box>

                <StyledTabHead className={classes.tabsHead} position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="Student tabs">
                        <Tab className={classes.tabs} label="Overview" {...a11yProps(0)} />
                        {/* <Tab disabled className={classes.tabs} label="Modifications" {...a11yProps(1)} /> */}
                    </Tabs>
                </StyledTabHead>
                <TabPanel value={value} index={0}>
                    {loader
                        ?
                        <Box
                            mb={7}
                            boxShadow={0}
                            className={classes.paper}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <React.Fragment>
                                {loadError
                                    ?
                                    <Box className={classes.paper} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                        <WarningRoundedIcon color="error" />
                                        <Typography variant="h6" color='error'> An error has occured!</Typography>
                                    </Box>
                                    :
                                    <React.Fragment>
                                        <Box mr={2}><CircularProgress /></Box>
                                        <Typography>Loading</Typography>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        </Box>
                        :
                        <React.Fragment>
                            {bookingSeries.length < (sessionIndex - 1)
                                ? <Box className={classes.paper} display="flex" justifyContent="center" alignItems="center" mr={2}>
                                    <WarningRoundedIcon color="error" />
                                    <Typography variant="h6" color='error'> No data found!</Typography>
                                </Box>
                                :
                                <React.Fragment>
                                    <Box
                                        mb={7}
                                        boxShadow={0}
                                        className={classes.paper}
                                    >
                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="start"
                                            >
                                                <BoldHeading text="Course Details" />
                                                {editLoader ?
                                                    <CircularProgress size={24} />
                                                    :
                                                    <React.Fragment>
                                                        {editValues
                                                            ?
                                                            <Box>
                                                                <Button onClick={handleCancelEdit}>Cancel</Button>
                                                                <Button onClick={handleUpdateValues}>Save</Button>
                                                            </Box>
                                                            :
                                                            <IconButton onClick={handleEditValues}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </Box>
                                            <Divider className={classes.divider} />
                                            {/* Course Details */}
                                            <Grid spacing={8} container>
                                                <Grid item md={6}>
                                                    <label className={classes.label} htmlFor="course_name" >Title</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Course Title'
                                                            variant="outlined"
                                                            id='course_name'
                                                            readOnly={editValues ? false : true}
                                                            value={values.course_name ?? ''}
                                                            placeholder={values.course_name == null ? 'Not provided' : null}
                                                            onChange={handleUpdate}
                                                            name='Course Title'
                                                        />
                                                    </FormControl>
                                                    <label className={classes.label} htmlFor="crn" >CRN</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='crn'
                                                            variant="outlined"
                                                            id='crn'
                                                            value={values.crn ?? ''}
                                                            placeholder={values.crn == null ? 'Not provided' : null}
                                                            readOnly={editValues ? false : true}
                                                            onChange={handleUpdate}
                                                            name='crn'
                                                        />
                                                    </FormControl>
                                                    {/* <label className={classes.label} htmlFor="language" >Language of class delivery</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='language'
                                                            variant="outlined"
                                                            id='language'
                                                            value={values.language ?? ''}
                                                            placeholder={values.language == null ? 'Not provided' : null}
                                                            readOnly={editValues ? false : true}
                                                            onChange={handleUpdate}
                                                            name='language'
                                                        />
                                                    </FormControl> */}
                                                </Grid>
                                                <Grid item md={6}>
                                                    <label className={classes.label} htmlFor="course_code" >Course code</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Course code'
                                                            variant="outlined"
                                                            id='course_code'
                                                            value={values.course_code ?? ''}
                                                            placeholder={values.course_code == null ? 'Not provided' : null}
                                                            readOnly={editValues ? false : true}
                                                            onChange={handleUpdate}
                                                            name='Course Code'
                                                        />
                                                    </FormControl>
                                                    <label className={classes.label} htmlFor="subject_matter" >Subject matter</label>
                                                    <Subjects
                                                        value={values.subject_matter ?? ''}
                                                        onHandleSelectChange={handleSelectChange}
                                                        readOnly={editValues}
                                                        subjects={subjects}
                                                        className={clsx(classes.input, editValues ? null : classes.selectDisabled)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/* Instructor Details */}
                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="start"
                                            >
                                                <BoldHeading text="Instructor Details" />
                                                {editLoader ?
                                                    <CircularProgress size={24} />
                                                    :
                                                    <React.Fragment>
                                                        {editValues
                                                            ?
                                                            <Box>
                                                                <Button onClick={handleCancelEdit}>Cancel</Button>
                                                                <Button onClick={handleUpdateValues}>Save</Button>
                                                            </Box>
                                                            :
                                                            <IconButton onClick={handleEditValues}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </Box>
                                            <Divider className={classes.divider} />
                                            <Grid spacing={8} container>
                                                <Grid item md={6}>
                                                    <Box className={classes.formRow} display="flex">
                                                        <Grid item md={6}>
                                                            <label className={classes.label} htmlFor="ins_first_name" >First Name</label>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    className={classes.input}
                                                                    aria-labelledby='Instructor First Name'
                                                                    variant="outlined"
                                                                    id='ins_first_name'
                                                                    readOnly={editValues ? false : true}
                                                                    placeholder={values.ins_first_name == null ? 'Not provided' : null}
                                                                    value={values.ins_first_name ?? ''}
                                                                    onChange={handleUpdate}
                                                                    name='Instructor first name'
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <label className={classes.label} htmlFor="ins_last_name" >Last Name</label>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    className={classes.input}
                                                                    aria-labelledby='Instructor Last Name'
                                                                    variant="outlined"
                                                                    id='ins_last_name'
                                                                    value={values.ins_last_name ?? ''}
                                                                    placeholder={values.ins_last_name == null ? 'Not provided' : null}
                                                                    readOnly={editValues ? false : true}
                                                                    onChange={handleUpdate}
                                                                    name='Instructor last name'
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                    <label className={classes.label} htmlFor="ins_email" >Email adress</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Instructor email adress'
                                                            variant="outlined"
                                                            id='ins_email'
                                                            value={values.ins_email ?? ''}
                                                            placeholder={values.ins_email == null ? 'Not provided' : null}
                                                            readOnly={editValues ? false : true}
                                                            onChange={handleUpdate}
                                                            name='Instructor email'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <label className={classes.label} htmlFor="ins_title" >Title</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Instructor title'
                                                            variant="outlined"
                                                            id='ins_title'
                                                            value={values.ins_title ?? ''}
                                                            placeholder={values.ins_title == null ? 'Not provided' : null}
                                                            readOnly={editValues ? false : true}
                                                            onChange={handleUpdate}
                                                            name='Instructor title'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/* Student Details */}
                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="start"
                                            >
                                                <BoldHeading text="Student Details" />
                                            </Box>
                                            <Divider className={classes.divider} />
                                            <Grid spacing={8} container>
                                                <Grid item md={6}>
                                                    <Typography className={classes.label}>
                                                        Name
                                                    </Typography>
                                                    <Box alignItems="center" display="flex">
                                                        <Box>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    className={classes.input}
                                                                    aria-labelledby='Student Name'
                                                                    variant="outlined"
                                                                    id='stu_name'
                                                                    value={values.stu_name ?? 'Missing Credentials!'}
                                                                    readOnly={true}
                                                                    name='Student Name'
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        {/* <Box flexGrow={1}>
                                    <Typography style={{marginBottom:'24px'}}>
                                        <Link href="#" color="primary">
                                            View account
                                        </Link>
                                    </Typography>
                                </Box> */}
                                                    </Box>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <label className={classes.label} htmlFor="stu_email" >Student Email</label>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            className={classes.input}
                                                            aria-labelledby='Student email'
                                                            variant="outlined"
                                                            id='stu_email'
                                                            value={values.stu_email ?? 'Missing Credentials!'}
                                                            readOnly={true}
                                                            name='Student Email'
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/* SP Details */}
                                        {spInfo ?
                                            <Box>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="space-between"
                                                    alignItems="start"
                                                >
                                                    <BoldHeading text="Student Details" />
                                                </Box>
                                                <Divider className={classes.divider} />
                                                <Grid spacing={8} container>
                                                    <Grid item md={6}>
                                                        <label for="sp_name" className={classes.label}>
                                                            Name
                                                        </label>
                                                        <Box alignItems="center" display="flex">
                                                            <Box>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <OutlinedInput
                                                                        className={classes.input}
                                                                        aria-labelledby='Service provider Name'
                                                                        variant="outlined"
                                                                        id='sp_name'
                                                                        value={spInfo.name ?? 'Missing Credentials!'}
                                                                        readOnly={true}
                                                                        name='Service provider Name'
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <label className={classes.label} htmlFor="sp_email" >Service provider Email</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Service provider email'
                                                                variant="outlined"
                                                                id='sp_email'
                                                                value={spInfo.email ?? 'Missing Credentials!'}
                                                                readOnly={true}
                                                                name='Service provider Email'
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            : null
                                        }
                                    </Box>
                                    {/* Booking Creator - No data to display for now */
            /* <Box
                mb={7}
                boxShadow={0}
                className={classes.paper}
            >
                <Box>
                    <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="start"
                    >
                        <BoldHeading text="Booking Creator"/>
                    </Box>
                    <Divider className={classes.divider} /> 
                    <Grid spacing={8} container>
                        <Grid item md={6}>
                            <label className={classes.label} htmlFor="creator_name" >Name</label> 
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                className={classes.input}
                                aria-labelledby='creator_name'
                                variant="outlined"
                                id='creator_name'
                                value={values.book_name}
                                readOnly={true}
                                name='Creator Name' 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <label className={classes.label} htmlFor="creator_email" >Email</label> 
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                className={classes.input}
                                aria-labelledby='Creator Email'
                                variant="outlined"
                                id='creator_email'
                                readOnly={true}
                                value={values.book_email}
                                name='Creator Email' 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */
           /* Booking Schedule */}
                                    <Box
                                        mb={7}
                                        boxShadow={0}
                                        className={classes.paper}
                                    >
                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="start"
                                            >
                                                <BoldHeading text="Booking Schedule" />
                                                {editLoader ?
                                                    <CircularProgress size={24} />
                                                    :
                                                    <React.Fragment>
                                                        {editValues
                                                            ?
                                                            <Box>
                                                                <Button onClick={handleCancelEdit}>Cancel</Button>
                                                                <Button onClick={handleUpdateValues}>Save</Button>
                                                            </Box>
                                                            :
                                                            <IconButton onClick={handleEditValues}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </Box>
                                            <Divider className={classes.divider} />
                                            <Grid spacing={8} container>
                                                <Grid item md={6}>
                                                    <Box className={classes.formRow} display="flex">
                                                        <Grid item md={4}>
                                                            <label className={classes.label} htmlFor="Date" >Date</label>
                                                            <FormControl fullWidth variant="outlined">
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        keyboardIcon={editValues ? <Today /> : null}
                                                                        className={classes.input}
                                                                        autoOk
                                                                        inputVariant="outlined"
                                                                        variant="inline"
                                                                        format="MM/dd/yyyy"
                                                                        value={values.first_date ?? new Date()}
                                                                        readOnly={editValues ? false : true}
                                                                        inputProps={{ readOnly: editValues ? false : true }}
                                                                        name="First date"
                                                                        id="first_date"
                                                                        onChange={date => handleDateChange(date)}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                    <Box className={classes.formRow} display="flex">
                                                        <Grid item md={4}>
                                                            <label className={classes.label} htmlFor="start_time" >Start time</label>
                                                            <FormControl fullWidth variant="outlined">
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardTimePicker
                                                                        keyboardIcon={editValues ? <Schedule /> : null}
                                                                        className={classes.input}
                                                                        variant="inline"
                                                                        inputVariant='outlined'
                                                                        value={values.start_time ?? new Date()}
                                                                        id="start_time"
                                                                        name="Start time"
                                                                        onChange={date => setValues({ ...values, 'start_time': date })}
                                                                        ampm={true}
                                                                        autoOk
                                                                        inputProps={{ readOnly: editValues ? false : true }}
                                                                        readOnly={editValues ? false : true}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <label className={classes.label} htmlFor="end_time" >End Time</label>
                                                            <FormControl fullWidth variant="outlined">
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardTimePicker
                                                                        keyboardIcon={editValues ? <Schedule /> : null}
                                                                        className={classes.input}
                                                                        variant="inline"
                                                                        inputVariant='outlined'
                                                                        value={values.end_time ?? new Date()}
                                                                        id="end_time"
                                                                        name="End time"
                                                                        onChange={date => setValues({ ...values, 'end_time': date })}
                                                                        ampm={true}
                                                                        autoOk
                                                                        inputProps={{ readOnly: editValues ? false : true }}
                                                                        readOnly={editValues ? false : true}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="time_zone" >Time Zone</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Time Zone'
                                                                variant="outlined"
                                                                id='time_zone'
                                                                readOnly
                                                                value={values.time_zone ?? 'Not provided'}
                                                                onChange={handleUpdate}
                                                                name='Time Zone'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="time_zone" >Local Start Time</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Local start time'
                                                                variant="outlined"
                                                                id='local_start_time'
                                                                readOnly
                                                                value={values.localTime ?? 'Not provided'}
                                                                onChange={handleUpdate}
                                                                name='Local start time'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    {/* Reccurence details WiP, not important for now though
                             <Box>
                                <label className={classes.label} htmlFor="recurrence_details" >Recurrence Details</label> 
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                    className={classes.input}
                                    aria-labelledby='Recurrence details'
                                    variant="outlined"
                                    id='recurrence_details'
                                    readOnly
                                    value={values.reccurence ?? 'Not provided'}
                                    onChange={handleUpdate}
                                    name='Recurrence details' 
                                    />
                                </FormControl>
                            </Box> */}
                                                </Grid>
                                                <Grid item md={6}>
                                                    {/* MP Link isn't in dbs yet
                            <Box>
                                <label className={classes.label} htmlFor="messenger_pigeon_link" >Messenger Pigeon Link</label> 
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                    className={classes.input}
                                    aria-labelledby='Messenger Pigeon Link'
                                    variant="outlined"
                                    multiline={true}
                                    rows='2'
                                    id='messenger_pigeon_link'
                                    readOnly={true}
                                    inputProps={{readOnly:true}}
                                    value={values.mp_link ?? ''}
                                    placeholder={values.mp_link == null ? 'Not available' : null}
                                    name='Messenger Pigeon Link' 
                                    />
                                </FormControl>
                            </Box> */}
                                                    <Box>
                                                        <label className={classes.label} htmlFor="Notes" >Notes</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Notes'
                                                                variant="outlined"
                                                                id='notes'
                                                                multiline={true}
                                                                rows='3'
                                                                readOnly={editValues ? false : true}
                                                                value={values.notes ?? ''}
                                                                placeholder={values.notes == null ? 'Not provided' : null}
                                                                onChange={handleUpdate}
                                                                name='Notes'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="notes_recipient" >Summary notes recipient</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Summary Notes Recipient'
                                                                variant="outlined"
                                                                id='notes_recipient'
                                                                readOnly={editValues ? false : true}
                                                                value={values.notes_recipient ?? ''}
                                                                placeholder={values.notes_recipient == null ? 'Not provided' : null}
                                                                onChange={handleUpdate}
                                                                name='Summary Notes Recipient'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                    {/* Credentials */}
                                    <Box
                                        mb={7}
                                        boxShadow={0}
                                        className={classes.paper}
                                    >
                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="start"
                                            >
                                                <BoldHeading text="Credentials" />
                                                {editLoader ?
                                                    <CircularProgress size={24} />
                                                    :
                                                    <React.Fragment>
                                                        {editValues
                                                            ?
                                                            <Box>
                                                                <Button onClick={handleCancelEdit}>Cancel</Button>
                                                                <Button onClick={handleUpdateValues}>Save</Button>
                                                            </Box>
                                                            :
                                                            <IconButton onClick={handleEditValues}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </Box>
                                            <Divider className={classes.divider} />
                                            <Grid spacing={8} container>
                                                <Grid item md={6}>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="Platform" >Platform</label>
                                                        <Platforms
                                                            org={org}
                                                            value={values.platform ?? ''}
                                                            onHandleSelectChange={handleSelectChange}
                                                            readOnly={editValues}
                                                            className={clsx(classes.input, editValues ? null : classes.selectDisabled)}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="url" >Link</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Link'
                                                                variant="outlined"
                                                                id='url'
                                                                readOnly={editValues ? false : true}
                                                                value={values.url ?? ''}
                                                                placeholder={values.url == null ? 'Not provided' : null}
                                                                onChange={handleUpdate}
                                                                name='url'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="Username" >Username</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Username'
                                                                variant="outlined"
                                                                id='username'
                                                                readOnly={editValues ? false : true}
                                                                value={values.username ?? 'Not provided'}
                                                                placeholder={values.username == null ? 'Not provided' : null}
                                                                onChange={handleUpdate}
                                                                name='username'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <Box>
                                                        <label className={classes.label} htmlFor="Password" >Password</label>
                                                        <FormControl fullWidth variant="outlined">
                                                            <OutlinedInput
                                                                className={classes.input}
                                                                aria-labelledby='Password'
                                                                variant="outlined"
                                                                id='password'
                                                                readOnly={editValues ? false : true}
                                                                value={values.password ?? ''}
                                                                placeholder={values.password == null ? 'Not provided' : null}
                                                                onChange={handleUpdate}
                                                                name='Password'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {/* <Box>
                        <PillButton noIcon variant="accentOutlined" text="Request missing notes"/>
                    </Box> */}
                                        </Box>
                                    </Box>
                                    {/* Other Booking in the series */}
                                    <Box
                                        mb={7}
                                        boxShadow={0}
                                        className={classes.paper}
                                    >
                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="start"
                                            >
                                                <BoldHeading text="Other Bookings in the Series" />
                                            </Box>
                                            <Divider className={classes.divider} />
                                            <Grid spacing={8} container>
                                                <Grid item md={6}>
                                                    <Box>
                                                        <BookingsTimeline sessionId={sessionId} bookingSeries={bookingSeries} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </TabPanel>
                {/* 2nd Panel */}
                <TabPanel value={value} index={1}>
                    <Box
                        boxShadow={0}
                        className={classes.paper}
                    >
                        <Box mb={3}>
                            <BoldHeading text="Booking List" />
                            <Divider />
                        </Box>
                    </Box>
                </TabPanel>
                <StylizedModal header="Save Changes?" content="Are you sure you want to make changes to student profile?" open={open} onHandleConfirm={handleConfirm} onHandleClose={handleClose} />
            </Container>
        </React.Fragment>
    );
};


export function Toast(props) {

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (props.alert.message === null || props.alert.severity === null) {
            return;
        }
        else {
            setOpen(true)
        }
    }, [props.alert]);

    return (
        <React.Fragment>
            <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.alert.severity}>
                    {props.alert.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
function Alert(props) {
    return <MuiAlert elevation={4} variant="filled" {...props} />;
}

function Subjects(props) {

    return (
        <React.Fragment>
            <FormControl fullWidth variant="outlined">
                <Select
                    value={props.value}
                    onChange={props.onHandleSelectChange}
                    className={props.className}
                    displayEmpty
                    name='subject_matter'
                    readOnly={props.readOnly ? false : true}
                    inputProps={{ readOnly: props.readOnly ? false : true }}
                >
                    <MenuItem disabled value="">Not provided</MenuItem>
                    {props.subjects.map(Subject => {
                        return (
                            <MenuItem key={Subject.id} value={Subject.id}>{Subject.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}
function Platforms(props) {
    const platformsBase = [
        { Name: 'Coral', id: 0 },
        { Name: 'Zoom', id: 10 },
        { Name: 'Microsoft Teams', id: 20 },
        { Name: 'WebEx', id: 30 },
        { Name: 'Blackboard', id: 40 },
        { Name: 'Canvas', id: 50 },
        { Name: 'Moodle', id: 60 },
        { Name: 'Big Blue Button', id: 70 },
        { Name: 'Slate', id: 80 },
        { Name: 'Other', id: 90 },
        { Name: 'In person', id: 100 },
        { Name: 'To be provided later', id: 110 },
        { Name: 'Not required', id: 120 },
    ]
    const [platforms, setPlatforms] = React.useState(platformsBase)


    useEffect(() => {
        if (props.org && props.org.toLowerCase() == 'connexcel') {
            setPlatforms([{ Name: 'Zhumu', id: 130 }, ...platformsBase])
        }
    }, [props.org])

    useEffect(() => {
        if (!platforms.filter(el => el.Name.includes(props.value)).length) {
            let platformsCopy = [...platforms]
            platformsCopy.push({ Name: props.value, id: platformsCopy.length * 10 })
            setPlatforms(platformsCopy)
        }
    }, [props.value])

    return (
        <React.Fragment>
            <FormControl fullWidth variant="outlined">
                <Select
                    displayEmpty
                    value={props.value ?? ''}
                    onChange={props.onHandleSelectChange}
                    className={props.className}
                    name='platform'
                    readOnly={props.readOnly ? false : true}
                    inputProps={{ readOnly: props.readOnly ? false : true }}
                >
                    <MenuItem disabled value="">Not provided</MenuItem>
                    {platforms.map(Platform => {
                        return (
                            <MenuItem key={Platform.id} value={Platform.Name}>{Platform.Name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}
import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
  typography:{
    fontFamily:[
      'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    averta:[
      'Averta',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    ppWoodland:[ 
      '"PP Woodland"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1:{
      fontSize:'1rem',
      lineHeight:'1.375rem',
      letterSpacing:'0.5px',
    },
    h2:{
      fontSize:'2.125rem',
      lineHeight:'2.5rem',
      fontFamily:'"PP Woodland"',
      fontWeight:400,
    },
  },
  palette: {
    secondary:'#705C76',
    text:{
      emphasis1:'#1b1b1b',
      light:{
        onSecondaryHighEmphasis:'#fbfbfa',
      },
      dark:{
        primary:'#818181',
        black:'#000000',
        body:'#333333',
        mediumEmphasis:'rgba(0,0,0,0.4)',
        mediumEmphasis2: 'rgba(27, 27, 27, 0.74)',
      },
    },
    primary: {
      main: '#877594',
    },
    secondary: {
      main: '#55BBB2',
    },
    accent: {
      purple:'#877594',
      gold:'#C3782E',
    },
    error:{
      main:'#B00020',
    },
    background: {
      default: '#FBFBFA',
      loginBg:"F1F1F1",
      paper:'#FBFBFA',
      card:'#F7F7F7;',
      white:'#FFFFFF',
      onSurface:'#333333',
      surface2: 'rgba(196, 196, 196, 0.2)'
    },
  },
  // palette:{
  //   primary:{
  //     50:'#F2E7FE',
  //     100:'#DBB2FF',
  //     200:'#BB86FC',
  //     300:'#985EFF',
  //     A400:'#7F39FB',
  //     500:'#6200EE',
  //     600:'#5600E8',
  //     700:'#3700B3',
  //     800:'#30009C',
  //     900:'#23036A',
  //   },
  //   secondary:{
  //     50:'#F2E8F0',
  //     100:'#D5C9D6',
  //     200:'#B5A8B9',
  //     300:'#97869A',
  //     A400:'#806D85',
  //     500:'#6A5370',
  //     600:'#5E4B65',
  //     700:'#503F57',
  //     800:'#3F334A',
  //     900:'#30263B',
  //   },
  //   accent1:'#5B8466',
  //   accent2:'#877594',
  //   background:'#F2F7F0',
  //   surface:'#FBFBFA',
  //   error:'#B00020',
  //   onPrimary:'#FBFBFA',
  //   onSecondary:'#000000',
  //   onBackground:'#1B1B1B',
  //   onSurface:'#1B1B1B',
  //   onError:'#FBFBFA',
  //  },
});

export default theme;

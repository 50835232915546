import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextInput from '../BookingCommon/TextInput';
import InstanceDate from './InstanceDate';
import InstanceTime from './InstanceTime';
import CustomSwitch from '../../Common/CustomSwitch';
import format from 'date-fns/format';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { getHours } from 'date-fns';
import { DateComparer } from '../../../services/dateComparer';

const useStyles = makeStyles((theme) => ({
    tableCell:{
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        fontFamily:theme.typography.averta,
        letterSpacing:'0.15px',
        fontWeight:'normal',
        '&sizeSmall':{
          padding:0,
        }
    },
    switchText:{
      fontSize:'0.875rem',
      lineHeight:'1rem',
      fontWeight:500,
      textAlign:'right',
      letterSpacing:'0.1px',
      },
    inputText:{
      fontSize:'0.875rem',
      lineHeight:'1rem',
      fontWeight:700,
      letterSpacing:'0.1px',
      marginBottom:theme.spacing(0.625)
    },
}));


export default function InstanceRow(props) {
  const [values, setValues] = React.useState( props.row ?? {
      booked:true,
      date: format(new Date(), 'MM/dd/yyyy'),
      start:'08:00',
      end:'10:30',
      message:'',
      hours:2.5
  })
  const classes = useStyles();
  const dateCpompar = new DateComparer()
  const handleDateChange = (event,props) =>{
    setValues({...values,date:props});
  }

  const handleTimeChange = (id,prop) =>{
    var hours = dateCpompar.getHours(id=='start'?prop: values.start,id=='end'?prop: values.end);
    setValues({...values,[id]:prop,hours:hours});
  //  setValues({...values,hours:housr});
  }
  const handleSwitchChange = (event) =>{
    setValues({...values,'booked':event})
  }

  const handleTextChange = (prop) => (event) =>{
    setValues({ ...values, [prop]: event.target.value });
  }

  useEffect(()=>{
    props.onUpdateValues(values,props.rowID);
  },[values])

  return (
    <React.Fragment>
      <TableRow className={classes.tableCell}>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {props.rowNum}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
            <Box display="flex">{values.date} <InstanceDate  onHandleChange={handleDateChange} value={values.date}/></Box>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
            <Box display="flex">{values.start} <InstanceTime timeId="start" onHandleChange={handleTimeChange}  value={values.start} text="Edit Start Time"/> </Box>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
            <Box display="flex">{values.end} <InstanceTime timeId="end" onHandleChange={handleTimeChange}  value={values.end} text="Edit End Time"/> </Box>
        </TableCell>
        <TableCell width={250} className={classes.tableCell} align="right">
          <Box justifyContent="flex-end" alignItems="center" display="flex"> 
          <Typography className={classes.switchText}>
          {values.booked ? 'Booked' : 'Not booked'}
          </Typography>           
          <CustomSwitch state={values.booked} onHandleSwitchChange={handleSwitchChange} name={('Override'+(props.rowNum)).toString()} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 'unset' }} colSpan={6}>
          <Collapse in={values.booked === false} timeout="auto" unmountOnExit>
            <Box margin={1}>
                <Typography className={classes.inputText}>Please leave a note</Typography>
                <TextInput onHandleChange={handleTextChange} name="message" placeholder="Message" id="message" value={values.message}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

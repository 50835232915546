import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header:{
        fontSize:'0.875rem',
        lineHeight:'1rem',
        textTransform:'uppercase',
        letterSpacing:'1.25px',
        color:theme.palette.text.dark.body,
        fontWeight:500,
    },
}));

export default function ButtonHeading(props) {
    const classes = useStyles();
    return (
        <Typography align={props.textAlign} className={classes.header}>{props.text}</Typography>
    );
  }
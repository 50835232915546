import { useState } from 'react';

const axios = require('axios').default;

export default function getService() {

  const url1 = process.env.REACT_APP_API_ENDPOINT_4
  const url2 = process.env.REACT_APP_API_ENDPOINT_2
  const url3 = process.env.REACT_APP_API_ENDPOINT_3
  const url4 = process.env.REACT_APP_API_ENDPOINT_5

  const postAxios = function get(slug,params,urltype){
    let url
    switch (urltype){
      case 1:
        url = url1
        break;
      case 2:
        url = url2
        break;
      case 3:
        url = url3
        break;
      case 4:
        url = url4
        break;
      default:
        url = url1
    }
    try{
      return axios.get(url+slug,params).catch(error=>{
        if (error.response) {
        return error.response;
        }
        return {data:{error:true,message:error.message}};
      });
    }
    catch(error) {
      return {data:{error:true,message:error.message}};
    };
  }
  return {
    get: postAxios
  }
}
import React from 'react';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    studentListHeader:{
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.5rem',
        lineHeight:'1.5rem',
        marginBottom:theme.spacing(2),
        minHeight:48,
    },
    formField:{
        marginBottom:theme.spacing(3),
        '& fieldset':{
            border:'solid 1px #3c3c3c',
        },
    },
}));

export default function StudentSearch(props){

    const classes = useStyles();

    return(
    <React.Fragment>
        <Typography className={classes.studentListHeader} variant="body1" component="p">
            Lookup an existing student
        </Typography>
        <FormControl fullWidth className={classes.formField} variant="outlined">
            <OutlinedInput
            variant="outlined"
            id="studentSearch"
            placeholder="Type here"
            name="studentSearch"
            autoComplete="studentSearch"
            onChange={props.onHandleSearchChange()}
            value={props.SearchValue}
            endAdornment={
                <InputAdornment position="end"
                disablePointerEvents
                >
                <IconButton 
                    aria-label="search student list"
                    edge="end"
                    >
                    <SearchIcon/>
                </IconButton>
                </InputAdornment>
            }
            />
        </FormControl>
    </React.Fragment>
    )
}
import React, {Suspense} from 'react'
import { Route, Switch,useRouteMatch, useHistory } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../components/Common/PageTitle'
import { makeStyles } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import AccountProfile from '../components/Account/AccountProfile';
import AccountOrganizations from '../components/Account/AccountOrganisations';
import OrganizationProfile from '../components/Account/OrganizationProfile';
import AddAdmin from '../components/Account/AddAdmin';
import { Link as RouterLink } from 'react-router-dom';
import { KeyboardReturn } from '@material-ui/icons';
import ChangePassword from '../components/Account/ChangePassword';


const useStyles = makeStyles((theme) => ({

}));

export default function Account(props){
    let history = useHistory();

    let {path,url} = useRouteMatch();
    
    const [values, setValues] = React.useState({});

    const goBack = () => {
      history.goBack()
  }
    // Lift state up - receiving end
    const setProfileInfo = (values) =>{
      setValues(values)
    }
    const handleUpdate = (e) =>{
        console.log(values);
    }
    return(
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={10} lg={8}>
                            <Switch>
                              <Route exact path={path}>
                                <PageTitle text='My Account'/>
                                <Box mb={2}>
                                  <Paper>
                                    <Box p={4}>
                                      <AccountProfile onProfileInfo={setProfileInfo} user={props.user} />  
                                    </Box>
                                    {/* <Box p={4} mt={1}>
                                      <ChangePassword email={values.email} />  
                                    </Box> */}
                                  </Paper>
                                </Box>
                                <Box mb={2}>
                                  <Paper>
                                    <Box p={4}>
                                    <AccountOrganizations organisations={props.user?.duorg_id} />
                                    </Box>
                                  </Paper>
                                </Box>
                              </Route>
                              <Route exact path={`${path}/organization/:id`}>
                                <Button startIcon={<KeyboardReturn/>} component={RouterLink} to="/account" color="inherit">
                                    Return to Profile
                                </Button>
                               <PageTitle text='Organisation Profile'/>                               
                               <Box mb={2}>
                                    <OrganizationProfile user={props.user}/>
                                </Box>
                              </Route>
                              <Route exact path={`${path}/organization/:id/addAdmin`}>
                                <AddAdmin user={props.user}/>
                              </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BookingHome from './bookingHome';
import CourseSelect from '../components/BookingProcess/CourseSelect/CourseSelect';
import CourseDetails from '../components/BookingProcess/CourseDetails/CourseDetails';
import CourseSchedule from '../components/BookingProcess/CourseSchedule/CourseSchedule';
import CourseServices from '../components/BookingProcess/CourseServices/CourseServices';
import CourseCredentials from '../components/BookingProcess/CourseCredentials/CourseCredentials';
import CourseInstances from '../components/BookingProcess/CourseInstances/CourseInstances';
import CourseReview from '../components/BookingProcess/CourseReview/CourseReview';
import BookingComplete from '../components/BookingProcess/BookingComplete/BookingComplete';
import CourseSessions from '../components/BookingProcess/CourseSessions/CourseSessions';


export default function BookingProcess(props) {
    let { path } = useRouteMatch();
    return (
        <React.Fragment>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route exact path={path} component={e => <BookingHome course={props.course} user={props.user} />} />
                    <Route path={`${path}/CourseSelect`} component={e => <CourseSelect user={props.user} course={props.course} />} />
                    <Route path={`${path}/CourseDetails`} component={e => <CourseDetails user={props.user} course={props.course} />} />
                    <Route path={`${path}/CourseSchedule`} component={e => <CourseSchedule course={props.course} />} />
                    <Route path={`${path}/CourseServices`} component={e => <CourseServices user={props.user} course={props.course} />} />
                    <Route path={`${path}/CourseSessions`} component={e => <CourseSessions user={props.user} course={props.course} />} />
                    <Route path={`${path}/CourseCredentials`} component={e => <CourseCredentials user={props.user} course={props.course} />} />
                    <Route path={`${path}/CourseInstances`} component={e => <CourseInstances course={props.course} />} />
                    <Route path={`${path}/CourseReview`} component={e => <CourseReview course={props.course} user={props.user} />} />
                    <Route path={`${path}/BookingComplete`} component={BookingComplete} />
                    <Route component={() => (<div>404 Not found </div>)} />
                </Switch>
            </Suspense>
        </React.Fragment>
    );
};
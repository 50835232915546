import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import StudentEntry from './StudentListEntry';
import CircularProgress from '@material-ui/core/CircularProgress';

const getStudentList = gql`
query studentQuery($organisation_id: uuid) {
  User(where: {_or:[{deleted: {_neq: true}},{deleted: {_is_null: true}}] Students: {}, organisation_id: {_eq: $organisation_id}}, order_by: {last_name: asc}) {
    Students {
      id
    }
    first_name
    id
    last_name
    email
  }
}`;



export default function StudentListQuery(props){
    let orgId = props.user.dorg_id??props.user.duorg_id

    if(!orgId){
      orgId = props.user.org_id
    }


    const queryId = Array.isArray(orgId) ?props.organization: orgId

    const { loading, error, data,refetch } = useQuery(getStudentList,{
      variables:{
        organisation_id: queryId
      }
    }); 

    /* INF - Pass a changing prop here, function will run again*/ 
    useEffect(()=>{
      refetch({
        organisation_id:queryId
      })
    },[queryId])

    if (loading) { 
      return <CircularProgress/>; 
    } 
    if (error) { 
      console.error(error); 
      return <div>Error!</div>; 
    } 
    if (props.SearchValue != ''){
      const SearchResults = data.User.filter(user => {
        return(
          [user.first_name,user.last_name].join(' ').toLowerCase().includes(props.SearchValue.toLowerCase().trim())
        )},
      )
      if (SearchResults !=""){
        if(loading){
          return <CircularProgress/>; 
        }
        return(
          SearchResults.map(({first_name, last_name, id, email,Students}) =>(
            <StudentEntry onHandleLookupStudent={props.onHandleLookupStudent} email={email} id={Students[0].id} key={id} first_name={first_name} last_name={last_name} />
          ))
        )
      }
      else{
        return(
          <div>No students found</div>
        )
      }

    }
    if(data?.User?.length === 0){
      return(
        <div>No students available</div>
      )
    }
    else{
    return data.User.map(({first_name, last_name, id, email,Students}) =>(
      <StudentEntry onHandleLookupStudent={props.onHandleLookupStudent} email={email} id={Students[0].id} key={id} first_name={first_name} last_name={last_name} />
    )); 
    }
  };
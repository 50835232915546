import React from 'react';
import { withStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';


const CustomProgressBar = withStyles(() => ({
    root: {
      height: 8,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: 'rgba(196, 196, 196, 0.3)',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FBD3AC',
    },
  }))(LinearProgress);

  
export default function ProgressBar(props){
    const normalise = value => (value - props.min) * 100 / (props.max - props.min);
    
    return (
        <CustomProgressBar variant="determinate" value={normalise(props.value)}/>
    )
}
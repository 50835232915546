import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    label:{
        marginRight:'auto',
        marginLeft:'unset',
        '& .MuiFormControlLabel-label':{
            minWidth:125,
        },
    },
  }));

  const CCheckbox = withStyles((theme) => ({
    root: {
        color:theme.palette.text.dark.body,
      '&$checked': {
        color:theme.palette.text.dark.body,
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />);

export default function CustomCheckbox(props) {
    const classes = useStyles();
  return (
    <FormControl component="fieldset">
        <FormControlLabel className={classes.label}
          control={<CCheckbox label={props.label} name={props.name} onChange={props.onCheckboxHandler(props.name)} className={classes.checkbox} checked={props.value}/>}
          label={props.label}
          labelPlacement="start"
        />
    </FormControl>
  );
}

import React, {useEffect} from 'react'
import { Container, Divider, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import TitleLine from '../Common/TitleLine';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { useLazyQuery, gql } from '@apollo/client';

const profileRequest = gql`
query requestProfile($id: uuid) {
    users(where: {id: {_eq: $id}}) {
        email
        first_name
        last_name
        name
        primary_phone
      }
}`;


const useStyles = makeStyles((theme) => ({
    label:{
        textTransform:'uppercase',
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#A3a3a3',
    },
    input:{
        '& input':{
            color:'#191919',
        }
    }
}));

export default function AccountProfile(props){
    const [profile, setProfile] = React.useState({});

    const [
        requestProfile, 
        { loading, data, error }
    ] = useLazyQuery(profileRequest,{
        onCompleted: data => {
            setProfile(data?.users[0])
            // Lift state up - Giving End
            props.onProfileInfo(data?.users[0])
            if(error){
                alert('An error has occured')
            }
        },
        fetchPolicy: 'no-cache'
    });
    if(error){
        alert('An error has occured')
    }

    useEffect(() => {
        requestProfile({
            context:{
                uri:process.env.REACT_APP_TS_GRAPHQL_ENDPOINT,
                headers:{
                'x-hasura-admin-secret':process.env.REACT_APP_TS_HASURA_KEY,
                }
            },
            variables: { id:props?.user?.user_id }},{fetchPolicy: 'network-only'})
    },[]);

    const classes = useStyles();

    const handleUpdate = (e) =>{
        console.log(values);
    }
    return(
        <React.Fragment>
            <Box mb={3}>
                <TitleLine text='Your Profile'/>
                <Divider/>
            </Box>
            <Grid spacing={3} container>
                <Grid item xs={6}>
                    <Typography className={classes.label}>First Name</Typography>
                    <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="first_name"
                    type='text'
                    name="First name"
                    required
                    value={profile.first_name
                        ?profile.first_name
                        :profile.name??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Last Name</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="last_name"
                    type='text'
                    name="Last name"
                    required
                    value={profile.last_name??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Phone number</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="phone"
                    type='text'
                    name="Phone"
                    required
                    value={profile.phone??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Email Adress</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="email"
                    type='email'
                    name="Email"
                    required
                    value={profile.email??'Not provided'}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { UnsubscribeTwoTone } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
formField:{
    marginBottom:theme.spacing(3),
    '& fieldset':{
        border:'solid 1px #3c3c3c',
    },
    '& .MuiFormHelperText-root.Mui-error':{
        margin:theme.spacing(1,0,0),
    },
},
}));

export default function CreateStudentInput(props){
    const classes = useStyles();
    return(
    <FormControl fullWidth className={classes.formField} variant="outlined">
        <TextField
            error={props.error}
            helperText={props.error ? props.helperText : null}
            variant="outlined"
            id={props.FieldId}
            placeholder={props.FieldName}
            InputLabelProps={{shrink: false}}
            name={props.FieldId}
            autoComplete={props.FieldId}
            onChange={props.onHandleInputChange(props.FieldId)}
        />
    </FormControl>
)}
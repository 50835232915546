import moment from "moment";

export function DateComparer(){
    
    function getInterval(repeat) {
        switch(repeat) {
          case 'Daily':
            return 1;
          case 'Weekly' :
            return 7;
          case 'Bi weekly' :
            return 14;
          case 'Monthly' :
              return 30;
          default:
            return 7;
        }
      }
      
   function getHours(startTime, endTime){
        var start = moment('01/01/2021 ' +startTime,'DD/MM/YYYY HH:mm');
        var end =moment('01/01/2021 '+endTime,'DD/MM/YYYY HH:mm')

        var duration =moment.duration(end.diff(start));
        return  duration.asHours().toFixed(2);
    }
    function getDays(startDate,endDate){
         var start = moment(startDate+' 00:00','DD/MM/YYYY HH:mm');
         var end =moment(endDate+ ' 23:59','DD/MM/YYYY HH:mm')
         var duration =moment.duration(end.diff(start));
         return  duration.asDays().toFixed(0);
     }
     function getDateLessThan(startDate,endDate,interval){

        var date = moment(startDate, "DD/MM/YYYY").add(interval, 'days');
        var end =moment(endDate,'DD/MM/YYYY')

        if(end > date)
          return date.format('DD/MM/YYYY');
        else
          return null
    }
  return  {
    getInterval,
    getHours,
    getDays,
    getDateLessThan
  }
}
import React from 'react';
import { Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    button:{
        textTransform:'unset',
        background:theme.palette.background.onSurface,
        color:theme.palette.text.light.onSecondaryHighEmphasis,
        borderRadius:8,
        display:'inline-flex',
        alignItems:'center',
        padding:theme.spacing(2),
        margin:'auto',
        '&:hover':{
            filter:'brightness(1.1)',
            background:theme.palette.background.onSurface,
            color:theme.palette.text.light.onSecondaryHighEmphasis,
        },
        '&:disabled':{
            filter:'brightness(0.9)',
            background:theme.palette.background.onSurface,
            color:theme.palette.text.light.onSecondaryHighEmphasis,
        },
    },
    btnText:{
        minWidth:116,
        fontWeight:500,
        fontSize:'0.875rem',
    },
    spinnyboi:{
        color:'white',
    },
}));

export default function BookingBtn(props) {
    const classes = useStyles();

    return (
        <Button
        component={props.component}
        to={props.to}
        type="{props.type || 'button'}"
        disabled={props.loading}
        className={classes.button}
        onClick={props.onHandleUpdate}
        endIcon={props.loading?null:<ChevronRightIcon fontSize="large" className={classes.btnIcon} />}>            
           <Typography className={classes.btnText}>{props.loading?props.textLoading??props.text:props.text}</Typography>
            {props.loading
            ?<CircularProgress className={classes.spinnyboi} size={24} color="secondary" /> :null
            }
        </Button>
    );
  }
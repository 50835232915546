import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import CourseListWrapper from './CourseListWrapper'
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'unset',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3,4),
    background: '#FBFBFA',
    boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
    minWidth:375,
  },
  courseListHeader:{
    fontFamily:theme.typography.averta,
    fontWeight:'bold',
    fontSize:'1.5rem',
    lineHeight:'1.5rem',
    marginBottom:theme.spacing(2),
  },
    formField:{
        marginBottom:theme.spacing(3),
        '& fieldset':{
            border:'solid 1px #3c3c3c',
        },
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};


export default function CourseSearch(props) {
    const classes = useStyles();
    const [searchValue, setSearchValue] = React.useState('');
    const handleSearch = (e) =>{
        setSearchValue(e.target.value)
    }
  return (
    <div>
      <Modal
        aria-labelledby="course_selection"
        aria-describedby="modal-description"
        className={classes.modal}
        open={props.modalState}
        onClose={props.handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.modalState}>
          <div className={classes.paper}>
                <Typography id="modal-description" className={classes.courseListHeader} variant="body1" component="p">
                    Lookup a course
                </Typography>
                <FormControl fullWidth className={classes.formField} variant="outlined">
                    <OutlinedInput
                    variant="outlined"
                    id="courseSearch"
                    placeholder="Type here"
                    name="courseSearch"
                    autoComplete="courseSearch"
                    onChange={handleSearch}
                    value={props.SearchValue}
                    endAdornment={
                        <InputAdornment
                        position="end"
                        disablePointerEvents
                        >
                        <IconButton 
                            aria-label="search course list"
                            edge="end"
                            >
                            <SearchIcon/>
                        </IconButton>
                        </InputAdornment>
                    }
                    />
                </FormControl>
            <CourseListWrapper courseData={props.courseData} user={props.user} onHandleCourseSelect={props.onHandleCourseSelect} SearchValue={searchValue}/>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
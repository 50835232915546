import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle';

const useStyles = makeStyles((theme) => ({
    icon:{
        display:'flex',
        alignItems:'center',
        fontWeight:700,
        color:theme.palette.text.dark.black,
        marginBottom:theme.spacing(1.25),
        '& svg':{
            marginRight:theme.spacing(0.5),
        },
    },
    bodyText:{
        fontSize:'0.875rem',
        lineHeight:'1rem',
        fontWeight:500,
        color:theme.palette.text.dark.mediumEmphasis2,
        marginBottom:theme.spacing(2.375),
        letterSpacing:'0.1px',
    },
    divider:{
        backgroundColor:'rgba(0, 0, 0, 0.25)',
        marginBottom:theme.spacing(0.375),
    },
}));

export default function bookingProcessHeader(props) {
    const classes = useStyles();
    return (
    <React.Fragment>
        <PageTitle mb={2.5} text="Courses"/>
        <Typography className={classes.icon} >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21C9 21.55 9.45 22 10 22H14C14.55 22 15 21.55 15 21V20H9V21ZM12 2C8.14 2 5 5.14 5 9C5 11.38 6.19 13.47 8 14.74V17C8 17.55 8.45 18 9 18H15C15.55 18 16 17.55 16 17V14.74C17.81 13.47 19 11.38 19 9C19 5.14 15.86 2 12 2ZM14.85 13.1L14 13.7V16H10V13.7L9.15 13.1C8.48749 12.6407 7.94594 12.0279 7.57155 11.314C7.19717 10.6001 7.00107 9.80615 7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 10.63 16.2 12.16 14.85 13.1Z" fill="#877594"/>
            </svg> 
            Keep it simple
        </Typography>
        <Typography className={classes.bodyText} >You will have a chance to input details like<br/>course code on upcoming pages.</Typography>
        <Divider className={classes.divider} />
    </React.Fragment>
    );
  }
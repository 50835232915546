import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Logo from '../../img/habitat-logo.svg';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Navigation from './Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logolink: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  bar: {
    background: 'transparent',
    boxShadow: 'unset',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  navbar: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(1.1),
    fontFamily: theme.typography.ppWoodland,
    color: theme.palette.text.emphasis1,
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  linkbar: {
    display: 'flex',
    marginLeft: 'auto',
  },
  link: {
    flex: '0 0 auto',
    textAlign: 'center',
    fontFamily: theme.typography.averta,
    color: theme.palette.text.emphasis1,
    fontSize: '18px',
    lineHeight: '24px',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    '&[disabled]': {
      opacity: 0.3,
      textDecoration: 'unset',
      cursor: 'not-allowed',
    },
  },
  logout: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '500',
    letterSpacing: '1.25px',
    padding: theme.spacing(1.25),
    background: '#ffffff',
    color: theme.palette.text.emphasis1,
    borderRadius: '5rem',
    minWidth: '115px',
    '&:hover': {
      background: '#ffffff',
      color: theme.palette.text.emphasis1,
    },
  },
  drawerToggle: {
    marginLeft: 'auto',
  },
  drawer: {
    width: 150,
  },
  drawerLink: {
    fontFamily: theme.typography.averta,
    color: theme.palette.text.emphasis1,
    fontSize: '18px',
    lineHeight: '24px',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    '&[disabled]': {
      opacity: 0.3,
      textDecoration: 'unset',
      cursor: 'not-allowed',
    },
  },
}));

export default function MenuAppBar({ isLoggedIn, setToken, setUser, user }) {
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 980
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }

  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} position="static">
        <Toolbar disableGutters>
          <Link className={classes.logolink} href="/">
            <img src={Logo} width="38.8" height="38.8" alt="Habitat Logo" />
            <Typography variant="h6" className={classes.title}>
              Habitat
            </Typography>
          </Link>
          {isLoggedIn ?
            <React.Fragment>
              {mobileView
                ? <React.Fragment key='left'>
                  <IconButton className={classes.drawerToggle} onClick={toggleDrawer('left', true)}>
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                  >
                    <div
                      className={classes.drawer}
                      role="presentation"
                      onClick={toggleDrawer('left', false)}
                      onKeyDown={toggleDrawer('left', false)}
                    >
                      <List>
                        {Navigation.map((elem) => (
                          <ListItem button key={elem.name}>
                            <Link className={classes.drawerLink} href={elem.url}>{elem.name}</Link>
                          </ListItem>
                        ))}
                        <ListItem>
                          <Button
                            type="button"
                            variant="contained"
                            align="center"
                            color="primary"
                            className={classes.logout}
                            onClick={e => { setToken(''); setUser(null); }}
                          >
                            Logout
                          </Button>
                        </ListItem>
                      </List>
                    </div>
                  </Drawer>
                </React.Fragment>
                : <React.Fragment>
                  <div className={classes.navbar}>
                    <Typography className={classes.linkbar}>
                      {Navigation.map((elem) => (
                        elem.disabled ??
                        <Link disabled={elem.disabled} className={classes.link} href={elem.url} key={elem.name}>
                          {elem.name}
                        </Link>
                      ))}
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      align="center"
                      color="primary"
                      className={classes.logout}
                      onClick={e => { setToken(''); setUser(null); }}
                    >
                      Logout
                    </Button>
                  </div>
                </React.Fragment>
              }</React.Fragment>
            : null}
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React, { useEffect } from 'react';
import { CircularProgress, FormControl} from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CourseSearch from './CourseSearch'
import { useLazyQuery, gql } from '@apollo/client';
import PillButton from '../../Common/Buttons/PillButton';
import {useParams, useRouteMatch, Link as RouterLink} from 'react-router-dom'


const FindCourse = gql`
query MyQuery($email: String = "", $course_code: String = "", $course_name: String = "", $id: uuid = "", $date: date = "") {
    CourseStudent(where: {Student: {User: {email: {_eq: $email}}}, Course: {course_code: {_eq: $course_code}, course_name: {_eq: $course_name}, id: {_eq: $id}, start_day: {_eq: $date}}}) {
      Course {
        course_code
        course_name
        id
        start_day
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
fieldNum:{
    textAlign:'left',
    fontFamily:theme.typography.ppWoodland,
    color:theme.palette.text.dark.mediumEmphasis,
    fontWeight:'bolder',
    fontSize:'3rem',
    lineHeight:'3.5rem',
    marginLeft:theme.spacing(-0.5)
},
fieldGroup:{
    flex:'1 1 auto',
    width:'100%',
    '& input':{
        textOverflow:'ellipsis',
    },
},
subtitleContainer:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
},
fieldSubtitle:{
    textAlign:'right',
    fontSize:'0.875rem',
    lineHeight:'1rem',
    minHeight:16,

},
fieldBtn:{
    border:'solid 1px gray',
    padding:theme.spacing(0.25),
},
formField:{

},
inputGrid:{
    marginBottom:theme.spacing(2.125),
},
/* Global component? */ 
lookupBtn:{
    fontSize:'14px',
    lineHeight:'1rem',
    fontWeight:'500',
    letterSpacing:'1.25px',
    padding:theme.spacing(1.25,1.9),
    background:theme.palette.background.white,
    boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.1)',
    border:' 1px solid rgba(0, 0, 0, 0.25)',
    color:theme.palette.text.emphasis1,
    borderRadius:'5rem',
    marginBottom:theme.spacing(3.625),
    '&:hover': {
        background:theme.palette.background.white,
        color:theme.palette.text.emphasis1,
      },
},
divider:{
    backgroundColor:'rgba(0, 0, 0, 0.25)',
},
inputContainer:{
    position:'relative',
},
removeBtn:{
    position: 'absolute',
    left: '-1.125rem',
    top: '50%',
    transform: 'translateY(-50%)',
    padding:'0.5rem',
    zIndex:10,
},
removeBtnIcon:{
    width:15,
    height:15,
    color:theme.palette.accent.purple,
},
noOutlineInput:{
    '& fieldset':{
        border:'unset',
    },
},
courseLabel:{
    color:theme.palette.text.dark.mediumEmphasis2,
    lineHeight:'1rem',
    top:10,
},
}));

export default function CourseSelection(props){
    //console.log(props,'CourseSelection props')
    //const current = props.course.getCurrent()
    const id = props.id;

    let {path,url} = useRouteMatch();

    const [loader,setLoader] = React.useState(false)

    const [studentMail, setStudentMail] = React.useState(props?.courseData?.email ?? null)

    const [courseData,setCourseData] = React.useState(props.courseValues[id]?? {
        course_code:'',
        course_name:'',
        id:'',
        isExisting:false,
        status:0
    })

    const [requestFindCourse,{ loading, data, error }] = useLazyQuery(FindCourse,{
        onCompleted: data => {
            setLoader(false)
            if(data?.CourseStudent.length){
                setCourseData({...courseData,['found']:true})
            }
            else{
                setCourseData({...courseData,['found']:false})
            }
            if(error){
                alert('An error has occured')
            }
        },
        fetchPolicy: 'no-cache'
    });
    if(error){
        alert('An error has occured')
    }

    const courseLookup = (props) => (e) =>{
        //course_info below is added to course prop in CourseSearchList
        setCourseData({...courseData,
            isExisting:true,
            course_name:props.course_name,
            course_code:props.course_code,
            id:props.id,
            platform_id:props.course_info[0] ?? null,
            platform_location:props.course_info[1] ?? null,
            platform_password:props.course_info[2] ?? null,
            platform_url:props.course_info[3] ?? null,
            platform_username:props.course_info[4] ?? null,
            instructor_email:props.course_info[5][0]?.Instructor?.User?.email ?? null,
            instructor_name:(props.course_info[5][0]?.Instructor?.User?.first_name === undefined ? null : props.course_info[5][0]?.Instructor?.User?.first_name+' '+props.course_info[5][0]?.Instructor?.User?.last_name) ?? null,
            platform:props.course_info[6] ?? null,
            instructor_id:props.course_info[5][0]?.Instructor?.id,
            instructor_user_id:props.course_info[5][0]?.Instructor?.user_id,
            subject_names:props.course_info[7] ?? "",
            start_day:props.course_info[8] ?? "",
            end_day:props.course_info[9] ?? "",
            sessions:props.course_info[10] ?? "",
        });
        setLoader(true)
        requestFindCourse({variables: {
            email: studentMail,
            course_code:props.course_code,
            date:props.course_info[8],
            id:props.id,
            course_name:props.course_name,
        }},{fetchPolicy: 'network-only'})
        handleClose();
    }

    const handleInput = (e) =>{
        setCourseData({...courseData,
            isExisting:false,
            course_name:e.target.value,
            course_code:'',
            id:'',
        });
    }
    const removeExistingCourse = (e) =>{
        setCourseData({...courseData,
            isExisting:false,
            course_name:'',
            course_code:'',
            id:'',
            found:false,
        });
    }

    //Runs on render, need to fix that later
    useEffect(()=>{
        props.onCourseCallback(courseData,id);
    }, [courseData])

    const [open, setOpen] = React.useState(false);
    
    const handleOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    //console.log('WEEE',props?.courseValues?.[id]?.found,props?.courseValues)
    const classes = useStyles();
    return(
    <React.Fragment>
        <Grid container spacing={1} justifyContent="center" display="flex">
            <Grid className={classes.subtitleContainer} item xs={12} md={10}>
                    <Typography className={classes.fieldSubtitle} >
                        {courseData.isExisting 
                        ? null
                        : [(props.num === 1
                            ? 'eg: Equestrian science 3'
                            : null
                         ),
                        ]
                    }
                    </Typography>                
            </Grid>
        </Grid>
        <Grid className={classes.inputGrid} container spacing={2} justifyContent='space-around' alignItems="center" display="flex">
            <Grid item xs={1}>
             <Typography className={classes.fieldNum} >{props.num}</Typography>
            </Grid>
            <Grid item xs={10} className={classes.fieldGroup}>
                {courseData.isExisting 
                ?
                    <Grid container className={classes.inputContainer}>
                        <IconButton onClick={removeExistingCourse} className={classes.removeBtn} aria-label="Remove course">
                            <CancelIcon className={classes.removeBtnIcon} fontSize="small" />
                        </IconButton>
                        <FormControl fullWidth className={classes.formField} variant="outlined">
                            <InputLabel className={classes.courseLabel} htmlFor={props.id}>{courseData.course_code}</InputLabel>
                            <OutlinedInput
                            className={classes.noOutlineInput}
                            aria-describedby="Course name"
                            variant="outlined"
                            id={props.id}
                            label={courseData.course_code}
                            name={props.id}
                            disabled
                            value={courseData.course_name}
                            />
                        </FormControl>
                    </Grid>
                :
                    <FormControl fullWidth className={classes.formField} variant="outlined">
                        <OutlinedInput
                        aria-describedby="Course name"
                        variant="outlined"
                        id={props.id}
                        placeholder="Course name"
                        name={props.id}
                        autoComplete="Course_name"
                        onChange={handleInput}
                        value={courseData.course_name}
                        />
                    </FormControl>
                }
            </Grid>
            {props.courseQuantity != 1 ?
            <Grid item xs={1} md={1}>
                <IconButton onClick={props.onRemoveCourse} className={classes.fieldBtn} aria-label="Remove course">
                <RemoveIcon fontSize="small" />
                </IconButton>
            </Grid>
            :<Grid item xs={1} md={1}></Grid>
            }
        </Grid>
        <Grid container spacing={1} justifyContent="center" display="flex">
            {courseData.isExisting 
                ? null
                :
            <Grid item xs={10}>
                <Button
                    variant="contained"
                    align="center"
                    className={classes.lookupBtn}
                    onClick={handleOpen}
                    >
                    Lookup existing course
                </Button>
            </Grid>
            }
        </Grid>
        <Box mb={2}>
            {loader
            ? <React.Fragment>
                <Box alignItems='center' display='flex'>
                    <Box mr={1}>
                        <CircularProgress size={28}/>
                    </Box>
                    <Typography>Checking for existing courses</Typography>
                </Box>
                </React.Fragment>
            :
            <React.Fragment>
            {props?.courseValues?.[id]?.found === true ?
            <React.Fragment>
                <Box mb={1}>
                    <Typography>
                        Bookings for that course have been found, would you like to add more sessions instead?
                    </Typography>
                </Box>
                <PillButton noIcon onHandleUpdate={props.onHandleUpdate} variant="accentOutlined" text="Add more sessions"/>
            </React.Fragment>
            : null}
            </React.Fragment>
            }
        </Box>
    <Divider className={classes.divider} />
    <CourseSearch courseData={props.courseData} user={props.user} onHandleCourseSelect={courseLookup} handleModalClose={handleClose} modalState={open} modalContent={props.modalContent} />
   </React.Fragment>
)}
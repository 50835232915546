import React, {useEffect ,Fragment} from 'react';
import { makeStyles } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import {List} from '@material-ui/core'
import StudentList from './StudentList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { InputLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    select:{
        marginBottom:theme.spacing(3),
    },
    studentList:{
        height:216,
        padding:'unset',
        border:'solid 1px '+theme.palette.text.dark.body,
        borderRadius:4,
        '& ul':{
            padding:theme.spacing(1,2),
        },
    },
}))

export default function StudentListWrapper(props){  

    let sortedOrg

    if(props.user.duorg_id && props.user.duorg_id.length > 1){
       sortedOrg = props.user.duorg_id.sort((a,b) =>{
            let nA = a.Organisation.name.toLowerCase();
            let nB = b.Organisation.name.toLowerCase();
            if(nA < nB){
                return -1
            }
            if (nA > nB){
                return 1;
            }
            return 0;
        })
    }

    const classes = useStyles();

    const [organization, setOrganization] = React.useState(Array.isArray(props.user.duorg_id) && props.user.duorg_id.length > 0?props.user.duorg_id[0].organisation_id:props.user.org_id);

    const handleChange = (event) => {
     setOrganization(event.target.value);
    };

    useEffect(()=>{
        props.onSelectOrg(organization)
    },[organization])

    return(
        <Fragment> 
            {props.user.duorg_id && props.user.duorg_id.length > 1
            ?<FormControl fullWidth className={classes.select} variant="outlined">
            <InputLabel id="organization-select-label">Select organization</InputLabel>
            <Select              
              autoWidth
              labelId="organization-select"
              id="organization-select"
              value={organization}
              IconComponent={ArrowDropDownIcon}
              onChange={handleChange}
              label="Select organization"
            >
                {sortedOrg.map(item=>(
                    <MenuItem value={item.organisation_id} key={item.id}>{item.Organisation.name} - {item.Organisation.department}</MenuItem>
                ))}
            </Select>
          </FormControl>
            :null}
            <Scrollbars
                className={classes.studentList} 
                style={{height:216}}
            >
                <List component="ul" aria-label="Students list">
                    <StudentList organization={organization} SearchValue={props.SearchValue} onHandleLookupStudent={props.onHandleLookupStudent} user={props.user}/>
                </List>
            </Scrollbars>            
        </Fragment>
    );
};
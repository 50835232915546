import React, {Suspense} from 'react';
import { Container, TextField } from '@material-ui/core';
import PageTitle from '../components/Common/PageTitle';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import {ReactComponent as DownloadIcon} from '../img/icon-download.svg';
import StudentTable from '../components/Students/StudentTable';
import PillButton from '../components/Common/Buttons/PillButton';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import ViewStudent from '../components/Students/ViewStudent/ViewStudent';
import CreateStudent from '../components/Students/CreateStudent/CreateStudent';
import Students from '../components/Students/Students';
import ViewStudents from '../components/Students/ViewStudents';
import StudentFilters from '../components/Students/StudentFilters';

const useStyles = makeStyles((theme) => ({
    body:{
        marginTop:theme.spacing(5),
    },
    tableElements:{
        gap:'1.5rem',
    },
    search:{
        '& input':{
            padding:theme.spacing(1),
        },
    },
}));

export default function StudentView(props) {
    let {path,url} = useRouteMatch();
    return(
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route exact path={path} component={w=><ViewStudents path={path} user={props.user}/>}/>
                <Route path={`${path}/edit/:id`} component={w=><ViewStudent user={props.user}/>}/>
                <Route path={`${path}/CreateStudent`} component={w=><CreateStudent user={props.user}/>}/>
            </Switch>
        </Suspense>
    )
}

// function Old_Students() {
//     let {path,url} = useRouteMatch();
//     const classes = useStyles();

//     /*MenuThingy*/ 
//     const sort = React.createRef();
//     const tableRef = useRef();
//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const [tableSort, setTableSort] = React.useState(null);
//     const [searchVal, setSearchVal] = React.useState(null);
//     const [redirect, setRedirect] = React.useState(null);

//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
  
//     const handleClose = () => {
//       setAnchorEl(null);
//     };

//     const handleSort = (event) => {
//         setTableSort(event.target.id);
//         handleClose();
//     };

//     const handleRedirect = (event) =>{
//         console.log(event)
//     }
    
//     useEffect(()=>{
//         console.log('useEffect')
//     },[tableSort])

//     return (
//     <React.Fragment>
//         <Container className={classes.body} fixed>
//             <PageTitle text="View your Students"/>
//             <Box
//             mb={3}
//             display="flex" 
//             alignItems="center"
//             justifyContent="space-between"
//             >
//                 <Typography>Student List</Typography>
//                 <TextField
//                 className={classes.search}
//                 variant="outlined"
//                 placeholder="Search"
//                 onChange={(event) => setSearchVal(event.target.value)}
//                 InputProps={{
//                     endAdornment:(
//                         <InputAdornment position="end">
//                         <IconButton
//                             aria-label="search"
//                             edge="end"
//                             onClick={()=>console.log('Search')}
//                         >
//                             <SearchIcon/>
//                         </IconButton>
//                         </InputAdornment>
//                     ),
//                 }}
//                 >
//                     Search
//                 </TextField>
//             </Box>
//             <StudentTable searchVal={searchVal} ref={tableRef} sort={tableSort} mb={5}/>
//             <Box textAlign="end">
//                    <PillButton component={RouterLink} to={`${url}/CreateStudent`} variant='accentOutlined' noIcon text="Add student" />
//             </Box>
//         </Container>            
//     </React.Fragment>
//     );
//   }

//   function CreateStudents(){
//       return(
//           <CreateStudent/>
//       )
//   }
import React, {useEffect} from 'react'
import { CircularProgress, Container, Divider, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import TitleLine from '../Common/TitleLine';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {useParams, useRouteMatch, Link as RouterLink} from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client';
import PillButton from '../Common/Buttons/PillButton'

const orgAdminsQuery = gql`
query OrgAdmins($role: uuid = "", $orgId: uuid = "") {
    UserOrganisation(where: {organisation_id: {_eq: $orgId}, User: {role_id: {_eq:$role}}}) {
      User {
        first_name
        last_name
        id
        email
      }
    }
  }`

const useStyles = makeStyles((theme) => ({
    label:{
        textTransform:'uppercase',
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#A3a3a3',
    },
    input:{
        '& input':{
            color:'#191919',
        }
    }
}));



function OrganizationAdmins(props){

    const [
        getOrgAdmins, 
        { loading, data,error }
    ] = useLazyQuery(orgAdminsQuery,{
        onCompleted: data => {
            if(error){
                return(
                    <Box p={1}>
                      <Typography color="error">An error has occured</Typography>
                    </Box>
                )
            }
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        getOrgAdmins({
            variables: { orgId:props.id,role:process.env.REACT_APP_ADMIN_ROLE_ID }},{fetchPolicy: 'network-only'})
    },[props.id])

    if(loading){
        return(
            <Box p={3} display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={42}/>
            </Box>
        )
    }

    if(error){
        return(
            <Box p={1}>
                <Typography color="error">An error has occured</Typography>
            </Box>
        )
    }
    
    return(
        <React.Fragment>
        {data?.UserOrganisation.map((admin) => (
            <React.Fragment key={admin.User.id}>
            <Box alignItems="center" justifyContent="space-between" display="flex">
                <Box p={1}>
                {admin.User.first_name+" "+admin.User.last_name??'Not provided'}
                </Box>
                <Box>
                    {admin.User.email}
                </Box>
            </Box>
            <Box>
                <Divider/>
            </Box>        
            </React.Fragment>        
        ))}    
        </React.Fragment>
    )
}

export default function OrganizationProfile(props){

    let {id} = useParams();
    let {path,url} = useRouteMatch();



    let organizationData = props.user.duorg_id.find(x =>x.organisation_id === id)
    const classes = useStyles();

    const handleUpdate = (e) =>{
        console.log(values);
    }
    return(
        <React.Fragment>
            <Box mb={2}>
            <Paper>
                <Box p={4}>
            <Box mb={3}>
                <TitleLine text='Institution info'/>
                <Divider/>
            </Box>
            <Grid spacing={3} container>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Institution name</Typography>
                    <TextField
                    fullWidth
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="name"
                    type='text'
                    name="name"
                    required
                    value={organizationData?.Organisation?.name??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Department</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="department"
                    type='text'
                    name="Department"
                    required
                    value={organizationData?.Organisation?.department??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Adress</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="street_adress"
                    type='text'
                    name="Street adress"
                    required
                    value={organizationData?.Organisation?.street_adress??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>City</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="city"
                    type='text'
                    name="City"
                    required
                    value={organizationData?.Organisation?.city??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Country</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="country"
                    type='text'
                    name="Country"
                    required
                    value={organizationData?.Organisation?.country??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Postal code</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="postal_code"
                    type='text'
                    name="Postal code"
                    required
                    value={organizationData?.Organisation?.postal_code??'Not provided'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Time zone</Typography>
                    <TextField
                    fullWidth
                    disabled
                    className={classes.input}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    id="tz"
                    type='text'
                    name="Country"
                    required
                    value={organizationData?.Organisation?.tz??'Not provided'}
                    />
                </Grid>
            </Grid>
            </Box>
            </Paper>
            </Box>
            <Box>
                <Paper>
                    <Box p={4}>
                        <Box mb={3}>
                            <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                                <TitleLine text='Organization administrators'/>
                                <PillButton component={RouterLink} to={url+"/addAdmin"} noIcon variant="accent" text="Add admin"/>
                            </Box>
                            <Divider/>
                            <OrganizationAdmins id={id}/>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </React.Fragment>
    );
};
import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import {List} from '@material-ui/core'
import CourseList from './CourseSearchList';


const useStyles = makeStyles((theme) => ({
    studentList:{
        height:216,
        padding:'unset',
        border:'solid 1px '+theme.palette.text.dark.body,
        borderRadius:4,
        '& ul':{
            padding:theme.spacing(1,2),
        },
    },
}))

export default function CourseListWrapper(props){  

    const classes = useStyles();

    return(
        <Fragment>
            <Scrollbars
                className={classes.studentList} 
                style={{height:216}}
            >
                <List component="ul" aria-label="Course List">
                    <CourseList courseData={props.courseData} user={props.user} SearchValue={props.SearchValue} onHandleCourseSelect={props.onHandleCourseSelect} />
                </List>
            </Scrollbars>            
        </Fragment>
    );
};
import React, { useEffect }  from 'react'
import 'date-fns';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle'
import TitleLine from '../../Common/TitleLine'
import CourseTile from '../BookingCommon/CourseTile';
import TextInput from '../BookingCommon/TextInput';
import BookingBtn from '../BookingCommon/BookingBtn';
import { makeStyles } from '@material-ui/core';
import ButtonHeading from '../../Common/ButtonHeading';
import PlatformSelect from './PlatformSelect';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { red } from '@material-ui/core/colors';
import CourseProgress from '../BookingCommon/CourseProgress';
import { Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    flatBtn:{
        textTransform:'unset',
    },
    /* Need to make this into global component */
    Button:{
        fontSize:'0.5rem',
        padding:theme.spacing(0.25),
        border:'solid 1px gray',
        '&:disabled':{
            border:'solid 1px rgba(0, 0, 0, 0.26)',
        },
    },
    Icon:{
        fontSize:'1.25rem',
    },
}));

export default function CourseCredentials(props){
    const classes = useStyles();
    const current = props.course.getCurrent()
    const course = props.course.dataCourse()
    const userInfo = props.user;
    const history=useHistory()
    let {path,url} = useRouteMatch();
    
    //console.log(current)
    // const data = JSON.parse(localStorage.getItem('bookingData'))

    const [values ,setValues] = React.useState(current.credentials ?? {
        platform:current.platform,
        location:current.platform_location,
        url:current.platform_url,
        username:current.platform_username,
        password:current.platform_password,
        notes:current.platform_notes ?? ""
    });

    const handleSelectChange = (id,prop) =>{
        setValues({ ...values, [id]: prop });
    }

   // const [redirect, setRedirect] = React.useState(null)
    const handleUpdate = (e) =>{
        
        props.course.setCurrnet({...current,credentials:values,status:4})
      //  localStorage.setItem('bookingData',JSON.stringify(values));
        setRedirect(`./CourseInstances`);
    }

    const handleChange = (prop) => (e) =>{
        setValues({ ...values, [prop]: e.target.value });
    }
    function setRedirect(redirect){
        history.push(redirect)
     //   return <Redirect to={redirect}/>
    }
    return(
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={4}>
                            
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box mb={3.125}>
                               <PageTitle mb={2.5} color='accent' text='Credentials'/>
                                <TitleLine text="What platform will be used?"/>
                                <PlatformSelect userInfo={userInfo} course={course} onHandleSelectChange={handleSelectChange} id="platform" name="Platform" placeholder="Choose platform" value={values.platform}/>
                                <Box mt={1}>
                                      <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'platform': 'To be provided later' })}>To be provided later</Button>
                                      <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'platform': 'Not required' })}>Not required</Button>
                                </Box>
                            </Box>
                            {values.platform=='In person'?
                                <Box mb={3.125}>                                
                                    <TitleLine text="Location"/>
                                    <TextInput placeholder="Type here" onHandleChange={handleChange} id="location" name="location" value={values.location}/>
                                    <Box mt={1}>
                                      <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'location': 'To be provided later' })}>To be provided later</Button>
                                      <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'location': 'Not required' })}>Not required</Button>
                                    </Box>
                                </Box>
                           :'' }
                           {values.platform!='In person'?
                                <Box mb={3.125}>                                
                                    <TitleLine text="URL"/>
                                    <TextInput placeholder="Type here" onHandleChange={handleChange} id="url" name="Url" value={values.url}/>
                                    <Box mt={1}>
                                      <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'url': 'To be provided later' })}>To be provided later</Button>
                                      <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'url': 'Not required' })}>Not required</Button>
                                    </Box>
                                </Box>
                            :''}
                            <Box mb={3.125}>                                
                                <TitleLine text="Username / sign in email"/>
                                <TextInput placeholder="Type here" onHandleChange={handleChange} id="username" name="Username" value={values.username}/>
                                <Box mt={1}>
                                    <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'username': 'To be provided later' })}>To be provided later</Button>
                                    <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'username': 'Not required' })}>Not required</Button>
                                </Box>
                            </Box>
                            <Box mb={3.125}>                                
                                <TitleLine text="Password"/>
                                <TextInput placeholder="Type here" onHandleChange={handleChange} id="password" name="Password" value={values.password}/>
                                <Box mt={1}>
                                    <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'password': 'To be provided later' })}>To be provided later</Button>
                                    <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'password': 'Not required' })}>Not required</Button>
                                </Box>
                            </Box>
                            <Box mb={3.125}>                                
                                <TitleLine text="Notes"/>
                                <TextInput placeholder="Type here" multiline={true} rows="4" onHandleChange={handleChange} id="notes" name="Notes" value={values.notes}/>
                                <Box mt={1}>
                                    <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'notes': 'To be provided later' })}>To be provided later</Button>
                                    <Button className={classes.flatBtn} onClick={()=>setValues({ ...values, 'notes': 'Not required' })}>Not required</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                         <CourseProgress course={props.course}/>
                        </Grid>
                        <Box mb={4}>
                            <BookingBtn onHandleUpdate={handleUpdate} text="Proceed"/>
                        </Box>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
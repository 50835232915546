import { useState } from 'react';

export default function SelectedCources() {

  function getSelected(){
   return localStorage.getItem('selectedCourse') ?? 1;
  }
   const [selectedId, setNext] = useState(getSelected());
   const setSelected=function(sel){

    localStorage.setItem('selectedCourse', sel);
    setNext(sel);
   }
   const getObject = () => {
     return JSON.parse(localStorage.getItem('bookingData'))
   };
   const getCurrent = () => {
     let dd =localStorage.getItem('bookingData');
     if(dd){
       try{
        let d= JSON.parse(dd,true)
        return d['Course'+selectedId];
       }catch(e){
        console.log('eeerrorr',e,dd);
       }
     }else
     return {course_name:'',course_code:''};
   }
   const [current, setCurrnet] = useState(getCurrent());
   const [dataCourse, setCource] = useState(getObject());

   const setCurrnetCource = obj => {
     let dataCourse= JSON.parse(localStorage.getItem('bookingData'))
     dataCourse['Course'+selectedId] = obj;
     localStorage.setItem('bookingData', JSON.stringify(dataCourse));
     setCurrnet(obj);
   };
   const setDataCource = obj => {
     localStorage.setItem('bookingData', JSON.stringify( obj));
     setCource(obj);
   };
   const goCourseNext = ()=>{

    var itms= JSON.parse(localStorage.getItem('bookingData'));
    for (var i = 0; i < itms.courseQuantity; i++) {
     let j=i+1
     if(itms['Course'+j] && itms['Course'+j].status!=5){
       setSelected(j)
       return itms['Course'+j];
     }
    } 
    return null;
   }

   const selectCurrent=function(id){
    setSelected(id)
    var itms= JSON.parse(localStorage.getItem('bookingData'));
    return itms['Course'+id];
   }


   return {
     goNext:goCourseNext,
     setCurrnet: setCurrnetCource,
     setData: setDataCource,
     getCurrent:getCurrent,
     selectedId,
     dataCourse:getObject,
     setNext:setNext,
     selectCurrent:selectCurrent,
     getSelected:getSelected
   }
}
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



export default function RepeatSelect(props) {
    const [value, setValue] = React.useState(props.value);
  return (
    <Autocomplete
      id={props.id}
      options={repeatSchedule}
      onChange={(event, newValue) => {
        setValue(newValue);
        props.onHandleSelectChange(props.id,newValue);
      }}
      value={props.value??'Weekly'}
      getOptionLabel={(option) => option}
      renderInput={(params) => <TextField value={props.title} placeholder={props.name} fullWidth {...params} variant="outlined" />}
    />
  );
}
const repeatSchedule = [
     'Daily' ,
     'Weekly' ,
     'Bi weekly' ,
     'Monthly' ,
]
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { CircularProgress, FilledInput, FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { useHistory } from 'react-router-dom';
import loginService from '../services/loginService'
import getService from '../services/getService'
import Link from '@material-ui/core/Link';
import { TrendingUpTwoTone } from '@material-ui/icons';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    container:{
      margin:'auto',
    },
    margin:{
      marginBottom: theme.spacing(4),
    },
    loginbox:{
      padding:theme.spacing(4.75,3,5.75),
      boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.2);'
    },
    subheader:{
      fontSize:'34px',
      lineHeight:'36px',
      color:'#333333',
      fontFamily:theme.typography.ppWoodland,
      marginBottom:theme.spacing(2),
    },
    intro:{
      color:'#888',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      marginBottom:theme.spacing(5.5),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
    },
    submit: {
      fontSize:'14px',
      lineHeight:'16px',
      fontWeight:'500',
      letterSpacing:'1.25px',
      padding:theme.spacing(1.25),
      background:'#ffffff',
      color:theme.palette.text.emphasis1,
      borderRadius:'5rem',
      minWidth:'161px',
      '&:hover': {
        background:'#ffffff',
        color:theme.palette.text.emphasis1,
      },
    },
    message:{
      margin:theme.spacing(1),
      color:theme.palette.error.main,
      fontWeight:500,
  },
  }));
  

export default function remindPassword() {

  const {post} = loginService()
  const [message, setMessage] = React.useState('');
  const [email, setEmail] = useState();
  const [passwordResetted, setPasswordResetted] = useState(false);
  const [loader, setLoader] = useState(false);

  let history = useHistory();
   async function handleSubmit(event){
      setMessage('');
      event.preventDefault();
      setLoader(true)

      const { data } = await post("forgot-password",{
        email:email,
      });
      if(data.error){
        console.log('There is an error')
        setLoader(false)
        setMessage(data.message.statusText ?? 'An error has occured!')
        return;
      }
      setLoader(false)
      setPasswordResetted(true)
    }
    
    const [values, setValues] = React.useState({
      email: '',
    });

    const classes = useStyles();
    return (
     <Container className={classes.container} component="main" maxWidth="xs">
     <Box className={classes.loginbox} bgcolor="#fbfbfa" borderRadius={24}>
        <div className={classes.paper}>
          {passwordResetted
          ?
          <React.Fragment>
          <Box display="flex" flexGrow={1} width="100%">
            <Typography component="h1" variant="subtitle2" className={classes.subheader}>
                Password reset successfull!
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.intro} component="p">
              Your new password has been sent to your email adress.
            </Typography>
          </Box>
          <Box mt={12.5} mb={6.25} textAlign="center">
              <Button
                  type="submit"
                  variant="contained"
                  align="center"
                  color="primary"
                  onClick={()=>history.push('/')}
                  className={classes.submit}
                >
                  Login
              </Button>
              </Box>
          </React.Fragment>
          :
          <React.Fragment>
          <Box display="flex" flexGrow={1} width="100%">
            <Typography component="h1" variant="subtitle2" className={classes.subheader}>
                Forgot your password?
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.intro} component="p">
              No worries, please enter your account’s email to confirm password reset.
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <FilledInput
              variant="filled"
              required
              disableUnderline
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={e=>setEmail(e.target.value)}
              />
            </FormControl>
            <Typography align="center" className={classes.message}>
              {message}
            </Typography>
            <Box mt={12.5} mb={6.25} textAlign="center">
              <Button
                  type="submit"
                  variant="contained"
                  align="center"
                  color="primary"
                  disabled={loader ? true : false}
                  className={classes.submit}
                >
                  {loader
                  ? <CircularProgress size={20} />
                  : 'Submit'
                  }
              </Button>
              </Box>
              <Box mt={1.375} mb={4.25} textAlign="center">                
                <Link href="#" onClick={() => { history.goBack(); }} className={classes.remind}>
                      Go back           
                </Link>
              </Box>
          </form>
          </React.Fragment>
          }
         </div>
       </Box>
      </Container>
    );
  }
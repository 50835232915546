import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header:{
        fontSize:'1.5rem',
        lineHeight:'1.5rem',
        fontFamily:theme.typography.averta,
        color:theme.palette.text.dark.black,
        fontWeight:'regular',
        marginBottom: props => props.mb 
        ? theme.spacing(props.mb)
        : null,
    },
}));

export default function RegularHeading(props) {
    const classes = useStyles(props);
    return (
        <Typography align={props.textAlign} className={classes.header}>{props.text}</Typography>
    );
  }
import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';

export default function DashboardMain() {
    const history=useHistory()
    history.push('/bookingCreation')
    return (
        <React.Fragment>
        <Container maxWidth="xl">
        <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item xs={12} sm={8}>
            <h1>Dashboard</h1>
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <span>SideBar Content</span>
            </Grid>
        </Grid>
        </Container>
    </React.Fragment>
    );
  }
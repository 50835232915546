import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    subTitle:{
        fontSize:'0.875rem',
        lineHeight:'1rem',
        letterSpacing:'0.1px',
        color:theme.palette.text.dark.body,
        fontWeight:500,
        marginBottom:theme.spacing(1)
    },
}));

export default function Subtitle2(props) {
    const classes = useStyles();
    return (
        <Typography align={props.textAlign} className={classes.subTitle}>{props.text}</Typography>
    );
  }
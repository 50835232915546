import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



export default function PlatformSelect(props) {
  const platformsBase = [
    'Zoom',
    'Microsoft Teams',
    'WebEx',
    'Blackboard',
    'Canvas',
    'Moodle',
    'Big Blue Button',
    'Slate',
    'Other',
    'In person',
    'To be provided later',
    'Not required',
  ]

  const [platforms, setPlatforms] = React.useState(platformsBase)
  const [value, setValue] = React.useState("");

  let org = props.userInfo.duorg_id.filter(org => org.organisation_id === props.course.selectedOrg) 
  
  useEffect(()=>{
    if(org && org[0].Organisation.name.toLowerCase() == 'connexcel'){
      setPlatforms(['Zhumu',...platformsBase])
    }    
  },[props.userInfo])

  return (
    <Autocomplete
      id={props.id}
      options={platforms}
      onChange={(event, newValue) => {
        setValue(newValue);
        props.onHandleSelectChange(props.id, newValue);
      }}
      getOptionLabel={(platforms) => platforms.toString()}
      value={props.value}
      renderInput={(params) => <TextField placeholder={props.placeholder} value={props.title} name={props.name} fullWidth {...params} variant="outlined" />}
    />
  );
}
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardMain from './dashboardMain'
import Account from './account'
import Students from './students'
import Help from './help'
import BookingProcess from './bookingProcess';
import Courses from './Courses'
import Bookings from './Bookings';
import SelectedCources from '../services/selectedCources';
import BulkUpload from './bulkUpload';
import { useCookies } from 'react-cookie';
// import dbTweak from './local/help';

export default function Dashboard({ user, token }) {
  
  const [cookies, setCookie, removeCookie] = useCookies(['userToken'])
  setCookie('userToken', token, { path: '/', maxAge: 86400, sameSite: 'Lax' })
  
  const course = SelectedCources();

  return (
    /* REV - Fix link and redirects */
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={DashboardMain} />
          <Route path="/bookingCreation" component={w => <BookingProcess course={course} user={user} />} />
          <Route path="/dashboard" component={DashboardMain} />
          <Route path="/courses" component={Courses} />
          <Route path="/booking" component={w => <Bookings user={user} />} />
          <Route path="/account" component={w => <Account user={user} />} />
          <Route path="/students" component={w => <Students user={user} />} />
          <Route path="/help" component={Help} />
          <Route path="/bulkUpload" component={w => <BulkUpload user={user} />} />
          {/* <Route path ="/test" component={dbTweak}/> */}
          <Route component={() => (<div>404 Not found</div>)} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
}
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D1D1D1',
    color: '#191919',
    textTransform:'uppercase',
    fontFamily:theme.typography.averta,
    fontWeight:'bold',
    fontSize:'18px;'
  },
  body: {
    background:'white',
    '&:nth-child(4)':{
        background:'#FDE9D5'
    },
    '&:nth-child(5)':{
        background:'#F5DEE2'
    },
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(courses, bookings, status, warnings, errors) {
  return { courses, bookings, status, warnings, errors };
}

export default function BulkTable(props) {

    const rows = [
        createData(
            Math.floor(Math.random() * 12), 
            Math.floor(Math.random() * 145), 
            Math.floor(Math.random() * 8), 
            Math.floor(Math.random() * 34), 
            Math.floor(Math.random() * 15), 
        )
    ];
      
    const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    });

    const classes = useStyles();
    
return (    
  <TableContainer>
    <Table className={classes.table} aria-label="Bulk upload status">
      <TableHead>
        <TableRow>
          <StyledTableCell>Number of courses</StyledTableCell>
          <StyledTableCell>Total Bookings</StyledTableCell>
          <StyledTableCell>Status</StyledTableCell>
          <StyledTableCell>Warning</StyledTableCell>
          <StyledTableCell>Errors</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow key={row.courses}>
            <StyledTableCell component="th" scope="row">
              {row.courses > 1 ? row.courses+' Courses':row.courses+' Course'}
            </StyledTableCell>
            <StyledTableCell>{row.bookings > 1 ? row.bookings+' Instances':row.bookings+' Instance'}</StyledTableCell>
            <StyledTableCell>{row.status + ' Pending'}</StyledTableCell>
            <StyledTableCell>{row.warnings}</StyledTableCell>
            <StyledTableCell>{row.errors}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}
import React, { Component } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    studentListItem:{
        padding:theme.spacing(1,0,0.2),
        margin:theme.spacing(0,0,0.5),
    },
}))
 
export default function StudentEntry(props){
    
    const classes = useStyles();

    return(
        <ListItem
        button
        dense
        divider
        disableGutters
        id={props.id}
        first_name={props.first_name}
        last_name={props.last_name}
        onClick={props.onHandleLookupStudent(["first_name","last_name",'id','email'])}
        email={props.email}
        className={classes.studentListItem}
        >
          {props.first_name +' '+ props.last_name}
        </ListItem>
    );
};
import React, { useEffect } from 'react'
import { makeStyles} from '@material-ui/core/styles';
import 'date-fns';
import { Container, Typography} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle';
import BookingBtn from '../BookingCommon/BookingBtn';
import ButtonHeading from '../../Common/ButtonHeading';
import SessionsTable from './SessionsTable';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DateComparer } from '../../../services/dateComparer';
import moment from "moment";
import { gql, useMutation, } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import loginService from '../../../services/loginService';


const ADD_SESSION = gql`
mutation AddSessions($sessions: [CourseSession_insert_input!]!) {
    insert_CourseSession(objects: $sessions) {
      returning {
        id
        created_at
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    label:{
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        marginBottom:theme.spacing(1),
        textTransform:'uppercase',
        color:'#979797', //Primary 900?
        display:'inline-block',
    },
    input:{
        marginBottom:theme.spacing(3),
    },
  }));

export default function CourseSessions(props){
    const classes = useStyles();
    const {post} = loginService()
    const current = props.course.getCurrent()
    const data = props.course.dataCourse()
    const history = useHistory()
    const dateCompar = new DateComparer();
    let {path,url} = useRouteMatch();

    const orgIds = props.user.duorg_id ?? null;
    const orgTz = orgIds.find(org => org.organisation_id === data.selectedOrg)
    const timezone = orgTz?.Organisation?.tz ?? null

    const sEmail = data?.email ?? null

    const [message, setMessage] = React.useState(null)
    const [loadState, setLoadState] = React.useState(false)

    const [AddSessions, { session, as_loading, as_error }] = useMutation(ADD_SESSION, {onCompleted: (data3) =>{
        let element = data3.insert_CourseSession.returning
        //console.log(element,'sessions element')
        createTSCourse(element)
        // if(!error){
        // }
     },  onError: (err) => {
        setLoadState(false);
        setMessage(err.message);
    }});

    async function createTSCourse(element){
        const {data}  = await post("create_ts_booking?sms_course_id="+current?.id+"&sms_booker_id="+(props.user.aorg_id+"&student_email="+sEmail),null,3);

        if(data?.success){
            setRedirect(`./BookingComplete`);
        }else if(data?.errorMessage){
            console.log('TS error')
            setMessage(data.errorMessage)
            setLoadState(false);
        }
    }

    function checkInterval(a,b) {
        let interval = b.diff(a,'days')
        switch(interval) {
          case 1:
            return 'Daily';
          case 7 :
            return 'Weekly';
          case 14 :
            return 'Bi weekly';
          case 30 :
              return 'Monthly';
          default:
            return 'Weekly';
        }
      }

    function checkIntervalNum(interval) {
        switch(interval) {
          case 'Daily':
            return 1;
          case 'Weekly' :
            return 7;
          case 'Bi weekly' :
            return 14;
          case 'Monthly' :
              return 30;
          default:
            return 7;
        }
      }

    const [sessions,setSessions] = React.useState({
        startDate:moment(current.start_day).format('DD/MM/YYYY'),
        endDate:moment(current.end_day).format('DD/MM/YYYY'),
        startTime:current?.sessions[0]?.start_time,
        endTime:current?.sessions[0]?.end_time,
        captioning:current?.sessions[0]?.live_captioning,
        noteTaking:current?.sessions[0]?.live_note_taking,
        repeat:checkInterval(moment(current?.sessions[0]?.start_time),moment(current?.sessions[1]?.start_time)) ?? 'Weekly',
    })

    const [time, setTime] = React.useState({
        start:current?.sessions[0]?.start_time,
        end:current?.sessions[0]?.end_time,
        date:current?.sessions[0]?.start_time,
        startDate:current?.start_day,
        endDate:current?.end_day,
    })

    const [disabled, setDisabled] = React.useState(false)

   // const data = JSON.parse(localStorage.getItem('bookingData'))

    function getDatesArray(startDate,endDate,startTime,endTime,repeat){
        var arr=[]
        let i=0
        var hours = dateCompar.getHours(startTime,endTime);
        var interval = dateCompar.getInterval(repeat)
        var lastDate = startDate;
        if(interval>0){
            while(startDate != null){
                arr[i]={
                    session:'Session-'+(i+1),
                    booked:true,
                    date:startDate,
                    hours: hours,
                    start:startTime,
                    end:endTime,
                    message:'',
                }
                startDate =dateCompar.getDateLessThan(startDate,endDate,interval)
                if(startDate != null){
                    lastDate = startDate
                }
                i=i+1;
            }     
            if(lastDate && lastDate != endDate){
                arr[i]={
                    session:'Session-'+(i+1),
                    booked:true,
                    date:endDate,
                    hours: hours,
                    start:startTime,
                    end:endTime,
                    message:'',
                }
            }
        }
        else{
            arr[i]={
                session:'Session-'+(i+1),
                booked:true,
                date:startDate,
                hours: hours,
                start:startTime,
                end:endTime,
                message:'',
            }
        }
        return arr;
    }
   
    const [values ,setValues] = React.useState(
        getDatesArray(
            sessions.startDate,
            sessions.endDate,
            moment(sessions.startTime).format('HH:mm'),
            moment(sessions.endTime).format('HH:mm'),
            sessions.repeat
        )
    );

    const changeTime = (date,id) =>{
        setTime({...time,[id]:moment(date).format("YYYY-MM-DDTHH:mm:ss")})
        let fDate
        if(id === 'start' || id === 'end'){
            fDate = moment(date).format('HH:mm')
        }
        else{
            fDate = moment(date).format('DD/MM/yyyy')
            return;
        }
        const newValues = values.map(session=>{
            return{...session,[id]:fDate}
        })
        setValues(newValues)
    }

    const updateTimings = (event)=>{
        setTime({...time,['dummy']:'dummy'})
        let newValues = []
        let interval = checkIntervalNum(sessions.repeat)
        let sessionQuantity = (moment(time.endDate).startOf('day').diff(moment(time.date).startOf('day'),'days') ) + 1
        for(let i = 0; i < (Math.ceil(sessionQuantity / interval)); i++){
            if(i === 0){
                values[i].date = moment(time.date).format('DD/MM/yyyy')
                newValues.push(values[i])
            }
            else{
                let prevSessionDate = newValues[i-1]?.date
                let newDate = moment(prevSessionDate, 'DD-MM-YYYY').add(interval,'d').format('DD/MM/yyyy')
                if(moment(newDate, 'DD-MM-YYYY').isSameOrBefore(moment(time.endDate),'day')){
                    if (values[i] != undefined){
                        values[i].date = newDate;
                        newValues.push(values[i]);
                    }
                    else{
                        newValues.push({
                            session:'Session-'+(i+1),
                            booked:true,
                            date:newDate,
                            start:moment(time.start).format('HH:mm'),
                            end:moment(time.end).format('HH:mm'),
                            message:'',
                            hours: moment(time.end).diff(moment(time.start),'hours', true).toFixed(2).toString(),
                        })
                    }                    
                }
                else{
                    return false;
                }
            }
        }
        // values.forEach((date,index)=>{
        //     console.log(date,'forEach')
        //     if(index === 0){
        //         date.date = moment(time.date).format('DD/MM/yyyy')
        //         newValues.push(date)
        //     }
        //     else{
        //         let prevSessionDate = newValues[index-1]?.date
        //         let newDate = moment(prevSessionDate, 'DD-MM-YYYY').add(interval,'d').format('DD/MM/yyyy')
        //         if(moment(newDate, 'DD-MM-YYYY').isSameOrBefore(moment(time.endDate),'day')){
        //             date.date = newDate
        //             newValues.push(date)
        //         }
        //         else{
        //             return false;
        //         }
        //     }
        // })
        setValues(newValues)
    }

    // const changeLength = (length) =>{
    //     console.log(length.target.value)
    // }

    useEffect(()=>{
        updateTimings()
    },[time.date])

    useEffect(()=>{
        updateTimings()
    },[time.endDate])

    const handleValueUpdate = (event) =>{
        setValues(event)
    }

    const handleUpdate = (e) =>{
        setLoadState(true)
        let $sessions=[]
        for(let i=1;i<=values.length;i++){
            $sessions[i-1] = {
                data:values.map((w)=>{
                    return {
                        end_time: moment(w.date+' '+w.end,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
                        start_time:moment(w.date+' '+w.start,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
                        tz:timezone,
                        live_captioning:sessions?.captioning || false,
                        live_note_taking:sessions?.noteTaking || false,
                        course_id:current?.id || null,
                    }
                })
            }
        }
        AddSessions({ variables: {
            sessions:$sessions[0]?.data
            }
        }) 
    }
    function setRedirect(redirect){
        history.push(redirect)
       // return <Redirect to={redirect}/>//
    }
    return(
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={2} lg={3}>
                        </Grid>
                        <Grid item xs={12} md={8} lg={6}>
                            <PageTitle mb={2.5} color='accent' text='Add sessions'/>
                            <Grid spacing={2} container>
                                {/* <Grid item md={6}>
                                <label className={classes.label} htmlFor="sessions" >Number of sessions</label> 
                                    <FormControl fullWidth variant="outlined">
                                    <TextField
                                        id="sessions"
                                        type="number"
                                        defaultValue={values.length}
                                        onChange={()=>changeLength(event)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Typography>Set start and end time for all sessions of the booking</Typography>
                                </Grid>
                                <Grid item md={6} lg={4}>
                                    <label className={classes.label} htmlFor="startTime" >Start time</label> 
                                    <FormControl fullWidth variant="outlined">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            disabled={disabled}
                                            className={classes.input}
                                            variant="inline"
                                            inputVariant='outlined'
                                            value={new Date(time.start ?? null)}
                                            id="start"
                                            name="Start time"
                                            onChange={date => changeTime(date,'start')}
                                            ampm={false}
                                            autoOk={false}
                                        />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} lg={4}>
                                    <label className={classes.label} htmlFor="endTime" >End time</label> 
                                    <FormControl fullWidth variant="outlined">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            className={classes.input}
                                            variant="inline"
                                            inputVariant='outlined'
                                            value={new Date(time.end ?? null)}
                                            id="end"
                                            name="End time"
                                            onChange={date => changeTime(date,'end')}
                                            ampm={false}
                                            autoOk={false}
                                        />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Set start and end date for all of the sessions</Typography>
                                </Grid>
                                <Grid item md={6} lg={4}>
                                <label className={classes.label} htmlFor="date" >Start date</label> 
                                    <FormControl fullWidth variant="outlined">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            maxDate={time.endDate}
                                            disabled={disabled}
                                            className={classes.input}
                                            format="dd/MM/yyyy"
                                            variant="inline"
                                            inputVariant='outlined'
                                            value={new Date(time.date ?? null)}
                                            id="date"
                                            name="Date"
                                            onChange={date => changeTime(date,'date')}
                                            autoOk={false}
                                        />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} lg={4}>
                                <label className={classes.label} htmlFor="endDate" >End date</label> 
                                    <FormControl fullWidth variant="outlined">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            minDate={time.date}
                                            disabled={disabled}
                                            className={classes.input}
                                            format="dd/MM/yyyy"
                                            variant="inline"
                                            inputVariant='outlined'
                                            value={new Date(time.endDate ?? null)}
                                            id="endDate"
                                            name="End date"
                                            onChange={date => changeTime(date,'endDate')}
                                            autoOk={false}
                                        />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>                        
                            <Box mb={3.625}>
                                <ButtonHeading text="Are there any days in this series you want to block?"/>
                            </Box>
                            <SessionsTable onValueHandleUpdate={handleValueUpdate} time={time} rows={values}/>
                            <Box mt={2} mb={2}>
                              <Typography align='center' color='error' variant='h6'>{message}</Typography>
                             </Box>
                        </Grid>
                        <Grid item xs={12} md={2} lg={3}>
                        </Grid>
                        <Box mt={4} mb={4}>
                            <BookingBtn loading={loadState} onHandleUpdate={handleUpdate} text="Confirm sessions"/>
                        </Box>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
import React from 'react'
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Link } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    courseBox:{
        padding:theme.spacing(2),
        '&:nth-of-type(odd)':{
            background:theme.palette.background.surface2,
        },
    },
    courseTitle:{
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.5rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.dark.body,
        letterSpacing:'0.18px',
    },
    body1:{
        fontFamily:theme.typography.averta,
        fontWeight:'regular',
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.dark.body,
        letterSpacing:'0.15px',
        '&.bold':{
            fontWeight:'bold',
        },
    },
    link:{
        marginLeft:theme.spacing(1),
        fontFamily:theme.typography.manrope,
        fontSize:'0.875rem',
        lineHeight:'1.375rem',
        letterSpacing:'0.25px',
        color:theme.palette.text.dark.primary,
    },
}));

export default function CourseInfo(props){

    const data = props.courseData
    const classes = useStyles();

    const displayServices = (s) =>{
        let filter = Object.entries(s).filter(([key,value]) => value === true)
        return filter.map(el=>el[0].replaceAll('_',' '))
    }

    return(
        <Box className={classes.courseBox}>    
            <Box mb={2}>
                <Typography className={classes.courseTitle}>{data.course_name}</Typography>
                <Typography className={classes.body1}>{data.course_code}</Typography>
            </Box> 
            {
            data.services?.live_captions || data.services?.summary_notes?                          
            <Box mb={1}>
                <Typography className={classes.body1}>Services</Typography>
                <ul>
                    {data.services?.live_captions? <li className={clsx(classes.body1,'bold')}>Live Captions</li>:''}
                    {data.services?.summary_notes? <li className={clsx(classes.body1,'bold')}>Live Note Taking</li>:''}
                    
                </ul>
            </Box>
            :
                <React.Fragment>
                    <Typography className={classes.body1}>Services</Typography>
                    <ul>
                        {displayServices(data.services).map((element)=>{
                        return <li className={clsx(classes.body1,'bold')} style={{textTransform:'uppercase'}}>{element}</li>
                        })}
                    </ul>
                </React.Fragment>
            }
            <Box mb={1}>
                <Typography className={classes.body1}>Schedule</Typography>
                <ul>
                    <li className={classes.body1}>
                        {data.instances?.events?.length ?? 0} reoccuring instances
                        {/* <Link className={classes.link}>1 modifications</Link> */}
                    </li>
                    <li className={classes.body1}>
                        Beginning {(data.instances?.events[0] ?data.instances?.events[0].date:data.schedule?.Start_date ) ?? data.schedule?.Start_date}, Ending {(data.instances?.events[data.instances?.events.length-1] ?data.instances?.events[data.instances?.events.length-1].date:data.schedule?.End_date ) ?? data.schedule?.End_date  }
                    </li>
                    <li className={classes.body1}>
                         {(data.instances?.events[0] ?data.instances?.events[0].start:data.schedule?.Start_time ) ?? data.schedule?.Start_time}-{(data.instances?.events[0] ?data.instances?.events[0].end:data.schedule?.End_time ) ?? data.schedule?.End_time  }
                        {/* <Link className={classes.link}>2 modifications</Link> */}
                    </li>
                    <li className={classes.body1}>
                        {data.instances?.events?.reduce((a,v) =>  a = parseFloat(a) + parseFloat(v.hours) , 0 )??data.schedule?.hours}   hours booked 
                        <ul>
                            <li className={classes.body1}>(may increase due to sessions running over-time.)</li>
                        </ul>
                    </li>
                </ul>
            </Box>
            {data.services?.NotesRecipients.length>0 || data.services?.TranscriptionRecipient.length>0 ?
            <Box mb={1}>
                <Typography className={classes.body1}>Extra Recipients</Typography>
                <ul>
                {data.services?.NotesRecipients.map((e, index) => 
                    <li key={"Note"+index} className={classes.body1}>{e} will be emailed notes</li>
                )}            
                {data.services?.TranscriptionRecipient.map((e, index) => 
                    <li key={"Tran"+index} className={classes.body1}>{e} will be emailed transcripts</li>
                )}            
                </ul>
            </Box>
            :''}
        </Box>
    );
};
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header:{
        marginTop: (props) => props.mt ? theme.spacing(props.mt) : null,
        marginBottom: (props) => props.mb ? theme.spacing(props.mb) : theme.spacing(1.375),
        fontSize:'3rem',
        lineHeight:'3.5rem',
        fontFamily:theme.typography.ppWoodland,
        color:(props) => {
            switch(props.color){
                case 'accent':
                    return theme.palette.accent.purple;
                default :
                    return theme.palette.text.dark.black;
            }
        },
        fontWeight:500,
    },
}));

export default function PageTitle(props) {
    const classes = useStyles(props);
    return (
        <Typography align={props.textAlign} className={classes.header}>{props.text}</Typography>
    );
  }
import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, Collapse, Dialog, FormControlLabel, InputLabel, IconButton, MenuItem, Popover, Radio, RadioGroup, TablePagination, Typography, TextField, Grid } from '@material-ui/core';
import { AccessTime, CalendarTodayOutlined, FirstPage, KeyboardArrowDown, KeyboardArrowDownRounded, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, LastPage, MoreVert, ReportProblem } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete, ToggleButtonGroup, createFilterOptions } from '@material-ui/lab';
import BookingSessions from './bookingSessions';
import { default as IcnButton } from '../Common/Buttons/IconButton'


export default function BookingInfo({ row, index, onUpdateRows, onDeleteBooking, createSession, validateFields, components, user, subjects, createSessions, onDuplicateBooking, genKey, submitLoad, timeZones }) {

    const useStyles = makeStyles({
        warn: {
            backgroundColor: '#FDE9D5'
        },
        error: {
            backgroundColor: '#F5DEE2',
        },
        toggleError: {
            border: 'solid 1px #B00020'
        }
    });

    const classes = useStyles();
    const filter = createFilterOptions();

    const setSubjectsType = (org) => {
        let copy = [...subjects]
        if (org && org.toLowerCase() == 'connexcel') {
            return copy.filter(subject => subject.source.toLowerCase() == 'connexcel')
        }
        else {
            return copy.filter(subject => subject.source.toLowerCase() == 'beehive')
        }
    }

    const StyledTableRow = components.StyledTableRow
    const StyledTableCell = components.StyledTableCell
    const StyledSubCell = components.StyledSubCell
    const StyledTextField = components.StyledTextField
    const LinkButton = components.LinkButton
    const StyledToggle = components.StyledToggle

    const platformsBase = [
        'Coral',
        'Zoom',
        'Microsoft Teams',
        'WebEx',
        'Blackboard',
        'Canvas',
        'Moodle',
        'Big Blue Button',
        'Slate',
        'Other',
        'To be provided later',
        'Not required',
    ]

    const [platforms, setPlatforms] = React.useState(platformsBase)
    const [data, setData] = React.useState(row)
    const [popover, setPopover] = React.useState(null)
    const [actionEl, setActionEl] = React.useState(null)
    const [page, setPage] = React.useState(0)
    const [sessionErrors, setSessionErrors] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [subjectsSet, setSubjectsSet] = React.useState(setSubjectsType())
    const [services, setServices] = React.useState([{ value: 'Live_captions', name: 'Live Captions' }, { value: 'Live_notes', name: 'Live Notes' }, { value: 'Captions_notes', name: 'Captions and Notes' }])
    const [editableSubjects, setEditableSubjects] = React.useState(false)

    const setPlatformType = (org) => {
        if (org && org.toLowerCase() == 'connexcel') {
            let newPlatforms = platformsBase.concat('Zhumu')
            setPlatforms(newPlatforms)
        }
        else {
            setPlatforms(platformsBase)
        }
    }

    const setServicesType = (org) => {
        let services = []
        if (org && org.length) {
            // Map the custom service types if org has them
            if (org[0]?.team?.teams_service_types && org[0]?.team?.teams_service_types.length != 0) {
                org[0]?.team.teams_service_types.map(service => services.push({
                    value: service.service_type_name ? service.service_type_name.trim().replaceAll(' ', '_').toLowerCase() : 'Unknown Service Type!',
                    name: service.service_type_name ? service.service_type_name.trim() : 'Unknown Service Type!',
                }))
            }
            else{
                services = [{ value: 'Live_captions', name: 'Live Captions' }, { value: 'Live_notes', name: 'Live Notes' }, { value: 'Captions_notes', name: 'Captions and Notes' }]
            }
        }
        else {
            // Default set of services
            services = [{ value: 'Live_captions', name: 'Live Captions' }, { value: 'Live_notes', name: 'Live Notes' }, { value: 'Captions_notes', name: 'Captions and Notes' }]
        }
        return services
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [errors, setErrors] = React.useState({
        stu_firstName: validateFields(data.stu_firstName, 'text', 'stu_firstName'),
        stu_lastName: validateFields(data.stu_lastName, 'text', 'stu_lastName'),
        stu_email: validateFields(data.stu_email, 'text', 'stu_email'),
        institution: validateFields(data.institution, 'select', 'institution'),
        services: validateFields(data.services, 'radio', 'services', services),
        // institution2: validateFields(data.institution2, 'select', 'institution2'),
        course_name: validateFields(data.course_name, 'text', 'course_name'),
        course_code: validateFields(data.course_code, 'text', 'course_code'),
        //course_crn: validateFields(data.course_crn, 'text', 'course_crn'),
        course_subject: validateFields(data.course_subject, 'select', 'course_subject'),
        course_location: data.course_location ? validateFields(data.course_location, 'text', 'course_location') : false,
        course_platform: data.course_platform ? validateFields(data.course_platform, 'select', 'course_platform') : false,
        course_link: data.course_link ? validateFields(data.course_link, 'text', 'course_link', data) : false,
        course_format: validateFields(data.course_format, 'select', 'course_format'),
        start_date: validateFields(data.start_date, 'date', 'start_date', data),
        end_date: validateFields(data.end_date, 'date', 'end_date', data),
        repeats: validateFields(data.repeats, 'toggle', 'repeats'),
        start_time: validateFields(data.start_time, 'date', 'start_time', data),
        end_time: validateFields(data.end_time, 'date', 'end_time', data),
        timezone: validateFields(data.timezone, 'select', 'timezone'),
    })

    const [warns, setWarns] = React.useState({
        ins_firstName: validateFields(data.ins_firstName, 'text', 'ins_firstName'),
        ins_lastName: validateFields(data.ins_lastName, 'text', 'ins_lastName'),
        ins_email: validateFields(data.ins_email, 'text', 'ins_email'),
    })

    useEffect(() => {
        let currentData = data
        let warnArr = Object.values(warns)
        let totalWarn = warnArr.filter(error => error == true)
        currentData.warns = totalWarn.length

        let errArr = Object.values(errors)
        let totalErr = errArr.filter(error => error == true)
        currentData.errors = totalErr.length
        setData({ ...currentData })
    }, [warns, errors])

    useEffect(() => {
        if ((data.course_format && data.course_format === 'online') || (data.course_format && data.course_format === 'blended')) {
            if (data.course_platform === 'To be provided later' || data.course_platform === 'Not required') {
                let errs = { ...errors }
                let nData = { ...data }
                nData.course_link = ''
                errs.course_link = false
                setData(nData)
                setErrors(errs)
            }
            else {
                setErrors({ ...errors, ['course_link']: validateFields(data.course_link, 'text', 'course_link', data) })
            }
        }
    }, [data.course_platform])

    useEffect(() => {
        if (data.institution) {
            let orgs = [...user.duorg_id]
            let org = orgs.find(org => org.organisation_id === data.institution)
            if (org) {
                let services = user.duorg_id.filter(x => x.organisation_id == data.institution)[0].services
                setServices(setServicesType(org.services))
                setData({ ...data, ['userServices']: services })
                setPlatformType(org.Organisation.name)
                setSubjectsSet(setSubjectsType(org.Organisation.name))
            }
            else {
                setServices(setServicesType())
                setPlatformType()
                setSubjectsSet(setSubjectsType())
            }
        }
    }, [data.institution])

    useEffect(() => {
        let valid = validateFields(data.services, 'radio', 'services', services)
        setErrors({ ...errors, ['services']: valid })
    }, [services])

    useEffect(() => {
        if (data.course_format) {
            let errs = { ...errors }
            let nData = { ...data }
            switch (data.course_format) {
                case 'person':
                    nData.course_link = ''
                    nData.course_platform = ''
                    setData(nData)
                    errs.course_link = false
                    errs.course_platform = false
                    errs.course_location = validateFields(data.course_location, 'text', 'course_location')
                    setErrors(errs)
                    break;
                case 'online':
                    nData.course_location = ''
                    setData(nData)
                    errs.course_link = validateFields(data.course_link, 'text', 'course_link', data)
                    errs.course_platform = validateFields(data.course_platform, 'select', 'course_platform')
                    errs.course_location = false
                    setErrors(errs)
                    break;
                case 'blended':
                    errs.course_link = validateFields(data.course_link, 'text', 'course_link', data)
                    errs.course_platform = validateFields(data.course_platform, 'select', 'course_platform')
                    errs.course_location = validateFields(data.course_location, 'text', 'course_location')
                    setErrors(errs)
                    break
                default:
                    console.warn('DEFAULT')
            }
        }
    }, [data.course_format])

    const setOpen = () => {
        setData({ ...data, ['open']: !data.open })
    }

    const handleClose = () => {
        setPopover(null)
    }

    const handleActionsClose = () => {
        setActionEl(null)
    }

    const handleInputChange = (e) => {
        let valid = validateFields(e.target.value, 'text', e.target.name)
        let elem = e.target.name
        let val = e.target.value
        setData({ ...data, [elem]: val })
        if (e.target.name === 'ins_firstName' || e.target.name === 'ins_lastName' || e.target.name === 'ins_email') {
            setWarns({ ...warns, [elem]: valid })
        } else if (e.target.name != 'comment') {
            setErrors({ ...errors, [elem]: valid })
        }
    }

    const handleToggleChange = (e, formats) => {
        let current = { ...data }
        current.repeats = formats
        current.sessions = createSessions(data.start_date, data.end_date, data.start_time, data.end_time, formats, data.services)
        setData({ ...current })
        let valid = validateFields(formats, 'toggle', 'repeats')
        setErrors({ ...errors, ['repeats']: valid })
    }

    const handleDateChange = (e, name) => {
        let current = { ...data }
        switch (name) {
            case 'start_time':
                current.start_time = e
                errors.start_time = validateFields(e, 'date', name, current)
                errors.end_time = validateFields(current.end_time, 'date', 'end_time', current)
                current.sessions = createSessions(data.start_date, data.end_date, e, data.end_time, data.repeats, data.services)
                setErrors({ ...errors })
                break;
            case 'end_time':
                current.end_time = e
                errors.start_time = validateFields(current.start_time, 'date', 'start_time', current)
                errors.end_time = validateFields(e, 'date', name, current)
                current.sessions = createSessions(data.start_date, data.end_date, data.start_time, e, data.repeats, data.services)
                setErrors({ ...errors })
                break;
            case 'start_date':
                current.start_date = e
                errors.start_date = validateFields(e, 'date', name, current)
                errors.end_date = validateFields(current.end_date, 'date', 'end_date', current)
                current.sessions = createSessions(e, data.end_date, data.start_time, data.end_time, data.repeats, data.services)
                setErrors({ ...errors })
                break;
            case 'end_date':
                current.end_date = e
                errors.start_date = validateFields(current.start_date, 'date', 'start_date', current)
                errors.end_date = validateFields(e, 'date', name, current)
                current.sessions = createSessions(data.start_date, e, data.start_time, data.end_time, data.repeats, data.services)
                setErrors({ ...errors })
                break;
            default:
                let valid = validateFields(e, 'date', name, current)
                setErrors({ ...errors, [name]: valid })
        }
        if (!e) {
            e = ''
        }
        setData({ ...current })
    }

    const handleAutocompleteChange = (e, val, name) => {
        if (typeof val == 'object') {
            if (!val) {
                setData({ ...data, [name]: null })
                setErrors({ ...errors, [name]: true })
                return;
            }
            let valid = validateFields(val.name, 'select', name)
            setData({ ...data, [name]: val })
            setErrors({ ...errors, [name]: valid })
        }
        else {
            let valid = validateFields(val, 'select', name)
            setData({ ...data, [name]: val })
            setErrors({ ...errors, [name]: valid })
        }
    }

    const handleSelectChange = (e) => {
        let elem = e.target.name
        let val = e.target.value
        let valid = validateFields(elem, 'select', val)
        setData({ ...data, [elem]: val })
        setErrors({ ...errors, [elem]: valid })
    }

    const handleRadioChange = (e) => {
        let current = { ...data }
        let valid = validateFields(e.target.value, 'radio', 'services', services)
        current.services = e.target.value
        current.sessions = createSessions(data.start_date, data.end_date, data.start_time, data.end_time, data.repeats, e.target.value)
        setErrors({ ...errors, ['services']: valid })
        setData({ ...current })
    }

    const handleSessionUpdate = (e, index) => {
        let current = { ...data }
        let sessions = current.sessions
        let newSessions = sessions.map((session, i) => i !== index ? session : e)
        setData({ ...data, ['sessions']: newSessions })
    }

    const duplicateSession = () => {
        let current = { ...data }
        let sessions = [...current.sessions]
        let dup = sessions.map((session, index) => ({ ...session, index: index })).filter(session => session.checked === true)
        dup.map(session => ({ ...session, key: genKey() })).forEach(session => sessions.splice(session.index + 1, 0, session))
        let newSessions = sessions.map(session => ({ ...session, checked: false }))
        setData({ ...data, ['sessions']: newSessions })
    }

    const addSession = () => {
        let current = { ...data }
        let sessions = [...current.sessions]
        sessions.push(createSession())
        setData({ ...data, ['sessions']: sessions })
    }

    const removeSessions = () => {
        let current = { ...data }
        let rem = current.sessions.filter(session => session.checked === false)
        setData({ ...data, ['sessions']: rem })
    }

    const openDialog = (e) => {
        setDialogOpen(true)
    }

    const handleDialog = (e, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            setCancelDialog(true)
        }
    }

    const cancelSessionsUpdate = () => {
        setCancelDialog(false)
        setDialogOpen(false)
        setData({ ...data, ['sessions']: copy })
    }

    const autoCompleteTest = (e, value) => {
        console.log(e, value, 'Autocomplete event')
    }

    useEffect(() => {
        onUpdateRows(data, index)
        let sessFilt = data.sessions.filter(session => session.errors)
        let sessMap = sessFilt.map(session => session.errors)
        let sessErrs = sessMap.reduce((sum, a) => sum + a, 0)
        setSessionErrors(sessErrs)
    }, [data])

    // useEffect(() => {
    //     let sessions = createSessions(data.start_date, data.end_date, data.start_time, data.end_time, data.repeats, data.services)
    //     setData({ ...data, ['sessions']: sessions })
    // }, [data.start_date, data.end_date, data.start_time, data.end_time, data.repeats, data.services])

    const popoverOpen = Boolean(popover);
    const actionOpen = Boolean(actionEl);

    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [cancelDialog, setCancelDialog] = React.useState(false)
    const [copy, setCopy] = React.useState([])

    useEffect(() => { setCopy([...data.sessions]) }, [dialogOpen])



    return (
        <React.Fragment>
            <StyledTableRow hover>
                <StyledTableCell onClick={setOpen} align='center' style={{ width: 56 }} component="th" scope="row">{index + 1}</StyledTableCell>
                <StyledTableCell onClick={setOpen}>
                    <IconButton style={{ marginRight: '0.5rem' }} aria-label="Expand row" size="small">
                        {data.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    {!data.stu_firstName && !data.stu_lastName
                        ? 'Please provide first and last name'
                        : data.stu_firstName + ' ' + data.stu_lastName
                    }
                </StyledTableCell>
                <StyledTableCell onClick={setOpen}>{data.course_code}</StyledTableCell>
                <StyledTableCell onClick={setOpen}>{data.sessions.length} {data.sessions.length != 1 ? ' instances' : ' instance'}</StyledTableCell>
                {/* <StyledTableCell>{data.status}</StyledTableCell> */}
                <StyledTableCell onClick={setOpen} className={data.warns != 0 ? classes.warn : null}>{data.warns}</StyledTableCell>
                <StyledTableCell onClick={setOpen} className={data.errors + sessionErrors != 0 ? classes.error : null}>
                    {data.errors + sessionErrors}
                </StyledTableCell>
                <StyledTableCell style={{ width: 56, textAlign: 'center' }}>
                    <IconButton disabled={submitLoad} onClick={(e) => { setPopover(e.currentTarget) }} size="small" aria-label="Options">
                        <MoreVert />
                    </IconButton>
                    <Popover
                        open={popoverOpen}
                        anchorEl={popover}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box display='flex' flexDirection='column'>
                            {/* <Button onClick={() => console.log(errors)}>
                                Print Errors
                            </Button> */}
                            <Button onClick={() => onDuplicateBooking(index, data)}>
                                Duplicate
                            </Button>
                            <Button onClick={() => onDeleteBooking(index)}>
                                <Typography style={{ fontSize: '0.875rem' }} color='error'>Delete</Typography>
                            </Button>
                            {/* <Button target="_blank" href="https://www.youtube.com/watch?v=QB7ACr7pUuE" type='link'>
                                Coffee :)
                            </Button> */}
                        </Box>
                    </Popover>
                </StyledTableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={12}>
                    <Collapse in={data.open} timeout='auto' unmountOnExit>
                        <Box>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Campus</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <Box display='flex' gridGap={16}>
                                                    <StyledTextField disabled={submitLoad} name="institution" onChange={handleSelectChange} value={data.institution ?? ''} style={{ width: 248 }} size='small' variant='outlined' select label="*Campus" error={errors.institution}>
                                                        <MenuItem disabled value="">*Select a campus</MenuItem>
                                                        {user.duorg_id.map(org => <MenuItem key={org.organisation_id} value={org.organisation_id}>{org.Organisation.name + ' - ' + org.Organisation.department}</MenuItem>)}
                                                    </StyledTextField>
                                                    {/* Institution will probably be split in 2 parts Main and Campus, leaving this for now
                                                    <StyledTextField name="institution2" onChange={handleSelectChange} value={data.institution2 ?? ''} style={{ width: 248 }} size='small' variant='outlined' select label="Institution2" error={errors.institution2}>
                                                        <MenuItem disabled value="">Select a thingy</MenuItem>
                                                        <MenuItem value="Ins1">Institute 1</MenuItem>
                                                        <MenuItem value="Ins2">Institute 2</MenuItem>
                                                        <MenuItem value="Ins3">Institute 3</MenuItem>
                                                        <MenuItem value="Ins4">Institute 4</MenuItem>
                                                    </StyledTextField> */}
                                                </Box>
                                                {errors.institution
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Required field, please select the campus to edit other fields
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row" />
                                        <StyledSubCell style={{ width: 224 }}>Student Info</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <Box display='flex' gridGap={16}>
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="stu_firstName" onChange={handleInputChange} style={{ width: 120 }} size="small" value={data.stu_firstName ?? ''} label="*First Name" variant="outlined" error={errors.stu_firstName} />
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="stu_lastName" onChange={handleInputChange} style={{ width: 120 }} size="small" value={data.stu_lastName ?? ''} label="*Last Name" variant="outlined" error={errors.stu_lastName} />
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="stu_email" onChange={handleInputChange} style={{ width: 248 }} size="small" value={data.stu_email ?? ''} label="*Email" variant="outlined" error={errors.stu_email} />
                                                </Box>
                                                {errors.stu_firstName || errors.stu_lastName || errors.stu_email
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            {errors.stu_email && !errors.stu_lastName && !errors.stu_firstName
                                                                ? 'Invalid email'
                                                                : 'Required field'
                                                            }
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Services</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <RadioGroup disabled={submitLoad || errors.institution} row aria-label="Services" id={'Services' + index} name="services" value={data.services} onChange={handleRadioChange}>
                                                    {services.map(service => <FormControlLabel key={service.value} disabled={submitLoad || errors.institution} value={service.value} control={<Radio />} label={service.name} />)}
                                                </RadioGroup>
                                                <Box gridGap={8} display='flex' alignItems="center">
                                                    {errors.services
                                                        ?
                                                        <React.Fragment>
                                                            <Typography color='error'>
                                                                Required field
                                                            </Typography>
                                                            <ReportProblem color="error" />
                                                        </React.Fragment>
                                                        : null
                                                    }
                                                </Box>
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Delivery type</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" gridGap={16} justifyContent='space-between' alignItems='center'>
                                                <Box flexGrow={1} display='flex' gridGap={16}>
                                                    <Box>
                                                        <StyledTextField disabled={submitLoad || errors.institution} name="course_format" onChange={handleSelectChange} value={data.course_format ?? ''} style={{ width: 176 }} size='small' variant='outlined' select label="*Delivery type" error={errors.course_format}>
                                                            <MenuItem disabled value="">*Select a delivery type</MenuItem>
                                                            <MenuItem value="person">In-person</MenuItem>
                                                            <MenuItem value="online">Online</MenuItem>
                                                            <MenuItem value="blended">Blended</MenuItem>
                                                        </StyledTextField>
                                                    </Box>
                                                    <Grid container spacing={2}>
                                                        {
                                                            (data.course_format && data.course_format.toLowerCase() === 'online') || (data.course_format && data.course_format.toLowerCase() === 'blended')
                                                                ?
                                                                <React.Fragment>
                                                                    <Grid item xs={4}>
                                                                        <StyledTextField disabled={submitLoad || errors.institution} name="course_platform" onChange={handleSelectChange} value={data.course_platform ?? ''} fullWidth size='small' variant='outlined' select label="*Platform" error={errors.course_platform}>
                                                                            <MenuItem disabled value="">Select an Online platform</MenuItem>
                                                                            {platforms.map(platform => <MenuItem key={platform} value={platform}>{platform}</MenuItem>)}
                                                                        </StyledTextField>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <StyledTextField disabled={submitLoad || errors.institution || data.course_platform === 'To be provided later' || data.course_platform === 'Not required'} name="course_link" onChange={handleInputChange} value={data.course_link ?? ''} fullWidth size='small' variant='outlined' label="*Link" error={errors.course_link} />
                                                                    </Grid>
                                                                </React.Fragment>
                                                                : null
                                                        }
                                                        {
                                                            (data.course_format && data.course_format.toLowerCase() === 'person') || (data.course_format && data.course_format.toLowerCase() === 'blended')
                                                                ?
                                                                <Grid item xs={12}>
                                                                    <StyledTextField disabled={submitLoad || errors.institution} name="course_location" onChange={handleInputChange} value={data.course_location ?? ''} fullWidth size='small' variant='outlined' placeholder='*Building, location, room number, etc.' label="*Location" error={errors.course_location} />
                                                                    {/* <Autocomplete
                                                                        //onChange={(event, value) => handleAutocompleteChange(event, value, 'course_location')}
                                                                        onChange={(event, value) => {
                                                                            if (typeof value === 'string') {
                                                                                handleAutocompleteChange(event, value, 'course_location')
                                                                            } else if (value && value.inputValue) {
                                                                                handleAutocompleteChange(event, value.inputValue, 'course_location')
                                                                            } else {
                                                                                handleAutocompleteChange(event, value, 'course_location')
                                                                            }
                                                                        }}
                                                                        filterOptions={(options, params) => {
                                                                            const filtered = filter(options, params);
                                                                            if (params.inputValue !== '') {
                                                                                filtered.push({
                                                                                    inputValue: params.inputValue,
                                                                                    title: `Add "${params.inputValue}"`,
                                                                                });
                                                                            }
                                                                            return filtered;
                                                                        }}
                                                                        id="course_location"
                                                                        name="course_location"
                                                                        options={platforms}
                                                                        disabled={submitLoad}
                                                                        size="small"
                                                                        value={data.course_location ?? null}
                                                                        getOptionLabel={(option) => {
                                                                            if (typeof option === 'string') {
                                                                                return option;
                                                                            }
                                                                            if (option.inputValue) {
                                                                                return option.inputValue;
                                                                            }
                                                                            return option;
                                                                        }}
                                                                        renderOption={(option) => {
                                                                            if (typeof option === 'string') {
                                                                                return option;
                                                                            }
                                                                            if (option.inputValue) {
                                                                                return option.inputValue;
                                                                            }
                                                                            return option;
                                                                        }}
                                                                        freeSolo
                                                                        selectOnFocus
                                                                        clearOnBlur
                                                                        handleHomeEndKeys
                                                                        renderInput={(params) => <StyledTextField size="small" {...params} placeholder='*Building, location, room number, etc.' label="*Location" error={errors.course_location} variant="outlined" />}
                                                                    /> */}
                                                                </Grid>
                                                                :
                                                                null
                                                        }
                                                    </Grid>
                                                    {/* <StyledTextField name="course_format" onChange={handleSelectChange} value={data.course_format ?? ''} style={{ width: 176 }} size='small' variant='outlined' select label="Format" error={errors.course_format}>
                                                        <MenuItem disabled value="">Select a format</MenuItem>
                                                        <MenuItem value="Format1">Format 1</MenuItem>
                                                        <MenuItem value="Format2">Format 2</MenuItem>
                                                    </StyledTextField> */}
                                                </Box>
                                                {errors.course_location || errors.course_format || errors.course_link || errors.course_platform
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Required field
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Course Info</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <Box display='flex' gridGap={16}>
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="course_name" onChange={handleInputChange} value={data.course_name ?? ''} style={{ width: 248 }} size='small' variant='outlined' label="*Course Name" error={errors.course_name} />
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="course_code" onChange={handleInputChange} value={data.course_code ?? ''} style={{ width: 144 }} size='small' variant='outlined' label="*Course Code" error={errors.course_code} />
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="course_crn" onChange={handleInputChange} value={data.course_crn ?? ''} style={{ width: 120 }} size='small' variant='outlined' label="Course CRN" />
                                                    {/* <StyledTextField disabled={submitLoad || errors.institution} name="course_subject" onChange={handleSelectChange} value={data.course_subject ?? ''} style={{ width: 248 }} size='small' variant='outlined' select label="*Subject" error={errors.course_subject}>
                                                        <MenuItem disabled value="">Select a subject</MenuItem>
                                                        {subjectsSet != undefined ?
                                                            subjects.Subject.map(subject => <MenuItem key={subject.id} value={subject.id}>{subject.name}</MenuItem>)
                                                            : <MenuItem value=""><Typography color="error">Error occured</Typography></MenuItem>
                                                        }
                                                    </StyledTextField> */}
                                                    <Autocomplete
                                                        onChange={(event, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                handleAutocompleteChange(event, newValue, 'course_subject')
                                                            }
                                                            else if (newValue && newValue.inputValue) {
                                                                let newSubjects = [...subjectsSet]
                                                                newSubjects.push({ name: newValue.inputValue, category: newValue.inputValue })
                                                                setSubjectsSet(newSubjects)
                                                                handleAutocompleteChange(event, newValue.inputValue, 'course_subject')
                                                            }
                                                            else {
                                                                handleAutocompleteChange(event, newValue, 'course_subject')
                                                            }
                                                        }}
                                                        // filterOptions={(options, params) => {
                                                        //     const filtered = filter(options, params)
                                                        //     console.log(filtered)
                                                        //     if (params.inputValue !== '') {
                                                        //         filtered.push({
                                                        //             inputValue: params.inputValue,
                                                        //             name: `Add "${params.inputValue}"`,
                                                        //         })
                                                        //     }
                                                        //     return filtered
                                                        // }}
                                                        //freeSolo
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === 'string') {
                                                                return option
                                                            }
                                                            if (option.inputValue) {
                                                                return option.inputValue
                                                            }
                                                            return option.name
                                                        }}
                                                        id="course_subject"
                                                        name="course_subject"
                                                        options={(subjectsSet)}
                                                        renderOption={(option) => option.name}
                                                        disabled={submitLoad || errors.institution}
                                                        size="small"
                                                        value={data.course_subject ?? null}
                                                        style={{ width: 248 }}
                                                        renderInput={(params) => <StyledTextField size="small" {...params} label="*Subject" error={errors.course_subject} variant="outlined" />}
                                                    />
                                                </Box>
                                                {errors.course_name || errors.course_code || errors.course_subject
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Required field
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Link</StyledSubCell>
                                        <StyledSubCell>
                                            <StyledTextField value={data.mp_link} style={{ width: 512 }} size='small' variant='outlined' label="Messenger Pigeon Link" />
                                        </StyledSubCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Instances</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <Box display="flex" gridGap={16}>
                                                    <StyledTextField name="bookings" value={data.sessions.length} style={{ width: 120 }} inputProps={{ readOnly: true }} size='small' variant='outlined' label="Instances" />
                                                    <LinkButton disabled={submitLoad || errors.institution} onClick={openDialog} color='primary' size="small"> View/edit all instances </LinkButton>
                                                </Box>
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Duration</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <Box display='flex' alignItems='center' gridGap={16}>
                                                    <InputLabel>Start Date:</InputLabel>
                                                    <Box alignSelf={'start'}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                disabled={submitLoad || errors.institution}
                                                                style={{ width: 160 }}
                                                                inputVariant='outlined'
                                                                size='small'
                                                                format='dd/MM/yy'
                                                                name="start_date"
                                                                value={data.start_date}
                                                                label='*Start Date'
                                                                keyboardIcon={<CalendarTodayOutlined />}
                                                                onChange={date => handleDateChange(date, 'start_date')}
                                                                error={errors.start_date}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                    <InputLabel>End Date:</InputLabel>
                                                    <Box alignSelf={'start'}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                disabled={submitLoad || errors.institution}
                                                                style={{ width: 160 }}
                                                                inputVariant='outlined'
                                                                size='small'
                                                                format='dd/MM/yy'
                                                                name="end_date"
                                                                value={data.end_date}
                                                                label='*End Date'
                                                                keyboardIcon={<CalendarTodayOutlined />}
                                                                onChange={date => handleDateChange(date, 'end_date')}
                                                                error={errors.end_date}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                </Box>
                                                {errors.start_date || errors.end_date
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Invalid date
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Repeats</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                <Box display='flex' gridGap={16} alignItems='center'>
                                                    <InputLabel>Repeats on:</InputLabel>
                                                    <ToggleButtonGroup disabled={submitLoad || errors.institution} name="repeats" value={data.repeats} exclusive onChange={handleToggleChange}>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='0'>
                                                            S
                                                        </StyledToggle>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='1'>
                                                            M
                                                        </StyledToggle>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='2'>
                                                            T
                                                        </StyledToggle>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='3'>
                                                            W
                                                        </StyledToggle>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='4'>
                                                            T
                                                        </StyledToggle>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='5'>
                                                            F
                                                        </StyledToggle>
                                                        <StyledToggle className={errors.repeats ? classes.toggleError : null} disabled={submitLoad || errors.institution} value='6'>
                                                            S
                                                        </StyledToggle>
                                                    </ToggleButtonGroup>
                                                </Box>
                                                {errors.repeats
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Required field
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Time</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                <Box display='flex' alignItems='center' gridGap={16}>
                                                    <InputLabel>Start Time:</InputLabel>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Box alignSelf={'start'}>
                                                            <KeyboardTimePicker
                                                                disabled={submitLoad || errors.institution}
                                                                style={{ width: 160 }}
                                                                inputVariant='outlined'
                                                                size='small'
                                                                format='HH:mm'
                                                                name="start_time"
                                                                value={data.start_time ?? new Date()}
                                                                label='*Start Time'
                                                                keyboardIcon={<AccessTime />}
                                                                onChange={date => handleDateChange(date, 'start_time')}
                                                                error={errors.start_time}
                                                            />
                                                        </Box>
                                                    </MuiPickersUtilsProvider>
                                                    <InputLabel>End Date:</InputLabel>
                                                    <Box alignSelf={'start'}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardTimePicker
                                                                disabled={submitLoad || errors.institution}
                                                                style={{ width: 160 }}
                                                                inputVariant='outlined'
                                                                size='small'
                                                                format='HH:mm'
                                                                name="end_time"
                                                                value={data.end_time ?? new Date()}
                                                                label='*End Time'
                                                                keyboardIcon={<AccessTime />}
                                                                onChange={date => handleDateChange(date, 'end_time')}
                                                                error={errors.end_time}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                </Box>
                                                {errors.start_time || errors.end_time
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Invalid date
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Time zone</StyledSubCell>
                                        <StyledSubCell>
                                            <Box display="flex" justifyContent='space-between' alignItems='center'>
                                                {/* <StyledTextField disabled={submitLoad} name="tz" onChange={handleSelectChange} value={data.tz ?? ''} style={{ width: 248 }} size='small' variant='outlined' select label="Time zone" error={errors.tz}>
                                                    <MenuItem disabled value="">Select a timezone</MenuItem>
                                                    {timeZones.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                                                </StyledTextField> */}
                                                <Autocomplete
                                                    onChange={(event, value) => handleAutocompleteChange(event, value, 'timezone')}
                                                    id="timezone"
                                                    name="timezone"
                                                    options={timeZones}
                                                    disabled={submitLoad || errors.institution}
                                                    size="small"
                                                    value={data.timezone ?? null}
                                                    getOptionLabel={(option) => option}
                                                    style={{ width: 248 }}
                                                    renderInput={(params) => <StyledTextField size="small" {...params} label="*Time zone" error={errors.timezone} variant="outlined" />}
                                                />
                                                {errors.timezone
                                                    ?
                                                    <Box gridGap={8} display='flex' alignItems="center">
                                                        <Typography color='error'>
                                                            Required field
                                                        </Typography>
                                                        <ReportProblem color="error" />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Comments</StyledSubCell>
                                        <StyledSubCell>
                                            <StyledTextField disabled={submitLoad || errors.institution} index={index} name="comment" onChange={handleInputChange} value={data.comment} style={{ width: 512 }} size='small' variant='outlined' label="Comments" multiline minRows={3} />
                                        </StyledSubCell >
                                    </TableRow>
                                    <TableRow>
                                        <StyledSubCell style={{ width: 56 }} component="th" scope="row"></StyledSubCell>
                                        <StyledSubCell style={{ width: 224 }}>Instructor Info</StyledSubCell>
                                        <StyledSubCell>
                                            <Box justifyContent='space-between' display='flex' gridGap={16}>
                                                <Box display='flex' gridGap={16}>
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="ins_firstName" onChange={handleInputChange} value={data.ins_firstName} style={{ width: 120 }} size="small" label="First Name" variant="outlined" />
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="ins_lastName" onChange={handleInputChange} value={data.ins_lastName} style={{ width: 120 }} size="small" label="Last Name" variant="outlined" />
                                                    <StyledTextField disabled={submitLoad || errors.institution} name="ins_email" onChange={handleInputChange} value={data.ins_email} style={{ width: 248 }} size="small" label="Email" variant="outlined" />
                                                </Box>
                                                {warns.ins_firstName || warns.ins_lastName || warns.ins_email
                                                    ?
                                                    <Box style={{ width: 280 }} flexShrink={1} gridGap={8} display='flex' alignItems="center">
                                                        <Typography align='right' style={{ color: '#C3782E' }}>
                                                            We suggest filling this, but you can still submit without it.
                                                        </Typography>
                                                        <ReportProblem style={{ color: '#FBD3AC', width: '2rem', height: '2rem' }} />
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </StyledSubCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <Dialog scroll='body' maxWidth='lg' onClose={(e, reason) => handleDialog(e, reason)} open={dialogOpen}>
                <Box p={8} >
                    <Box mb={3}>
                        <Typography align='center' variant='h2' component='p'>All bookings in the series</Typography>
                    </Box>
                    <Box style={{ maxWidth: 660 }} display='flex' mx='auto' mb={7}>
                        <Typography align='center' component='p'>You can view and edit each information for the following booking series. Don’t forget to save changes before exiting this window.</Typography>
                    </Box>
                    {/* <Button onClick={() => console.log(copy)}>Print copy of sessions</Button> */}
                    <Box mb={8}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ width: '228px' }}>Student Name</StyledTableCell>
                                        <StyledTableCell style={{ width: '228px' }}>Course Code</StyledTableCell>
                                        <StyledTableCell style={{ width: '228px' }}>Bookings</StyledTableCell>
                                        <StyledTableCell style={{ width: '228px' }}>Warnings</StyledTableCell>
                                        <StyledTableCell style={{ width: '228px' }}>Errors</StyledTableCell>
                                        {/* Status Tab <StyledTableCell style={{ width: '228px' }}>Status</StyledTableCell> */}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {!data.stu_firstName && !data.stu_lastName
                                                ? 'Please provide first and last name'
                                                : data.stu_firstName + ' ' + data.stu_lastName
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {data.course_code}
                                        </TableCell>
                                        <TableCell>
                                            {data.sessions.length} {data.sessions.length != 1 ? ' instances' : ' instance'}
                                        </TableCell>
                                        <TableCell className={data.warns != 0 ? classes.warn : null}>
                                            {data.warns}
                                        </TableCell>
                                        <TableCell className={data.errors + sessionErrors != 0 ? classes.error : null}>
                                            {data.errors + sessionErrors}
                                        </TableCell>
                                        {/* Status Tab <TableCell>
                                            Pending
                                        </TableCell> */}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box mb={4}>
                        <Box display='flex' gridGap={8} mb={2}>
                            <Button onClick={(e) => setActionEl(e.currentTarget)}>
                                Actions
                                <KeyboardArrowDownRounded />
                            </Button>
                            <Popover
                                open={actionOpen}
                                anchorEl={actionEl}
                                onClose={handleActionsClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Box display='flex' flexDirection='column'>
                                    <Button onClick={() => { addSession(); handleActionsClose() }}>Add session</Button>
                                    <Button disabled={data.sessions.filter(session => session.checked === true).length == 0} onClick={() => { duplicateSession(); handleActionsClose() }}>Duplicate sessions</Button>
                                    {/* <Button onClick={() => { console.log(data, 'Sessions'); handleActionsClose() }}>Print sessions</Button> */}
                                    <Button disabled={data.sessions.filter(session => session.checked === true).length == 0} onClick={() => { removeSessions(); handleActionsClose() }}><Typography style={{ fontSize: '0.875rem' }} color={data.sessions.filter(session => session.checked === true).length == 0 ? 'initial' : 'error'}>Remove sessions</Typography></Button>
                                </Box>
                            </Popover>
                        </Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ width: '56px' }} />
                                        <StyledTableCell style={{ width: '56px' }} />
                                        <StyledTableCell style={{ minWidth: '210px' }}>Date</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: '240px' }}>Duration</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: '210px' }}>Services</StyledTableCell>
                                        {/* <StyledTableCell style={{ minWidth: '210px' }}>Location</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: '210px' }}>Username</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: '210px' }}>Password</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: '280px' }}>Messenger Pigeon Link</StyledTableCell> */}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0 ? data.sessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data.sessions).map((session, index) => <BookingSessions services={services} validateFields={validateFields} components={components} index={index} onHandleSessionUpdate={handleSessionUpdate} key={session.key} session={session} bookingData={data} />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, { label: 'All', value: -1 }]}
                            component='div'
                            count={data.sessions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='center' gridGap={16} align='center'>
                        <IcnButton onHandleUpdate={() => setCancelDialog(true)} variant="light" noIcon text="Cancel" />
                        <IcnButton onHandleUpdate={() => setDialogOpen(false)} variant="accent" noIcon text="Save" />
                    </Box>
                    <Dialog onClose={() => setCancelDialog(false)} open={cancelDialog}>
                        <Box p={8}>
                            <Box mb={3}>
                                <Typography align='center' variant='h2' component='p'>Are you sure you want to cancel changes?</Typography>
                            </Box>
                            <Box display='flex' flexDirection='row' justifyContent='center' gridGap={16} align='center'>
                                <IcnButton onHandleUpdate={() => setCancelDialog(false)} variant="light" noIcon text="Keep changes" />
                                <IcnButton onHandleUpdate={cancelSessionsUpdate} variant="error" noIcon text="Cancel changes" />
                            </Box>
                        </Box>
                    </Dialog>
                </Box>
            </Dialog>
        </React.Fragment >
    )
}


function TablePaginationActions(props) {

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
        <Box
            display='flex'
        >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPage />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPage />
            </IconButton>
        </Box>
    )
}
import React,{Suspense} from 'react';
import { Box } from '@material-ui/core';
import { Container } from '@material-ui/core';
import ViewBookings from '../components/Bookings/ViewBookings'
import EditBookings from '../components/Bookings/EditBookings';
import {Route, Switch, useRouteMatch } from 'react-router-dom';

export default function Bookings(props) {
    let {path,url} = useRouteMatch();
    return(
    <Suspense fallback={<div>Loading...</div>}>
    <Switch>
        <Route exact path={path} component={()=><ViewBookings path={path} user={props.user}/>}/>
        <Route path={`${path}/edit/:studentId/:id/:sessionId/`} component={()=><EditBookings user={props.user}/>}/>
        <Route path={`${path}/add`}>same as above</Route>
    </Switch>
    </Suspense>
    )
  }
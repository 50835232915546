import React, { useEffect } from 'react';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PillButton from '../Common/Buttons/PillButton';
import Link from '@material-ui/core/Link';
import { CircularProgress } from '@material-ui/core';


const getStudents = gql`
query getStudents($ids: [uuid!] = "") {
  User(where: {_or:[{deleted: {_neq: true}},{deleted: {_is_null: true}}], role_id: {_eq: "a2f699ca-fa3b-4ff0-a19c-afcc55d3ef15"}, organisation_id: {_in: $ids}}) {
    email
    first_name
    last_name
    id
    Organisation {
      department
      name
    }
    Students {
      id
      academic_email
      reference
      CourseStudents {
        course_id
        id
      }
    }
  }
}
`

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-body': {
      wordWrap: 'anywhere',
      color: theme.palette.text.dark.body,
    },
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
    }
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
    }
  },
  pagination: {
    borderBottom: 'unset',
    borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
  },
  search: {
    '& input': {
      padding: theme.spacing(1),
    },
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box display={'flex'} className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton> */}
      <Divider orientation='vertical' flexItem />
      <Box>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>

      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton> */}
    </Box>
  );
}

const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 201, 175, 0.1)',
  },
}))(TableRow);
const StyledCellHead = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.averta,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    borderLeft: 'solid 1px transparent',
    borderRight: 'solid 1px transparent',
    '&:not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)'
    },
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      background: 'rgba(251, 251, 250, 1)',
    },
    backgroundColor: '#FFFFFF',
  },
}))(TableRow);
const StyledRowHead = withStyles((theme) => ({
  body: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.1px',
  },
}))(TableCell);

function createData(student_id, name, institution, email, bookings, id,studentId) {
  let bookingsNo = bookings.length ?? 0
  return {
    student_id,
    name,
    institution,
    email,
    bookingsNo,
    id,
    studentId
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment key={row.id} >
      <StyledTableRow className={classes.root}>
        <TableCell width="12%" component="th" scope="row">
          {row.student_id != '' ? row.student_id : 'N/A'}
        </TableCell>
        <TableCell width="18%" component="th" scope="row">
          <Link component={RouterLink} color="primary" to={props.path + '/edit/' + row.id}>
            {row.name}
          </Link>
        </TableCell>
        <TableCell width="25%">{row.institution}</TableCell>
        <TableCell width="25%">
          {row.email}
        </TableCell>
        <TableCell width="20%">
          {row.bookingsNo != 0 ? row.bookingsNo : 'No bookings'}
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function Students(props) {

  let { url } = useRouteMatch();
  const classes = useStyles1();
  let rows = []

  useEffect(() => {
    handleRequestSort(props.sortValue)
  }, [props.sortValue]);

  useEffect(() => {
    setPage(0);
  }, [props.searchValue]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === event && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(event);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.sort);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows, setEmptyRows] = React.useState(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage));

  useEffect(() => {
    setEmptyRows(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage))
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const orgArray = props.user.duorg_id.map(function (org) {
    return org.organisation_id
  });
  const { loading, error, data } = useQuery(getStudents, {
    variables: {
      ids: orgArray ?? props.user.duorg_id[0].id
    }
  });
  // REV / SUG - Wrap table in component to reduce reusing it?
  if (loading) {
    return (
      <Box mb={props.mb}>
        <TableContainer>
          <Table aria-label="Student table">
            <TableHead>
              <StyledTableHead>
                <StyledCellHead width="12%">Student ID</StyledCellHead>
                <StyledCellHead width="18%">Student Name</StyledCellHead>
                <StyledCellHead width="25%">Institution</StyledCellHead>
                <StyledCellHead width="25%">Email</StyledCellHead>
                <StyledCellHead width="20%">Bookings</StyledCellHead>
              </StyledTableHead>
            </TableHead>
            <TableBody>
              <StyledTableRow style={{ height: 52 * 5 }} className={classes.root}>
                <TableCell width="100%" colSpan={12} component="th" scope="row">
                  <Box textAlign={'center'}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
  if (error) {
    console.error(error);
    return (
      <Box mb={props.mb}>
        <TableContainer>
          <Table aria-label="Student table">
            <TableHead>
              <StyledTableHead>
                <StyledCellHead width="12%">Student ID</StyledCellHead>
                <StyledCellHead width="18%">Student Name</StyledCellHead>
                <StyledCellHead width="25%">Institution</StyledCellHead>
                <StyledCellHead width="25%">Email</StyledCellHead>
                <StyledCellHead width="20%">Bookings</StyledCellHead>
              </StyledTableHead>
            </TableHead>
            <TableBody>
              <StyledTableRow style={{ height: 52 * 5 }} className={classes.root}>
                <TableCell width="100%" colSpan={12} component="th" scope="row">
                  <Box textAlign={'center'}>
                    <Typography color='error'>
                      An error has occured!
                    </Typography>
                  </Box>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={5} textAlign='end'>
          <PillButton component={RouterLink} to={`${url}/CreateStudent`} variant='accentOutlined' noIcon text="Add student" />
        </Box>
      </Box>
    );
  }
  else {
    data.User.forEach(student => {
      rows.push(createData(
        student.Students[0]?.reference ?? 'N/A',
        student.first_name.concat(' ', student.last_name) ?? 'N/A',
        student.Organisation ? student.Organisation.name.concat(' - ', student.Organisation.department) : 'N/A',
        student.email ?? 'N/A',
        student.Students ? student.Students.flatMap(student => student.CourseStudents) : [],
        student.id,
        student.Students[0].id,
      ))
    })
    // data.Student.forEach(Student => {
    //   rows.push(createData(
    //     Student?.reference ?? 'N/A',
    //     Student?.User?.first_name + ' ' + Student?.User?.last_name ?? 'N/A',
    //     Student?.User?.Organisation?.name ?? 'N/A',
    //     Student?.User?.email ?? 'N/A',
    //     Student?.CourseStudents ?? 'N/A',
    //     Student?.User?.id ?? null,
    //   ))
    // })
  }
  if (props.searchValue !== '' && props.searchValue !== null) {
    const searchRes = rows.filter(row => {
      return (
        row.name.toLowerCase().includes(props.searchValue.toLowerCase().trim()) ||
        row.email.toLowerCase().includes(props.searchValue.toLowerCase().trim())
      )
    },
    )
    if (searchRes.length !== 0) {
      rows = searchRes;
    }
    else {
      return (
        <Box mb={props.mb}>
          <TableContainer>
            <Table aria-label="Student table">
              <TableHead>
                <StyledTableHead>
                  <StyledCellHead width="12%">Student ID</StyledCellHead>
                  <StyledCellHead width="18%">Student Name</StyledCellHead>
                  <StyledCellHead width="25%">Institution</StyledCellHead>
                  <StyledCellHead width="25%">Email</StyledCellHead>
                  <StyledCellHead width="20%">Bookings</StyledCellHead>
                </StyledTableHead>
              </TableHead>
              <TableBody>
                <StyledTableRow style={{ height: 52 * 5 }} className={classes.root}>
                  <TableCell width="100%" colSpan={12} component="th" scope="row">
                    <Box textAlign={'center'}>No Students found</Box>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={5} textAlign='end'>
            <PillButton component={RouterLink} to={`${url}/CreateStudent`} variant='accentOutlined' noIcon text="Add student" />
          </Box>
        </Box>
      )
    }
  }
  else {
    rows = rows
  }

  return (
    <Box mb={props.mb}>
      <TableContainer>
        <Table aria-label="Student table">
          <TableHead>
            <StyledTableHead>
              <StyledCellHead>Student ID</StyledCellHead>
              <StyledCellHead>Student Name</StyledCellHead>
              <StyledCellHead>Institution</StyledCellHead>
              <StyledCellHead>Email</StyledCellHead>
              <StyledCellHead>Bookings</StyledCellHead>
            </StyledTableHead>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <Row path={props.path} key={row.id} row={row} />
            ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 52 * emptyRows }}>
                <TableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.pagination}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={12}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box mt={5} textAlign='end'>
        <PillButton component={RouterLink} to={`${url}/CreateStudent`} variant='accentOutlined' noIcon text="Add student" />
      </Box>
    </Box>
  );
};


function TableContent(props) {
  console.log(props, 'props in TableContent')
  return (
    'Component'
  )
}
import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, TextField } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import BookingInfo from './bookingInfo';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#E8E8E8',
    color: '#191919',
    textTransform: 'uppercase',
    fontFamily: theme.typography.averta,
    fontWeight: 'bold',
    fontSize: '18px;'
  },
  body: {
    border: 'solid 1px rgb(0 0 0 / 0.15)',
    fontSize: 14,
  },
}))(TableCell);

const StyledSubCell = withStyles((theme) => ({
  body: {
    '&:nth-child(1)': {
      background: 'unset',
      border: 'unset',
    },
    '&:nth-child(2)': {
      background: '#F4F4F4',
      textTransform: 'uppercase',
      fontFamily: theme.typography.averta,
      color: '#191919',
      fontWeight: 'bold',
      fontSize: '1.1rem',
    }
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#fdfdfd",
    },
    backgroundColor: 'white',
    cursor: 'pointer',
  },
}))(TableRow);

const PopupButton = withStyles((theme) => ({
  root: {
    width: '100%',
    justifyContent: 'space-between',
    textTransform: 'unset',
  }
}))(Button);

const LinkButton = withStyles((theme) => ({
  root: {
    textTransform: 'unset',
    textDecoration: 'underline',
    fontSize: '1rem',
    '&:hover': {
      background: 'unset',
      textDecoration: 'underline',
    }
  },
}))(Button);

const StyledTextField = withStyles((theme) => ({
  root: {
    borderRadius: '6px',
    fontSize: '12px',
    '& [readonly]': {
      background: '#F4F4F4',
      color: '#BABABA',
    },
    // INF - withStyles props ternary
    // border:props=>
    //   props.warn === 'true'
    //   ?'solid 1px #C3782E'
    //   : null,
    // color:props=>
    //   props.warn === 'true'
    //   ?'#C3782E'
    //   : null
  }
}))(TextField);

const StyledToggle = withStyles((theme) => ({
  root: {
    width: '40px',
    height: '32px',
    color: '#191919',
    '&.Mui-selected, &.Mui-selected:hover': {
      background: theme.palette.primary.main,
      color: 'white',
    },
  },
}))(ToggleButton);

function compareDate(a, b) {
  if (a && b) {
    let diff = b.getTime() - a.getTime()
    return (diff < 0)
  }
  else return true
}

function validateFields(elem, type, name, data) {
  // I hate regex, goddamnit
  let emailRegex = new RegExp('[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+')

  // NINTENDO
  switch (type) {
    case 'text':
      switch (name) {
        case 'course_link':
          if (data) {
            if (data.course_platform === 'To be provided later' || data.course_platform === 'Not required') {
              return false
            }
            else {
              if (!elem || elem.length == 0) {
                return true;
              }
            }
          }
          else {
            if (!elem || elem.length == 0) {
              return true;
            }
          }
          break;
        case 'ins_email':
        case 'stu_email':
          if (!elem || elem.length == 0) {
            return true;
          }
          else {
            return (emailRegex.test(elem) === false)
          }
        default:
          return (!elem || elem.length == 0)
      }
    case 'toggle':
      return (!elem || elem.length == 0)
    case 'select':
      return (!elem || elem === '')
    case 'radio':
      if (elem && data) {
        let filter = data.filter(data => data.value === elem)
        if (filter.length === 0) {
          return true
        }
      }
      return (!elem || elem === '')
    case 'date':
      if (!elem) {
        return true
      }
      if (elem.toString() === 'Invalid Date') {
        return true;
      }
      switch (name) {
        case 'start_time':
          if (!data.end_time) {
            return true
          }
          else {
            return compareDate(elem, data.end_time)
          }
        case 'start_date':
          if (!data.end_date) {
            return true
          }
          else {
            return compareDate(new Date(elem.getFullYear(), elem.getMonth(), elem.getDate()), new Date(data.end_date.getFullYear(), data.end_date.getMonth(), data.end_date.getDate()))
          }
        //Remember to reset time when comparing dates 
        case 'end_date':
        case 'date':
          if (!data.start_date) {
            return true
          }
          else {
            return compareDate(new Date(data.start_date.getFullYear(), data.start_date.getMonth(), data.start_date.getDate()), new Date(elem.getFullYear(), elem.getMonth(), elem.getDate()))
          }
        case 'end_time':
          if (!data.start_time) {
            return true
          }
          else {
            return compareDate(data.start_time, elem)
          }
        default:
          return true
      }
    default:
      console.warn('No type provided, defaulting to invalid')
      return true;
  }
}

export default function BulkStudentTable({ rows, onUpdateRows, onDeleteBooking, createSession, user, subjects, addBooking, createSessions, onDuplicateBooking, genKey, submitLoad,timeZones }) {

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="Bulk upload status">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 56 }} />
            <StyledTableCell style={{ width: 280 }}>Student Name</StyledTableCell>
            <StyledTableCell style={{ width: 200 }}>Course Code</StyledTableCell>
            <StyledTableCell style={{ width: 200 }}>Total Bookings</StyledTableCell>
            {/* Status Tab <StyledTableCell style={{ width: 130 }}>Status</StyledTableCell> */}
            <StyledTableCell style={{ width: 144 }}>Warning</StyledTableCell>
            <StyledTableCell style={{ width: 128 }}>Errors</StyledTableCell>
            <StyledTableCell style={{ width: 56 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows || rows.length === 0
            ? <TableRow><StyledTableCell align='center' colSpan={12}>
              <Button onClick={() => addBooking()} style={{ textTransform: 'unset' }} startIcon={<AddCircleOutlineOutlined />} color='primary'>Start Booking</Button>
            </StyledTableCell>
            </TableRow>
            :
            <React.Fragment>
              {rows.map((row, index) => (
                <BookingInfo timeZones={timeZones} submitLoad={submitLoad} createSessions={createSessions} subjects={subjects} user={user} key={row.key} validateFields={validateFields} createSession={createSession} onDeleteBooking={onDeleteBooking} onDuplicateBooking={onDuplicateBooking} onUpdateRows={onUpdateRows} index={index} row={row} genKey={genKey} components={{ StyledTableCell, StyledSubCell, StyledTableRow, PopupButton, LinkButton, StyledTextField, StyledToggle }} />
              ))}
            </React.Fragment>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from 'react';
import { Container, Paper } from '@material-ui/core';
import PageTitle from '../Common/PageTitle';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import BoldHeading from '../Common/BoldHeading';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';
import Students from './Students';
import StudentFilters from './StudentFilters';

const useStyles = makeStyles((theme) => ({
    root:{
        paddingTop:theme.spacing(5),
        paddingBottom:theme.spacing(10),
        background:'#F3F9F6',
    },
    info:{
        background:theme.palette.background.white,
    },
    paper:{
        padding:theme.spacing(7),
        background:'#ffffff',
    },
    buttonWrapper:{
        gap:theme.spacing(3),
    },
    tabsHead:{
        background:'unset',
        boxShadow:'unset',
        '& .indicator':{
            color:'crimson',
            background:'crimson',
        }
    },
    tabs:{
        fontFamily:theme.typography.averta,
        color:theme.palette.text.dark.body,
        fontSize:'1.25rem',
        lineHeight:'1.5rem',
        minWidth:160,
        fontWeight:'bold',
        '&indicator':{
            color:'crimson',
            background:'crimson',
        },
    },
    input:{
        marginBottom:theme.spacing(3),
        '& input:read-only + fieldset':{
            border:'unset',
            outline:'unset',
        }
    },
    divider:{
        marginBottom:theme.spacing(7),
    },
    infoBox:{
        padding:theme.spacing(3,8),
    },
    header:{
        fontFamily: theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#818181',
        marginBottom:theme.spacing(1),
    },
    subheader:{
        fontFamily: theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.25rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#3C3C3C',
    },
    divider:{
        marginBottom:theme.spacing(2.75),
    },
    label:{
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        marginBottom:theme.spacing(1),
        textTransform:'uppercase',
        color:'#979797', //Primary 900?
        display:'inline-block',
    },
    formRow:{
        gap:theme.spacing(4.25),
    },
    tableElements:{
        gap:'1.5rem',
    },
}));  

export default function ViewStudents(props) {
    /* Modal */
    const [open, setOpen] = React.useState(false);
    const handleConfirm = (e) =>{
        console.log(e);
    }
    const handleOpen = (e) => {
      setOpen(true);
    };
  
    const handleClose = (e) => {
      setOpen(false);
    };

    /* Profile Info */
    const [editProfile, setEditProfile] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState('')
    const [sortValue, setSortValue] = React.useState(null)
    const [values, setValues] = React.useState({
        first_name:'Steve',
        last_name:'Delany',
        email:'s.delany@acad.com',
        profile_notes:'',
        institution_select: 'C',
        student_number:'1172552320',
    })
    const [initialValues, setInitialValues] = React.useState({})

    const handleSelectChange = (prop,e) =>{
        if(e != null){
            setValues({...values,[prop]:e.option})
        }else{
            setValues({...values,[prop]:''})
        }
    }

    const handleEditProfile = (e) => {
        setInitialValues(values)
        setEditProfile((prev) => !prev);
    }
    const handleCancelEdit = (e) =>{
        setEditProfile((prev) => !prev);
        setValues(initialValues)
    }

    const handleUpdate = (e) =>{
        setValues({...values,[e.target.id]:e.target.value})
    }
    
    /* Tabs */
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleSearch = (event) =>{
        setSearchValue(event)
    }
    const handleSort = (event) =>{
        setSortValue(event)
    }

    const classes = useStyles();

    return (
    <React.Fragment>
        <Container maxWidth="lg">
            <Box mt={8}>
                <Box mb={7}>
                    <PageTitle text="View your students"/>
                </Box>
                <Box
                    elevation={0}
                    component={Paper}
                >
                    <StudentFilters onSort={handleSort} onSearch={handleSearch}/>
                    <Students path={props.path} sortValue={sortValue} searchValue={searchValue} user={props.user}/>
                </Box>
            </Box>
        </Container>         
    </React.Fragment>
    );
  }
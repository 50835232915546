import React from 'react';
import { useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button, Container, Badge, Box, Divider, Grid, Snackbar, Select, MenuItem, FormControl, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PageTitle from '../../Common/PageTitle';
import { makeStyles } from '@material-ui/styles';
import TextInput from '../../BookingProcess/BookingCommon/TextInput';
import RegularHeading from '../../Common/Typography/RegularHeading';
import StylizedModal from '../../Common/UI/StylizedModal';
import BoldHeading from '../../Common/BoldHeading';
import loginService from '../../../services/loginService';

const addStudent = gql`
    mutation MyMutation($objects: [Student_insert_input!] = {}) {
        insert_Student(objects: $objects){
            returning{
                id
            }
        }
    }
`

const tsAddStudent = gql`
mutation TsAddStudent($objects: [users_insert_input!]!) {
    insert_users(objects: $objects) {
        returning {
          id
          first_name
          last_name
        }
      }
    }
`;

function errorMessage(error) {
    if (!error) {
        return 'An error occured'
    }
    let msg = error.toString()
    switch (msg) {
        case 'Error: Uniqueness violation. duplicate key value violates unique constraint \"CourseSession_start_time_end_time_tz_course_id_key\"':
            return 'Existing session with same date, start time and/or end time found in the database, please review the sessions.' + msg;
        case 'Error: Uniqueness violation. duplicate key value violates unique constraint "User_email_key"':
            return 'A Student is already registered using that email';
        default:
            return 'A following error occured while creating courses, please contact administrator: ' + msg
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(10),
        background: '#F3F9F6',
    },
    paper: {
        padding: theme.spacing(7),
        background: '#ffffff',
    },
    formControl: {
        marginBottom: theme.spacing(3),
    },
    label: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.15px',
        marginBottom: theme.spacing(1),
        textTransform: 'uppercase',
        color: '#979797', //Primary 900?
        display: 'inline-block',
    },
    formRow: {
        gap: theme.spacing(4.25),
    },
    buttonGroup: {
        gap: theme.spacing(1),
        maxWidth: 160,
        margin: 'auto',
    },
    addStudent: {
        fontFamily: theme.typography.averta,
        letterSpacing: '0.18px',
        textTransform: 'unset',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        color: '#877594',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(10),
    },
    divider: {
        marginBottom: theme.spacing(2.75),
    },
    badge: {
        marginRight: theme.spacing(1),
        display: 'inline-block',
        width: 8,
        height: 4,
        verticalAlign: 'middle',
        '& .MuiBadge-badge': {
            background: theme.palette.accent.gold,
        },
    }
}));

export default function CreateStudent(props) {

    const {post} = loginService()
    const [alert, setAlert] = React.useState({
        message: null,
        severity: null,
    })
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false)
    const [value, setValue] = React.useState()
    const [label, setLabel] = React.useState()
    const [student, setStudent] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        notes: '',
        number: '',
        campus:'',
    })


    let orgs = props.user.duorg_id

    const handleChange = (prop) => (e) => {
        setStudent({ ...student, [e.target.name]: e.target.value })
    }

    const handleSelectChange = (prop, e) => {
        setStudent({ ...student, [prop.target.name]: prop.target.value })
    }

    const classes = useStyles();

    const createStudent = (e) => {
        setLoader(true)
        if (student.first_name === '' || student.last_name === '' || student.email === '' || student.campus === '') {
            setAlert({
                message: 'Please fill in required fields',
                severity: 'error',
            })
            setLoader(false)
        }
        else {
            let Student = {
                academic_email: student.email.toLowerCase().trim().replace(/[<>;]+/g, ''),
                comment: student.notes,
                reference: student.number,
                User: {
                    data: {
                        role_id: 'a2f699ca-fa3b-4ff0-a19c-afcc55d3ef15',
                        organisation_id: student.campus,
                        email: student.email.toLowerCase().trim().replace(/[<>;]+/g, ''),
                        first_name: student.first_name.trim(),
                        last_name: student.last_name.trim(),
                    }
                }
            }
            addStudentSMS({
                variables: {
                    objects: Student
                }
            })
        }
    }

    async function tsSubmit(){
        let config = {
            headers:{
                'API-Key':process.env.REACT_APP_BEEHIVE_API_KEY,
            }
        }
        const { data } = await post("create_user",{
          name:  student.first_name.trim().concat(' ',student.last_name.trim()),
          first_name:  student.first_name.trim(),
          last_name:  student.last_name.trim(),
          email:  student.email.toLowerCase().trim().replace(/[<>;]+/g, ''),
          password:  process.env.REACT_APP_STUDENT_DEFAULT_PASSWORD,
          role:'student'
        },1,config);
        if(data.error){
            console.log(data,'Err')
            setLoader(false)
            setAlert({
                message: errorMessage('An error has occured'),
                severity: 'warning',
            })
        }
        else if(data){
            console.log(data,'Succ')
            setAlert({
                message: 'Student added successfully!',
                severity: 'success',
            })
            setLoader(false)
            setStudent({
                first_name: '',
                last_name: '',
                email: '',
                notes: '',
                campus: '',
                number: '',
            })
        }
        else{
            setLoader(false)
            setAlert({
                message: errorMessage('An error has occured'),
                severity: 'warning',
            })
        }
    }

    const [addStudentSMS] = useMutation(addStudent, {
        onCompleted: data => {
            tsSubmit()
            // let tsStudent = {
            //     email: student.email.toLowerCase().trim().replace(/[<>;]+/g, ''),
            //     first_name: student.first_name.trim(),
            //     last_name: student.last_name.trim(),
            //     password: process.env.REACT_APP_STUDENT_DEFAULT_PASSWORD
            // }
            // addStudentTS({
            //     context: {
            //         uri: process.env.REACT_APP_TS_GRAPHQL_ENDPOINT,
            //         headers: {
            //             'x-hasura-admin-secret': process.env.REACT_APP_TS_HASURA_KEY,
            //         }
            //     },
            //     variables: {
            //         objects: tsStudent
            //     }
            // })
        },
        onError: err => {
            setLoader(false)
            setAlert({
                message: errorMessage(err),
                severity: 'warning',
            })
        }
    })
    const [addStudentTS] = useMutation(tsAddStudent, {
        onCompleted: data => {
            setAlert({
                message: 'Student added successfully!',
                severity: 'success',
            })
            setLoader(false)
            setStudent({
                first_name: '',
                last_name: '',
                email: '',
                notes: '',
                campus: '',
                number: '',
            })
        },
        onError: err => {
            setLoader(false)
            setAlert({
                message: errorMessage(err),
                severity: 'warning',
            })
        }
    })

    return (
        <React.Fragment>
            <Box className={classes.root}>
                <Container fixed>
                    <PageTitle text="Create a new student" />
                    <RegularHeading mb={6} text="Please fill out the details to set up a student account." />
                    <Box
                        boxShadow={0}
                        className={classes.paper}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="start"
                        >
                            <BoldHeading text="New Student profile" />
                        </Box>
                        <Divider className={classes.divider} />
                        <Grid spacing={8} container>
                            <Grid item md={6}>
                                <Box className={classes.formRow} display="flex">
                                    <Grid item md={6}>
                                        <Badge color="primary" className={classes.badge} variant="dot"></Badge>
                                        <label className={classes.label} htmlFor="first_name" >First Name</label>
                                        <TextInput disabled={loader} value={student.first_name} mb={3} placeholder="Student's first name" id="first_name" name="first_name" onHandleChange={handleChange} />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Badge color="primary" className={classes.badge} variant="dot"></Badge>
                                        <label className={classes.label} htmlFor="last_name" >Last Name</label>
                                        <TextInput disabled={loader} value={student.last_name} mb={3} placeholder="Student's last name" id="last_name" name="last_name" onHandleChange={handleChange} />
                                    </Grid>
                                </Box>
                                <Grid item md={12}>
                                    <Badge color="primary" className={classes.badge} variant="dot"></Badge>
                                    <label className={classes.label} htmlFor="mail" ><Badge />Academic Email</label>
                                    <TextInput disabled={loader} value={student.email} mb={3} placeholder="Student's email" id="mail" name="email" onHandleChange={handleChange} />
                                    <label className={classes.label} htmlFor="profile_notes" >Profile notes</label>
                                    <TextInput disabled={loader} value={student.notes} mb={3} placeholder="Notes about the student" multiline={true} rows="3" id="profile_notes" name="notes" onHandleChange={handleChange} />
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Badge color="primary" className={classes.badge} variant="dot"></Badge>
                                <label className={classes.label} htmlFor="institution_select">Campus</label>
                                <Box mb={3}>
                                    <FormControl style={{width:'100%'}} variant="outlined">
                                        <Select disabled={loader} id="campus" name="campus" value={student.campus ?? ""} onChange={handleSelectChange}>
                                            <MenuItem disabled value="">
                                                Select campus
                                            </MenuItem>
                                            {orgs.map(org=>
                                                <MenuItem 
                                                    key={org.id}
                                                    value={org.organisation_id}
                                                >
                                                    {org.Organisation.name.concat(' - ',org.Organisation.department)}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <label className={classes.label} htmlFor="student_number" >Student Number</label>
                                <TextInput disabled={loader} mb={3} value={student.number} placeholder="Enter student number" id="student_number" name="number" onHandleChange={handleChange} />
                            </Grid>
                        </Grid>
                        <Box textAlign='center'>
                            <Button disabled={loader} onClick={createStudent} variant="contained" color="primary">
                                Add student
                                {loader? <CircularProgress size={18}/> : null}
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <Toast alert={alert} />
            </Box>
        </React.Fragment>
    );
}

function Toast(props) {

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (props.alert.message === null || props.alert.severity === null) {
            return;
        }
        else {
            setOpen(true)
        }
    }, [props.alert]);

    return (
        <React.Fragment>
            <Snackbar style={{ maxWidth: 600 }} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.alert.severity}>
                    {props.alert.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
function Alert(props) {
    return <MuiAlert elevation={4} variant="filled" {...props} />;
}

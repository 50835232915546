import React, {useEffect} from 'react'
import { CircularProgress, Container, Divider, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import TitleLine from '../Common/TitleLine';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {useParams, useHistory, Link as RouterLink} from 'react-router-dom'
import { gql, useLazyQuery,useMutation } from '@apollo/client';
import PillButton from '../Common/Buttons/PillButton'
import PageTitle from '../Common/PageTitle';
import loginService from '../../services/loginService'
import { KeyboardReturn, Visibility, VisibilityOff } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@material-ui/core';

const checkUser = gql`
query CheckUser($email: String = "") {
    User(where: {email: {_eq: $email}}) {
      id
    }
}`

const createSMSAdmin = gql`
mutation AddSMSAdmin($email: String = "", $first_name: String = "", $last_name: String = "", $organisation_id: uuid = "", $role_id: uuid = "", $phone: String = "", $id: uuid = "") {
    insert_OrganisationAdmin(objects: {User: {data: {first_name: $first_name, last_name: $last_name, organisation_id: $organisation_id, role_id: $role_id, email: $email, UserOrganisations: {data: {organisation_id: $organisation_id}}}}, phone: $phone}, on_conflict: {constraint: OrganisationAdmin_pkey}) {
      returning {
        id
        User {
          id
        }
      }
    }
  }`

const useStyles = makeStyles((theme) => ({
    label:{
        textTransform:'uppercase',
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        color:'#A3a3a3',
    },
    input:{
        '& input':{
            color:'#191919',
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddAdmin(props){

    let {id} = useParams();
    const {post} = loginService()
    const history = useHistory()

    const goBack = () =>{
        history.goBack();
    }

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
        setTimeout(() => {
            history.goBack()
        }, 1000);
    };


    const classes = useStyles();

    const [loader, setLoader] = React.useState(false)
    const [values, setValues] = React.useState({
        first_name:"",
        last_name:"",
        email:"",
        phone:"",
        password:"",
        password2:"",
    })

    
    const setMessage = (msg) =>{
        setMsg(msg)
        setTimeout(() => {
            setMsg('')
        }, 5000);
    }
    
    const [message, setMsg] = React.useState(null)
    const [pass, setPass] = React.useState({
     showPassword: false,
    });

    const handleClickShowPassword = () => {
        setPass({ ...pass, showPassword: !pass.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleForm =(e) =>{
        e.preventDefault()
        if(values.password.length < 8){
            setMessage('Please use at least 8 characters in a password')
            return;
        }
        if(values.password != values.password2){
            setMessage('Passwords don\'t match!')
            return;
        }
        else{
            setLoader(true)
            checkEmail({ variables: { email: values.email }},{fetchPolicy: 'network-only'})
        }
    }
    const [checkEmail,{ loading, data }] = useLazyQuery(checkUser,{
        onError:data=>{
            console.log(data,'error')
            setLoader(false)
            setMessage('An error has occured')
        },
        onCompleted: data => {
          console.log('Data: ',data)
          if(data.User.length){
            setLoader(false);
            setMessage('User with following email already exists, please provide different email adress')
            return;
          }
          else{
            AddSMSAdmin({
                variables:{
                    email: values.email,
                    phone: values.phone,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    organisation_id:id,
                    role_id:process.env.REACT_APP_ADMIN_ROLE_ID
                }
            })
          }
        }, fetchPolicy: 'no-cache'
    
    });

    const [AddSMSAdmin, { sms_data, sms_loading, sms_error }] = useMutation(createSMSAdmin, {
    onCompleted: sms_data =>{
        tsSubmit();        
    },
    onError(err){
        console.log(err.message)
        setLoader(false)
    }});
    
    async function tsSubmit(){
        let config = {
            headers:{
                'API-Key':process.env.REACT_APP_BEEHIVE_API_KEY,
            }
        }
        const { data } = await post("create_user",{
          name:  values.first_name+' '+values.last_name,
          first_name:  values.first_name,
          last_name:  values.last_name,
          email:  values.email,
          password:  values.password,
        },1,config);
        if(data.error){
            console.log(data,'Err')
            setMessage('An error has occured')
            setLoader(false)
        }
        else if(data){
            console.log(data,'Succ')
            setLoader(false)
            setOpen(true)
        }
        else{
            setMessage('An error has occured')
            setLoader(false)
        }
    }
    
    const handleUpdate = (e) =>{
        console.log(values);
    }
    return(
        <React.Fragment>
            <Button startIcon={<KeyboardReturn/>} onClick={goBack} color="inherit">
                Return to Organization Profile
            </Button>
            <PageTitle text="Add organization admin"/>
            <Box mb={2}>
            <Paper>
                <Box p={4}>
            <form onSubmit={handleForm}>
            <Grid container>
                <Grid lg={6} spacing={3} item container>
                    <Grid item xs={12}>
                        <TitleLine required text="First Name"/>
                        <TextField
                        fullWidth
                        required
                        disabled={loader}
                        className={classes.input}
                        variant="outlined"
                        id="name"
                        type='text'
                        name="name"
                        onChange={(e)=>setValues({...values,'first_name':e.target.value})}
                        value={values.first_name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TitleLine required text="Last Name"/>
                        <TextField
                        fullWidth
                        required
                        disabled={loader}
                        className={classes.input}
                        variant="outlined"
                        id="name"
                        type='text'
                        name="name"
                        onChange={(e)=>setValues({...values,'last_name':e.target.value})}
                        value={values.last_name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TitleLine required text="Email"/>
                        <TextField
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        required
                        disabled={loader}
                        id="name"
                        type='email'
                        name="name"
                        onChange={(e)=>setValues({...values,'email':e.target.value})}
                        value={values.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TitleLine required text="Phone"/>
                        <TextField
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        required
                        disabled={loader}
                        id="phone"
                        type='text'
                        name="name"
                        onChange={(e)=>setValues({...values,'phone':e.target.value})}
                        value={values.phone}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TitleLine required text="Password"/>
                        <TextField
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        required
                        disabled={loader}
                        id="name"
                        type={pass.showPassword ? 'text': 'password'}
                        name="name"
                        onChange={(e)=>setValues({...values,'password':e.target.value})}
                        value={values.password}
                        InputProps={{
                            endAdornment:(
                            <InputAdornment position="end">
                              <IconButton 
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {pass.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TitleLine required text="Repeat password"/>
                        <TextField
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        required
                        disabled={loader}
                        id="name"
                        type={pass.showPassword ? 'text': 'password'}
                        name="name"
                        onChange={(e)=>setValues({...values,'password2':e.target.value})}
                        value={values.password2}
                        InputProps={{
                            endAdornment:(
                            <InputAdornment position="end">
                              <IconButton 
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {pass.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {message?
                        <Box mb={2}>
                        <Typography color="error">
                            {message}
                        </Typography>
                        </Box>
                        :null
                        }
                         <PillButton disabled={loader} loadState={loader} text='Create admin' loadText="Please wait" noIcon variant="accent" type="submit"/>
                    </Grid>
                </Grid>
            </Grid>
            </form>
            </Box>
            </Paper>
            </Box>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Account successfully created!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};
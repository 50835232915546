import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
headerBox:{
    alignSelf:'center',
    gridArea:"container",
    marginBottom:'10%',
    [theme.breakpoints.down('md')]: {
        marginBottom: '10%',
    },
},
overheader:{
    fontFamily:theme.typography.averta,
    fontSize:'2.125rem',
    lineHeight:'2.25rem',
    [theme.breakpoints.down('sm')]: {
        fontSize:'1.5rem',
        lineHeight:'1.575rem',
    },
},
header:{
    fontFamily:theme.typography.ppWoodland,
    fontWeight:'500',
    fontSize:'6.5rem',
    lineHeight:'6.625rem',
    maxWidth:840,
    [theme.breakpoints.down('sm')]: {
        fontSize:'5rem',
        lineHeight:'4.9rem',
    },
},
subheader:{
    fontFamily:theme.typography.averta,
    fontWeight:'400',
    fontSize:'1.5rem',
    lineHeight:'1.5rem',
    marginLeft:theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
        fontSize:'1.25rem',
        lineHeight:'1.25rem',
    },
},
}));

export default function BookingHeader(){

    const classes = useStyles();

    return(
        <Box className={classes.headerBox}>
            <Typography className={classes.overheader} variant="body1" component="p">
                Habitat Booking
            </Typography>
            <Typography className={classes.header} variant="h1" component="h1">
                Learning without limits
            </Typography>
            <Typography className={classes.subheader} variant="body1" component="p">
                Book services for educational accessibility.
            </Typography>
        </Box>
    )
}
import React from 'react'
import 'date-fns';
import { Container, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../Common/PageTitle'
import { makeStyles } from '@material-ui/core';
import { Link } from '@material-ui/core';
import BoldHeading from '../Common/BoldHeading';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    courseBox:{
        padding:theme.spacing(2),
        '&:nth-of-type(odd)':{
            background:theme.palette.background.surface2,
        },
    },
    courseTitle:{
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.5rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.dark.body,
        letterSpacing:'0.18px',
    },
    courseCode:{
        fontFamily:theme.typography.averta,
        fontWeight:'regular',
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.dark.body,
        letterSpacing:'0.15px',
    },
    listText:{
        fontFamily:theme.typography.averta,
        fontWeight:'regular',
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.dark.body,
        letterSpacing:'0.15px',
    },
    link:{
        marginLeft:theme.spacing(1),
        fontFamily:theme.typography.manrope,
        fontSize:'0.875rem',
        lineHeight:'1.375rem',
        letterSpacing:'0.25px',
        color:theme.palette.text.dark.primary,
    },
    listTextBold:{
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.dark.body,
        letterSpacing:'0.15px',
    },
}));

export default function BulkComplete(props){
    
    const [values, setValues] = React.useState({});

    const handleUpdate = (e) =>{
        console.log(values);
    }
    return(
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box textAlign='center' mb={5.625}>
                                <PageTitle  text='🎉 Booking complete!'/>
                            </Box>
                            <BoldHeading textAlign="center" text="What happens now?"/>
                            <Box textAlign="center" mb={2}>
                                <Typography variant="body1" component="p">Confirmation emails will be sent to you and the booked student containing  the links needed to access the booked services.</Typography>
                            </Box>
                            <Box textAlign="center" mb={2}>
                                <Typography variant="body1" component="p">If you booked captions, the student will also receive a reminder email on the day of their classes, providing them with the link again (just to be helpful 😉)</Typography>
                            </Box>
                            {/* Temporary comment, since View Bookings isn't ready yet <Box mt={3} mb={1.125}>
                                <BookingBtn onHandleUpdate={handleUpdate} text="View bookings"/>
                            </Box> */}
                            <Box textAlign="center">
                                <Link href="#" color="inherit" onClick={()=>window.location.reload()}>Book some more!</Link>
                                {/* <BookingBtn component={RouterLink} to="/booking" onHandleUpdate={handleUpdate} text="Book some more!"/> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};
import React from 'react'
import { Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CreateStudentInput from './CreateStudentInput'

const useStyles = makeStyles((theme) => ({
    studentListHeader:{
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        fontSize:'1.5rem',
        lineHeight:'1.5rem',
        marginBottom:theme.spacing(2),
        minHeight:48,
    },
}));

export default function CreateStudent(props){
    const classes = useStyles();

    return(
        <Grid item xs={6}>
            <Typography className={classes.studentListHeader} variant="body1" component="p">
                Create a new student
            </Typography>
            <CreateStudentInput helperText="Please provide a first name" error={props.error.first_name} Value={props.Values.first_name} onHandleInputChange={props.onHandleInputChange} FieldId='first_name' FieldName='First Name'/>
            <CreateStudentInput helperText="Please provide a last name" error={props.error.last_name} Value={props.Values.last_name} onHandleInputChange={props.onHandleInputChange} FieldId='last_name' FieldName='Last Name'/>
            <CreateStudentInput helperText="Please enter a valid email address" error={props.error.email} Value={props.Values.email} onHandleInputChange={props.onHandleInputChange} FieldId='email' FieldName='Email adress'/>
            <CreateStudentInput helperText="Please provide a student number" error={props.error.reference} Value={props.Values.reference} onHandleInputChange={props.onHandleInputChange} FieldId='reference' FieldName='Student number'/>
        </Grid>
    )
};
import { Box, Button, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { ReactComponent as UploadIcon } from '../../img/icon-upload-file.svg';
import { ReactComponent as ExcelIcon } from '../../img/icon-excel-file.svg';
import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Cancel } from '@material-ui/icons';
import * as xlsx from 'xlsx'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#474747',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function BulkDropzone({ handleFile, files, progress }) {

  const filterHeader = (elem) =>{
    let keywords = ['status','studentfirstname','studentsfirstname']
    return keywords.includes(elem)
  }

  const colName = (name) => {
    return name.toString().replace(/[^a-zA-Z]/g, "").toLowerCase().trim()
  }

  const processFile = (ws) =>{
    var modifier = 0
    let headers = []
    var range = xlsx.utils.decode_range(ws['!ref'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var addr = xlsx.utils.encode_cell({ r: range.s.r + modifier, c: C });
      var cell = ws[addr];
      if (!cell) continue;
      headers.push(colName(cell.v));
    }
    if((!headers.some(filterHeader))){
      modifier = 1
      headers = []
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var addr = xlsx.utils.encode_cell({ r: range.s.r + modifier, c: C });
        var cell = ws[addr];
        if (!cell) continue;
        headers.push(colName(cell.v));
      }
    }
    var json = xlsx.utils.sheet_to_json(ws, { header:headers, range:1 + modifier, raw: false, date: 'yyyy"."mm"."dd' });
    return json
  }

  const onDrop = useCallback((acceptedFiles) => {
    let sessions = []
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        var data = e.target.result;
        var workbook = xlsx.read(data, { type: "array" });
        var sheetName = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[sheetName];
        var json = processFile(worksheet)
        json.forEach(course => {
          if (course.status) {
            if(course.status.toString().toLowerCase().trim() != 'cancelled' && course.status.toString().toLowerCase().trim() != 'ended'){
              sessions.push(course)
            }
          }
          else if (course.studentsfirstname || course.studentfirstname) {
            sessions.push(course)
          }
        })
        handleFile(sessions)
      }
      reader.readAsArrayBuffer(file)
    })

  }, [])

  const handleDragOver = (e) => {
    console.log(e, 'Files?')
  }
  const removeFile = () => {
    handleFile()
  }
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel', maxFiles: 1, onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <React.Fragment>
      {files
        ? <Box display="flex" gridGap={8} alignItems="center">
          <ExcelIcon />
          <Box>
            <Typography>{files[0].name}</Typography>
            <Box width={360} my={1}>
              <LinearProgress variant='determinate' value={progress} />
            </Box>
            <Typography>{(files[0].size / 1024).toFixed(2)} kB</Typography>
          </Box>
          <IconButton aria-label='Remove file' onClick={removeFile} color="primary"><Cancel fontSize='small' /></IconButton>
        </Box>
        :
        <Box>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box display="flex" gridGap={16} alignItems="center" justifyContent="center">
              <UploadIcon />
              <Typography>Drag and drop an excel file here or</Typography>
              <Button variant="contained" color="primary">Browse files</Button>
            </Box>
          </div>
        </Box>
      }
    </React.Fragment>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TitleLine from '../Common/TitleLine';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  search: {
    '& input': {
      padding: theme.spacing(1),
    },
  }
}));


export default function StudentFilters(props) {

  let { path, url } = useRouteMatch();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (event) => {
    props.onSort(event.target.id)
    handleClose();
  };

  return (
    <Box display="flex" alignItems="center" mt={5} mb={4}>
      <Box>
        <TitleLine mb={0} text='Student List' />
      </Box>
      {/* <Box mr={3}>
            <PillButton onHandleUpdate={handleClick} variant='accent' text="Sort By" />
            <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem id="name" onClick={handleSort}>Student Name</MenuItem>
                <MenuItem id="email" onClick={handleSort}>Student email</MenuItem>
                <MenuItem id="institution" onClick={handleSort}>Student institution</MenuItem>
            </Menu>
          </Box> */}
      <Box ml="auto">
        <TextField
          className={classes.search}
          variant="outlined"
          placeholder="Search"
          onChange={(event) => props.onSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                disablePointerEvents
              >
                <IconButton
                  aria-label="search"
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        >
          Search
        </TextField>
      </Box>
    </Box>
  );
};

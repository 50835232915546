import { React, Fragment, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Background from '../img/clouds-bg.png';
import Background1 from '../img/booking-bg-1.png';
import Background2 from '../img/booking-bg-2.png';
import Background3 from '../img/booking-bg-3.png';
import { Grid } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import IconNumber from '../img/icon-numbersign.svg';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import BookingHeader from '../components/BookingHome/BookingHeader';
import SelectedStudent from '../components/BookingHome/SelectedStudent';
import StudentListWrapper from '../components/BookingHome/StudentListWrapper'
import CourseSelectPill from "../components/BookingHome/CourseSelect"
import CreateStudent from '../components/BookingHome/CreateStudent';
import StudentSearch from '../components/BookingHome/StudentSearch';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import CheckStudent from '../components/BookingHome/CheckStudent';

// Random background using makeStyles, buuuuuut it changes on actions causing a rerender, so not optimal at all
//         background:({background}) => 'url('+background+') no-repeat fixed center center / cover',

const useStyles = makeStyles((theme) => ({
    background: {
        background: 'url(' + Background2 + ')',
        overflow: 'hidden',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            overflow: 'auto',
        },
    },
    gridContainer: {
        display: 'grid',
        gridTemplate: "'container'",
        width: '100%',
        height: '100%',
    },
    gridWrapper:{
        gridArea:'container',
        width:'max-content',
        padding:'unset',
        display:'grid',
        gridTemplateRows:'1fr auto',
        gridTemplateColumns:'1fr 1fr',
        marginBottom:'5%',
        justifySelf:'center',
    },
    intro: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
    },
    pill: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    // Wrapper
    contentGrid: {
        height:'100%',
        display:'grid',
        gridTemplateRows:'1fr auto',
        gridTemplateColumns:'1fr 1fr',
        gap:'0.5rem',
        maxWidth:'770px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateRows:'auto auto',
            gridTemplateColumns:'1fr 1fr',
        },
    },
    pillContainer: {
        gridArea: "container",
        height:'100%',
        alignSelf: 'end',
        width: 'max-content',
        paddingBottom: '5%',
        position: 'relative',
        padding: 'unset',
        // [theme.breakpoints.down('md')]: {
        //     paddingBottom: '5%',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: 'auto',
        //     paddingBottom: '2rem',
        // },
        '& form':{
            height:'100%',
        }
    },
    // Main Pill
    buttonContainer: {
        gridColumnStart: '1',
        gridColumnEnd: '-1',
        gridRowStart: '2',
        gridRowEnd: '-1',
        overflow: 'hidden',
        borderRadius: '100px',
        background: '#FBFBFA',
        boxShadow: '0px 7px 26px rgba(0, 0, 0, 0.2)',
    },
    //
    pillBtn: {
        borderRadius: '68px',
        minWidth: 300,
        padding: theme.spacing(4, 5, 5),
        textTransform: 'unset',
        '&:hover, &.active': {
            background: 'unset',
            boxShadow: '0px 7px 26px rgba(0, 0, 0, 0.2);',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 1, 5),
            minWidth: 'unset',
            flex: '1 1 auto',
        },
    },
    btnSub: {
        fontFamily: theme.typography.averta,
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        color: theme.palette.text.emphasis1,
    },
    btnMain: {
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        fontSize: '2.125rem',
        lineHeight: '2.25rem',
        color: theme.palette.text.black,
    },
    spacer: {
        margin: theme.spacing(2.5, 2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2.5, 0.25),
            flex: '0 1 auto',
        },
    },
    confirm: {
        background: theme.palette.secondary.main,
        color: theme.palette.text.light.onSecondaryHighEmphasis,
        width: 72,
        height: 72,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3),
        flex: '0 0 auto',
        '&:hover': {
            background: '#3c3c3c',
            color: theme.palette.text.light.onSecondaryHighEmphasis,
        },
        [theme.breakpoints.down('sm')]: {
            width: 50,
            height: 50,
        },
    },
    // Student Modal
    studentSelect: {
        gridColumnStart: '1',
        gridColumnEnd: '-1',
        gridRowStart: '1',
        gridRowEnd: '2',
        alignSelf:'end',
        width:'auto',
        margin:'unset',
        background: '#FBFBFA',
        boxShadow: '0px 7px 26px rgba(0, 0, 0, 0.2)',
        borderRadius: '68px',
        padding: theme.spacing(5, 5.75),
        transition: 'all 0.25s ease-in-out',
        opacity: 0,
        pointerEvents: 'none',
    },
    studentSelectActive: {
        opacity: 1,
        transition: 'all 0.75s ease-in-out',
        pointerEvents: 'initial',
        maxHeight: 600,
    },
}));

const ADD_STUDENT = gql`
  mutation AddStudent( $email: String , $first_name: String , $last_name: String ,$reference:String,$organisation_id:uuid) {
    insert_Student(objects: {academic_email:$email,reference:$reference,User: {data: {first_name: $first_name, email: $email, last_name: $last_name, role_id: "a2f699ca-fa3b-4ff0-a19c-afcc55d3ef15",organisation_id:$organisation_id}}}) {
      returning {
        id
        user_id
      }
    }
  }
  `;

const TS_ADD_STUDENT = gql`
  mutation TsAddStudent($email:String, $first_name:String, $last_name:String, $password:String){
    insert_users_one(object: {email: $email, first_name: $first_name, last_name: $last_name, password: $password}, on_conflict: {constraint: users_email_unique,update_columns: []}) {
      id
    }
}
  `;

/*Really bad idea, but if they want to set user_id for student as logged user id to display Students settled to that user..*/
const ADD_STUDENT_BY_ID = gql`
  mutation AddStudent($user_id:uuid, $email: String , $first_name: String , $last_name: String ,$reference:String) {
    insert_Student(objects: {academic_email:$email,reference:$reference,user_id:$user_id ,comment:"nowhere to set $first_name $last_name" }) {
      returning {
        id
        user_id
      }
    }
  }
  `;

export default function BookingHome(props) {
    let response;

    const bgArray = [Background1, Background2, Background3];
    const bg = Math.floor(Math.random() * bgArray.length);
    const selectedBg = bgArray[bg];

    const props2 = {
        background: selectedBg
    }

    const history = useHistory();
    const [AddStudent, { data, loading, error }] = useMutation(ADD_STUDENT, {
        onCompleted: data => {
            console.log(error);
            if (!error) {
                student.courseQuantity = courseQuantity
                console.log(data, data.insert_Student.returning[0].id)
                student.id = data.insert_Student.returning[0].id
                student.selectedOrg = selectedOrg
                props.course.setData(student)
                console.log(1, student)
                //  localStorage.setItem('bookingData',JSON.stringify(student));
                setRedirect(`CourseSelect`);
            }
        }, onError(err) {
            response = `Submission error! ${err.message}`
            console.log(err.message)
            alert(err.message)
        }
    });

    const [TsAddStudent, { ts_data, ts_loading, ts_error }] = useMutation(TS_ADD_STUDENT, {
        onCompleted: ts_data => {
            console.log(ts_error);
            if (!ts_error) {
                console.log('TS Call: ', ts_data)
            }
        }, onError(err) {
            response = `Submission error! ${err.message}`
            console.log(err.message)
            alert(err.message)
        }
    });



    if (loading) response = 'Submitting...';
    if (error) response = `Submission error! ${error.message}`;

    let { path, url } = useRouteMatch();


    const handleSearch = (e) => {
        CheckStudent(values.email)
    }

    const classes = useStyles(props2);


    const [searchValue, setSearchValue] = useState("");
    const handleSearchChange = (prop) => (event) => {
        setSearchValue(event.target.value);
    };

    const [selectedOrg, setSelectedOrg] = useState(null);

    const selectOrg = (e) => {
        setSelectedOrg(e)
        setValues({
            first_name: '',
            last_name: '',
            email: '',
            id: '',
            reference: ''
        });
        setInvalidField({
            first_name: false,
            last_name: false,
            email: false,
            //  id: false,
        });
        setStudent({
            first_name: "",
            last_name: "",
            id: "",
            email: "",
        });
        setCurrentStudent('Student');
    }

    /* REV - Marked for revision, using so much repeating states it's a crime */
    const [student, setStudent] = useState({
        first_name: '',
        last_name: '',
        email: '',
        id: '',
    })

    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        id: '',
    });

    const [newStudent, setNewStudent] = useState(true);
    const [studentSelect, setStudentSelect] = useState(false);
    const [proceedBtn, setProceedBtn] = useState(true);
    const [currentStudent, setCurrentStudent] = useState('Student');
    const [courseSelect, setCourseSelect] = useState(false);
    const [courseQuantity, setCourseQuantity] = useState(0);
    const [decreaseCourse, setDecreaseCourse] = useState(true)
    const [increaseCourse, setIncreaseCourse] = useState(false)
    //const [redirect, setRedirect] = useState(null)
    //let redirect=null;
    const handleLookupStudent = (prop) => (event) => {
        setNewStudent(false);
        setProceedBtn(true);
        setValues({
            first_name: '',
            last_name: '',
            email: '',
            id: '',
            reference: ''
        });
        setInvalidField({
            first_name: false,
            last_name: false,
            email: false,
            //  id: false,
        });
        setStudent({
            first_name: event.target.getAttribute('first_name'),
            last_name: event.target.getAttribute('last_name'),
            id: event.target.getAttribute('id'),
            email: event.target.getAttribute('email'),
        });
        setCurrentStudent(event.target.getAttribute('first_name') + ' ' + (event.target.getAttribute('last_name')).substring(0, 1) + '.');
        setProceedBtn(false);
    };

    function handleCourseSelect() {
        if (studentSelect === true) {
            setStudentSelect(!studentSelect);
        }
        setCourseSelect(!courseSelect);
    }

    const [invalidField, setInvalidField] = useState({
        first_name: false,
        last_name: false,
        email: false,
        id: false,
    })

    /* REV - sets invalid feedback for last field, why? */
    const invalidFields = (props) => {
        console.log(props);
        setInvalidField({ ...invalidField, [props]: true })
    }

    const handleInputChange = (prop) => (event) => {
        setProceedBtn(true);
        setNewStudent(true);
        setInvalidField({ ...invalidField, [prop]: (prop != 'reference' ? event.target.value == '' : false) })
        if (prop === 'email') {
            setValues({ ...values, [prop]: event.target.value.toLowerCase() });
            setStudent({ ...values, [prop]: event.target.value.toLowerCase() });
        }
        else {
            setValues({ ...values, [prop]: event.target.value });
            setStudent({ ...values, [prop]: event.target.value });
        }
        if (prop === "first_name") {
            if (values.last_name === "") {
                setCurrentStudent(event.target.value)
            }
            else {
                setCurrentStudent(event.target.value + ' ' + values.last_name.substring(0, 1) + '.')
            }
        }
        if (prop === "last_name") {
            setCurrentStudent(values.first_name + ' ' + event.target.value.substring(0, 1) + '.')
        }
    };
    const confirmStudent = (e) => {
        setProceedBtn(false);
        setStudentSelect(false);
        setCourseSelect(true);
    }
    useEffect(() => {
        if (courseQuantity > 0) {
            setDecreaseCourse(false);
        } else {
            setDecreaseCourse(true);
        }
        // TODO - Quite buggy if there are multiple courses
        if (courseQuantity === 1) {
            setIncreaseCourse(true);
        } else {
            setIncreaseCourse(false);
        }
    }, [courseQuantity]);

    useEffect(()=>{
        if(values.first_name != '' && values.last_name != '' && values.email != '' && values.reference != ''){
            setProceedBtn(false);
        }
    },[values])

    function handleStudentSelect() {
        if (courseSelect === true) {
            setCourseSelect(!courseSelect);
        }
        setStudentSelect(!studentSelect);
    }
    function handleSubmit(event) {
        event.preventDefault();
        if (Object.values(invalidField).includes(true)) {
            setStudentSelect(true);
            return;
        }
        if (courseQuantity === 0) {
            setStudentSelect(false);
            setCourseSelect(true);
            return;
        }
        else {
            if (newStudent) {
                console.log(selectedOrg, 'Selected org')
                console.log(selectedOrg !== null ? selectedOrg : props.user.dorg_id ?? props.user.duorg_id)
                AddStudent({
                    variables: {
                        email: student.email, first_name: student.first_name, last_name: student.last_name, reference: student.reference, organisation_id: selectedOrg !== null ? selectedOrg : props.user.dorg_id ?? props.user.duorg_id,
                    }
                })
                TsAddStudent({
                    context: {
                        uri: process.env.REACT_APP_TS_GRAPHQL_ENDPOINT,
                        headers: {
                            'x-hasura-admin-secret': process.env.REACT_APP_TS_HASURA_KEY,
                        }
                    },
                    variables: { email: student.email, first_name: student.first_name, last_name: student.last_name, password: process.env.REACT_APP_STUDENT_DEFAULT_PASSWORD }
                })

                /* uncomment if they are sure to set student's user_id as logged user, theres no student name to place */
                // AddStudentById({ variables: {user_id:props.user.user_id,email: student.email, first_name: student.first_name, last_name: student.last_name, reference:student.reference}})

            }
            else {
                student.courseQuantity = courseQuantity
                student.selectedOrg = selectedOrg
                props.course.setData(student)
                //  localStorage.setItem('bookingData',JSON.stringify(student));
                setRedirect(`CourseSelect`);
            }
        }
    }
    const handleClick = (type) => {
        if (type === 'course') {
            if (courseSelect) {
                setCourseSelect(true)
            }
            else {
                setCourseSelect(false)
            }
        }
        if (type === 'student') {
            setStudentSelect(false)
        }
    }

    function setRedirect(redirect) {
        history.push({ pathname: `${path}/${redirect}`, state: selectedOrg });
        return;
    }

    function handleStudentPopover(ref, props) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setStudentSelect(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref])
    }

    const studentPopoverRef = useRef(null)
    handleStudentPopover(studentPopoverRef, props)


    return (
        <Fragment>
            <div className={classes.background}>
                <Container className={classes.intro} maxWidth="xl">
                    <Box className={classes.gridContainer}>
                        <BookingHeader />
                        <Container className={classes.pillContainer} maxWidth="md">
                            <form onSubmit={handleSubmit}>
                                <Grid className={classes.contentGrid} container alignItems="center">
                                    <CourseSelectPill handleClick={handleClick} onSetCourseQuantity={setCourseQuantity} IncreaseCourse={increaseCourse} DecreaseCourse={decreaseCourse} CourseQuantity={courseQuantity} CourseSelect={courseSelect} />
                                    <Grid ref={studentPopoverRef} spacing={4} className={clsx(classes.studentSelect, studentSelect ? classes.studentSelectActive : null)} item container>
                                        <CreateStudent error={invalidField} Values={values} onHandleInputChange={handleInputChange} />
                                        <Grid item xs={6}>
                                            <StudentSearch onHandleSearchChange={handleSearchChange} SearchValue={searchValue} onHandleSearch={handleSearch} />
                                            <StudentListWrapper onSelectOrg={selectOrg} SearchValue={searchValue} onHandleLookupStudent={handleLookupStudent} currentName={currentStudent} user={props.user} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CheckStudent onConfirmStudent={confirmStudent} newStudent={newStudent} onInvalidFields={invalidFields} values={values} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems="center" className={classes.buttonContainer}>
                                        <SelectedStudent currentName={currentStudent} onStudentSelect={handleStudentSelect} />
                                        <Divider className={classes.spacer} orientation="vertical" flexItem />
                                        <Button className={classes.pillBtn} onClick={handleCourseSelect}
                                            disableRipple
                                        >
                                            <Box display="flex" alignItems="center">
                                                <img src={IconNumber} width="28" height="26" alt="Number Sign Icon" />
                                                <Box ml={1} textAlign="left">
                                                    <Typography className={classes.btnSub}>
                                                        How many courses?
                                                    </Typography>
                                                    <Typography className={classes.btnMain}>
                                                        {courseQuantity} <Fragment>{courseQuantity === 1 ? "Course" : "Courses"}</Fragment>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Button>
                                        <IconButton disabled={proceedBtn} type="submit" className={classes.confirm} background="secondary">
                                            <ArrowForwardRoundedIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </Box>
                </Container>
            </div>
        </Fragment>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import DatePicker from '../../Common/DatePicker';
import { Box, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import format from 'date-fns/format';

const useStyles = makeStyles((theme) => ({
    button:{
        marginLeft:'0.25rem',
        padding:'unset',
    },
    icon: {
        fontSize: '1.25rem',
        padding:'unset',
    },
    popover:{
        marginLeft:theme.spacing(2),
        '& .MuiPopover-paper':{
            background:theme.palette.background.paper,
            borderRadius:12,
            boxShadow:theme.shadows[3],
            padding:theme.spacing(1.75,1.5,2
                )
        }
    },
    picker:{
        maxWidth:120,
    },
    confirm:{
        width:44,
        height:44,
        marginLeft:theme.spacing(1.5),
        color:theme.palette.text.light.onSecondaryHighEmphasis,
        background:theme.palette.accent.purple,
        borderRadius:8,
        '&:hover':{
            color:theme.palette.text.light.onSecondaryHighEmphasis,
            background:theme.palette.accent.purple,
        },
    },    
}));

export default function InstanceDate(props) {
  const classes = useStyles();
  const [thisDate, setDate] = React.useState(props.value ?? format(new Date(), 'dd/MM/yyyy'))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDateChange = (prop,e) =>{
    setDate(prop);
  };
  const handleUpdate = (event) =>{
      props.onHandleChange(event,thisDate)
      handleClose();
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'Instance Date' : undefined;

  return (
      <React.Fragment>
        <IconButton className={classes.button} disableRipple aria-describedby={id} aria-label="Edit date" onClick={handleClick}>
            <CreateIcon className={classes.icon} fontSize="small" />
        </IconButton>
        <Popover
            className={classes.popover}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Edit Date</Typography>
                <IconButton onClick={handleClose} className={classes.button}>
                    <CloseIcon className={classes.icon} fontSize="small"/>
                </IconButton>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <DatePicker onHandleDateChange={handleDateChange} limitWidth value={thisDate}/>
                <IconButton
                    className={classes.confirm}
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                >
                    <CheckIcon/>
                </IconButton>
            </Box>
        </Popover>
      </React.Fragment>
  );
}

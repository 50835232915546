import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import IconButton from '../Buttons/IconButton';


/* REV - Make this a fully fledged customized modal */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth:544,
    borderRadius:8,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(8, 6, 5),
  },
  header:{
    fontFamily:theme.typography.ppWoodland,
    fontSize:'2.125rem',
    lineHeight:'2rem',
    color:theme.palette.text.dark.body,
    marginBottom:theme.spacing(4.5),
  },
  content:{
    fontFamily:theme.typography.averta,
    fontSize:'1.25rem',
    lineHeight:'1.5rem',
    color:theme.palette.text.dark.body,
    letterSpacing:'0.15px',
    marginBottom:theme.spacing(4),
  },
  buttonWrapper:{
    gap:theme.spacing(3),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function StylizedModal(props) {
  const classes = useStyles();

  return (
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.onHandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box textAlign="center" className={classes.paper}>
            <Typography className={classes.header} id="modal-title">{props.header}</Typography>
            <Typography className={classes.content} id="modal-description">{props.content}</Typography>                    
            <Box
            display="flex" 
            justifyContent="center"
            className={classes.buttonWrapper
            }>
              <IconButton text="Leave Page" onHandleUpdate={props.onHandleConfirm} noIcon variant="light" />
              <IconButton text="Stay on page" onHandleUpdate={props.onHandleClose} noIcon />
            </Box>
          </Box>
        </Fade>
      </Modal>
  );
}

import React, { useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-body': {
      wordWrap: 'anywhere',
      color: theme.palette.text.dark.body,
    },
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
    }
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
    }
  },
  pagination: {
    borderBottom: 'unset',
    borderRight: 'solid 1px rgba(0, 0, 0, 0.15)',
  },
  search: {
    '& input': {
      padding: theme.spacing(1),
    },
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box display={'flex'} className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton> */}
      <Divider orientation='vertical' flexItem />
      <Box>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>

      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton> */}
    </Box>
  );
}

const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 201, 175, 0.1)',
  },
}))(TableRow);
const StyledCellHead = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.averta,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    borderLeft: 'solid 1px transparent',
    borderRight: 'solid 1px transparent',
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      background: 'rgba(251, 251, 250, 1)',
    },
    backgroundColor: '#FFFFFF',
  },
}))(TableRow);
const StyledRowHead = withStyles((theme) => ({
  body: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.1px',
  },
}))(TableCell);

function createData(id, course_name, course_code, start_day, start_time, captioning, note_taking, sessions,sessionid) {
  return {
    id,
    course_name,
    course_code,
    start_day,
    start_time,
    captioning: captioning ? 'Captions' : null,
    note_taking : note_taking ? 'Notes' : null,
    sessions,
    sessionid,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment key={row.id} >
      <StyledTableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.course_name}
        </TableCell>
        <TableCell component="th" scope="row">
          <Link component={RouterLink} color="primary" to={'/booking/edit/'.concat(props.studentId,'/',row.id,'/',row.sessionid)}>
            {row.course_code}
          </Link>
        </TableCell>
        <TableCell>
          {new Date(row.start_time).toLocaleString('en-US',{dateStyle:'full',timeStyle:'short'})}
        </TableCell>
        <TableCell>
          {!row.captioning && !row.note_taking
            ? <Typography>Captioning</Typography>
            : <React.Fragment>
                {row.captioning}
                {row.captioning && row.note_taking ? ', ' : null}
                {row.note_taking}
              </React.Fragment>
          }
        </TableCell>
        <TableCell>
          {row.sessions.length ?? 'No bookings'}
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function StudentBookings(props) {
  const classes = useStyles1();
  let rows = []

  useEffect(() => {
    handleRequestSort(props.sortValue)
  }, [props.sortValue]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(5)
  }, [props.searchValue]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === event && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(event);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.sort);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows, setEmptyRows] = React.useState(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage));

  useEffect(() => {
    setEmptyRows(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage))
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  props.data.forEach(Booking => {
    rows.push(createData(
      Booking.course_id,
      Booking.Course.course_name,
      Booking.Course.course_code,
      Booking.Course.start_day,
      Booking.Course.CourseSessions[0].start_time,
      Booking.Course.CourseSessions[0].live_captioning,
      Booking.Course.CourseSessions[0].live_note_taking,
      Booking.Course.CourseSessions ?? [],
      Booking.Course.CourseSessions[0].id
    ))
  })

  if (props.searchValue !== '' && props.searchValue !== null) {
    const searchRes = rows.filter(row => {
      return (
        row.course_name.toLowerCase().includes(props.searchValue.toLowerCase().trim()) ||
        row.course_code.toLowerCase().includes(props.searchValue.toLowerCase().trim())
      )
    },
    )
    if (searchRes.length !== 0) {
      rows = searchRes;
    }
    else {
      return (
        <Box mb={props.mb}>
        <TableContainer>
          <Table aria-label="Student table">
            <TableHead>
            <StyledTableHead>
              <StyledCellHead width="25%">Course Name</StyledCellHead>
              <StyledCellHead width="20%">Course ID</StyledCellHead>
              <StyledCellHead width="25%">Start Date & Time</StyledCellHead>
              <StyledCellHead width="20%">Services</StyledCellHead>
              <StyledCellHead width="10%">Sessions</StyledCellHead>
            </StyledTableHead>
            </TableHead>
            <TableBody>
                <StyledTableRow style={{ height: 52 * 5 }}>
                  <TableCell colSpan={12}>
                    <Box textAlign={'center'}>
                      No courses found
                    </Box>
                  </TableCell>
                </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      )
    }
  }
  else {
    rows = rows
  }

  return (
    <Box mb={props.mb}>
      <TableContainer>
        <Table aria-label="Student table">
          <TableHead>
            <StyledTableHead>
              <StyledCellHead width="25%">Course Name</StyledCellHead>
              <StyledCellHead width="20%">Course ID</StyledCellHead>
              <StyledCellHead width="30%">Start Date & Time</StyledCellHead>
              <StyledCellHead width="15%">Services</StyledCellHead>
              <StyledCellHead width="10%">Sessions</StyledCellHead>
            </StyledTableHead>
          </TableHead>
          <TableBody>
            {rows.length === 0 ?
              <React.Fragment>
                <StyledTableRow style={{ height: 52 * 5 }}>
                  <TableCell textAlign='center' colSpan={12}>
                    <Box textAlign='center'>
                      <Typography>No booking available</Typography>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            :
              <React.Fragment>
              {stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Row studentId={props.values.studentId} path={props.path} key={row.id} row={row} />
              ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 52 * emptyRows }}>
                  <TableCell colSpan={6} />
                </StyledTableRow>
              )}
              </React.Fragment>
            }
          </TableBody>
          {rows.length > 5
          ?
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={12}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
          : null
            }
        </Table>
      </TableContainer>
    </Box>
  );
};

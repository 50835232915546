import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import logo from '../img/habitat-logo.svg';
import { CircularProgress, FilledInput, FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import loginService from '../services/loginService'
import { useHistory  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    margin:{
      marginBottom: theme.spacing(4),
    },
    loginbox:{
      marginBottom:theme.spacing(4),
      padding:theme.spacing(4.75,3,5.75),
      boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.2);'
    },
    header:{
        fontFamily:theme.typography.averta,
        fontSize:'18px',
        lineHeight:'24px',
        color:theme.primary,
        opacity:'0.3',
    },
    subheader:{
      fontSize:'34px',
      lineHeight:'36px',
      color:'#333333',
      fontFamily:theme.typography.ppWoodland,
    },
    intro:{
      color:'#888',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      fontSize:'14px',
      lineHeight:'16px',
      fontWeight:'500',
      letterSpacing:'1.25px',
      padding:theme.spacing(1.25,2),
      background:'#ffffff',
      color:theme.palette.text.emphasis1,
      borderRadius:'5rem',
      minWidth:'161px',
      '&:hover': {
        background:'#ffffff',
        color:theme.palette.text.emphasis1,
      },
    },
    remind:{
      fontSize:'16px',
      lineHeight:'24px',
      letterSpacing:'0.5px',
      color:"#000000",
      textDecoration:'underline',
    }, 
    message:{
      margin:theme.spacing(1),
      color:theme.palette.error.main,
      fontWeight:500,
  },
  }));
  

const{post} = loginService()

export default function CreateUser({ setToken }) {
    let history = useHistory();

  const [message, setMessage] = React.useState('');
  const [loader, setLoader] = useState(false);

    async function handleSubmit(event){
      setMessage('');
      setLoader(true)
      event.preventDefault();
      if(values.password !== values.password2){
        setMessage('Passwords don\'t match!')
        setTimeout(() => setMessage(''), 5000);
        setLoader(false)
      }
      else{
        const { data } = await post("create_user",{
          name:values.first_name+" "+values.last_name,   
          first_name:values.first_name,
          last_name:values.last_name,
          email:values.email,
          password:values.password,
          college_name:values.college_name,
          campus:values.campus.length !== 0 ?values.campus:'main'
        })
        setMessage(data.message);

        if(data[0]?.remember_token){
         setLoader(false);
         setToken(data[0]?.remember_token);
         history.push("/");
        }
      }
    }

    const [values, setValues] = React.useState({
      first_name:'',
      last_name:'',
      email:'',
      college_name:'',
      campus:'',
      password:'',
      password2:'',
      showPassword: false,
    });

    const [error, setError] = React.useState({
      first_name:false,
      last_name:false,
      email:false,
      college_name:false,
      campus:false,
      password:false,
      password2:false,
      showPassword: false,
    });

    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const classes = useStyles();
    return (
     <Container component="main" maxWidth="xs">
     <Box className={classes.loginbox} bgcolor="#fbfbfa" borderRadius={24}>
        <div className={classes.paper}>
          <Box mb={1.4} display="flex" flexGrow={1} width="100%">
              <img src={logo} width="55" height="56" alt="Habitat Logo" />
              <Box ml={2.1}>
                  <Typography component="span" variant="subtitle1" className={classes.header}>
                      Welcome to Habitat
                  </Typography>
                  <Typography component="h1" variant="subtitle2" className={classes.subheader}>
                      Create account
                  </Typography>
              </Box>
          </Box>
          <Box mb={1}>
            <Typography className={classes.intro} component="p">
              Please fill in the form
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="first_name">First name</InputLabel>
            <FilledInput
              variant="filled"
              type="text"
              required
              disableUnderline
              id="first_name"
              label="First name"
              onChange={e => setValues({...values,'first_name':e.target.value})} 
              name="first_name"
              autoComplete="first_name"
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="name">Last name</InputLabel>
            <FilledInput
              variant="filled"
              type="text"
              required
              disableUnderline
              id="last_name"
              label="Last name"
              onChange={e => setValues({...values,'last_name':e.target.value})} 
              name="Last name"
              autoComplete="last_name"
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <FilledInput
              variant="filled"
              type="email"
              required
              disableUnderline
              id="email"
              label="Email Address"
              onChange={e => setValues({...values,'email':e.target.value})} 
              name="email"
              autoComplete="email"
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="name">College name</InputLabel>
            <FilledInput
              variant="filled"
              type="text"
              required
              disableUnderline
              id="college_name"
              label="College name"
              onChange={e => setValues({...values,'college_name':e.target.value})} 
              name="College name"
              autoComplete="college_name"
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel htmlFor="name">Campus</InputLabel>
            <FilledInput
              variant="filled"
              type="text"
              disableUnderline
              id="campus"
              label="Campus"
              onChange={e => setValues({...values,'campus':e.target.value})} 
              name="Campus"
              autoComplete="campus"
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
              <InputLabel htmlFor="password">Password</InputLabel>
                <FilledInput
                variant="filled"
                disableUnderline
                required
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={e => setValues({...values,'password':e.target.value})} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton 
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
            </FormControl>
            <FormControl fullWidth className={classes.margin} variant="filled">
              <InputLabel htmlFor="password">Confirm password</InputLabel>
                <FilledInput
                variant="filled"
                disableUnderline
                required
                  id="password2"
                  label="Confirm password"
                  name="password2"
                  autoComplete="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password2}
                  onChange={e => setValues({...values,'password2':e.target.value})} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton 
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
            </FormControl>
            <Typography align="center" className={classes.message}>
                {message}
            </Typography>
            <Box mt={1.375} mb={4.25} textAlign="center">
              <Button
                  type="submit"
                  variant="contained"
                  align="center"
                  color="primary"
                  className={classes.submit}
                >
                  {loader
                  ?<CircularProgress color="secondary" size={24}/>
                  :'Create account'
                  }
              </Button>
            </Box>
            <Box mt={1.375} mb={4.25} textAlign="center">
            
            <Link href="#" onClick={() => { history.goBack(); }} className={classes.remind}>
                    Go back           
            </Link>
            </Box>
          </form>
         </div>
       </Box>
      </Container>
    );
  }
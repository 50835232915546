import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    courseSelect: {
        gridRowStart:'1',
        gridRowEnd:'2',
        gridColumnStart:'2',
        gridColumnEnd:'-1',
        alignSelf: 'end',
        justifySelf: 'center',
        marginBottom: '0.25rem',
        width: 250,
        background: '#FBFBFA',
        boxShadow: '0px 7px 26px rgba(0, 0, 0, 0.2)',
        borderRadius: '68px',
        padding: theme.spacing(1.75, 3.75),
        transition: 'all 0.25s ease-in-out',
        opacity: 0,
        pointerEvents: 'none',
        // [theme.breakpoints.down('sm')]: {
        //     position: 'relative',
        //     transform: 'unset',
        //     left: 'unset',
        //     top: 'unset',
        //     gridArea: 'content',
        //     placeSelf: "end",
        //     margin: "1rem 12%",
        // },
    },
    courseSelectActive: {
        opacity: 1,
        transition: 'all 0.75s ease-in-out',
        pointerEvents: 'initial',
    },
    courseSelectBtn: {
        border: 'solid 1px gray',
        '&:disabled': {
            border: 'solid 1px rgba(0, 0, 0, 0.26)',
        },
    },
    courseSelectNumber: {
        fontFamily: theme.typography.averta,
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
    },
}));

function handleClick(ref,props){
    useEffect(()=>{
        function handleClickOutside(event){
            if(ref.current && !ref.current.contains(event.target))
            {
                props.handleClick('course')
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    },[ref])
}

export default function CourseSelect(props) {
    const wrapperRef = useRef(null)
    handleClick(wrapperRef,props)
    const classes = useStyles();

    return (

        <Grid ref={wrapperRef} spacing={2} justifyContent="space-between" alignItems='center' className={clsx(classes.courseSelect, props.CourseSelect ? classes.courseSelectActive : null)} item container>
            <Grid item>
                <IconButton disabled={props.DecreaseCourse} onClick={() => props.onSetCourseQuantity(props.CourseQuantity - 1)} className={classes.courseSelectBtn} aria-label="Decrease number of courses">
                    <RemoveIcon fontSize="small" />
                </IconButton>
            </Grid>
            <Grid item>
                <Typography className={classes.courseSelectNumber} variant="body1" component="p">
                    {props.CourseQuantity}
                </Typography>
            </Grid>
            <Grid item>
                <IconButton disabled={props.IncreaseCourse} onClick={() => props.onSetCourseQuantity(props.CourseQuantity + 1)} className={classes.courseSelectBtn} aria-label="Increase number of courses">
                    <AddIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    )
}
import React, { useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Box, Checkbox, MenuItem, Popover, Typography } from '@material-ui/core';
import { CalendarTodayOutlined, KeyboardArrowDownRounded } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';



export default function BookingSessions({ session, index, onHandleSessionUpdate, components, validateFields, bookingData,services }) {
    const StyledTextField = components.StyledTextField
    const PopupButton = components.PopupButton

    let data = { ...session }

    const [errors, setErrors] = React.useState({
        date: validateFields(data.date, 'date', 'date', bookingData),
        start_time: validateFields(data.start_time, 'date', 'start_time', data),
        end_time: validateFields(data.end_time, 'date', 'end_time', data),
        // services: validateFields(data.services, 'select'),
        // location: validateFields(data.location, 'select'),
        // username: validateFields(data.username, 'text'),
        // password: validateFields(data.password, 'text'),
        // mp_link: validateFields(data.mp_link, 'text'),
    })

    const [anchorEl, setAnchorEl] = React.useState({
        date: null,
        duration: null,
        services: null,
        location: null,
        username: null,
        password: null,
        mp_link: null,
    });

    const [id, setId] = React.useState(undefined)

    const handleClick = (event) => {
        let anchor = event.currentTarget.name
        setAnchorEl({ ...anchorEl, [anchor]: event.currentTarget });
        setId(event.currentTarget.name)
    };


    const handleClose = () => {
        setAnchorEl({
            date: null,
            duration: null,
            services: null,
            location: null,
            username: null,
            password: null,
            mp_link: null,
        });
        setId(undefined)
    };

    const handleSessionDate = (e, name) => {
        switch (name) {
            case 'date':
                data.date = e
                setErrors({ ...errors, [name]: validateFields(e, 'date', name, bookingData) })
                onHandleSessionUpdate(data, index)
                break;
            case 'start_time':
                data.start_time = e
                errors.start_time = validateFields(e, 'date', 'start_time', data)
                errors.end_time = validateFields(data.end_time, 'date', 'end_time', data)
                setErrors({ ...errors })
                onHandleSessionUpdate(data, index)
                break;
            case 'end_time':
                data.end_time = e
                errors.end_time = validateFields(e, 'date', 'end_time', data)
                errors.start_time = validateFields(data.start_time, 'date', 'start_time', data)
                onHandleSessionUpdate(data, index)
                break;
            default:
                break;
        }
    }

    const handleCheckboxChange = (e) => {
        data.checked = e.target.checked
        onHandleSessionUpdate(data, index)
    }

    const handleSelectChange = (e) => {
        let elem = e.target.name
        data[elem] = e.target.value
        setErrors({ ...errors, [elem]: validateFields(e.target.value, 'select') })
        onHandleSessionUpdate(data, index)
    }

    const handleInputChange = (e) => {
        let current = data
        let elem = e.target.name
        current[elem] = e.target.value
        setErrors({ ...errors, [elem]: validateFields(e.target.value, 'text') })
        onHandleSessionUpdate(current, index)
    }

    useEffect(() => {
        let current = data
        let errArr = Object.values(errors)
        let totalErr = errArr.filter(error => error == true)
        current.errors = totalErr.length
        onHandleSessionUpdate(current, index)
    }, [errors])

    const openDate = Boolean(anchorEl.date);
    const openDuration = Boolean(anchorEl.duration);
    const openServices = Boolean(anchorEl.services);
    const openLocation = Boolean(anchorEl.location);
    const openUsername = Boolean(anchorEl.username);
    const openPassword = Boolean(anchorEl.password);
    const openMp_link = Boolean(anchorEl.mp_link);

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    style={{ padding: 'unset' }}
                    checked={data.checked}
                    onChange={handleCheckboxChange}
                    color='primary'
                    inputProps={{ 'aria-label': 'Select instance' }}
                    size='small'
                />
            </TableCell>
            <TableCell>
                {index + 1}
            </TableCell>
            <TableCell>
                <PopupButton name="date" id={'date' + index} onClick={handleClick}>
                    <Typography style={{ fontSize: '0.9rem' }} color={errors.date || !data.date ? 'error' : 'inherit'}>
                        {data.date
                            ? data.date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' })
                            : 'Invalid Date'
                        }
                    </Typography>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openDate}
                    anchorEl={anchorEl.date}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '180px' }}
                                inputVariant='outlined'
                                size='small'
                                format='dd/MM/yy'
                                name="date"
                                label='Date'
                                error={errors.date}
                                keyboardIcon={<CalendarTodayOutlined />}
                                onChange={date => handleSessionDate(date, 'date', index)}
                                value={data.date}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </Popover>
            </TableCell>
            <TableCell>
                <PopupButton name="duration" id='duration' onClick={handleClick}>
                    <Typography style={{ fontSize: '0.9rem' }} size='small' color={errors.start_time || errors.end_time || !data.start_time || !data.end_time ? 'error' : 'inherit'}>
                        {data.start_time
                            ? data.start_time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
                            : 'Invalid start time'
                        }
                        { ' - ' }
                        {data.end_time
                            ? data.end_time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
                            : 'Invalid end time'
                        }
                    </Typography>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openDuration}
                    anchorEl={anchorEl.duration}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box display='flex' gridGap={8} p={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                style={{ width: '140px' }}
                                value={data.start_time}
                                inputVariant='outlined'
                                size='small'
                                format='HH:mm'
                                name="start_time"
                                label='Start time'
                                invalidDateMessage='Blammo'
                                error={errors.start_time}
                                keyboardIcon={<CalendarTodayOutlined />}
                                onChange={date => handleSessionDate(date, 'start_time')}
                                helperText={errors.start_time ? 'Invalid end time' : null}
                                FormHelperTextProps={{ style: { margin: '0.25rem 0' } }}
                            />
                            <KeyboardTimePicker
                                style={{ width: '140px' }}
                                value={data.end_time}
                                inputVariant='outlined'
                                size='small'
                                format='HH:mm'
                                name="end_time"
                                label='End time'
                                error={errors.end_time}
                                keyboardIcon={<CalendarTodayOutlined />}
                                onChange={date => handleSessionDate(date, 'end_time')}
                                helperText={errors.start_time ? 'Invalid start time' : null}
                                FormHelperTextProps={{ style: { margin: '0.25rem 0' } }}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </Popover>
            </TableCell>
            <TableCell>
                {/* INF - Passing styles to nested props - SelectProps */}
                <PopupButton name="services" id={'services' + index} onClick={handleClick}>
                    <StyledTextField error align='left' name="services_alt" disabled inputProps={{ readOnly: true }} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: () => null, style: { color: errors.services ? 'crimson' : 'inherit', fontSize: '0.9rem' } }} value={data.services ?? ""} fullWidth size='small' select label="">
                        <MenuItem disabled value="">Select the services</MenuItem>
                        {services.map(service => <MenuItem key={service.value} value={service.value}>{service.name}</MenuItem>)}
                    </StyledTextField>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openServices}
                    anchorEl={anchorEl.services}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={3}>
                        <StyledTextField name="services" error={errors.services} onChange={handleSelectChange} value={data.services ?? ''} style={{ width: 248 }} size='small' variant='outlined' select label="Services">
                            <MenuItem disabled value="">Select the services</MenuItem>
                            {services.map(service => <MenuItem key={service.value} value={service.value}>{service.name}</MenuItem>)}
                        </StyledTextField>
                    </Box>
                </Popover>
            </TableCell>
            {/* <TableCell>
                <PopupButton name="location" id={'location' + index} onClick={handleClick}>
                    <StyledTextField align='left' name="location_label" disabled inputProps={{ readOnly: true }} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: () => null, style: { color: errors.location ? 'crimson' : 'inherit', fontSize: '0.9rem' } }} value={data.location ?? ''} fullWidth size='small' select label="">
                        <MenuItem disabled value="">Select a location</MenuItem>
                        <MenuItem value="Loc1">Location 1</MenuItem>
                        <MenuItem value="Loc2">Location 2</MenuItem>
                        <MenuItem value="Loc3">Location 3</MenuItem>
                        <MenuItem value="No location">No location</MenuItem>
                    </StyledTextField>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openLocation}
                    anchorEl={anchorEl.location}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={3}>
                        <StyledTextField error={errors.location} name="location" onChange={handleSelectChange} value={data.location ?? ''} style={{ width: 248 }} size='small' variant='outlined' select label="Services">
                            <MenuItem disabled value="">Select a location</MenuItem>
                            <MenuItem value="Loc1">Location 1</MenuItem>
                            <MenuItem value="Loc2">Location 2</MenuItem>
                            <MenuItem value="Loc3">Location 3</MenuItem>
                            <MenuItem value="No location">No location</MenuItem>
                        </StyledTextField>
                    </Box>
                </Popover>
            </TableCell> */}
            {/* <TableCell>
                <PopupButton name="username" id={'username' + index} onClick={handleClick}>
                    <Typography size='small' color={errors.username ? 'error' : 'inherit'}>
                        {errors.username ? 'Field required' : data.username}
                    </Typography>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openUsername}
                    anchorEl={anchorEl.username}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={3}>
                        <StyledTextField name="username" error={errors.username} onChange={handleInputChange} value={data.username} size="small" label="Username" variant="outlined" />
                    </Box>
                </Popover>
            </TableCell> */}
            {/* <TableCell>
                <PopupButton name="password" id={'password' + index} onClick={handleClick}>
                    <Typography size='small' color={errors.password ? 'error' : 'inherit'}>
                        {errors.password ? 'Field required' : data.password}
                    </Typography>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openPassword}
                    anchorEl={anchorEl.password}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={3}>
                        <StyledTextField error={errors.password} name="password" onChange={handleInputChange} value={data.password} size="small" label="Password" variant="outlined" />
                    </Box>
                </Popover>
            </TableCell> */}
            {/* <TableCell>
                <PopupButton name="mp_link" id={'mp_link' + index} onClick={handleClick}>
                    <Typography size='small' color={errors.mp_link ? 'error' : 'inherit'}>
                        {errors.mp_link ? 'Field required' : data.mp_link}
                    </Typography>
                    <KeyboardArrowDownRounded />
                </PopupButton>
                <Popover
                    id={id}
                    open={openMp_link}
                    anchorEl={anchorEl.mp_link}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={3}>
                        <StyledTextField error={errors.mp_link} inputProps={{ readOnly: true }} name="mp_link" onChange={handleInputChange} value={data.mp_link} size="small" label="MP Link" variant="outlined" />
                    </Box>
                </Popover>
            </TableCell> */}
        </TableRow>
    )
}
import React from 'react'
import 'date-fns';
import { Container, FormGroup, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import PageTitle from '../../Common/PageTitle'
import TitleLine from '../../Common/TitleLine'
import CourseTile from '../BookingCommon/CourseTile';
import TextInput from '../BookingCommon/TextInput';
import BookingBtn from '../BookingCommon/BookingBtn';
import Subtitle2 from '../../Common/Subtitle2';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import CustomCheckbox from '../../Common/Checkbox';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import CourseProgress from '../BookingCommon/CourseProgress';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    /* Need to make this into global component */
    Button: {
        fontSize: '0.5rem',
        padding: theme.spacing(0.25),
        border: 'solid 1px gray',
        '&:disabled': {
            border: 'solid 1px rgba(0, 0, 0, 0.26)',
        },
    },
    Icon: {
        fontSize: '1.25rem',
    },
}));

export default function CourseServices(props) {

    const data = props.course.dataCourse()
    const current = props.course.getCurrent()
    let { path, url } = useRouteMatch();
    const history = useHistory()
    //const data = JSON.parse(localStorage.getItem('bookingData'))


    const [values, setValues] = React.useState(current.services ?? {
        NotesRecipients: [],
        TranscriptionRecipient: []
    });

    const [checked,setChecked] = React.useState(0)
    const [error,setError] = React.useState(false)

    const classes = useStyles();

    const setServicesTypes = (user, org) => {
        let services = []
        if (user && org && org.length) {
            if (org[0]?.team?.teams_service_types && org[0]?.team?.teams_service_types.length != 0) {
                org[0]?.team.teams_service_types.map(service => services.push({
                    value: service.service_type_name ? service.service_type_name.trim().replaceAll(' ', '_').toLowerCase() : 'Unknown Service Type!',
                    name: service.service_type_name ? service.service_type_name.trim() : 'Unknown Service Type!',
                }))
            }
            else{
                services = [{ value: 'Live_captions', name: 'Live Captions' }, { value: 'Live_notes', name: 'Live Notes' }, { value: 'Captions_notes', name: 'Captions and Notes' }]
            }
        }
        return [{name:'live_captions',label:"Live Captions"},{name:'summary_notes',label:'Live Summary Notes'}]
    }

    const [notesRecipients, setNotesRecipients] = React.useState(values.NotesRecipients?.length ?? 0)
    const [transcriptRecipients, setTranscriptRecipients] = React.useState(values.TranscriptionRecipient?.length ?? 0)
    const [services, setServices] = React.useState(setServicesTypes(data, props.user))


    const checkboxHandler = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.checked });
        setError(false)
        if(e.target.checked === true){
            setChecked(checked + 1)
        }
        else{
            setChecked(checked - 1)
        }
    }

    // const [redirect, setRedirect] = React.useState(null)
    const handleUpdate = (e) => {
        if(checked === 0){
            setError(true)
            e.preventDefault()
            return;
        }
        props.course.setCurrnet({ ...current, services: values, status: 3 })
        // localStorage.setItem('bookingData',JSON.stringify(values));
        setRedirect(`./CourseCredentials`);
    }
    const handleChange = (prop, i) => (e) => {
        if (values[e.target.name] == undefined) {
            values[e.target.name] = []
        }
        values[e.target.name][i] = e.target.value.toLowerCase();
        setValues({ ...values, [e.target.name]: values[e.target.name] });

    }
    function setRedirect(redirect) {
        history.push(redirect)
        // return <Redirect to={redirect}/>
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <form onSubmit={handleUpdate}>
                    <Box mt={16}>
                        <Grid container justifyContent="center" spacing={3}>
                            <Grid item xs={12} md={4}>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box mb={3.125}>
                                    <PageTitle mb={2.5} color="accent" text='Services' />
                                    <TitleLine text="What services does this course require?" />
                                    <Subtitle2 text="*Transciption is included with live captions.  " />
                                    <FormGroup>
                                    {services.map(service => <CustomCheckbox key={service.name} name={service.name} onCheckboxHandler={checkboxHandler} label={service.label} value={values[service.name] || false} />)}
                                    </FormGroup>
                                    {error? <Typography color='error'>Select at least one service!</Typography> :null}
                                </Box>
                                <Box mb={3.125}>
                                    <TitleLine text="Add additional Notes recipients email (optional)" />
                                    {Array.from(Array(notesRecipients)).map((dum, index) =>
                                        <Box mb={2} key={('NotesRecipients' + (index + 1))}>
                                            <TextInput type="email" placeholder="Add a recipient email" name={'NotesRecipients'} id={('notes_recipients' + (index + 1))} onHandleChange={e => handleChange(e, index)} key={(index + 1).toString()} value={(values.NotesRecipients ? values.NotesRecipients[index] : "") || ''} />
                                        </Box>
                                    )}
                                    <IconButton onClick={() => setNotesRecipients(notesRecipients + 1)} className={classes.Button} aria-label="Add recipients">
                                        <AddIcon className={classes.Icon} />
                                    </IconButton>
                                </Box>
                                <Box mb={3.125}>
                                    <TitleLine text="Add additional Transcript recipients email (optional)" />
                                    {Array.from(Array(transcriptRecipients)).map((dum, index) =>
                                        <Box mb={2} key={('TranscriptionRecipient' + (index + 1))}>
                                            <TextInput type="email" placeholder="Add a recipient email" name={'TranscriptionRecipient'} id={('transcription_recipient' + (index + 1))} onHandleChange={e => handleChange(e, index)} key={(index + 1).toString()} value={(values.TranscriptionRecipient ? values.TranscriptionRecipient[index] : "") || ''} />
                                        </Box>
                                    )}
                                    <IconButton onClick={() => setTranscriptRecipients(transcriptRecipients + 1)} className={classes.Button} aria-label="Add recipients">
                                        <AddIcon className={classes.Icon} />
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CourseProgress course={props.course} />
                            </Grid>
                            <Box mb={4}>
                                <BookingBtn type="submit" text="Proceed" />
                            </Box>
                        </Grid>
                    </Box>
                </form>
            </Container>
        </React.Fragment>
    );
};
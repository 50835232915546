import React from 'react';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

/* INF - Switch example */
const useStyles = makeStyles((theme) => ({
    button:{
        background:(props) => {
            switch(props.variant){
                case 'light':
                    return theme.palette.background.surface2;
                case 'accent':
                    return theme.palette.accent.purple;
                case 'accentOutlined':
                    return 'unset';
                default :
                    return theme.palette.background.onSurface;
            }
        },
        color:(props) => {
            switch(props.variant){
                case 'light':
                    return theme.palette.text.dark.body;
                case 'accent':
                    return theme.palette.text.light.onSecondaryHighEmphasis;
                case 'accentOutlined':
                    return theme.palette.accent.purple;
                default :
                    return theme.palette.text.light.onSecondaryHighEmphasis;
            }
        },
        border:(props) => {
            switch(props.variant){
                case 'accentOutlined':
                    return 'solid 2px'+theme.palette.accent.purple;
                default :
                    return 'unset';
            }
        },
        /* INF - styles ternary operator example */
        textTransform:props => props.noUppercase
            ? 'unset'
            : 'unset',
        borderRadius:24,
        display:'inline-flex',
        alignItems:'center',
        padding:theme.spacing(1.25,2.25),
        '&:disabled':{
            filter:'brightness(0.9)',
            color:'white',
        },
        '&:hover':{
            filter:'brightness(1.1)',
            background:(props) => {
                switch(props.variant){
                    case 'light':
                        return theme.palette.background.surface2;
                    case 'accent':
                        return theme.palette.accent.purple;
                    case 'accentOutlined':
                        return theme.palette.accent.purple;
                    default :
                        return theme.palette.background.onSurface;
                }
            },
             color:(props) => {
                switch(props.variant){
                    case 'light':
                        return theme.palette.text.dark.body;
                    case 'accent':
                        return theme.palette.text.light.onSecondaryHighEmphasis;
                    case 'accentOutlined':
                        return theme.palette.text.light.onSecondaryHighEmphasis;
                    default :
                        return theme.palette.text.light.onSecondaryHighEmphasis;
                }
            },
        },
    },
    btnText:{
        fontWeight:400,
        fontSize:'1rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
    },
}));

export default function PillButton(props) {
    const classes = useStyles(props);
    return (
        <Button
        form={props.form}
        component={props.component}
        to={props.to}
        disabled={props.disabled}
        type={props.type??null}
        className={classes.button}
        onClick={props.onHandleUpdate}
        startIcon={props.loadState
        ?<CircularProgress color="inherit" size={16} />
        :null
        }
        endIcon={props.noIcon
        ? null
        : <ArrowDropDownIcon fontSize="large" className={classes.btnIcon} />
        }>
            <Typography className={classes.btnText}>{props.loadState?props.loadText??props.text:props.text}</Typography>
        </Button>
    );
  }
import React from 'react';
import { Container, Grid } from '@material-ui/core';
import CourseSelection from './CourseSelection';
import BookProcessHeader from './BookingProcessHeader';
import BookingBtn from '../BookingCommon/BookingBtn';
import { Box } from '@material-ui/core';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PillButton from '../../Common/Buttons/PillButton'


export default function CourseSelect(props) {
    const history = useHistory()
    const [message, setMessage] = React.useState(null);
    // useEffect(
    //     () => {
    //       setTimeout(() => setMessage(''), 5000);
    //     },
    //     [message]
    // );


    const data = props.course.dataCourse()
    const [values ,setValues] = React.useState(data);
    const [courses, setCourses] = React.useState();

    const [invalidFields, setInvalidFields] = React.useState({});

    const [courseQuantity, setCourseQuantity] = React.useState(data.courseQuantity);

    const courseCallback = (props,id) =>{
        setValues({...values,[id]:props});
        setCourses({...courses, [id]:props});
    }

    //const [redirect, setRedirect] = React.useState(null)

    const handleUpdate = (e) =>{
        for (const [key, value] of Object.entries(courses)) {
            if(value.course_name === "" && value.isExisting === false){
                setMessage('Please select the courses')
            }
            else{
                props.course.setData(values)                
                props.course.selectCurrent(1)
               // localStorage.setItem('bookingData',JSON.stringify(values));
                setRedirect(`./CourseDetails`);
            }
        }
    }
    const handleSessions = (e) =>{
        props.course.setData(values)                
        props.course.selectCurrent(1)
       // localStorage.setItem('bookingData',JSON.stringify(values));
        setRedirect(`./CourseSessions`);
    }
    const removeCourse = (e,i)=>{
        var list =  values;
       // delete list[i]

       for(var s=i;s<values.courseQuantity;s++){
           if(list['Course'+(s+1)]){
             list['Course'+s] =  list['Course'+(s+1)];
             delete list['Course'+(s+1)]
           } 
       }

        setValues(values,  list)
        setCourseQuantity(courseQuantity - 1)
        setValues({...values,'courseQuantity':courseQuantity - 1})
    }
    const addCourse = (e)=>{
        var list =  values;
        list['Course'+(courseQuantity+1)]={
            course_code:'',
            course_name:'',
            id:'',
            isExisting:false,
            status:0
        };
        setValues(values,  list)
        setCourseQuantity(courseQuantity + 1)
        setValues({...values,'courseQuantity':courseQuantity + 1})
    }
    function setRedirect(redirect){
        history.push(redirect)
        return;
      //  return <Redirect to={redirect} course={props.course}/>
    }
    return (
    <React.Fragment>
        <Container maxWidth="xl">
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} md={4}>
                    <BookProcessHeader />
                    {[...Array(courseQuantity)].map((e, index) => <CourseSelection onHandleUpdate={handleSessions} courseValues={values} courseData={data} courseQuantity={courseQuantity} onRemoveCourse={w=>removeCourse(w,(index+1))} onCourseCallback={courseCallback}  id={('Course'+(index+1)).toString()} modalContent={('Course'+(index+1)).toString()} key={('Course'+(index+1)).toString()} num={index+1} user={props.user} />)}
                    {/* <Box mt={4} mb={4}>
                        <PillButton onHandleUpdate={()=>addCourse()} text="Add another course" noIcon variant="accent"/> 
                    </Box> */}
                    <Box mt={4} mb={4}>
                        <Box m={1}>
                            <Typography color="error" align="center" variant="h6" component="p">
                                {message}
                            </Typography>
                        </Box>
                        <Typography align="center">
                         <BookingBtn onHandleUpdate={handleUpdate} text="Proceed"/>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </React.Fragment>
    );
  }
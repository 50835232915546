import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Badge } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    titleLine:{
        marginTop: (props) => props.mt ? theme.spacing(props.mt) : null,
        marginBottom: (props) => props.mb ? theme.spacing(props.mb) : null,
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        letterSpacing:'0.15px',
        fontFamily:theme.typography.averta,
        color:(props) => {
            switch(props.color){
                case 'accent':
                    return theme.palette.accent.purple;
                default :
                    return theme.palette.text.dark.black;
            }
        },
        fontWeight:700,
        marginBottom:theme.spacing(1.375),
    },
    badge:{
        marginRight:theme.spacing(1),
        display:'inline-block',
        width:8,
        height:8,
        verticalAlign:'middle',
        '& .MuiBadge-badge':{
            background:theme.palette.accent.gold,
        },
    }
}));

export default function TitleLine(props) {
    const classes = useStyles(props);
    return (
        <Typography align={props.textAlign} className={classes.titleLine}>            
            {props.required
                ? <Badge color="primary" className={classes.badge} variant="dot"></Badge>
                : null
            }
            {props.text}
        </Typography>
    );
  }
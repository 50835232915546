import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button } from '@material-ui/core';
import IconPerson from '../../img/icon-person.svg';


const useStyles = makeStyles((theme) => ({
    pillBtn:{
        borderRadius:'68px',
        minWidth:325,
        padding:theme.spacing(4,5,5),
        textTransform:'unset',
        '&:nth-of-type(2)':{
            minWidth:300,
        },
        '&:hover, &.active':{
            background:'unset',
            boxShadow:'0px 7px 26px rgba(0, 0, 0, 0.2);',
        },
        [theme.breakpoints.down('sm')]: {
            padding:theme.spacing(4,1,5),
            minWidth:'unset',
            flex:'1 1 auto',
        },
    },
    btnSub:{
        fontFamily:theme.typography.averta,
        fontSize:'1.125rem',
        lineHeight:'1.5rem',
        color:theme.palette.text.emphasis1,
    },
    btnMain:{
        fontFamily:theme.typography.averta,
        fontWeight:'bold',
        fontSize:'2.125rem',
        lineHeight:'2.25rem',
        color:theme.palette.text.black,
    },
}))




export default function SelectedStudent(props){


    const classes = useStyles();

    return(
        
    <Button className={classes.pillBtn} 
    disableRipple
    onClick={props.onStudentSelect}
    >
    <Box display="flex" alignItems="center">
    <img src={IconPerson} width="44" height="44" alt="Person Icon" />
        <Box textAlign="left">
            <Typography className={classes.btnSub}>
                Who are we booking?
            </Typography>
            <Typography className={classes.btnMain}>
                {props.currentName === "" ? 'Student': props.currentName}
            </Typography>
        </Box>
    </Box> 
    </Button>
    )
}
import React from 'react';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    studentListItem:{
        padding:theme.spacing(1,0,0.2),
        margin:theme.spacing(0,0,0.5),
    },
}))
 
export default function CourseEntry(props){
    const data = props;
    const classes = useStyles();

    return(
        <ListItem
        button
        dense
        divider
        disableGutters
        onClick={props.onHandleCourseSelect(data)}
        id={props.id}
        course_name={props.course_name}
        course_code={props.course_code}
        className={classes.studentListItem}
        >
          {props.course_name}
        </ListItem>
    );
};
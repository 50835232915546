import 'date-fns';
import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles } from '@material-ui/core';

/* REV - Change to use prop's value further down the line*/

const useStyles = makeStyles((theme) =>({
  input: {
    maxWidth: (props) => props.limitWidth ? 175 : null,
    margin:'unset',
  },
}));

export default function DatePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState(moment(props.value,'DD/MM/YYYY').format("DD/MM/YYYY"));
  const classes = useStyles(props);

  const onDateChange = (date, value) => {
    setSelectedDate(date);
    setInputValue(value)
    props.onHandleDateChange(value,props.id);
  };

  useEffect(()=>{
    setInputValue(props.value)
  },[props])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
        className={classes.input}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id={props.id}
          disabled={props.disabled}
          readOnly={props.readOnly}
          placeholder="DD/MM/YYYY"
          value={selectedDate}
          inputValue={inputValue}
          onChange={onDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          rifmFormatter={str => {return str;}}
        />
    </MuiPickersUtilsProvider>
  );
}

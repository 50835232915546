import React, { useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery, gql } from '@apollo/client';
import XLSXTemplate from '../public/Template.xlsx'
import { makeStyles } from '@material-ui/styles';
import { Grid, Container, Box, Button, Typography, Link, withStyles, AppBar, Tabs, Tab, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import PageTitle from '../components/Common/PageTitle'
import BulkDropzone from '../components/BulkUpload/bulkDropzone';
import BulkTable from '../components/BulkUpload/bulkTable';
import BulkStudentTable from '../components/BulkUpload/bulkStudentTable';
import { AddCircleOutlineOutlined, ArrowBack, GetApp } from '@material-ui/icons';
import loginService from '../services/loginService';
import BulkComplete from '../components/BulkUpload/BulkComplete';

const checkUsers = gql`
query checkUsers($emails: [String!]!) {
    User(where: {email: {_in: $emails}}) {
      email
      first_name
      last_name
      role_id
      id
      Students(order_by: {created_at: asc}) {
        academic_email
        user_id
        id
      }
      Instructors(order_by: {created_at: asc}) {
        user_id
        id
      }
    }
  }  
`

const tsUpsertUser = gql`
mutation TsAddStudent($objects: [users_insert_input!]!) {
    insert_users(objects: $objects, on_conflict: {constraint: users_email_unique, update_columns: []}) {
        returning {
          id
          first_name
          last_name
        }
      }
    }
`;

const upsertCourse = gql`
mutation AddCourses($courses: [Course_insert_input!]!) {
    insert_Course(objects: $courses,on_conflict:{constraint:Course_course_code_end_day_start_day_course_name_key,update_columns:[updated_at]}) {
      returning {
        id
        organisation_id
        created_at
      }
    }
  }
`;

const getSubjects = gql`
query subjectsQuery($parent_id:uuid) {
    Subject(where: {_or:[{source: {_eq: "Beehive"},parent_id: {_eq: $parent_id}},{source:{_eq:"ConnExcel"}}]}) {
      id 
      category 
      name
      source
    }
}`;

function errorMessage(error) {
    if (!error) {
        return 'An error occured'
    }
    let msg = error.toString()
    console.log(error, 'Error message')
    switch (msg) {
        case 'Error: Uniqueness violation. duplicate key value violates unique constraint \"CourseSession_start_time_end_time_tz_course_id_key\"':
            return 'Existing session with same date, start time and/or end time found in the database, please review the sessions.' + msg;
        default:
            return 'A following error occured while creating courses, please contact administrator: ' + msg
    }
}


function queryData() {
    const query1 = useQuery(getSubjects, {
        variables: {
            parent_id: process.env.REACT_APP_DEFAULT_SUBJECT_PARENT_ID
        },
    });
    return query1.data?.Subject
}


export default function bulkUpload({ user }) {


    const timeZones = Intl.supportedValuesOf('timeZone').filter(tz => tz.includes('America') || tz.includes('Europe') || tz.includes('Asia'))

    const { post } = loginService()

    const [checkUsersQuery] = useLazyQuery(checkUsers, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            let flag
            let courses = []
            let tsUsers = []
            let ins
            let stu
            rows.forEach(row => {
                let usrFound = data.User.find(User => User.email == row.stu_email)
                if (usrFound && usrFound?.Students?.length != 0) {
                    stu = usrFound
                }
                let course = {
                    course_code: row.course_code,
                    course_name: row.course_name,
                    start_day: row.start_date.toISOString().split('.')[0],
                    end_day: row.end_date.toISOString().split('.')[0],
                    platform: row.course_location,
                    organisation_id: row.institution,
                    CourseSessions: {
                        data: row.sessions.map(session => {
                            return {
                                start_time: sessionTime(session.date, session.start_time),
                                end_time: sessionTime(session.date, session.end_time),
                                tz: row.timezone,
                                live_captioning: row.services == 'Live_captions' || row.services == 'Captions_notes',
                                live_note_taking: row.services == 'Live_notes' || row.services == 'Captions_notes' || row.services != null,
                            }
                        }),
                        // Check if session exists
                        // on_conflict: {
                        //     constraint: 'CourseSession_start_time_end_time_tz_course_id_key',
                        //     update_columns: ['updated_at']
                        // }
                    },
                }
                if (typeof row.course_subject === 'object') {
                    course.subject_ids = row.course_subject.id
                    course.subject_names = row.course_subject.name
                }
                else {
                    course.subject_names = row.course_subject
                }
                switch (row.course_format) {
                    case 'person':
                        course.platform = 'In person'
                        course.platform_url = null
                        course.platform_id = null
                        course.platform_location = row.course_location ?? ''
                        break;
                    case 'online':
                        course.platform = row.course_platform ?? ''
                        course.platform_url = row.course_link.toString().replace(/\s/g, '') ?? ''
                        course.platform_id = row.course_platform ?? ''
                        course.platform_location = row.course_platform ?? ''
                        break;
                    case 'blended':
                        //row.course_platform + ' / In person'
                        course.platform = row.course_platform ?? ''
                        course.platform_url = row.course_link.toString().replace(/\s/g, '') ?? ''
                        course.platform_id = row.course_platform ?? ''
                        course.platform_location = row.course_location ?? ''
                        break;
                    default:
                        course.platform = row.course_platform ?? ''
                        course.platform_url = row.course_link.toString().replace(/\s/g, '') ?? ''
                        course.platform_id = row.course_platform ?? ''
                        course.platform_location = row.course_platform ?? ''
                }
                if (row.ins_email) {
                    let ins = []
                    let newIns = []
                    row.ins_email.split(' ').forEach((email, index) => {
                        let em = data.User.find(User => User.email == email)
                        if (em) {
                            ins.push(em)
                        }
                        else {
                            newIns.push({ email: email, index: index })
                        }
                    })
                    if (data.User.length && ins.length > 0) {
                        if (data.User[0].Students.length) {
                            setAlert({
                                message: `Following user is registered as Student and cannot be an Instructor: ${data.User[0].email.toString()}`,
                                severity: 'error'
                            })
                            flag = true
                        }
                        else {
                            course.CourseInstructors = {
                                data: ins.map(ins => {
                                    return {
                                        Instructor: {
                                            data: {
                                                id: ins.Instructors[0].id,
                                                user_id: ins.Instructors[0].user_id
                                            },
                                            on_conflict: {
                                                constraint: 'Instructor_pkey',
                                                update_columns: ['updated_at']
                                            }
                                        }
                                    }
                                }),
                                on_conflict: {
                                    constraint: 'CourseInstructor_course_id_instructor_id_key',
                                    update_columns: ['updated_at']
                                }
                            }
                        }
                    }
                    else if (newIns.length > 0) {
                        newIns.map(ins => {
                            course.CourseInstructors = {
                                data: {
                                    Instructor: {
                                        data: {
                                            User: {
                                                data: {
                                                    role_id: '70c5272c-22f2-4744-9421-bca96f4335f1',
                                                    first_name: row.ins_firstName.split(' ')[ins.index] ?? 'N/A',
                                                    last_name: row.ins_lastName.split(' ')[ins.index] ?? 'N/A',
                                                    organisation_id: row.institution,
                                                    email: ins.email,
                                                },
                                                on_conflict: {
                                                    constraint: 'User_email_key',
                                                    update_columns: ['updated_at']
                                                }
                                            }
                                        },
                                        on_conflict: {
                                            constraint: 'Instructor_pkey',
                                            update_columns: [],
                                        }
                                    }
                                }
                            }
                        })
                        // Add intructor to TS db, not required atm, we'll see later
                        // let instructorTS = {
                        //     email: row.ins_email.toLowerCase().trim(),
                        //     first_name: row.ins_firstName.trim(),
                        //     last_name: row.ins_lastName.trim(),
                        //     password: process.env.REACT_APP_STUDENT_DEFAULT_PASSWORD
                        // }
                        // tsUsers.push(instructorTS)
                    }
                }
                if (data.User.length && stu) {
                    if (stu.Instructors.length > 0) {
                        setAlert({
                            message: 'Following user is registered as Instructor and cannot be a Student: ' + stu.email.toString(),
                            severity: 'error'
                        })
                        flag = true
                    }
                    course.CourseStudents = {
                        data: {
                            Student: {
                                data: {
                                    id: stu.Students[0].id,
                                    user_id: stu.Students[0].user_id
                                },
                                on_conflict: {
                                    constraint: "Student_pkey",
                                    update_columns: ["updated_at"]
                                }
                            }
                        },
                        on_conflict: {
                            constraint: "CourseStudent_course_id_student_id_key",
                            update_columns: ['student_id', 'course_id']
                        }
                    }
                }
                else {
                    course.CourseStudents = {
                        data: {
                            Student: {
                                data: {
                                    academic_email: row.stu_email.toLowerCase().trim().replace(/[<>;]+/g, ''),
                                    User: {
                                        data: {
                                            role_id: 'a2f699ca-fa3b-4ff0-a19c-afcc55d3ef15',
                                            organisation_id: row.institution,
                                            email: row.stu_email.toLowerCase().trim().replace(/[<>;]+/g, ''),
                                            first_name: row.stu_firstName.trim(),
                                            last_name: row.stu_lastName.trim(),
                                        },
                                        on_conflict: {
                                            constraint: "User_email_key", update_columns: ["updated_at"],
                                        }

                                    }
                                },
                                on_conflict: {
                                    constraint: "Student_pkey",
                                    update_columns: ["updated_at"],
                                    where: {
                                        academic_email: { _eq: row.stu_email.toLowerCase().trim().replace(/[<>;]+/g, '') }
                                    }
                                }
                            }
                        },
                        on_conflict: {
                            constraint: 'CourseStudent_course_id_student_id_key',
                            update_columns: [],
                        },
                    }
                    if (!tsUsers.some(e => e.email === row.stu_email.toLowerCase().trim())) {
                        let studentTS = {
                            email: row.stu_email.toLowerCase().trim().replace(/[<>;]+/g, ''), // I still hate regex, goddamnit
                            first_name: row.stu_firstName.trim(),
                            last_name: row.stu_lastName.trim(),
                            password: process.env.REACT_APP_STUDENT_DEFAULT_PASSWORD
                        }
                        tsUsers.push(studentTS)
                    }
                }
                courses.push(course)
            })
            // Submit Preventer
            // setAlert({
            //     message: errorMessage('Submit prevented'),
            //     severity: 'success'
            // })
            // setSubmitLoad(false)
            // console.log(courses,tsUsers)
            // return;
            if (flag) {
                setSubmitLoad(false)
                return;
            }
            AddCourses({
                variables: {
                    courses: courses
                }
            })
            if (tsUsers.length) {
                console.log(tsUsers, 'Users for TS')
                UpsertUserTS({
                    context: {
                        uri: process.env.REACT_APP_TS_GRAPHQL_ENDPOINT,
                        headers: {
                            'x-hasura-admin-secret': process.env.REACT_APP_TS_HASURA_KEY,
                        }
                    },
                    variables: {
                        objects: tsUsers
                    }
                })
            }
        },
        onError: (err) => {
            console.log('Check users error: ', err)
            setAlert({
                message: errorMessage(err).concat(' ERR-001'),
                severity: 'error'
            })
            setSubmitLoad(false)
        }
    })

    const [AddCourses] = useMutation(upsertCourse, {
        onCompleted: (data) => {
            console.log(data, 'Add courses successfull')
            let uId = new Set(data.insert_Course.returning.flatMap((x, index) => x.id))
            let tsCalls = Array.from(uId).map((uId, index) => createTSCourse(uId, index).then(response => {
                return response.data
            }))

            Promise.all(tsCalls).then((data) => {
                if (data.map(x => x.error).filter(x => x === true).length > 0) {
                    let errors = data.filter(x => x.error == true).map(x => x.message)
                    console.log('TS Api errors: ', errors)
                    setTSErrors(errors)
                    setAlert({
                        message: 'Courses added but there were some errors',
                        severity: 'warning',
                    })
                    setCompleted(true)
                }
                else {
                    console.log(data, 'TS Success')
                    setCompleted(true)
                }
            })
        },
        onError: (err) => {
            console.log(err, 'Add courses error')
            setSubmitLoad(false)
            setAlert({
                message: errorMessage(err).concat(' ERR-002'),
                severity: 'error',
            })
        }
    });

    const [UpsertUserTS] = useMutation(tsUpsertUser, {
        onCompleted: data => {
            console.log(data, 'TS No error data')
        },
        onError: err => {
            console.log(err, 'TS error')
            setAlert({
                message: errorMessage(err).concat(' ERR-003'),
                severity: 'warning',
            })
        }
    })

    async function createTSCourse(element, index) {
        let services
        let params
        if (rows[index].userServices && rows[index].userServices.length > 0 && rows[index].services === 'Computerized_notes') {
            services = rows[index].userServices
        }
        //Passing params object to post service doesn't work, so this monstrosity has to work for now, no refunds xd
        if (services && services.length > 0) {
            params = "create_ts_booking?sms_course_id=" + element + "&sms_booker_id=" + user.aorg_id + "&team_id=" + services[0].team.id + "&service_type=" + services[0].team.teams_service_types[0].service_type_name
        }
        else {
            params = "create_ts_booking?sms_course_id=" + element + "&sms_booker_id=" + user.aorg_id
        }
        const { data } = await post(params, null, 3)
        if (data?.success) {
            return { data }
        } else if (data?.errorMessage || data.error) {
            return { data }
        }
    }

    let subjects = queryData()

    const manualBooking = () => {
        setFiles('Manual')
    }

    function sessionTime(date, time) {
        let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), 0, 0)
        let offset = (newDate.getTimezoneOffset() * 60000)
        return new Date(newDate - offset).toISOString().split('.')[0];
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(10),
            background: '#F3F9F6',
        },
        info: {
            background: theme.palette.background.white,
        },
        paper: {
            padding: theme.spacing(7),
            background: '#ffffff',
        },
        buttonWrapper: {
            gap: theme.spacing(3),
        },
        tabsHead: {
            background: 'unset',
            boxShadow: 'unset',
            '& .indicator': {
                color: 'crimson',
                background: 'crimson',
            }
        },
        tabs: {
            fontFamily: theme.typography.averta,
            color: theme.palette.text.dark.body,
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            minWidth: 160,
            fontWeight: 'bold',
            '&indicator': {
                color: 'crimson',
                background: 'crimson',
            },
        },
        input: {
            marginBottom: theme.spacing(3),
            '& input:read-only + fieldset': {
                border: 'unset',
                outline: 'unset',
            }
        },
        divider: {
            marginBottom: theme.spacing(7),
        },
        infoBox: {
            padding: theme.spacing(3, 8),
        },
        header: {
            fontFamily: theme.typography.averta,
            fontWeight: 'bold',
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.15px',
            color: '#818181',
            marginBottom: theme.spacing(1),
        },
        subheader: {
            fontFamily: theme.typography.averta,
            fontWeight: 'bold',
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.15px',
            color: '#3C3C3C',
        },
        divider: {
            marginBottom: theme.spacing(2.75),
        },
        label: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.15px',
            marginBottom: theme.spacing(1),
            textTransform: 'uppercase',
            color: '#979797', //Primary 900?
            display: 'inline-block',
        },
        formRow: {
            gap: theme.spacing(4.25),
        },
        tableElements: {
            gap: '1.5rem',
        },
    }));


    const StyledTabHead = withStyles((theme) => ({
        root: {
            backgroundColor: 'unset',
        },
    }))(AppBar);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`bulk-tabpanel-${index}`}
                aria-labelledby={`bulk-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `bulk-tab-${index}`,
            'aria-controls': `bulk-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addBooking = () => {
        let current = [...rows]
        let newRow = createData()
        current.push(newRow)
        setRows([...current])
    }

    const deleteBooking = (e) => {
        let current = [...rows]
        current.splice(e, 1)
        setRows([...current])
    }

    const setTime = (h, m) => {
        let date = new Date()
        date.setHours(h, m, 0, 0)
        return (date)
    }

    const genKey = () => {
        return 'key' + Math.ceil(Math.random() * 694201337)
    }

    function createSession(date, start_time, end_time, services, location, username, password, mp_link) {
        if (!date) {
            date = new Date()
        }
        if (!start_time) {
            let st = new Date()
            st.setHours(8, 30)
            start_time = st
        }
        if (!end_time) {
            let et = new Date()
            et.setHours(12, 30)
            end_time = et
        }
        if (!services) {
            services = 'Live_captions'
        }
        if (!location) {
            location = 'No location'
        }
        if (!username) {
            username = 'Username'
        }
        if (!password) {
            password = 'Password'
        }
        if (!mp_link) {
            mp_link = 'MPLink'
        }
        let checked = false
        let key = genKey()
        return { date, start_time, end_time, services, location, username, password, mp_link, key, checked }
    }

    function createSessions(start_date, end_date, start_time, end_time, repeats, services) {
        if (typeof start_date != 'object' || !start_date || typeof end_date != 'object' || !end_date || typeof start_time != 'object' || !start_time || typeof end_time != 'object' || !end_time) {
            return [];
        }
        else {
            let sessions = []
            // These date objects, grrr
            let date = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate())
            let e_date = new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate())
            if (!repeats || repeats.length === 0) {
                repeats = start_date.getDay()
            }
            else {
                repeats = repeats[0]
            }
            if (repeats != start_date.getDay()) {
                let diff = start_date.getDay() - repeats
                // Difference between Days of week between Start Date and First Day of Course
                if (diff > 0) {
                    date.setDate(date.getDate() + (7 - diff))
                }
                else {
                    date.setDate(date.getDate() + Math.abs(diff))
                }
            }
            while (e_date - date >= 0) {
                sessions.push(createSession(new Date(date.valueOf()), start_time, end_time, services))
                date.setDate(date.getDate() + 7)
            }
            return sessions
        }
    }

    function createData(
        stu_firstName,
        stu_lastName,
        stu_email,
        institution,
        services,
        course_format,
        course_platform,
        course_link,
        course_location,
        course_name,
        course_code,
        course_crn,
        course_subject,
        start_date,
        end_date,
        repeats,
        start_time,
        end_time,
        timezone,
        ins_firstName,
        ins_lastName,
        ins_email,
        sessions
    ) {
        if (services === "" || !services) {
            if (institution) {
                let inst = user.duorg_id.filter(org => org.organisation_id === institution)[0]
                if (inst && inst.services.length > 0) {
                    services = inst.services[0].team.teams_service_types[0].service_type_name.toLowerCase().replaceAll(' ', '_')
                }
                else {
                    services = 'Live_captions'
                }
            }
            else if (user.available_tasks && user.available_tasks === 'cnt') {
                services = 'Computerized_notes'
            }
            else {
                services = 'Live_captions'
            }
        }
        if (!start_date) {
            start_date = new Date()
        }
        if (!end_date) {
            end_date = new Date()
        }
        if (!start_time) {
            let st = new Date()
            st.setHours(8, 30)
            start_time = st
        }
        if (!end_time) {
            let et = new Date()
            et.setHours(12, 45)
            end_time = et
        }
        if (!sessions) {
            sessions = createSessions(start_date, end_date, start_time, end_time, repeats, services)
        }
        if (!course_format) {
            course_format = ''
        }
        else {
            if (course_format.toLowerCase().includes('blended')) {
                course_format = 'blended'
            }
            else if (course_format.toLowerCase().includes('person')) {
                course_format = 'person'
            }
            else if (course_format.toLowerCase().includes('online')) {
                course_format = 'online'
            }
        }
        let key = genKey()
        let open = false
        let errors = 0
        return {
            stu_firstName,
            stu_lastName,
            stu_email,
            institution,
            services,
            course_format,
            course_platform,
            course_link,
            course_location,
            course_name,
            course_code,
            course_crn,
            course_subject,
            start_date,
            end_date,
            repeats,
            start_time,
            end_time,
            timezone,
            ins_firstName,
            ins_lastName,
            ins_email,
            sessions,
            key,
            open,
            errors,
        };
    }

    const [alert, setAlert] = React.useState({
        message: null,
        severity: null,
    })

    const [rows, setRows] = React.useState([])

    const resetEdit = () => {
        setRows([])
        setFiles(null)
        setLoader(false)
    }

    const updateRows = (e, index) => {
        let newRows = rows
        newRows[index] = e
        setRows(newRows)
    }

    const duplicateBooking = (index, data) => {
        let booking = { ...data }
        booking.key = genKey()
        let newRows = [...rows]
        newRows.splice(index + 1, 0, booking)
        setRows(newRows)
    }

    const daySet = (day) => {
        if (!day) {
            return ['1']
        }
        else {
            let d = day.toLowerCase()
            switch (d) {
                case 'sunday':
                    return ['0']
                case 'monday':
                    return ['1']
                case 'tuesday':
                    return ['2']
                case 'wednesday':
                    return ['3']
                case 'thursday':
                    return ['4']
                case 'friday':
                    return ['5']
                case 'saturday':
                    return ['6']
                default:
                    return null
            }
        }
    }

    const timetoDate = (date) => {
        if (date) {
            let time = date.split(' ')
            if (time[1] && time[1].toLowerCase() === 'pm') {
                if (parseInt(time[0].split(':')[0]) === 12) {
                    return setTime(parseInt(time[0].split(':')[0]), parseInt(time[0].split(':')[1]))
                }
                else {
                    return setTime(parseInt(time[0].split(':')[0]) + 12, parseInt(time[0].split(':')[1]))
                }
            } else {
                return setTime(parseInt(time[0].split(':')[0]), parseInt(time[0].split(':')[1]))
            }
        }
        else {
            return new Date()
        }
    }

    const selectCollegeInfo = (c, t, cam) => {
        if (c) {
            let orgs = user.duorg_id
            let filter = []
            if (cam) {
                let filterOrg = []
                orgs.forEach(ins => {
                    if (ins.Organisation.name.includes(c)) {
                        filterOrg.push(ins)
                    }
                })
                if (filterOrg.length === 0) {
                    filterOrg = orgs
                }
                filterOrg.forEach(ins => {
                    if (ins.Organisation.department.includes(cam)) {
                        filter.push(ins)
                    }
                })
            }
            else {
                c.split(/[\. \,-\;]/).forEach(c => {
                    orgs.forEach(ins => {
                        if (ins.Organisation.name.includes(c)) {
                            filter.push(ins)
                        }
                    })
                })
            }
            if (filter.length) {
                switch (t) {
                    case 'name':
                        return filter[0].organisation_id
                    case 'tz':
                        return filter[0].Organisation.tz
                    default:
                        return filter[0].organisation_id
                }
            }
            else {
                return ''
            }
        }
        else {
            return ''
        }
    }

    const filterValue = (v, t) => {
        if (v) {
            switch (t) {
                case 'service':
                    switch (v.toLowerCase().trim()) {
                        case 'live captions':
                            return 'Live_captions'
                        case 'live notes':
                            return 'Live_notes'
                        case 'captions and notes':
                            return 'Captions_notes'
                        case 'computerized notes':
                            return 'Computerized_notes'
                        default:
                            return v.toLowerCase().trim().replaceAll(' ', '_')
                    }
                case 'subject':
                    if (subjects) {
                        return subjects.filter(sub => sub.name.toLowerCase() === v.toLowerCase())[0]
                    }
                    else {
                        return ''
                    }
                default:
                    return ''
            }
        }
        else {
            return ''
        }
    }

    const matchTimezone = (timeZone) => {
        if (!timeZone) return null
        else return timeZones.filter(tz => tz.toLowerCase() === timeZone.toLowerCase().trim())[0]
    }

    const setInstructor = (v, t) => {
        if (v) {
            let em = v.toLowerCase().trim().replace(/[<>;]+/g, '')
            let emArr = [];
            em.split(' ').forEach(em => {
                emArr.push(em)
            })
            let uem = [...new Set(emArr)]
            let emails = []
            let names = []
            uem.forEach(em => {
                if (em.split('@')[0].includes('.')) {
                    names.push(em.split('@')[0].split('.')[0])
                }
                else {
                    names.push(em.split('@')[0])
                }
                emails.push(em)
            })
            if (t === 'name') {
                return names.join(' ')
            }
            else {
                return emails.join(' ')
            }
        }
        else {
            return ''
        }
    }

    const handleDate = (e) => {
        let date = new Date(e)
        if (date.getFullYear().toString() === 'NaN' || date.getFullYear().toString().length != 4 || Math.abs(date.getFullYear() - new Date().getFullYear()) > 5) {
            return new Date('Invalid')
        }
        return date
    }

    const handleFile = (e) => {
        setLoader(true)
        let data = []
        e.forEach(course => {
            data.push(
                createData(
                    course.studentfirstname ?? course.studentsfirstname,
                    course.studentslastname ?? course.studentlastname,
                    course.studentsemail ?? course.studentemail,
                    selectCollegeInfo(course.institution, 'name'),
                    filterValue(course.services, 'service'),
                    course.format,
                    course.platform,
                    course.link,
                    course.location,
                    course.coursename,
                    course.coursecode,
                    course.crn,
                    filterValue(course.subject, 'subject'),
                    handleDate(course.startdate),
                    handleDate(course.enddate),
                    daySet(course.day),
                    timetoDate(course.starttime),
                    timetoDate(course.endtime),
                    matchTimezone(course.timezone),
                    course.instructorfirstname,
                    course.instructorlastname,
                    course.instrutoremail,
                )
            )
        })
        setFiles(e)
        setRows(data)
        setTimeout(() => {
            setLoader(false)
        }, 3500);
        // if (files != undefined) {
        //     setInterval(() => {
        //         setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + Math.floor(Math.random() * 69)));
        //     }, 145);
        // }
        // else {
        //     setLoader(true)
        // }
    }

    const [value, setValue] = React.useState(0);
    const [files, setFiles] = React.useState();
    const [loader, setLoader] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [completed, setCompleted] = React.useState(false);
    const [TSErrors, setTSErrors] = React.useState(null)
    const [errors, setErrors] = React.useState(0)
    const [submitLoad, setSubmitLoad] = React.useState(false)

    useEffect(() => {
        let main = rows.map(row => row.errors)
        let sum = main.reduce((sum, a) => sum + a, 0)
        setErrors(sum)
    }, [rows])

    const confirmBooking = (e) => {
        setSubmitLoad(true)
        let errs = rows.map(row => [row.errors, row.sessions.map(session => session.errors)]).flat(2).reduce((sum, a) => sum + (a === undefined ? 0 : a), 0)

        let emptySessions = rows.map(row => row.sessions.length)

        if (!rows || rows.length === 0) {
            setSubmitLoad(false)
            setAlert({
                message: 'No courses found!',
                severity: 'error'
            })
            return;
        }

        if (emptySessions.indexOf(0) != -1) {
            setSubmitLoad(false)
            setAlert({
                message: 'Course with no sessions found, please add sessions to the course.',
                severity: 'error'
            })
            return;
        }

        if (errs === 0) {
            let emails = []
            rows.forEach(row => {
                if (!emails.includes(row.stu_email.toLowerCase().trim())) {
                    emails.push(row.stu_email.toLowerCase().trim())
                }
                if (row.ins_email) {
                    row.ins_email.split(' ').forEach(email => {
                        if (!emails.includes(email.toLowerCase().trim())) {
                            emails.push(email.toLowerCase().trim())
                        }
                    })
                }
            })
            checkUsersQuery({
                variables: {
                    emails: emails
                },
            })
        }
        else {
            setSubmitLoad(false)
            setAlert({
                message: 'Please fill in all the required fields',
                severity: 'error'
            })
        }
    }

    const classes = useStyles();

    // useEffect(() => {
    //     console.log(TSErrors, 'Task stream errors')
    // }, [TSErrors])

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <Box mt={16}>
                    {completed
                        ?
                        <Box>
                            {TSErrors ?
                                TSErrors.map(er => {
                                    <React.Fragment>
                                        <Typography variant="error"> Some errors were present</Typography>
                                    </React.Fragment>
                                })
                                : null
                            }
                            <BulkComplete />
                        </Box>
                        :
                        <React.Fragment>
                            <Grid container justifyContent="center" spacing={3}>
                                <Grid item xs={12} md={12} lg={10}>
                                    <Box mb={2}>
                                        <PageTitle text='Bulk Booking' />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography mb={2}>Upload your spreadsheet below to start auto-filling, or manually fill in your data.</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Button startIcon={<GetApp />} component={Link} href={XLSXTemplate} download="Booking_Template.xlsx" variant="contained" color="primary" >Download Template</Button>
                                    </Box>
                                    <Box mb={4}>
                                        <StyledTabHead className={classes.tabsHead} position="static">
                                            <Tabs value={value} onChange={handleChange} aria-label="Bulk upload tabs">
                                                <Tab className={classes.tabs} label="Booking" {...a11yProps(0)} />
                                                {/* <Tab disabled className={classes.tabs} label="Manual Booking" {...a11yProps(1)} /> */}
                                            </Tabs>
                                        </StyledTabHead>
                                        <TabPanel value={value} index={0}>
                                            <Box
                                                boxShadow={0}
                                                className={classes.paper}
                                            >
                                                {/* <Box display="flex" gridGap={16} mb={5}>
                                            <Button variant="contained" color='primary' onClick={() => console.log(rows)}>Print rows</Button>
                                        </Box> */}
                                                {files
                                                    ?
                                                    <React.Fragment>
                                                        {loader
                                                            ?
                                                            <Box display='flex' justifyContent='center'>
                                                                <CircularProgress />
                                                            </Box>
                                                            :
                                                            <React.Fragment>
                                                                <Box mb={3}>
                                                                    {/* <BulkTable /> */}
                                                                    <Box mb={2}>
                                                                        <Box mb={2}>
                                                                            <Button disabled={submitLoad} onClick={() => resetEdit()} style={{ textTransform: 'unset' }} startIcon={<ArrowBack />}>Reset booking</Button>
                                                                        </Box>
                                                                        <Box mb={6}>
                                                                            <BulkStudentTable timeZones={timeZones} submitLoad={submitLoad} createSessions={createSessions} addBooking={addBooking} subjects={subjects} user={user} createSession={createSession} onDuplicateBooking={duplicateBooking} onDeleteBooking={deleteBooking} onUpdateRows={updateRows} rows={rows} genKey={genKey} />
                                                                        </Box>
                                                                        <Box display='flex' justifyContent='space-between'>
                                                                            <Button disabled={submitLoad} onClick={() => addBooking()} style={{ textTransform: 'unset' }} startIcon={<AddCircleOutlineOutlined />} color='primary'>Add booking</Button>
                                                                            <Button style={{ textTransform: 'capitalize' }} variant="contained" color="primary" onClick={() => confirmBooking()}>
                                                                                {submitLoad
                                                                                    ? <React.Fragment>
                                                                                        <Box mr={1}>Submitting</Box>
                                                                                        <CircularProgress size={24} color="inherit" />
                                                                                    </React.Fragment>
                                                                                    : 'Submit'
                                                                                }
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                    {/* Reset booking <Box display="flex" justifyContent='center'>
                                                                        <Button style={{ textTransform: 'capitalize' }} variant="contained" color="primary" onClick={() => resetEdit()}>
                                                                            Reset booking
                                                                        </Button>
                                                                    </Box> */}
                                                                    {/* <Box gridGap={16} alignItems='center' display='flex' flexDirection='column'>
                                                                <Button disableElevation variant="contained" color="primary">Proceed with file</Button>
                                                                <Button onClick={() => handleFile()} color="primary">Discard and start again</Button>
                                                            </Box> */}
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <Box>
                                                        <Box mb={3}>
                                                            <BulkDropzone progress={progress} files={files} handleFile={handleFile} />
                                                        </Box>
                                                        <Box textAlign='center'>
                                                            <Button onClick={() => manualBooking()} variant="contained" color="primary">Or start Booking manually</Button>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <Box
                                                boxShadow={0}
                                                className={classes.paper}
                                            >
                                                <Box mb={3}>
                                                    Nobody expects the Spanish inquisition
                                                </Box>
                                            </Box>
                                        </TabPanel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    <Toast alert={alert} />
                </Box>
            </Container>
        </React.Fragment>
    );
}


function Toast(props) {

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (props.alert.message === null || props.alert.severity === null) {
            return;
        }
        else {
            setOpen(true)
        }
    }, [props.alert]);

    return (
        <React.Fragment>
            <Snackbar style={{ maxWidth: 600 }} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.alert.severity}>
                    {props.alert.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
function Alert(props) {
    return <MuiAlert elevation={4} variant="filled" {...props} />;
}

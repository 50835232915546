import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {ReactComponent as LighbulbIcon} from '../../img/icon-lightbulb.svg';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 210,
    borderRadius:12,
  },
}));

export default function StudentPopover(props) {
  const classes = useStyles();
  const open = Boolean(props.popoverState);

  return (
    <div>
      <Popover
        id={props.popoverId}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={props.popoverState}
        anchorOrigin={{
          vertical: props.anchor[0],
          horizontal: props.anchor[1],
        }}
        transformOrigin={{
          vertical: props.origin[0],
          horizontal: props.origin[1],
        }}
        onClose={props.onHandlePopoverClose}
        disableRestoreFocus
      >
            <Grid
            container
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            >
                <Grid item xs={2}>
                    <LighbulbIcon/>
                </Grid>
                <Grid item xs={10}>
                    Student Details
                </Grid>
                <Grid item xs={10}>
                    Click student name to view and edit student information.
                </Grid>
            </Grid>
      </Popover>
    </div>
  );
}
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header:{
        fontSize:'1.5rem',
        lineHeight:'1.5rem',
        fontFamily:theme.typography.averta,
        color:(props) => {
            switch(props.color){
                case 'accent':
                    return theme.palette.accent.purple;
                default :
                    return theme.palette.text.dark.black;
            }
        },
        fontWeight:700,
        marginBottom:theme.spacing(2)
    },
}));

export default function BoldHeading(props) {
    const classes = useStyles();
    return (
        <Typography align={props.textAlign} className={classes.header}>{props.text}</Typography>
    );
  }
import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import StudentPopover from './StudentPopover';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

const StyledTableHead = withStyles((theme) => ({
    root: {
        backgroundColor: '#F3F9F6',
    },
}))(TableRow);
const StyledCellHead = withStyles((theme) => ({
    root:{
     fontFamily:theme.typography.averta,
     fontWeight:'bold',
     fontSize:'1.125rem',
     lineHeight:'1.5rem',
     letterSpacing:'0.15px',
     textTransform:'uppercase',
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FFFFFF',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#FBFBFA',
        },
    },
}))(TableRow);
const StyledRowHead = withStyles((theme) => ({
    body: {
        fontSize: '0.875rem',
        lineHeight:'1rem',
        letterSpacing:'0.1px',
      },
}))(TableCell);

function createData(id, name, institution, email, bookings) {
  return { id, name, institution, email, bookings };
}

const data = [
  createData('1000856763', 'Youl Yi', 'George Brown College', 'youl.yi@email.ca', 'VCC100, CCT109, FAH239'),
  createData('949494', 'Edric Tran', 'British Columbia Insititute of Technology', 'jimin.park@email.ca', 'VCC100'),
  createData('######', 'Grzegorz Brzęczyszczykiewicz', 'George Brown College', 'youl.yi@email.ca', 'VCC100, CCT109, FAH239'),
  createData('691337420', 'John Doe', 'George Brown College', 'youl.yi@email.ca', ''),
  createData('940131', 'Jane Doe', 'George Brown College', 'youl.yi@email.ca', null),
];

var rows = data;

const useStyles2 = makeStyles((theme) => ({
    rowLink: {
      textDecoration:"unset",
      '&:hover':{
          filter:'brightness(1.1)',
      },
    },
  }));


export default function StudentTable(props){

  const createSortHandler = (event) => {
    handleRequestSort(event);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === event && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(event);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [popover, setPopover] = React.useState(null);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.sort);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows, setEmptyRows] = React.useState(rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePopoverOpen = (event) => {
    setPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };
    if (props.searchVal !== '' && props.searchVal !== null){  
      const searchRes = data.filter(row => {
        return(
          row.name.toLowerCase().includes(props.searchVal.toLowerCase().trim())
        )},
      )
      if (searchRes !==""){
        rows = searchRes;
      }
      else{
        return(
          <div>No students found</div>
        )
      }
    }
    else{
      rows = data
    }
    let {path,url} = useRouteMatch();
  return (
      <Box mb={props.mb}>
        <TableContainer>
        <Table aria-label="Student table">
            <TableHead>
            <StyledTableHead>
                <StyledCellHead width="20%">Student ID</StyledCellHead>
                <StyledCellHead width="20%">
                    <Box
                    display="flex"
                    alignItems="center"
                    >
                    Student Name 
                    <InfoOutlinedIcon
                    aria-owns={open ? 'StudentNameInfo' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    />
                    <StudentPopover anchor={['top','right']} origin={['top','left']} popoverId='StudentNameInfo' onHandlePopoverClose={handlePopoverClose} popoverState={popover} />
                    </Box>
                </StyledCellHead>
                <StyledCellHead width="20%">Institution</StyledCellHead>
                <StyledCellHead width="20%">Email</StyledCellHead>
                <StyledCellHead width="20%">Bookings</StyledCellHead>
            </StyledTableHead>
            </TableHead>
            <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                <StyledTableRow key={row.name}>
                <StyledRowHead component="th" scope="row">{row.id}</StyledRowHead>
                <TableCell>
                  <Link component={RouterLink} to={`${url}/ViewStudent`}>
                   {row.name}
                  </Link>
                </TableCell>
                <TableCell>{row.institution}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.bookings ? row.bookings : 'No bookings'}</TableCell>
                </StyledTableRow>
            ))}
            {emptyRows > 0 && (
            <StyledTableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </StyledTableRow>
          )}
            </TableBody>
            <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
        </TableContainer>
    </Box>
  );
};